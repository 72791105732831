import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import './idCard.css'
import swal from "sweetalert";
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'

import { config } from 'src/utils/apiUrl'
import API from 'src/utils/apiCalling';
import { ToastContainer, toast } from 'react-toastify';
// import { config } from '../../utils/apiUrl';

// import ViewSelectedInProgress from '../In-progress/viewSelectedInProgress';
const ModalforUpdateDate = (props) => {
    const { isOpen1, modalUpdateDate, empUserID,getAllIDCardRequest} = props
    let api = new API()
    const [iDCardDetails, setIDCardDetails] = useState("")
    const [fileUpload, setFileUpload] = useState("")
    const [profilePic,setProfilePic] = useState()
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [expectedDate,setExpectedDate] = useState("")

    const onChangeFunctionUpload = (e) => {
        let files = e.target.files
        setFileUpload(files[0]);
    }

    const getAppliedAssetsRequest = async () => {
        let data = {
            emp_user_id : empUserID
        }
        let result = await api.get(config.getAllIDCardDetails,true,data)
        if (result && result.code === 200) {
            setIDCardDetails(result.data[0])
            setProfilePic(result.data[0].employee_passport_size_photo)
        }
    }
    useEffect(() => {
        getAppliedAssetsRequest()
    }, [empUserID])


const isAlertToggle = (msg) => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
};

const updateDate = async () => {
    let data = {
        expected_date : expectedDate,
        emp_user_id : empUserID
    }
    swal({
        html: true,
        title: "Are you sure?",
        text: `You want to accept the ID card request`,
        icon: "success",
        buttons: ["No, cancel it!", "Accept"],
        dangerMode: true,
    }).then(async function (isConfirm) {
        if (isConfirm) {
            let result = await api.post(config.updateExpectedDate,data)
            if (result && result.code === 200) {
                toast.success(result.message)
                modalUpdateDate(!isOpen1)
                getAllIDCardRequest()
            } else {
                toast.warning(result.message);
            }
        }
    });
}


    return (
        <div className="">
  
            <CModal 
                show={isOpen1}
                onClose={() => modalUpdateDate(!isOpen1)}
                size="xs"
            >

                <CModalHeader closeButton>
                    <CModalTitle>Expected Date</CModalTitle>
                </CModalHeader>
                <CModalBody empUserID={empUserID}>
                <div className="">
            <div className="container-fluid" >
            <div className="row">
            <div className="col-sm-2" style={{maxWidth:"50%"}}/>
                <div className="mb-1">
                        <label style={{color:"black",fontSize:"14px"}}><b>Expected Issue Date</b></label>
                        <input style={{color:"black"}} type="date" className="form-control" onChange={(e) => setExpectedDate(e.target.value)} name="date" placeholder="Enter Expected Date"/>
                </div>
                        <div className="mb-1">
                        <button type="submit" className="btn btn-sm btn-primary mr-3 mt-2" onClick={updateDate} >Submit</button>
                        </div>
            </div>
        </div>

</div>


                

                </CModalBody>
            </CModal>
            <ToastContainer />
        </div>

    )
}
export default ModalforUpdateDate