import React from 'react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';
import { isPermission } from 'src/utils/common';
import { CDataTable } from '@coreui/react';

const TourApprovalList = props => {
  const { data, type, renderDataStatus, renderDataActionBtn } = props;
  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: true,
      filter: true,
    },
    {
      label: 'Employee ID',
      key: 'emp_id',
      sorter: true,
      filter: true,
    },
    {
      label: 'Email',
      key: 'email',
      sorter: false,
      filter: false,
    },
    {
      label: 'Date From',
      key: 'applied_from',
      sorter: false,
      filter: false,
    },
    {
      label: 'Date To',
      key: 'applied_to',
      sorter: false,
      filter: true,
    },
    {
      label: 'Tour To',
      key: 'visiting_destination',
      sorter: false,
      filter: false,
    },
    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Created Date',
      key: 'created_at',
      sorter: true,
      filter: true,
    },
    {
      label: '',
      key: 'action',
      sorter: true,
      filter: true,
    },
  ];
  return (
    <CDataTable
    items={data}
    fields={fields}
    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
    itemsPerPageSelect
    itemsPerPage={10}
    hover
    sorter
    pagination
    scopedSlots={{
      status: item => <td>{renderDataStatus(item, type)}</td>,
      applied_to: item => (
        <td>{convertDate(item.applied_to, 'DD-MM-YYYY')}</td>
      ),
      applied_from: item => (
        <td>{convertDate(item.applied_from, 'DD-MM-YYYY')}</td>
      ),
      created_at: item => (
        <td>{convertDate(item.created_at, 'DD-MM-YYYY')}</td>
      ),
      s_no: (item, index) => (
        <td>
          <b>{index + 1}</b>
        </td>
      ),
      reason: (item, index) => (
        <td>
          {item.reason || ''}
        </td>
      ),
      action: item => <td>
      {isPermission('approve_reject_leave_request') && (
        <>{renderDataActionBtn(item, type)}</>
      )}
    </td>,
    }}
  />
  );
};

export default TourApprovalList;
