const getIsAttendanceBasicDetailsPending = state =>
  state.attendance.isGetAttendanceBasicDetailsPending;
const getAttendanceBasicDetailsRes = state =>
  state.attendance.getAttendanceBasicDetailsRes;
const getAttendanceBasicDetailsResError = state =>
  state.attendance.getAttendanceBasicDetailsResError;

const getIsAttendanceLogsWithDurationPending = state =>
  state.attendance.isGetAttendanceLogsWithDurationPending;
const getAttendanceLogsWithDurationRes = state =>
  state.attendance.getAttendanceLogsWithDurationRes;
const getAttendanceLogsWithDurationResError = state =>
  state.attendance.getAttendanceLogsWithDurationResError;

export default {
  getIsAttendanceBasicDetailsPending,
  getAttendanceBasicDetailsRes,
  getAttendanceBasicDetailsResError,

  getIsAttendanceLogsWithDurationPending,
  getAttendanceLogsWithDurationRes,
  getAttendanceLogsWithDurationResError,
};
