import localforage from 'localforage';
import { DB_NAME } from '../configs/BrowserStorage.config';

const config = {
  driver: localforage.INDEXEDDB,
  version: 3,
};

const setupBrowserStorage = () => (localforage.createInstance({ name: DB_NAME, ...config }));

const browserStorage = setupBrowserStorage();

export {
  browserStorage,
};
