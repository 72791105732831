import React, { useState } from 'react'
import {
    CRow,
    CCol,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CCollapse,
    CCard,
    CCardHeader} from '@coreui/react'
import { CardBody } from 'reactstrap';
import Moment from "react-moment";
import API from '../../src/utils/apiCalling';
import { config } from '../../src/utils/apiUrl';



const ViewSelectedAssignedRequisition = (props) => {
    let api = new API()
    let { assignedRequisition, mrfGetById } = props
    const [accordion, setAccordion] = useState(0)
    const [MRF_JD, setMRF_JD] = useState()

    const getJdByMrfId = async () => {
        setAccordion(accordion === 1 ? null : 1)
        let data = {
            mrf_id: assignedRequisition && assignedRequisition.mrf_id,
            applying_for: assignedRequisition && assignedRequisition.applying_for
        }
        let result = await api.get(config.getJdForMrf, true, data)
        if (result && result.code === 200) {
            setMRF_JD(result.data[0])
        } else {
            alert("something wrong....")
        }
    }
  
    return (
        <div className="">
            <CForm action="" encType="multipart/form-data" className="form-horizontal">
                <CRow>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Id</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={assignedRequisition && assignedRequisition.mrf_id} name="mrf_id" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Applying For</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={assignedRequisition && assignedRequisition.applying_for === "vacancy" ? "New Hiring" : assignedRequisition && assignedRequisition.applying_for} name="applying_for" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assigned To</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={assignedRequisition && assignedRequisition.assigned_to} name="assigned_to" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assigned By</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={assignedRequisition && assignedRequisition.assigned_by} name="assigned_by" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assigned At</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={assignedRequisition && assignedRequisition.assigned_at} name="assigned_at" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Status</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.mrf_active_status} name="mrf_active_status" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Head Of Department</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.head_of_department} name="head_of_department" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Designation</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.designation} name="designation" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Min Qualification</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.min_qualification} name="min_qualification" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                    <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Location</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.location} name="location" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >No. Of Position</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.no_of_position} name="no_of_position" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Learning Cost</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.learning_development_cost} name="learning_development_cost" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Experience</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.year_of_experience} name="year_of_experience" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Qualification</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.preffered_qualification} name="preffered_qualification" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Priority</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.priority} name="priority" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Type Of Appointment</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.type_of_appointment} name="type_of_appointment" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Justification</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={mrfGetById && mrfGetById.justification_of_vacancy} name="justification_of_vacancy" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Created At</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <label>
                                    {
                                        <Moment format="DD MMM YYYY HH:mm:ss A">
                                            {mrfGetById && mrfGetById.created_at}
                                        </Moment>} </label>
                            </CCol>
                        </CFormGroup>

                    </CCol>
                </CRow>
                <div className="container">
                    <CCard>
                        <CCardHeader onClick={getJdByMrfId}>
                            <strong>* JOB DESCRIPTION</strong><br/>
                            <small>(Click on Job Description to View)</small>
                        </CCardHeader>

                        <CardBody>
                            <div className=" mb-3">
                                <CCollapse show={accordion === 1}>
                                    <div className="jd">
                                        <strong>Location</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.location}<hr></hr>
                                        <strong>Role Summary</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.role_summary}<hr></hr>
                                        <strong>Responsibilities</strong> &nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.responsibilities}<hr></hr>
                                        <strong>Expirence</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.expirence}<hr></hr>
                                        <strong>Min Education Qualification</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.min_edu_qualification}<hr></hr>
                                        <strong>Preffered Education Qualification</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.preffered_edu_qualification}<hr></hr>
                                        <strong>Certificates</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.certificates}<hr></hr>
                                        <strong>Skill Needed</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.skill_needed}<hr></hr>
                                        <strong>Preffered Domain Knowledge</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.preffered_domain_knowledge}<hr></hr>
                                    </div>

                                </CCollapse>
                            </div>
                        </CardBody>
                    </CCard>
                </div>
            </CForm>

        </div>

    )
}
export default ViewSelectedAssignedRequisition







