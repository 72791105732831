

import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
    CCollapse
} from '@coreui/react'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'
import ViewSelectedHrShortList from './viewSelectedShortlist';

import Moment from "react-moment";
import moment from 'moment'



const HrShortList = (props) => {
    const { } = props
    let api = new API()

    const [mrfRecord, setMrfRecord] = useState()
    const [isSelectedView, setIsSelectedView] = useState(false)
    const [MRFData, setMRFData] = useState()
    const [details, setDetails] = useState([]);

    useEffect(() => {
        getMrfRecord()
    }, [])

    const getMrfRecord = async () => {
        // let data = {
        //     type: "hr",
        //     mrfType : "replacement"
        // }
        let result = await api.get(config.showAssignedMrfForRecruiter)
        if (result && result.code == 200) {
            // console.log("pending requisition", result)

            setMrfRecord(result.data)
        } else {
            // console.log("something wrong")
        }
    }

    const view_selected = (mrf) => {
        setMRFData(mrf)
        setIsSelectedView(!isSelectedView)
    }

    const fields = [
        // { label: "Assest Code (Host Name)", key: "code", _style: { width: "20%" } },
        { label: "MRF ID", key: "mrf_id", _style: { width: "5%" } },
        { label: "Applying for", key: "applying_for", _style: { width: "10%" } },
        { label: "MRF raised by", key: "created_by_name", _style: { width: "10%" }, },
        { label: "Assigned At", key: "assigned_at", _style: { width: "10%" }, },
        { label: "Location", key: "location", _style: { width: "10%" } },

        { label: "status", key: "status", _style: { width: "5%" } },
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
    const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };
    const getBadge = (status) => {
        switch (status) {
            case "0":
                return "success";
            case "Inactive":
                return "secondary";
            case "1":
                return "danger";
            case "Inuse":
                return "danger";
            case "Banned":
                return "danger";
            case "Damage":
                return "warning";
            default:
                return "primary";
        }
    };
    return (
        <div className="">
            {isSelectedView == false &&
                <div className="container">
                    <h3 className="text-uppercase text-center mt-3 mb-4"> <u>List Of Shortlist Requisition</u></h3>

                    <CCard>
                        {/* <CCardHeader>

                    </CCardHeader> */}
                        <CCardBody>
                            <CDataTable
                                items={mrfRecord && mrfRecord.length > 0 && mrfRecord.filter(mrf => {
                                    // console.log(asset.is_assigned)
                                    mrf.assigned_at = moment(mrf.assigned_at).format("DD-MM-YYYY")
                                    mrf.status = mrf.status == "1" ? "Pending" : "Complete"
                                    // if (mrf.applying_for == "vacancy") {
                                    //     mrf.applying_for = "New Hiring"
                                    // }
                                    return mrf
                                })}
                                fields={fields}
                                // columnFilter
                                tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                // footer
                                itemsPerPageSelect
                                itemsPerPage={10}
                                hover
                                sorter
                                pagination
                                scopedSlots={{
                                    status: (item) => (
                                        <td>
                                            <CBadge color={getBadge(item.status)}>
                                                {item.status}
                                            </CBadge>
                                        </td>
                                    ),
                                    show_details: (item, index) => {
                                        return (
                                            <td className="py-2">
                                                <CButton
                                                    color="primary"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    onClick={() => {
                                                        toggleDetails(index);
                                                    }}
                                                >
                                                    {details.includes(index) ? "Hide" : "Show"}
                                                </CButton>
                                            </td>
                                        );
                                    },
                                    details: (item, index) => {
                                        return (
                                            <CCollapse show={details.includes(index)}>
                                                <CCardBody>
                                                    {/* <h4>{item.username}</h4> */}
                                                    <p className="text-muted">
                                                        MRF Type: {item.mrf_type}
                                                    </p>
                                                    <CButton onClick={() => view_selected(item)}
                                                        size="sm"
                                                        color="info"
                                                        className="ml-1"
                                                    >
                                                        View
                                                    </CButton>

                                                </CCardBody>
                                            </CCollapse>
                                        );
                                    },
                                }}
                            />
                        </CCardBody>
                    </CCard>
                    {/* <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">MRF ID</th>
                                <th scope="col">Applying For</th>
                                <th scope="col">MRF Raised By</th>
                                <th scope="col">Assigned At</th>
                                <th scope="col">MRF Final Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {mrfRecord && mrfRecord.map((mrf, index) => (
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{mrf.mrf_id}</td>
                                    <td className="letter">{mrf.applying_for == "vacancy" ? "New Hiring" : mrf.applying_for}</td>
                                    <td>{mrf.created_by_name}</td>
                                    <td>{
                                        <Moment format="YYYY/MM/DD">
                                            {mrf.created_at}
                                        </Moment>}</td>
                                    <td>{mrf.mrf_final_status == 0 ? "Pending" : "Completed"}</td>


                                    <CRow>
                                        <CCol xs="12" md="12">
                                            <CButton data-toggle="tooltip" data-placement="top" title="View" className="btn btn-info mr-0 " onClick={() => view_selected(mrf)}><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                        </CCol>

                                    </CRow>
                                </tr>
                            ))}

                        </tbody>
                    </table> */}



                </div>
            }
            {isSelectedView &&
                <div className="container">
                    <ViewSelectedHrShortList MRFData={MRFData} view_selected={view_selected} />
                </div>
            }

        </div>

    )
}
export default HrShortList







