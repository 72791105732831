/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import Candidate_Library from '../candidate-library';

const HrDashbard = () => {
  useEffect(() => {}, []);
  return (
    <div>
      <Candidate_Library />
    </div>
  );
};
export default HrDashbard;
