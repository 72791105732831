import { createAction } from 'redux-act';

const getAttendanceBasicDetailsRequest = createAction(
  'GET_ATTENDANCE_DETAILS_REQUEST',
  data => data,
);
const getAttendanceBasicDetailsSuccess = createAction(
  'GET_ATTENDANCE_DETAILS_SUCCESS',
  data => data,
);
const getAttendanceBasicDetailsError = createAction(
  'GET_ATTENDANCE_DETAILS_ERROR',
);

const getAttendanceLogsWithDurationRequest = createAction(
  'GET_ATTENDANCE_LOGS_WITH_DURATION_REQUEST',
  data => data,
);
const getAttendanceLogsWithDurationSuccess = createAction(
  'GET_ATTENDANCE_LOGS_WITH_DURATION_SUCCESS',
  data => data,
);
const getAttendanceLogsWithDurationError = createAction(
  'GET_ATTENDANCE_LOGS_WITH_DURATION_ERROR',
);

const clearAttendanceStore = createAction(
  'CLEAR_ATTENDANCE_STORE',
  data => data,
);

export default {
  clearAttendanceStore,

  getAttendanceBasicDetailsRequest,
  getAttendanceBasicDetailsSuccess,
  getAttendanceBasicDetailsError,

  getAttendanceLogsWithDurationRequest,
  getAttendanceLogsWithDurationSuccess,
  getAttendanceLogsWithDurationError,
};
