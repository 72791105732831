/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { TheSidebar, TheHeader } from '../containers/index';
import DateRangePicker from 'src/views/common/dateRangePicker';
import { CustButton } from 'src/reusable';
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CCardFooter,
  CCard,
  CCardBody,
  CDataTable,
  CCol,
  CRow,
} from '@coreui/react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import swal from 'sweetalert';

const GuestDetails = props => {
  const { history } = props;
  const api = new API();
  const [active, setActive] = useState(0);
  const [allguestData, setAllguestData] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fields = [
    { label: 'S.No.', key: 'sno', _style: { width: '2%' } },
    { label: 'Name', key: 'guest_name', _style: { width: '5%' } },
    { label: 'Mobile No.', key: 'mobile', _style: { width: '5%' } },
    { label: 'Meeting With', key: 'name', _style: { width: '5%' } },
    { label: 'No. of Person', key: 'no_of_person', _style: { width: '5%' } },
    {
      label: 'Meeting Duration',
      key: 'meeting_duration',
      _style: { width: '5%' },
    },
    {
      label: 'Purpose',
      key: 'purpose',
      _style: { width: '5%' },
    },
    {
      label: 'Temp Card',
      key: 'temp_card',
      _style: { width: '2%' },
    },
    {
      key: 'created_at',
      label: 'Visited At',
      _style: { width: '5%' },
    },
    {
      key: 'temp_received_at',
      label: 'Card Received',
      _style: { width: '5%' },
    },
    {
      key: 'show_details',
      label: 'Action',
      _style: { width: '2%' },
    },
  ];

  const allguestDetails = async (startDate, endDate) => {
    let data = {
      start_date:
        moment(startDate).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
      end_date:
        moment(endDate).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
    };
    let result = await api.get(config.allguestDetails, true, data);
    if (result && result.code === 200) {
      setAllguestData(result?.data);
    } else {
    }
  };

  const handleOnDateChange = (dates, dateStrings) => {
    if (dates) {
      allguestDetails(dates[0], dates[1]);
      setStartDate(moment(dates[0]).format('YYYY-MM-DD'));
      setEndDate(moment(dates[1]).format('YYYY-MM-DD'));
    }
  };

  useEffect(() => {
    allguestDetails();
  }, []);

  const updateTempDetails = async id => {
    let data = {
      type: 'guest',
      id: id,
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure you recevied temp card`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Received'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updateTempCard, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          allguestDetails();
        } else {
          toast.error(result.message);
        }
      }
    });
  };

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="container mt-3">
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardBody>
                  <CTabs
                    activeTab={active}
                    onActiveTabChange={idx => setActive(idx)}
                  >
                    <div className="col-xl-12 text-right">
                      <label style={{ color: 'darkblue' }}>
                        Select Date Range
                      </label>
                      &nbsp;
                      <DateRangePicker
                        DateStart={setStartDate}
                        DateEnd={setEndDate}
                        onDateChange={handleOnDateChange}
                      />
                    </div>
                    <CNav variant="tabs">
                      <CNavItem>
                        <CNavLink>
                          <b>Guest Details</b>
                          {active === 0}
                        </CNavLink>
                      </CNavItem>
                    </CNav>
                    <CTabContent>
                      <CTabPane>
                        <CDataTable
                          items={allguestData}
                          fields={fields}
                          tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                          itemsPerPageSelect
                          itemsPerPage={10}
                          hover
                          pagination
                          scopedSlots={{
                            sno: (item, index) => <td>{index + 1}</td>,
                            show_details: (item, index) => {
                              return (
                                <td className="py-2">
                                  {item.temp_card !== '' &&
                                    item.temp_received === 0 && (
                                      <CustButton
                                        type="button"
                                        name="btn"
                                        text="Set Received"
                                        size="sm"
                                        color="primary"
                                        onClick={e =>
                                          updateTempDetails(item.id)
                                        }
                                        className="btn-loading btn-ladda ml-1"
                                        icon=""
                                        isDisabled={false}
                                      />
                                    )}
                                </td>
                              );
                            },
                          }}
                        />
                      </CTabPane>
                    </CTabContent>
                  </CTabs>
                </CCardBody>
                <CCardFooter>
                  <CButton
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Back"
                    color="success"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                  </CButton>{' '}
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default GuestDetails;
