import { createAction } from 'redux-act';

const shiftDetailRequestAction = createAction(
  'SHIFT_DETAIL_REQUEST',
  data => data,
);

const shiftDetailSuccessAction = createAction(
  'SHIFT_DETAIL_SUCCESS',
  data => data,
);

const shiftDetailFauilureAction = createAction(
  'SHIFT_DETAIL_FAILURE',
  data => data,
);

export default {
  shiftDetailRequestAction,
  shiftDetailSuccessAction,
  shiftDetailFauilureAction,
};
