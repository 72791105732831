import React, {  } from 'react'
import {
    CModal,
    CModalBody} from '@coreui/react'
import './newyear_popup.css'
import 'react-toastify/dist/ReactToastify.css';

const NewYearPopup = (props) => {
    const { isOpen, isViewModalOpen, setIsOpenContributionSurvey, employeeName } = props

    return (
        <div className="">
            <CModal style={{ background: "none", border: "none" }}
                show={isOpen}
                onClose={() => !isOpen}
                size="lg"
            >
                <CModalBody>
                            <div className="d-flex justify-content-center">
                                <div className="contribution_popup main-card mt-5 pb-5">
                                    <div className="mt-2 p-4 text-center">
                                    <img alt="" src="images/icons/emoji/popper.gif" height={"135px"} width={"135px"}/>
                                        <h2 className="mb-0" style={{ color: "#09386a", textAlign: "center", fontSize:"20px",fontFamily:"cursive" }}>Wishing you a HAPPY NEW YEAR <i style={{color:"#eb7c45"}}>{employeeName}</i> !</h2>
                                        <div className="d-flex flex-row justify-content-center mt-2">
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                    </div>
                                                    <h6 className="px-3 mt-3 text-center" style={{ color: "#E91E63",fontFamily:"system-ui"}}>May it be filled with new adventures and good fortunes..</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </CModalBody>
            </CModal>
        </div>

    )
}
export default NewYearPopup;