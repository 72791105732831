import { put, all, call, takeEvery } from 'redux-saga/effects';
// import { shiftDetailAction as actions, shiftDetailService } from './index';
// import { shiftDetailAction as actions, shiftDetailService } from './index';
import shiftDetailService from './shiftDetail.Service';
import shiftDetailAction from './shiftDetail.Action';

function* handleShiftDetail() {
  let res;
  try {
    res = yield call(shiftDetailService.getShiftDetailApiFun);

    if (res.isError) {
    } else {
      yield put(shiftDetailAction.shiftDetailSuccessAction(res));
    }
  } catch (error) {}
}

function* handleShiftDetailWatcher() {
  yield takeEvery(
    shiftDetailAction.shiftDetailRequestAction,
    handleShiftDetail,
  );
}

function* rootShiftDetailSaga() {
  yield all([handleShiftDetail(), handleShiftDetailWatcher()]);
}

export default rootShiftDetailSaga;
