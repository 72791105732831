import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';

import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ModalFinalJoined from './modal';
import { isPermission } from 'src/utils/common';

const FinalJoined = props => {
  const {} = props;
  let api = new API();

  const [CandidateRecord, setCandidateRecord] = useState();
  const [active, setActive] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingRequisition, setPendingRequisition] = useState();
  const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(
    false,
  );
  const [candidateDetails, setCandidateDetails] = useState();
  const [activeKey, setActiveKey] = useState(1);
  const [large, setLarge] = useState(false);
  const [candidate, setCandidate] = useState();
  const [finalJoined, setFinalJoined] = useState();
  useEffect(() => {
    getFinalJoinedCandidate();
  }, []);

  const getFinalJoinedCandidate = async () => {
    let result = await api.get(config.totalCandidateJoined);
    if (result && result.code == 200) {
      setFinalJoined(result.data);
    } else {
      // console.log("something wrong")
    }
  };

  const isViewModalOpen = candidate => {
    setIsModalOpen(!isModalOpen);
    setCandidateDetails(candidate);
  };

  const sendOfferLatter = candidate => {
    alert('offer later sending');
    setLarge(!large);
    setCandidate(candidate);
  };

  return (
    <div className="">
      <div className="container">
        <h3 className="text-uppercase text-center mt-3 mb-4">
          {' '}
          <u>Final Joined Candidates </u>
        </h3>
        <CRow>
          <CCol xs="12">
            <CCard>
              {/* <CCardHeader>
                                Final Joined:
                            </CCardHeader> */}

              <CCardBody>
                <CTabs
                  activeTab={active}
                  onActiveTabChange={idx => setActive(idx)}
                >
                  {/* <CNav variant="tabs"> */}
                  {/* <CNavItem>
                                            <CNavLink>
                                                Final Joined
                                            </CNavLink>
                                        </CNavItem> */}
                  {/* <CNavItem>
                                            <CNavLink>
                                                OPS Reject
                                            </CNavLink>
                                        </CNavItem> */}

                  {/* </CNav> */}
                  <CTabContent>
                    <CTabPane>
                      <table className="table">
                        <thead>
                          <tr>
                            <>
                              {/* <th scope="col">#</th> */}
                              <th scope="col">ID</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Designation</th>
                              <th scope="col">MRF ID</th>
                              <th scope="col">MRF Applying For</th>
                              <th scope="col">Overall Interview Status</th>
                            </>
                          </tr>
                        </thead>
                        <tbody>
                          {finalJoined &&
                            finalJoined.map((data, index) => (
                              <tr>
                                {/* <th scope="row">{"1"}</th> */}
                                <td>{data.id}</td>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.designation}</td>
                                <td>{data.mrf_id}</td>
                                <td>
                                  {data.mrf_applying_for == 'vacancy'
                                    ? 'New Hiring'
                                    : 'Replacement'}
                                </td>
                                <td>
                                  {data.is_final_selection_in_interv_status == 1
                                    ? ' Interview Selected'
                                    : 'Rejected'}
                                </td>
                                {isPermission('candiate_details_joined') && (
                                  <CRow>
                                    <CCol xs="12" md="12">
                                      <CButton
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View"
                                        className="btn btn-primary mr-0"
                                        onClick={() => isViewModalOpen(data)}
                                      >
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>
                                      </CButton>
                                    </CCol>

                                    {/* <CCol xs="12" md="6">
                                        <CButton  block shape="pill" className="btn btn-danger mr-0" onClick={() => sendOfferLatter(data)}>Final Approval</CButton>
                                    </CCol> */}
                                  </CRow>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </CTabPane>
                    {/* <CTabPane>

                                       
                                         </CTabPane> */}
                  </CTabContent>
                </CTabs>
                <CCardFooter>
                  <CButton
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Back"
                    color="success"
                  >
                    {' '}
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                  </CButton>{' '}
                </CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <ModalFinalJoined
        isModalOpen={isModalOpen}
        message="Final Joined Candidate Details"
        isViewModalOpen={isViewModalOpen}
        candidateDetails={candidateDetails}
      />
    </div>
  );
};
export default FinalJoined;
