import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CImg,
  } from "@coreui/react";
import Lottie from 'react-lottie-player'
import lottieJson from 'src/views/annimation/Arrow-Triplet-red.json'
import './dashboard360.css'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from '../../containers/index'
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CDataTable,
    CBadge,
    CForm,
    CFormGroup,
    CFormText,
    CInput,
    CInputFile,
    CInputRadio,
    CLabel,
    CSelect,
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import MyInfoTopSection from 'src/views/my_info/My_info_top_section';
import UserInfoDoc from 'src/HR-PANEL/offer_accept/user_info_doc';


const AdminDashboard360 = () => {

    const api = new API()
    const [userPersonalInfo, setUserPersonalInfo] = useState();
    const [candidateFullDocumentDetails, setCandidateFullDocumentDetails] = useState()

    useEffect(() => {
        userInfo()
        getDocumentDetails()
        let i;
        for (i = 0; i < 10; i++) {
            if (i == 3) {
                continue
            }
            if(true) {
                // console.log("inside second true", i)
            }
            // console.log("priting", i)
        }
    }, [])
    const userInfo = async () => {
        // console.log("inside user profile")

        let result = await api.get(config.profile)
        if (result && result.data && result.data.length>0) {
            setUserPersonalInfo(result.data[0])
            // console.log("user basic info>>>>>>>>>>>>>>>>>>>>>.", result.data[0])
        }
    }

    const getDocumentDetails = async () => {
        let data = {
            // candidate_email: "",
            // candidate_email: "yogesh.mathur@qdegrees.com"
        }
        let result = await api.get(config.getEmployeeAllPersonalDetails, true, data)
        if (result && result.code == 200) {
            setCandidateFullDocumentDetails(result.data)
        } else {
            // console.log("unable to get document details")
        }
    }
    return (
        <>
         <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />

        <div className="c-body">
  <main className='c-main'>
  <CCardHeader>
    <div className='row'>
        <div className='col-md-12'>
        <div className="main-dd" style={{minHeight:"35px"}}>
      <div className="name nameDashbod">
         <h4>Good Morning, <strong>Vinod Kumar Vijay</strong></h4>
         <CDropdown className="c-header-nav-items mx-2 linkOption" direction="down">
      <CDropdownToggle className="c-header-nav-link px-0" caret={false}>
        <div className="c-avatar">
         <div className='' style={{flexShrink:0, textAlign:"right", marginRight:10}}>
            <p className='mb-0'><span className='d-block '>Today:</span> 
            2022-1213 To 2022-12-13</p>
         </div>
         <img src='images/calender-blue-round.svg' className='' height="40" />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
       
        <CDropdownItem>
          <Link to="/admin-dashboard"> <strong> Today</strong></Link>
        </CDropdownItem>
        <CDropdownItem>
          <Link to="/dashboard/ChangePassword"><strong>Yesterday</strong></Link>
        </CDropdownItem>
        <CDropdownItem>
         <Link to="/dashboard/ChangePassword"><strong>Last 7 Days</strong></Link>
        </CDropdownItem>
        <CDropdownItem>
          <Link to="/dashboard/ChangePassword"><strong>Last 30 Days</strong></Link>
        </CDropdownItem>
        <CDropdownItem>
          <Link to="/dashboard/ChangePassword"><strong>This Month</strong></Link>
        </CDropdownItem>
        <CDropdownItem>
          <Link to="/dashboard/ChangePassword"><strong>Custome Range</strong></Link>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
      </div>
     </div>
        </div>
      
    </div>
   
      </CCardHeader>

         <div className='cardHdng w-100 bg-white'>
  <div className='row'>
    <div className='col-md-5'>
        <h5 className='hdngMn'>HR 360 View Dashbaord</h5>
    </div>
    <div className='col-md-7 '>
        <div className='btnBox text-right'>
            <a href='#' >Workforce Overview</a>
            <a href='#' className='active'>Time & Attendance</a>
            <a href='#'>Headcount</a>
        </div>
    </div>
  </div>
   </div>
   <div className='cardHdng w-100 p-0 py-2'>
  <div className='row'>
    <div className='col-md-8'>
       <div className='bg-white w-100 py-3 px-3 h-100'>
<div className='d-flex ' style={{justifyContent:"space-between"}}>
<h6 class="subHdngg">Employee Turnover</h6>
<div className='d-flex countValu'>
<p><span className='bluB'></span> Leave</p>
<p><span className='orgB'></span> Planned</p>
</div>
</div>
<div className='d-flex align-items-center position-relative pl-5'>
<span className='btnTag'>Result</span>
<div className='w-100'>
    <img src="images/g4.jpeg"  className='w-100'/>
</div>
</div>

       </div>
    </div>
    <div className='col-md-4'>
       <div className='bg-white w-100 p-3 h-100'>
    <h6 class="subHdngg">Leave vs. Planned Score</h6>
    
    <div className='contentpre text-center py-4'>
   <div className='d-flex w-100 align-items-center justify-content-center'>
        <h2>50  </h2>
        <div className='lottieB lottieA'>
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ height: 50 }}
    />
    </div>
   
      
        </div>
        <p>People on Leave <span className='textRed'>Increased</span> This Month</p>
        <hr/>
        <div className='d-flex w-100 align-items-center justify-content-center mb-3'>
      <div>
        <h5>40</h5>
        <p>Days Expected</p>
      </div>
      <h6 className='px-4'>VS.</h6>
      <div>
        <h5>31</h5>
        <p>Days Actual</p>
      </div>
        </div>
        <a href='#' className='btnRond'>Download Report</a>
    </div>
       </div>
    </div>
  </div>
   </div>

   <div className='cardHdng w-100 p-0 py-2'>
  <div className='row'>
    <div className='col-md-4'>
       <div className='bg-white w-100 py-3 px-3 h-100'>
<div className='d-flex ' style={{justifyContent:"space-between"}}>
<h6 class="subHdngg">Overtime</h6>
</div>
<div className='w-100 d-flex align-items-center'>
    <div className='courseBox'>
<div className='d-flex align-items-center position-relative pl-5'>
<span className='btnTag'>Cause</span>
<div className='w-100'>
    <img src="images/g3.svg"  className='w-100'/>
   <p className='text-center mt-3'>% Of Employees During Ea. Shift Working Overtime</p>
</div>
</div>
</div>
<div className='contentpre text-center py-2 contentBxx'>
  <div className='mb-3 w-100'>
      <div>
        <h5>40</h5>
        <p>Weekly Avg Hours Per Employee</p>
      </div>
     <div>
        <h5>31</h5>
        <p>Target</p>
      </div>
        </div>
        <a href='#' className='btnRond'>Download Report</a>
    </div>



</div>

       </div>
    </div>

    <div className='col-md-4'>
       <div className='bg-white w-100 py-3 px-3 h-100'>
<div className='d-flex ' style={{justifyContent:"space-between"}}>
<h6 class="subHdngg">Absence</h6>
</div>
<div className='w-100 d-flex align-items-center'>
    <div className='courseBox w-50'>
<div className='d-flex align-items-center position-relative pl-5'>
<div className='w-100'>
    <img src="images/g4.svg"  className='w-100' style={{maxHeight:"200px"}}/>
</div>
</div>
</div>
<div className='contentpre text-center py-2 contentBxx w-50'>
  <div className='mb-3 w-100'>
      <div>
        <h2>10</h2>
        <p># Of Days Employees Absent This Month</p>
        <div className='countValu '>
<p className='m-0 mb-1 '><span className='bluB '></span> 10 Actual</p>
<p className=''><span className='gryB'></span> 41 Expected</p>
</div>
      </div>
    
        </div>
        <a href='#' className='btnRond'>Download Report</a>
    </div>



</div>

       </div>
    </div>


    <div className='col-md-4'>
       <div className='bg-white w-100 p-3 h-100'>
    <h6 class="subHdngg">Illness By Department</h6>
    
    <div className='contentpre'>
        <p>People Out Ill Over a Month Period</p>
   <div className='w-100'>
    <img src="images/g5.jpeg" className='w-100'/>
   </div>
   <div className='text-center w-100 mt-3'>
        <a href='#' className='btnRond '>Download Report</a>
        </div>
    </div>
       </div>
    </div>
  </div>
   </div>


   <div className='cardHdng w-100 p-0 py-2'>
  <div className='row'>
   
    <div className='col-md-6'>
       <div className='bg-white w-100 p-3 h-100'>
    <h6 class="subHdngg">Productivity In Dept (Mthly)</h6>
    <div className='d-flex align-items-center position-relative pl-5 mt-3'>
<span className='btnTag'>Effect</span>
<div className='w-100'>
    <img src="images/g2.svg"  className='w-100'/>
</div>
</div>
    <div className='contentpre text-center py-2'>
        <div className='d-flex w-100 align-items-center' style={{justifyContent:"space-around"}}>
    <div>
   <div className='d-flex w-100 align-items-center justify-content-center'>
        <h2>9.4% </h2>
        <div className='lottieB lottieC'>
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ height: 50 }}
    />
    </div>
    </div>
        <p className='mb-0'>People on Leave <span className='textblue'>Increased</span> This Month</p>
        </div>
       <a href='#' className='btnRond'>Download Report</a>
       </div>
    </div>
       </div>
    </div>


    <div className='col-md-6'>
       <div className='bg-white w-100 p-3 h-100'>
    <h6 class="subHdngg">Leave Balance</h6>
    <div className='w-100 d-flex '>
    <div className='barSecmn mt-4'>
  <div className='barSec'>
    <div className='barBox' style={{backgroundColor:"#354C9E", width:"100%"}}>126</div>
    <span>0-5 Year</span>
  </div>
  <div className='barSec'>
    <div className='barBox' style={{backgroundColor:"#F47321", width:"40%"}}>54</div>
    <span>5-10 Year</span>
  </div>
  <div className='barSec'>
    <div className='barBox' style={{backgroundColor:"#EC6666", width:"20%"}}>25</div>
    <span>10-15 Year</span>
  </div>
  <div className='barSec'>
    <div className='barBox' style={{backgroundColor:"#FFB13D", width:"41%"}}>55</div>
    <span>15-20 Year</span>
  </div>

  <div className='barSec'>
    <div className='barBox' style={{backgroundColor:"#A4C45D",  width:"85%"}}>110</div>
    <span>20+ Year</span>
  </div>
        
    </div>
    <div className='contentpre contentpre2 text-center py-0'>
   <div className='d-flex w-100 align-items-center justify-content-center'>
        <h2>380  </h2>
        <div className='lottieB lottieA'>
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ height: 50 }}
    />
    </div>
    </div>
        <p>People Leave Balance </p>
        <div className='d-flex w-100 align-items-center justify-content-center mb-0'>
      <div>
        <h5>40</h5>
        <p>Target Of Leave Balance Days Monthly</p>
      </div>
     
        </div>
        <a href='#' className='btnRond'>Download Report</a>
    </div>
    </div>
       </div>
    </div>


  </div>
   </div>


                </main>
             </div>
             
        <TheFooter />
      </div>
    </div>
        </>)
}
export default AdminDashboard360
