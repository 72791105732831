import React, { useState, useEffect } from 'react'
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link
// } from "react-router-dom";

// import {
//     CBadge, CNav,
//     CInputRadio,
//     CNavItem,
//     CNavbar,
//     CListGroup, CListGroupItem,
//     CNavLink,
//     CTabContent,
//     CTabPane,
//     CTabs,
//     CButton,
//     CTextarea,
//     CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
//     CWidgetBrand,
//     CButtonGroup,
//     CWidgetIcon,
//     CCard,
//     CLink,
//     CCardBody,
//     CCardFooter,
//     CInputFile,
//     CCardHeader,
//     CCol,
//     CSwitch,
//     CCollapse,
//     CProgress,
//     CProgressBar,
//     CRow,
//     CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
//     CCallout
// } from '@coreui/react'



const Test = (props) => {

    const [count, setCount] = useState(0)
    useEffect(() => {
        func1().then(function () {
            // console.log("from then", "data")
        }).catch(function () {
            // console.log("from catch", "data")
        })
    }, [])

    const FunctState = () => {
        setCount((val) => val + 1, (val)=>{
            // console.log(val)
        } )
      
   
    }

    function func1() {
        return new Promise(function (resolve, reject) {
            setTimeout(() => {
                const error = false;
                if (!error) {

                    // console.log("your promise has been recolved")
                    resolve();
                } else {
                    // console.log("your promise has not been resolved")
                    reject('sorry not fullfilled')
                }
            }, 2000);
        })
    }


    return (

        <div>
            <h2>test....</h2>
            <button onClick={FunctState}>button</button>
            <h2 >{count}</h2>

        </div>
    )
}
export default Test