import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormGroup,
  CCol,
  CLabel,
} from '@coreui/react';
import DateRangePicker from 'src/views/common/dateRangePicker';
import { CustButton } from 'src/reusable';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import moment from 'moment';

const KPIDashboard = props => {
  let api = new API();
  const {
    open,
    handleCancel,
    selectedItem,
    hiringDashboardTabValue,
    setReportModalOpen,
  } = props;
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleOnSelectOptions = e => {
    let { name, value } = e.target;
    if (value === 'all') {
      if (name === 'monthly_metric') {
        let temp = [];
        hiringDashboardTabValue?.monthly_metric?.forEach(data => {
          temp.push(data.Date);
        });
        if (selectAll === false) {
          setSelectedValue(temp);
        } else {
          setSelectedValue([]);
        }
      } else {
        setSelectedValue('all');
      }
      setSelectAll(!selectAll);
    } else {
      if (selectedValue.includes(value)) {
        let newArray =
          selectedValue &&
          selectedValue.length > 0 &&
          selectedValue.filter(item => item !== value);
        setSelectedValue(newArray);
      } else {
        setSelectedValue([...selectedValue, value]);
      }
    }
  };

  // download report
  const downloadKPIReport = async () => {
    let data = {
      startDate: startDate,
      endDate: endDate,
      data: selectedValue,
      type: selectedItem,
    };
    let result = await api.post(config.getReportKPIDashboard, data);
    if (result && result.code === 200) {
      window.open(result.data.filePath, '_blank');
      toast.success(result.message);
      setReportModalOpen(false);
    } else {
    }
  };

  return (
    <div className="">
      <CModal show={open} onClose={handleCancel} size="lg">
        <CModalHeader closeButton>
          {selectedItem && (
            <CModalTitle>
              <div style={{ textTransform: 'capitalize' }}>
                {selectedItem.replace('_', ' ')} Reports
              </div>
            </CModalTitle>
          )}
        </CModalHeader>
        <CModalBody>
          <div>
            <CForm
              action=""
              encType="multipart/form-data"
              className="form-horizontal"
            >
              {selectedItem !== 'monthly_metric' && (
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel>
                      Select Date Range{' '}
                      <span className="required-field">*</span>
                    </CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <DateRangePicker
                      xs="12"
                      md="8"
                      DateStart={setStartDate}
                      DateEnd={setEndDate}
                    />
                  </CCol>
                </CFormGroup>
              )}
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Select Type <span className="required-field">*</span>
                  </CLabel>
                </CCol>
                <CCol xs="12" md="8">
                  {selectedItem === 'recruitment_funnel' && (
                    <>
                      <div className="d-inline-flex col-md-4">
                        <input
                          type="checkbox"
                          name="recruitment_funnel"
                          value="all"
                          onChange={e => handleOnSelectOptions(e)}
                        ></input>
                        &nbsp;All
                      </div>
                      {hiringDashboardTabValue &&
                        hiringDashboardTabValue?.recruitment_funnel.map(
                          data => (
                            <>
                              <div className="d-inline-flex col-md-4">
                                <input
                                  type="checkbox"
                                  name="recruitment_funnel"
                                  value={data.name}
                                  checked={
                                    selectAll ||
                                    selectedValue.includes(data.name)
                                      ? true
                                      : false
                                  }
                                  onChange={e => handleOnSelectOptions(e)}
                                ></input>
                                &nbsp;{data.name}
                              </div>
                            </>
                          ),
                        )}
                    </>
                  )}
                  {selectedItem === 'monthly_metric' && (
                    <>
                      <div className="d-inline-flex col-md-4">
                        <input
                          type="checkbox"
                          name="monthly_metric"
                          value="all"
                          onChange={e => handleOnSelectOptions(e)}
                        ></input>
                        &nbsp;All
                      </div>
                      {hiringDashboardTabValue &&
                        hiringDashboardTabValue?.monthly_metric.map(data => (
                          <>
                            <div className="d-inline-flex col-md-4">
                              <input
                                type="checkbox"
                                name="monthly_metric"
                                value={data.Date}
                                checked={
                                  selectAll || selectedValue.includes(data.Date)
                                    ? true
                                    : false
                                }
                                onChange={handleOnSelectOptions}
                              ></input>
                              &nbsp;{data.Date}
                            </div>
                          </>
                        ))}
                    </>
                  )}
                  {selectedItem === 'app_source' && (
                    <>
                      <div className="d-inline-flex col-md-4">
                        <input
                          type="checkbox"
                          name="app_source"
                          value="all"
                          onChange={e => handleOnSelectOptions(e)}
                        ></input>
                        &nbsp;All
                      </div>
                      {hiringDashboardTabValue &&
                        hiringDashboardTabValue?.application_source.map(
                          data => (
                            <>
                              <div className="d-inline-flex col-md-4">
                                <input
                                  type="checkbox"
                                  name="app_source"
                                  value={data.Source}
                                  checked={
                                    selectAll ||
                                    selectedValue.includes(data.Source)
                                      ? true
                                      : false
                                  }
                                  onChange={handleOnSelectOptions}
                                ></input>
                                &nbsp;{data.Source}
                              </div>
                            </>
                          ),
                        )}
                    </>
                  )}
                  {selectedItem === 'decline_reason' && (
                    <>
                      <div className="d-inline-flex col-md-4">
                        <input
                          type="checkbox"
                          name="decline_reason"
                          value="all"
                          onChange={e => handleOnSelectOptions(e)}
                        ></input>
                        &nbsp;All
                      </div>
                      {hiringDashboardTabValue &&
                        hiringDashboardTabValue?.decline_reason.map(data => (
                          <>
                            <div className="d-inline-flex col-md-4">
                              <input
                                type="checkbox"
                                name="decline_reason"
                                value={data.Reason}
                                checked={
                                  selectAll ||
                                  selectedValue.includes(data.Reason)
                                    ? true
                                    : false
                                }
                                onChange={handleOnSelectOptions}
                              ></input>
                              &nbsp;{data.Reason}
                            </div>
                          </>
                        ))}
                    </>
                  )}
                  {selectedItem === 'active_pipeline' && (
                    <>
                      <div className="d-inline-flex col-md-4">
                        <input
                          type="checkbox"
                          name="active_pipeline"
                          value="all"
                          onChange={e => handleOnSelectOptions(e)}
                        ></input>
                        &nbsp;All
                      </div>
                      {hiringDashboardTabValue &&
                        hiringDashboardTabValue?.active_pipeline.map(data => (
                          <>
                            <div className="d-inline-flex col-md-4">
                              <input
                                type="checkbox"
                                name="active_pipeline"
                                value={data.Apps}
                                checked={
                                  selectAll || selectedValue.includes(data.Apps)
                                    ? true
                                    : false
                                }
                                onChange={handleOnSelectOptions}
                              ></input>
                              &nbsp;{data.Apps}
                            </div>
                          </>
                        ))}
                    </>
                  )}
                  {selectedItem === 'pipeline_efficiency' && (
                    <>
                      <div className="d-inline-flex col-md-4">
                        <input
                          type="checkbox"
                          name="pipeline_efficiency"
                          value="all"
                          onChange={e => handleOnSelectOptions(e)}
                        ></input>
                        &nbsp;All
                      </div>
                      {hiringDashboardTabValue &&
                        hiringDashboardTabValue?.active_pipeline.map(data => (
                          <>
                            <div className="d-inline-flex col-md-4">
                              <input
                                type="checkbox"
                                name="pipeline_efficiency"
                                value={data.Apps}
                                checked={
                                  selectAll || selectedValue.includes(data.Apps)
                                    ? true
                                    : false
                                }
                                onChange={handleOnSelectOptions}
                              ></input>
                              &nbsp;{data.Apps}
                            </div>
                          </>
                        ))}
                    </>
                  )}
                </CCol>
              </CFormGroup>
            </CForm>
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Export"
            color="primary"
            onClick={downloadKPIReport}
            className="btn-loading btn-ladda mr-3"
            icon="fa-download"
            isDisabled={false}
          />
        </CModalFooter>
      </CModal>
      <ToastContainer />
    </div>
  );
};
export default KPIDashboard;
