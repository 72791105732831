import React, { useEffect } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormGroup,
  CCol,
  CLabel,
  CInput,
} from '@coreui/react';
import { CustButton } from 'src/reusable';

const PreConfirmInitCallInfoModal = props => {
  const {
    isGetAccessNumLoader,
    open,
    handleCancel,
    onChangeInput,
    onInitiateCall,
    agentNum,
    candidateNum,
    allFieldsReqErrMsg,
  } = props;

  useEffect(() => {}, []);

  return (
    <div className="">
      <CModal
        show={open}
        onClose={handleCancel}
        size="md"
        className="modalAlign"
      >
        <CModalHeader>
          <CModalTitle>Call Information</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {/* <CButton
            className="float-right"
            color="danger"
            onClick={() => handleCancel()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton> */}
          <div>
            {allFieldsReqErrMsg && (
              <p className="text-danger text-center">All fields are required</p>
            )}
            <CForm
              action=""
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Recruiter No. <span className="required-field">*</span>
                  </CLabel>
                </CCol>

                <CCol xs="12" md="8">
                  <CInput
                    value={agentNum}
                    onChange={onChangeInput}
                    name="agent_num"
                    placeholder="Enter Mobile Number"
                    required
                    maxlength="10"
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Candidate No. <span className="required-field">*</span>
                  </CLabel>
                </CCol>

                <CCol xs="12" md="8">
                  <CInput
                    value={candidateNum}
                    onChange={onChangeInput}
                    name="candidate_num"
                    placeholder="Enter Mobile Number"
                    required={true}
                    maxlength="10"
                  />
                </CCol>
              </CFormGroup>
            </CForm>
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Initiate Call"
            color="primary"
            onClick={onInitiateCall}
            className="btn-loading btn-ladda mr-3"
            icon="fa-paper-plane"
            isDisabled={isGetAccessNumLoader || false}
            isLoading={isGetAccessNumLoader || false}
          />
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default PreConfirmInitCallInfoModal;
