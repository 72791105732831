import React, { useEffect } from 'react';
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import ViewReceivedSeprationDetails from './viewReceivedSeprationDetails';

const ModalViewSepration = props => {
  const {
    isOpen,
    isViewModalOpen,
    empResignationData,
    reloadTaskBox,
    taskBox,
  } = props;
  return (
    <div className="">
      <CModal
        show={isOpen}
        onClose={() => isViewModalOpen(!isOpen)}
        size="lg"
        style={{ paddingTop: '3%', border: 'none' }}
      >
        <CModalHeader closeButton>
          <CModalTitle>Received Separation Request</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <ViewReceivedSeprationDetails
            seprationDetails={empResignationData}
            isViewModalOpen={isViewModalOpen}
            isOpen={isOpen}
            reloadTaskBox={reloadTaskBox}
            taskBox={taskBox}
          />
        </CModalBody>
        <CModalFooter style={{ height: '50px' }}></CModalFooter>
      </CModal>
    </div>
  );
};
export default ModalViewSepration;
