import React, { useState, useEffect } from 'react'
import EditPaySchedule from './EditPaySchedule'


const PaySchedule = () => {
    

    
    return (
        <div>
            <EditPaySchedule/>
         
          
        </div>
    )
}

export default PaySchedule