import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './dashboard360.css'
import moment from 'moment';
import {
  TheSidebar,
  TheFooter,
  TheHeader
} from '../../containers/index'

import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { CustButton } from 'src/reusable';
import { KPIDashboard } from 'src/modals';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import { isPermission } from 'src/utils/common';
import './hiringDashboard.css'


const HiringDashboard = () => {
  const employee_name = getLocalDataAsObject('user')[0].first_name+" "+getLocalDataAsObject('user')[0].last_name
  const api = new API();
  const [hiringDashboardTabValue,setHiringDashboardTabValue]=useState("")
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const KPIData = async() => {
    let result = await api.get(config.getKPIDashboard);
      if (result && result.code === 200) {
          setHiringDashboardTabValue(result?.data?.Tab_Value)
      }
    };
    useEffect(() => {
      KPIData();
    }, []);
    
    Highcharts.setOptions({
      colors: ['#b23621', '#dba600','#b9d871','#b8c520','#096045','#002f64']
     });
  const monthly_metrics = {
    chart: {
      type: 'column',
      inverted: true,
      height:'200px'
  },
  title: {
      align: 'left',
      text: ''
  },
  subtitle: {
      align: 'left',
      text: ''
  },
  accessibility: {
      announceNewData: {
          enabled: true
      }
  },
  xAxis: {
      categories:hiringDashboardTabValue && hiringDashboardTabValue?.monthly_metric.map((data)=> (data.Date))
  },
  yAxis: {
      title: {
          text: ''
      }

  },
  legend: {
      enabled: false
  },
  tooltip: {
      pointFormat: '<span style="color:{point.color}"></span><b>{point.y}</b><br/>'
  },
  series: [
      {
        colorByPoint:true,
          data: hiringDashboardTabValue && hiringDashboardTabValue?.monthly_metric.map((data)=> (data.Hired))
      }
  ],
};

const monthly_metrics_days = {
  chart: {
    type: 'column',
    inverted: true,
    height:'200px'
},
title: {
    align: 'left',
    text: ''
},
subtitle: {
    align: 'left',
    text: ''
},
accessibility: {
    announceNewData: {
        enabled: true
    }
},
xAxis: {
    categories:hiringDashboardTabValue && hiringDashboardTabValue?.monthly_metric.map((data)=> (data.Date))
},
yAxis: {
    title: {
        text: ''
    }

},
legend: {
    enabled: false
},
tooltip: {
    pointFormat: '<span style="color:{point.color}"></span><b>{point.y}</b><br/>'
},
series: [
    {
      colorByPoint:true,
      data: hiringDashboardTabValue && hiringDashboardTabValue?.monthly_metric.map((data)=> (data.difference))
    }
],
};

  const recruitment_funnel = {
  chart: {
      type: 'column',
      inverted: true,
      height:'250px'
  },
  title: {
      align: 'left',
      text: ''
  },
  subtitle: {
      align: 'left',
      text: ''
  },
  accessibility: {
      announceNewData: {
          enabled: true
      }
  },
  xAxis: {
    categories:hiringDashboardTabValue && hiringDashboardTabValue?.recruitment_funnel.map((data)=> (data.name))
},
  yAxis: {
      title: {
          text: ''
      }

  },
  legend: {
      enabled: false
  },
  plotOptions: {
      series: {
          borderWidth: 0,
          dataLabels: {
              enabled: true,
              format: '{point.y:.2f}%'
          }
      },
  },
  tooltip: {
      pointFormat: '<b>{point.y:.2f} % </b>of total<br/>'
  },
  series: [
      {
        colorByPoint:true,
        data: hiringDashboardTabValue && hiringDashboardTabValue?.recruitment_funnel.map((data)=> (data.percentage))
      }
  ],
};

const pipeline_efficiency = {
  chart: {
    type: "pie",
    height:"250px",
    animation: false
  },
  title: {
    text: ""
  },
  subtitle: {
    text: ""
  },

tooltip:{
  formatter:function() {
  var sliceIndex = this.series.data.indexOf(this.point);
  var sliceName = this?.series?.name?.[sliceIndex];
  return '<b>' + sliceName +' : ' + this.y  + '</b>';
}},

  plotOptions: {
    pie: {
      shadow: false,
      cursor:'pointer',
      allowPointSelect: true,
      depth: 30,
      dataLabels: {
        enabled: true
      },
  },
},
  series: [
    {
      name:hiringDashboardTabValue && hiringDashboardTabValue?.recruitment_funnel.map((data)=> (data.name)),
      colorByPoint:true,
      data: hiringDashboardTabValue && hiringDashboardTabValue?.monthly_metric.map((data)=> (data.difference)),
      size: "100%",
      innerSize: "40%",
      dataLabels: {
        format:"{point.y}",
        inside: true,
        color: 'white',
        distance:-25
    }
    }
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400
        },
      }
    ]
  }};

const app_source = {
  chart: {
    type: 'column',
    inverted: true,
    height:'200px'
},
title: {
    text: ''
},
subtitle: {
    text: ''
},
accessibility: {
    announceNewData: {
        enabled: true
    }
},
xAxis :{
  visible : false
},
yAxis: {
    title: {
        text: ''
    }
},
legend: {
    enabled: false
},
tooltip:{
  formatter:function() {
    return '<b>' + this.y + ' % of total' + '</b>';
}},
series: [{
      colorByPoint:true,
      data: hiringDashboardTabValue && hiringDashboardTabValue?.application_source.map((data)=> (data.Percentage))
}],
};

const decline_reason = {
  chart: {
    type: 'column',
    inverted: true,
    height:'200px'
},
title: {
    text: ''
},
subtitle: {
    text: ''
},
accessibility: {
    announceNewData: {
        enabled: true
    }
},
xAxis :{
  visible : false
},
yAxis: {
    title: {
        text: ''
    }
},
legend: {
    enabled: false
},
tooltip:{
  formatter:function() {
  return '<b>' + this.y + ' % of total' + '</b>';
}},
series: [
    {
      colorByPoint:true,
      data: hiringDashboardTabValue && hiringDashboardTabValue?.decline_reason.map((data)=> (data.Percentage))
    }
],
};

const toggleReportModal = data => {
  setSelectedItem(data);
  setReportModalOpen(!reportModalOpen);
};

    return (
        <>
         <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
       
        <div className="c-body">
  <main className='c-main'>
  <header className="card-header">
                  <div className="main-dd" style={{minHeight: '35px'}}>
                    <div className="name">
                      <h4 className='userclient mb-0'>
                        <span>{moment().format('HH:mm') > "03:00" && moment().format('HH:mm') < "11:59"
                        ? 'Good Morning, '
                        : moment().format('HH:mm') > "12:00" &&
                          moment().format('HH:mm') < "15:59"
                        ? 'Good Afternoon, '
                        : moment().format('HH:mm') > "16:00" &&
                          moment().format('HH:mm') < "19:59"
                        ? 'Good Evening, '
                        : moment().format('HH:mm') > "20:00" &&
                          moment().format('HH:mm') < "03:00"
                        ? 'Good Night, '
                        : ' '}<strong>{employee_name}</strong>
                        </span>
                      </h4>
                      <div className="clndr_item" style={{float: 'right', display: 'flex'}}>
                        <div className='clndrit text-right'>
                          <p>Today </p>
                          <b>{moment().format("DD MMM YYYY hh:mm:ss A")}</b>
                        </div> <div class="btn-group" role="group" aria-label="...">
                            <div class="btn-group" role="group" >
                                <img src="../images/cal.svg" alt='' height="90%" width="80%" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </header>

   <div className='cardHdng w-100 bg-white'>
     <div className='d-lg-flex'>
         <div className='col-lg-3'>
            <h5 className='hdngMn'>HR 360 View Dashbaord</h5>
         </div>

        <div className='col-lg-9'>
           <div className='row gap-2 btnDashItems justify-content-lg-end'>
                 <button className='btn-panel col-sm-2'> Workforce Overview </button>
                 <button className='btn-panel col-sm-2'>Time & Attendance</button>
                 <button className='btn-panel col-sm-2'>Headcount</button>
                  <button className='btn-panel col-sm-2  active'>Hiring</button>
             </div>
        </div>
    </div>
</div>

<div className=' main-panel row pt-3 row-cols-md-2 row-cols-1 row-cols-xl-4 g-2 g-lg-3 '>
    <div className='panel-info col  mb-md-3 mb-2 pe-lg-0 h-100'>
      <div className='bg-white detail p-3 h-100'>
         <div className='d-flex gap-3 align-items-center h-100'>
           <div className='img-Bg'>
               <img src='images/hiring-icon.svg' alt='' width={'36'} height={'36'}/>
            </div>
              <div className='content'>
                <span><b>Hired</b></span>
                <p className='mb-0'>{hiringDashboardTabValue?.hired}</p>
             </div>
          </div>
       </div>
     </div>

<div className='panel-info col mb-md-3 mb-2 pe-lg-0 h-100'>
<div className='bg-white detail p-3 h-100'>
         <div className='d-flex gap-3 align-items-center  h-100'>
           <div className='img-Bg'><img src='images/hire-icon.svg' alt='' width={'36'} height={'36'}/></div>
           <div className='content'><span><b>Days To Hire</b></span><p className='mb-0'>{hiringDashboardTabValue?.total_days}</p></div>
        </div>
</div>
</div>

<div className='panel-info col  mb-md-3 mb-2 pe-lg-0 h-100'>
<div className='bg-white detail p-3 h-100'>
         <div className='d-flex gap-3 align-items-center h-100'>
           <div className='img-Bg'><img src='images/cost-icon.svg' alt='' width={'36'} height={'36'}/> </div>
           <div className='content'><span><b>Cost Per Hire</b></span> <p className='mb-0'>1740</p></div>
       </div>
</div>
</div>

<div className='panel-info col mb-md-3 mb-2 h-100'>
<div className='bg-white detail p-3 h-100'>
         <div className='d-flex gap-3 align-items-center h-100'>
           <div className='img-Bg'><img src='images/position-icon.svg' alt='' width={'36'} height={'36'}/> </div>
           <div className='content'><span><b>Open Positions</b></span><p className='mb-0'>{hiringDashboardTabValue?.open_positions}</p></div>
         </div>
</div>
</div>
</div>


  <div className='row pt-2'>
                    <div className='col-md-4 pe-md-0 mb-3'>
                      <div className='boxItmsGraph hireGraph '>
                        <p>RECRUITEMENT FUNNEL</p>
                        <div className='graphDesign graphDesign2 d-flex flex-column h-100'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={recruitment_funnel}
                          />
                      {isPermission('recruitment_funnel_report') &&
                      <div className='text-center mt-auto mb-5'>
                      <CustButton
                      type="button"
                      name="recruitment_funnel"
                      text="Download Report"
                      color="primary"
                      onClick={(e) => toggleReportModal(e.target.name)}
                      className='grphBtn'
                      icon=""
                      isDisabled={false}
                      />
                      </div>
                      }
                      </div>
                       </div>
                    </div>

                    <div className='col-md-4 pe-md-0 mb-3'>
                      <div className='boxItmsGraph hireGraph'>
                        <p>MONTHLY METRICS (PAST 06 MTHS)</p>
                        <div className='graphDesign mx-2 d-flex flex-column h-100'>
                          <div className='row'>
                          <div className='col-sm-5 text-center'>
                            <label>Month</label>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={monthly_metrics}
                          /></div>
                          <div className='col-sm-2 text-center'>
                            <label>Hired</label><br/>
                            {hiringDashboardTabValue && hiringDashboardTabValue?.monthly_metric.map((data)=>(
                            <><b style={{lineHeight:'2'}}>{data.Hired}</b> <br/>
                            </>))}
                          </div>
                          <div className='col-sm-5 text-center'>
                          <label>Days to Hire</label>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={monthly_metrics_days}
                          /></div>
                          </div>
                          {isPermission('monthly_metric_report') &&
                          <div className='text-center mt-auto mb-5'>
                          <CustButton
                      type="button"
                      name="monthly_metric"
                      text="Download Report"
                      color="primary"
                      onClick={(e) => toggleReportModal(e.target.name)}
                      className='grphBtn'
                      icon=""
                      isDisabled={false}
                      />
                           </div>
                      }
                           </div>
                          </div>
                    </div>
                    
                    <div className='col-md-4 mb-3'>
                      <div className='boxItmsGraph hireGraph '>
                        <p>PIPELINE EFFICIENCY OF HIRING</p>
                        <small style={{fontSize:"12px",marginTop:"-0.75rem"}} id="emailHelp" className="form-text text-muted">Days Taken For Each Stage In Recruitment Process</small>
                        <div className='graphDesign mx-2 d-flex flex-column h-100'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pipeline_efficiency}
                          />
                          {isPermission('hiring_efficiency_report') &&
                          <div className='text-center mt-auto mb-5'>
                          <CustButton
                      type="button"
                      name="pipeline_efficiency"
                      text="Download Report"
                      color="primary"
                      onClick={(e) => toggleReportModal(e.target.name)}
                      className='grphBtn'
                      icon=""
                      isDisabled={false}
                      />
                          </div>
                      }
                          </div>
                       </div>
                    </div>
   
                    <div className='col-md-4  pe-md-0 mb-3'>
                      <div className='boxItmsGraph hireGraph '>
                        <p>APPLICATION SOURCES</p>
                        <div className='graphDesign graphDesign2 d-flex flex-column h-100'>
                        <div className='row'>
                          <div className='col-sm-5 text-center'>
                            <label>Platform</label><br/>
                            {hiringDashboardTabValue && hiringDashboardTabValue?.application_source.map((data)=>(
                            <><b style={{lineHeight:'2.5'}}>{data.Source}</b> <br/>
                             </>))}
                          </div>
                          <div className='col-sm-2 text-center'>
                            <label>#Hired</label><br/>
                            {hiringDashboardTabValue && hiringDashboardTabValue?.application_source.map((data)=>(
                            <><b style={{lineHeight:'2.5'}}>{data.Hired}</b> <br/>
                            </>))}
                          </div>
                          <div className='col-sm-5 text-center'>
                          <label>Conv Rate</label>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={app_source}
                          /></div>
                          </div>
                          {isPermission('application_sources_report') && 
                        <div className='text-center mt-auto mb-5'>
                        <CustButton
                      type="button"
                      name="app_source"
                      text="Download Report"
                      color="primary"
                      onClick={(e) => toggleReportModal(e.target.name)}
                      className='grphBtn'
                      icon=""
                      isDisabled={false}
                      />
                      </div>
                      }
                      </div>
                       </div>
                    </div>

                    <div className='col-md-4 pe-md-0 mb-3'>
                      <div className='boxItmsGraph hireGraph '>
                        <p>DECLINE REASONS</p>
                        <div className='graphDesign mx-2 d-flex flex-column h-100'>
                        <div className='row'>
                          <div className='col-sm-5 text-center'>
                            <label>Reasons</label><br/>
                            {hiringDashboardTabValue && hiringDashboardTabValue?.decline_reason.map((data)=>(
                            <><b style={{lineHeight:'2.5'}}>{data.Reason}</b> <br/>
                             </>))}
                          </div>
                          <div className='col-sm-2 text-center'>
                            <label>#Hired</label><br/>
                            {hiringDashboardTabValue && hiringDashboardTabValue?.decline_reason.map((data)=>(
                            <><b style={{lineHeight:'2.5'}}>{data.Hired}</b> <br/>
                            </>))}
                          </div>
                          <div className='col-sm-5 text-center'>
                          <label>% Of Apps</label>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={decline_reason}
                          /></div>
                          </div>
                         {isPermission('declined_reasons_report') && 
                          <div className='text-center mt-auto mb-5'>
                          <CustButton
                          type="button"
                          name="decline_reason"
                          text="Download Report"
                          color="primary"
                          onClick={(e) => toggleReportModal(e.target.name)}
                          className='grphBtn'
                          icon=""
                          isDisabled={false}
                          />
                           </div>
                          }
                           </div>
                          </div>
                    </div>
                    
                    <div className='col-md-4 mb-3'>
                      <div className='boxItmsGraph hireGraph '>
                        <p className='mb-0'>ACTIVE PIPELINE</p>
                        <div className=' pt-3 d-flex flex-column h-100'>
                            <div style={{marginTop:"3rem",marginLeft:"-0.75rem",marginRight:"-0.75rem"}} className='pipeline'>
                              <div className='row'>
                                <div className='col-xl-4 col-md-2 padding-0' style={{maxWidth:"20%"}}>
                                  <div className='contentBox' style={{backgroundColor:"#b23621"}}>
                              {hiringDashboardTabValue && hiringDashboardTabValue?.active_pipeline.map((data)=>(
                                <p className='mb-0 text-light'>{data.Apps === "Application" ? data.Total : ""}</p> 
                                    ))}
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0'  style={{maxWidth:"20%"}}>
                                  <div className='contentBox' style={{backgroundColor:"#dba600"}}>
                                  {hiringDashboardTabValue && hiringDashboardTabValue?.active_pipeline.map((data)=>(
                                <p className='mb-0 text-light'>{data.Apps === "HR Select" ? data.Total : ""}</p> 
                                    ))}
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0'  style={{maxWidth:"20%"}}>
                                  <div className='contentBox' style={{backgroundColor:"#b9d871"}}>
                                  {hiringDashboardTabValue && hiringDashboardTabValue?.active_pipeline.map((data)=>(
                                     <p className='mb-0'>{data.Apps === "Ops Select" ? data.Total : ""}</p>
                                     ))}
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0' style={{maxWidth:"20%"}}>
                                  <div className='contentBox' style={{backgroundColor:"#b8c520"}}>
                                  {hiringDashboardTabValue && hiringDashboardTabValue?.active_pipeline.map((data)=>(
                                     <p className='mb-0'>{data.Apps === "Onsite Interview" ? data.Total : ""}</p>
                                     ))}
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0' style={{maxWidth:"20%"}}>
                                  <div className='contentBox' style={{backgroundColor:"#096045"}}>
                                  {hiringDashboardTabValue && hiringDashboardTabValue?.active_pipeline.map((data)=>(
                                <p className='mb-0 text-light'>{data.Apps === "Offer" ? data.Total : ""}</p> 
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-xl-4 col-md-2 padding-0' style={{maxWidth:"20%"}}>
                                  <div className='contentBox2'>
                                     <b style={{textAlign:"left"}}>Application</b> 
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0'  style={{maxWidth:"20%"}}>
                                  <div className='contentBox2'>
                                     <b>HR Select</b> 
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0'  style={{maxWidth:"20%"}}>
                                  <div className='contentBox2'>
                                     <b>Ops Select</b>
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0' style={{maxWidth:"20%"}}>
                                  <div className='contentBox2'>
                                     <b>Onsite Interview</b> 
                                  </div>
                                </div>
                                <div className='col-xl-4 col-md-2 padding-0' style={{maxWidth:"20%"}}>
                                  <div className='contentBox2'>
                                     <b>Offer</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                         {isPermission('active_pipeline') &&
                          <div className='text-center mt-auto mb-5'>
                          <CustButton
                          type="button"
                          name="active_pipeline"
                          text="Download Report"
                          color="primary"
                          onClick={(e) => toggleReportModal(e.target.name)}
                          className='grphBtn'
                          icon=""
                          isDisabled={false}
                          />
                          </div>
                          }
                          </div>
                       </div>
                    </div>
   </div>



 </main>
</div>
             
             
        <TheFooter />         
      </div>
      <KPIDashboard
        open={reportModalOpen}
        handleCancel={toggleReportModal}
        dropdownList={null}
        selectedItem={selectedItem}
        hiringDashboardTabValue={hiringDashboardTabValue}
        setReportModalOpen={setReportModalOpen}
      />
    </div>
        </>)
}
export default HiringDashboard
