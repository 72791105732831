import React from 'react';
import { MultiSelect } from 'react-multi-select-component';

const CustMultiSelect = props => {
  const { values, onChange, optionsData, type } = props;
  const options = optionsData || [];

  return (
    <div>
      <MultiSelect
        options={options}
        onChange={data => onChange(data, type)}
        selectionType="label"
        labelledBy="Select"
        value={values}
      />
    </div>
  );
};

export default CustMultiSelect;
