import { createReducer } from 'redux-act';
import actions from './attendanceActions';

const initialState = {
  isGetAttendanceBasicDetailsPending: false,
  getAttendanceBasicDetailsRes: null,
  getAttendanceBasicDetailsResError: null,

  isGetAttendanceLogsWithDurationPending: false,
  getAttendanceLogsWithDurationRes: null,
  getAttendanceLogsWithDurationResError: null,
};

//
const handleGetAttendanceBasicDetailsRequest = state => {
  return {
    ...state,
    ...{
      isGetAttendanceBasicDetailsPending: true,
      getAttendanceBasicDetailsRes: null,
      getAttendanceBasicDetailsResError: null,
    },
  };
};
const handleGetAttendanceBasicDetailsSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      isGetAttendanceBasicDetailsPending: false,
      getAttendanceBasicDetailsRes: payload,
      getAttendanceBasicDetailsResError: null,
    },
  };
};
const handleGetAttendanceBasicDetailsError = (state, payload) => ({
  ...state,
  ...{
    isGetAttendanceBasicDetailsPending: false,
    getAttendanceBasicDetailsRes: null,
    getAttendanceBasicDetailsResError: payload,
  },
});

//
const handleGetAttendanceLogsWithDurationRequest = state => {
  return {
    ...state,
    ...{
      isGetAttendanceLogsWithDurationPending: true,
      getAttendanceLogsWithDurationRes: null,
      getAttendanceLogsWithDurationResError: null,
    },
  };
};
const handleGetAttendanceLogsWithDurationSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      isGetAttendanceLogsWithDurationPending: false,
      getAttendanceLogsWithDurationRes: payload,
      getAttendanceLogsWithDurationResError: null,
    },
  };
};
const handleGetAttendanceLogsWithDurationError = (state, payload) => ({
  ...state,
  ...{
    isGetAttendanceLogsWithDurationPending: false,
    getAttendanceLogsWithDurationRes: null,
    getAttendanceLogsWithDurationResError: payload,
  },
});

//
const handleClearAttendanceStore = (state, payload) => {
  switch (payload) {
    case 'CLEAR_ATTENDANCE_LOGS':
      return {
        ...state,
        ...{
          isGetAttendanceLogsWithDurationPending: false,
          getAttendanceLogsWithDurationRes: null,
          getAttendanceLogsWithDurationResError: null,
        },
      };
    case 'CLEAR_ATTENDANCE_BASIC_DETAILS':
      return {
        ...state,
        ...{
          isGetAttendanceBasicDetailsPending: false,
          getAttendanceBasicDetailsRes: null,
          getAttendanceBasicDetailsResError: null,
        },
      };
    default:
      return state;
  }
};

export default createReducer(
  {
    [actions.getAttendanceBasicDetailsRequest]: handleGetAttendanceBasicDetailsRequest,
    [actions.getAttendanceBasicDetailsSuccess]: handleGetAttendanceBasicDetailsSuccess,
    [actions.getAttendanceBasicDetailsError]: handleGetAttendanceBasicDetailsError,

    [actions.getAttendanceLogsWithDurationRequest]: handleGetAttendanceLogsWithDurationRequest,
    [actions.getAttendanceLogsWithDurationSuccess]: handleGetAttendanceLogsWithDurationSuccess,
    [actions.getAttendanceLogsWithDurationError]: handleGetAttendanceLogsWithDurationError,
  },
  initialState,
);
