// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics, getToken,getMessaging } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBlLWW14HS6XgOO_iq9okznf6m1hp3b5tY",
  authDomain: "chat-fd8ab.firebaseapp.com",
  projectId: "chat-fd8ab",
  storageBucket: "chat-fd8ab.appspot.com",
  messagingSenderId: "41287914771",
  appId: "1:41287914771:web:aba8ca7bb8cb2982e776e6",
  measurementId: "G-CB8YQNZ0BP"
};

// Initialize Firebase



function requestPermission() {
    // console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // console.log('Notification permission granted.');
        const app = initializeApp(firebaseConfig);
        // const analytics = getAnalytics(app);
        const messaging = getMessaging(app);
        getToken(messaging,{vapidKey:"BLrmzgoyc-3cKgT9G42b47oqDIhtCc0jJdRHUHTyKvxv1hzXzHBwYHXS6Jo7is5RkuQrVVsGULqKrWKXT-LRihA",
    }).then((currentToken)=>{
        if(currentToken){
            localStorage.setItem("chatToken",currentToken)
            // console.log("currentToken:",currentToken);
        }else{
            // console.log("can not get token");
        }
    });
}else{
    // console.log("Do not have permission!");
}
});
}
      requestPermission()