

import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'

import ModalProfileBank from './modal';

const OfferToSelection = (props) => {
    const { } = props
    let api = new API()

    const [CandidateRecord, setCandidateRecord] = useState()
    const [active, setActive] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pendingRequisition, setPendingRequisition] = useState()
    const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(false)
    const [candidateDetails, setCandidateDetails] = useState()
    useEffect(() => {
        getMrfRecord()
    }, [])

    const getMrfRecord = async () => {
        // let data = {
        //     type: "hr",
        //     mrfType : "replacement"
        // }
        let result = await api.get(config.profileBank)
        if (result && result.code == 200) {
            // console.log("pending requisition", result)

            setCandidateRecord(result.data)
        } else {
            // console.log("something wrong")
        }
    }



    const isViewModalOpen = (candidate) => {
        setIsModalOpen(!isModalOpen)
        setCandidateDetails(candidate)
    }



    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"> <u>Offer to Selection (offer latter sent)</u></h3>


                  <CRow>
                    <CCol xs="12" >
                        <CCard>
                            <CCardHeader>
                                HR Select:
                        </CCardHeader>
                            <CCardBody>
                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink>
                                            Offer Issued
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink>
                                                Offer Accept
                    
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink>
                                                Offer Negotation
                    
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink>
                                            Final Joined
                    
                                            </CNavLink>
                                        </CNavItem>
                                    </CNav>
                                    <CTabContent>
                                        <CTabPane>
                                        <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">applying_for</th>
                                <th scope="col">Applying For</th>
                                <th scope="col">Assigned To</th>
                                <th scope="col">Assigned By</th>
                                <th scope="col">Assigned At</th>
                                <th scope="col">MRF Final Status</th>

                            </tr>
                        </thead>
                        <tbody>
 
                                <tr>
                                    <th scope="row">{"1"}</th>
                                    <td>{"mrf.mrf_id"}</td>
                                    <td>{"mrf.applying_for"}</td>
                                    <td>{"mrf.assigned_to"}</td>
                                   <td>{"mrf.assigned_by"}</td>
                                    <td>{"mrf.assigned_at"}</td>
                                    <td>{"mrf.mrf_final_status"}</td>


                                    <CRow>
                                        <CCol xs="12" md="12">
                                            <CButton shape="pill" block-shape="pill" className="btn btn-primary mr-0 " >View</CButton>
                                        </CCol>
                                        {/* <CCol xs="12" md="4">
                                        <CButton shape="pill" block shape="pill" className="btn btn-danger mr-0" >Close MRF</CButton>
                                    </CCol> */}
                                    
                                    </CRow>
                                </tr>
                     

                        </tbody>
                    </table>
                                        </CTabPane>
                                        <CTabPane>

                                        <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">applying_for</th>
                                <th scope="col">Applying For</th>
                                <th scope="col">Assigned To</th>
                                <th scope="col">Assigned By</th>
                                <th scope="col">Assigned At</th>
                                <th scope="col">MRF Final Status</th>

                            </tr>
                        </thead>
                        <tbody>
 
                                <tr>
                                    <th scope="row">{"1"}</th>
                                    <td>{"mrf.mrf_id"}</td>
                                    <td>{"mrf.applying_for"}</td>
                                    <td>{"mrf.assigned_to"}</td>
                                   <td>{"mrf.assigned_by"}</td>
                                    <td>{"mrf.assigned_at"}</td>
                                    <td>{"mrf.mrf_final_status"}</td>


                                    <CRow>
                                        <CCol xs="12" md="12">
                                            <CButton shape="pill" block-shape="pill" className="btn btn-primary mr-0 ">View</CButton>
                                        </CCol>
                                        {/* <CCol xs="12" md="4">
                                        <CButton shape="pill" block shape="pill" className="btn btn-danger mr-0" >Close MRF</CButton>
                                    </CCol> */}
                                    
                                    </CRow>
                                </tr>
                     

                        </tbody>
                    </table>
                                         </CTabPane>
                                         <CTabPane>
                                     <h4>Offer negotation</h4>
                                        </CTabPane>
                                        <CTabPane>
                                     <h4>Final Joined</h4>
                                        </CTabPane>
                                    </CTabContent>
                                </CTabs>
                                <CCardFooter>
                                    <CButton color="success" >Back</CButton>{' '}

                                </CCardFooter>
           
                            </CCardBody>
                        </CCard>

                    </CCol>
                </CRow>




            </div>

        </div>

    )
}
export default OfferToSelection







