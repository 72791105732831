import notificationMsg from '../../assets/sounds/notification-msg.mp3';
import notificationGroupMsg from '../../assets/sounds/notification-group-msg.mp3';
import noSound from '../../assets/sounds/no-sound.mp3';

const audioDictionary = {};


const playSoundByName = (soundName, id = '', loop = false) => {
  let mp3File;

  switch (soundName) {
    case 'MSG':
      mp3File = notificationMsg;
      break;
    case 'GROUP_MSG':
      mp3File = notificationGroupMsg;
      break;
    case 'NO_SOUND':
      mp3File = noSound;
      break;
    default:
      mp3File = notificationMsg;
      break;
  }

  return playSound(mp3File, id, loop);
};

const playSound = (mp3File, id = '', loop = false) => {
  let audio = new Audio(mp3File);

  const loopProp = loop && id;

  if (loopProp) {
    audio.loop = true;
  }

  if (id) {
    removeAudio(id);
    addAudio(id, audio);
  }

  if (!loopProp) {
    audio.onended = () => {
      if (id) {
        removeAudio(id);
      } else {
        stopAudio(audio);
      }
    }
  }

  return new Promise((resolve) => {
    try {
      audio
        .play()
        .then(() => {
          resolve(true);
        })
        .catch(e => {
          console.warn('playSound 1', e);
          resolve(false);
        });
    } catch (e) {
      console.warn('playSound', e);
      resolve(false);
    }
  });
};

/**
 * Adds the audio into the dictionary
 *
 * @param {String} id
 * @param {HTMLAudioElement} audio
 * @returns void
 */
const addAudio = (id, audio) => {
  audioDictionary[id] = audio;
};

/**
 * Gets audio from dictionary
 *
 * @param {String} id
 * @returns {HTMLAudioElement}
 */
const getAudio = (id) => {
  return audioDictionary[id];
};

/**
 * Removes audio from dictionary
 *
 * @param {String} id
 * @returns void
 */
const removeAudio = (id) => {
  const audio = getAudio(id);

  if (audio) {
    stopAudio(audio);
    delete audioDictionary[id];
  }
};

/**
 * Stops the provided audio
 *
 * @param {HTMLAudioElement} audio
 * @returns void
 */
const stopAudio = (audio) => {
  audio.pause();
  audio.removeAttribute('src');
  audio.remove();
  audio = undefined;
};


export {
  playSound,
  playSoundByName,
  removeAudio,
};
