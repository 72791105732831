import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";
import {
    CNav,
    CNavItem,
    CNavLink,
    CCollapse,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { CardBody } from 'reactstrap';
import { color } from 'highcharts';
import Moment from "react-moment";
import Alert from "src/alert/alert";
import { isPermission } from 'src/utils/common';
const ViewSelectedPendingRequisition = (props) => {

    const { fun, pendingRequisition, mrfSelectedRecord } = props
    const [accordion, setAccordion] = useState(0)
    const [setStatus, setType] = useState({ e: "IJP" });
    const [settime, setTime] = useState({ e: "2" });
    const [setAmount33, setAmount3] = useState();
    const [setAmount32, setAmount2] = useState();
    const [setAmount31, setAmount1] = useState();
    
    const [setTypeOfAmountStatus, setTypeOfAmount] = useState({ e: "fix" });
    const [setInput, setInputRef] = useState({});
    const [MRF_JD, setMRF_JD] = useState()
    const [editMRFdisable, setEditMRFdisable] = useState(false);
    const [large, setLarge] = useState(false)
    const [largeR, setLargeR] = useState(false)
    const [DocDataR, setDocDataR] = useState("");
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert);
        setAlertMessage(msg);
    };
    const isAlertToggleClose = () => {
        setIsAlert(false);
    }
    let api = new API()
   
    useEffect(() => {
        resetAccordain()
    }, [fun])

  
    const ShareMRF = () => {
        swal({
            html: true,
            title: "Are you sure to share MRF?",
            // text: `Are you sure to post MRF`,
            icon: "success",
            buttons: ["No, cancel it!", "Share"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {

                let data = {

                }
                let result = await api.post("config.ShareMRF", data)
                if (result && result.code == 200) {
                    // console.log("mrf by id ", result.data[0])
                } else {
                    alert("something wrong....")
                }
            }
        })
    }
    const onChangeInput = (e) => {
        const { name, value } = e.target;
        setInputRef({
            ...setInputRef,
            [name]: value,
        });

    };
    const getJdByMrfId = async () => {
        setAccordion(accordion === 1 ? null : 1)
        let data = {
            mrf_id: pendingRequisition && pendingRequisition.mrf_id,
            applying_for: pendingRequisition && pendingRequisition.applying_for
        }
        let result = await api.get(config.getJdForMrf, true, data)
        if (result && result.code == 200) {
            // console.log("mrf jd by id ", result.data)
            setMRF_JD(result.data[0])
        } else {
            alert("something wrong....")
        }
    }
    const resetAccordain = () => {
        setAccordion(accordion == 0)
    }
    const submitApplyjob = async () => {
        if (setStatus.e == "REF") {
            var getData = {
                'setStatus': setStatus.e,
                'settime': settime.e,
                'setTypeOfAmountStatus': setTypeOfAmountStatus.e,
                "mrf_id": pendingRequisition.mrf_id,
                "fix_amount_and_percentageM": setAmount31.e,
                "fix_amount_and_percentageD": setAmount32.e,
                "applying_for": pendingRequisition.applying_for,
                "lastdate": setInput.lastdate
            }
        }
        if (setStatus.e == "IJP") {
            var getData = {
                'setStatus': setStatus.e,
                "mrf_id": pendingRequisition.mrf_id,
                "applying_for": pendingRequisition.applying_for,
                "lastdate": setInput.lastdate
            }
        }

        swal({
            content: true,
            title: "Are you sure?",
            text: `Are you sure to update`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.updateMrfType, getData)

                if (result && result.code == 200) {

                    // console.log("updated successfully", result)
                    isAlertToggle(result && result.message);
                    setLargeR(!largeR);

                } else {
                    // console.log("updated successfully", result)
                    isAlertToggle(result && result.message);

                    setLargeR(!largeR);
                    // alert(result && result.message)
                }
            }
        })
        // }
    }
    const applyJobRef = (dataReview) => {
        setEditMRFdisable(true);
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>___________________________", dataReview)
        setDocDataR(dataReview)
        setLargeR(!largeR);

    };
    const onChangeInputApplyJobRef = (e) => {

        setType({ e });

    };
    const onChangeInputTime = (e) => {

        setTime({ e });

    };
    const onChangeTypeOfAmount = (e) => {

        setTypeOfAmount({ e });

    };
    const onChange1 = (e) => {

        setAmount1({ e });

    };
    const onChange2 = (e) => {

        setAmount2({ e });

    };
    const onChange3 = (e) => {

        setAmount3({ e });

    };
  
    // console.log("setInput", setInput)
    //   alert(setStatus)
    //   console.log("setStatus",setStatus)
    return (
        <div className="">

            <CForm action="" encType="multipart/form-data" className="form-horizontal">

                <CRow>
                    <CCol md="6">

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Id</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={pendingRequisition && pendingRequisition.mrf_id} name="mrf_id" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Applying For</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={pendingRequisition && pendingRequisition.applying_for == "vacancy" ? "New Hiring" : pendingRequisition && pendingRequisition.applying_for} name="applying_for" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assigned To</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={pendingRequisition && pendingRequisition.assigned_to} name="assigned_to" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assigned By</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={pendingRequisition && pendingRequisition.assigned_by} name="assigned_by" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Final Status</CLabel>
                            </CCol>

                            <CCol xs="12" md="9">
                                <CInput value={pendingRequisition && pendingRequisition.mrf_final_status == 0 ? "Pending" : "Completed"} name="department" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <hr />
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Head Of Department</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.head_of_department} name="head_of_department" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Raised By</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.created_by_name} name="designation" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Min Qualification</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.min_qualification} name="min_qualification" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>


                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Location</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.location} name="location" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assigned At</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <label>
                                    {
                                        <Moment format="YYYY/MM/DD">
                                            {pendingRequisition && pendingRequisition.assigned_at}
                                        </Moment>} </label>

                            </CCol>
                        </CFormGroup>



                    </CCol>
                    <CCol md="6">



                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >No Of Position</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.no_of_position} name="no_of_position" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Learning Development Cost</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.learning_development_cost} name="learning_development_cost" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Year Of Experience</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.year_of_experience} name="year_of_experience" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Preffered Qualification</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.preffered_qualification} name="preffered_qualification" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Priority</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.priority} name="priority" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>
                        {/* <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Job Description</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.job_description} name="job_description" placeholder="" disabled/>
                            </CCol>
                        </CFormGroup> */}
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Type Of Appointment</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.type_of_appointment} name="type_of_appointment" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Justification Of Vacancy</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={mrfSelectedRecord && mrfSelectedRecord.justification_of_vacancy} name="justification_of_vacancy" placeholder="" disabled />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Created At</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <label>
                                    {
                                        <Moment format="YYYY/MM/DD">
                                            {mrfSelectedRecord && mrfSelectedRecord.created_at}
                                        </Moment>} </label>

                            </CCol>
                        </CFormGroup>

                    </CCol>
                </CRow>
                {isPermission('view_mrf_job_description') &&
                <div className="container">
                    <CCard>
                        <CCardHeader onClick={getJdByMrfId}>
                            <strong>JOB DESCRIPTION</strong>
                            {/* <CButton color="info" c lassName="text-center btn btn-sm mr-3 mt-2" onClick={getJdByMrfId} >Job Description</CButton>{' '} */}
                        </CCardHeader>

                        <CardBody>
                            <div className=" mb-3">
                                {/* JOB DESCRIPTION */}
                                <CCollapse show={accordion === 1}>
                                    <div className="jd">
                                        <strong>Location</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.location}<hr></hr>
                                        <strong>Role Summary</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.role_summary}<hr></hr>
                                        <strong>Responsibilities</strong> &nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.responsibilities}<hr></hr>
                                        <strong>Expirence</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.expirence}<hr></hr>
                                        <strong>Min Education Qualification</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.min_edu_qualification}<hr></hr>
                                        <strong>Preffered Education Qualification</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp;{MRF_JD && MRF_JD.preffered_edu_qualification}<hr></hr>
                                        <strong>Certificates</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.certificates}<hr></hr>
                                        <strong>Skill Needed</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.skill_needed}<hr></hr>
                                        <strong>Preffered Domain Knowledge</strong>&nbsp;<i className="fa fa-arrow-right"></i>&nbsp;&nbsp; {MRF_JD && MRF_JD.preffered_domain_knowledge}<hr></hr>
                                    </div>

                                </CCollapse>
                            </div>
                        </CardBody>

                    </CCard>

                </div>
                }
                <CCardFooter>
                    {isPermission('view_mrf_job_description') &&
                    <CButton color="info" className="text-center btn btn-sm mr-3  mt-2" onClick={getJdByMrfId} >Job Description</CButton>
                    }
                    {isPermission('ijp_referral_mrf') && 
                    <CButton
                        className=" mr-3"
                        data-toggle="tooltip"
                        data-placement="top"
                        style={{
                            background: "#2eb85c", border: "0px", float: "right"
                        }}
                        title="View"
                        onClick={() => applyJobRef()}
                        color="info"
                    >
                        IJP/REF
                    </CButton>
                    }
                    {/* <CButton type="button" onClick={() => applyJobRef()} className="btn btn-sm btn-primary mr-3 mt-2">Share MRF</CButton> */}

                </CCardFooter>

            </CForm>
            <CModal
                show={largeR}
                onClose={() => setLargeR(!largeR)}
                size="lg"
                color="success"
            >
                <CModalHeader closeButton>
                    <CModalTitle>For IJP/Referral<br /></CModalTitle>

                </CModalHeader>

                <CModalBody>
                    <CForm encType="multipart/form-data" className="form-horizontal" >

                        <CFormGroup row>
                            <CLabel col md="3" htmlFor="file-input">Type</CLabel>
                            <CCol xs="12" md="9">
                                <CSelect
                                    custom
                                    value={setStatus.e}
                                    onChange={(e) => onChangeInputApplyJobRef(e.target.value)}
                                    name="status"

                                >

                                    <option value="IJP">IJP</option>
                                    <option value="REF">REFERRALS</option>
                                </CSelect>

                            </CCol>
                        </CFormGroup>

                        {setStatus.e == "REF" &&
                            <>
                                <CFormGroup row>
                                    <CLabel col md="3" htmlFor="file-input">Disbursal Time</CLabel>
                                    <CCol xs="12" md="9">
                                        <CSelect
                                            custom
                                            value={settime.e}
                                            onChange={(e) => onChangeInputTime(e.target.value)}


                                        >

                                            <option value="1">1 Month</option>
                                            <option value="2">2 Month</option>
                                            <option value="3">3 Month</option>
                                            <option value="4">4 Month</option>
                                            <option value="5">5 Month</option>
                                            <option value="6">6 Month</option>
                                            <option value="7">7 Month</option>
                                            <option value="8">8 Month</option>
                                            <option value="9">9 Month</option>
                                            <option value="10">10 Month</option>
                                            <option value="11">11 Month</option>
                                            <option value="12">12 Month</option>
                                        </CSelect>

                                    </CCol>
                                </CFormGroup>
                                <CFormGroup row>
                                    <CLabel col md="3" htmlFor="file-input">Payment Type</CLabel>
                                    <CCol xs="12" md="9">
                                        <CSelect
                                            custom
                                            value={setTypeOfAmountStatus.e}
                                            onChange={(e) => onChangeTypeOfAmount(e.target.value)}
                                        >
                                            <option value="fix">Fixed</option>
                                            <option value="per">Percentage</option>


                                        </CSelect>

                                    </CCol>
                                </CFormGroup>
                                
                                    <>
                                        <CFormGroup row>
                                        {setTypeOfAmountStatus.e == "fix" &&
                                            <CCol md="3">
                                                <CLabel>Fixed Male Amount<span className="text-danger"> *</span></CLabel>
                                            </CCol>
}
{setTypeOfAmountStatus.e == "per" &&
                                <CCol md="3">

                                <CLabel >Percentage For Male<span className="text-danger"> *</span></CLabel>
                            </CCol> 
                            }
                                            <CCol md="9">
                                                <CInput type="number" id="fix_amount1" name="fix_amount1" placeholder="Enter Fixed Male Amount"  onChange={(e) => onChange1(e.target.value)}  required />
                                            </CCol>
                                        </CFormGroup>
                                        <CFormGroup row>
                                        {setTypeOfAmountStatus.e == "fix" &&
                                            <CCol md="3">
                                                <CLabel >Fixed Diversity Amount<span className="text-danger"> *</span></CLabel>
                                            </CCol>
}
{setTypeOfAmountStatus.e == "per" &&
                                <CCol md="3">

                                <CLabel >Percentage For Diversity<span className="text-danger"> *</span></CLabel>
                            </CCol> }
                                            <CCol md="9">
                                                <CInput type="number" id="fix_amount2" name="fix_amount2" placeholder="Enter Fixed Diversity Amount" onChange={(e) => onChange2(e.target.value)}  required />

                                            </CCol>
                                        </CFormGroup>
                                    </>
                            
                               
                                   

                            </>
                        }
                        <CFormGroup row>

                            <CCol md="3">

                                <CLabel >Apply Last Date<span className="text-danger"> *</span></CLabel>
                            </CCol>
                            <CCol md="9">
                                <CInput id="lastdate" type="date" name="lastdate" placeholder="Enter last date" onChange={onChangeInput} required />

                            </CCol>
                        </CFormGroup>
                    </CForm>
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={submitApplyjob} >Submit</CButton>{' '}
                    <CButton color="secondary" onClick={() => setLargeR(!largeR)}>Back</CButton>
                    {/* <CButton color="secondary" onClick={() => setLarge(!large)}>Cancel</CButton> */}
                </CModalFooter>
            </CModal>
            <Alert
                isAlert={isAlert}
                isAlertToggle={isAlertToggle}
                alertMessage={alertMessage}
                isAlertToggleClose={isAlertToggleClose}
            />
        </div>


    )
}
export default ViewSelectedPendingRequisition







