import { createReducer } from 'redux-act';
import action from './shiftDetail.Action';

const initialState = {
  shiftDetailPending: null,
  shiftDetailRes: null,
  shiftDetailError: null,
};

const shiftDetailRequestReducer = (state, payload) => {
  return {
    ...state,
    ...{
      shiftDetailPending: null,
      shiftDetailRes: null,
      shiftDetailError: null,
    },
  };
};
const shiftDetailSuccessReducer = (state, payload) => {
  return {
    ...state,
    ...{
      shiftDetailPending: null,
      shiftDetailRes: payload,
      shiftDetailError: null,
    },
  };
};
const shiftDetailErrorReducer = (state, payload) => {
  return {
    ...state,
    ...{
      shiftDetailPending: null,
      shiftDetailRes: payload,
      shiftDetailError: null,
    },
  };
};

export default createReducer(
  {
    [action.shiftDetailRequestAction]: shiftDetailRequestReducer,
    [action.shiftDetailSuccessAction]: shiftDetailSuccessReducer,
    [action.shiftDetailFauilureAction]: shiftDetailErrorReducer,
  },
  initialState,
);
