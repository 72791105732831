import { fork } from 'redux-saga/effects';
import { rootCommonSaga } from './common';
import { rootMessageSaga } from './messages';
import { rootAttendanceSaga } from './attendance';
import { rootMqttSaga } from './mqtt';
import { rootLeaveDataSaga } from './leaveDateList';
// import { rootShiftDetailSaga } from './shiftDeatilOfUser';
import rootShiftDetailSaga from './shiftDeatilOfUser/shiftDetail.Saga';

function* rootSaga() {
  yield fork(rootCommonSaga);
  yield fork(rootMessageSaga);
  yield fork(rootMqttSaga);
  yield fork(rootAttendanceSaga);
  yield fork(rootLeaveDataSaga);
  yield fork(rootShiftDetailSaga);
}

export default rootSaga;
