import React, { useState, useEffect } from 'react'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol} from '@coreui/react'
import ModalPreview from './modal_preview'
import ModalUpdate from './modal_update'
import ModalSendingWelcomeMail from '../new_candidate_access_management/modal_sending_welcome_mail'
import DateRangePicker from 'src/views/common/dateRangePicker';
import moment from 'moment';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'
import swal from 'sweetalert'
import { toast, ToastContainer } from 'react-toastify';
import ViewDetails from '../new_candidate_access_management/modal1';
import ViewDetails1 from '../new_candidate_access_management/modal2'

const IJPAnnouncement = (props) => {
    const { candidateIJP, selectedCandidateForijp } = props
    let api = new API()

    const [iDCardDetails, setIDCardDetails] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen1, setIsOpen1] = useState(false)
    const [active, setActive] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpen1, setIsModalOpen1] = useState(false)
    const [empUserID, setEmpUserID] = useState()
    const [candidateDetails, setCandidateDetails] = useState()
    const [finalJoined, setFinalJoined] = useState()
    const [generatedEmpId, setGeneratedEmpId] = useState()
    const [sendEmailModal, setSendEmailModal] = useState(false)
    const [selectedCandidate, setSelectedCandidate] = useState([])
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        getFinalJoinedCandidate()
    }, [candidateIJP])
    const getFinalJoinedCandidate = async () => {
        let result = await api.get(config.totalCandidateJoined)
        if (result && result.code === 200) {
            setFinalJoined(result.data)
        } else {

        }
    }

    const isViewModalOpen = (candidate) => {
        setIsModalOpen(!isModalOpen)
        setCandidateDetails(candidate)
    }
    const isViewModalOpen1 = (candidate) => {
        setIsModalOpen1(!isModalOpen1)
        setCandidateDetails(candidate)
    }

    const sendWelcomeMailFun = async (data) => {
        setSendEmailModal(!sendEmailModal)
        setSelectedCandidate(data)
    }

    const isViewModalOpen2 = (emp_user_id) => {
        setEmpUserID(emp_user_id)
        setIsOpen(!isOpen)
    }

    const isViewModalOpen3 = (emp_user_id) => {
        setEmpUserID(emp_user_id)
        setIsOpen1(!isOpen1)
    }


    const sendAnnouncement = async (data) => {
        let data1 = {
            candidate_id: data?.id,
            name: data?.name,
            department: data?.department,
            designation: data?.Designation,
            profile_picture : data?.employee_passport_size_photo,
            gender: data?.gender
        }
        swal({
            html: true,
            title: "Are you sure?",
            text: `You want to send IJP Announcement for ${data?.name}`,
            icon: "success",
            buttons: ["No, cancel it!", "Send"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.IJPAnnouncement,data1)
                if (result && result.code === 200) {
                    toast.success(result.message);
                    selectedCandidateForijp();
                }
                else{
                    toast.warning(result.message)
                }
            }
        })
    }


    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"> <u>IJP ANNOUNCEMENT</u></h3>
                <CRow>
                    <CCol xs="12" >
                        <CCard>
                            <CCardBody>
                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink>
                                                Pending Announcement
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink>
                                                Completed Announcement
                                            </CNavLink>
                                        </CNavItem>

                                    </CNav>
                                    <CTabContent>
                                        <CTabPane>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Candidate ID</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">MRF ID</th>
                                                            <th scope="col">MRF Applying For</th>
                                                            <th scope="col">Department</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">Action</th>
                                                        </>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {candidateIJP && candidateIJP.filter(candidate => candidate.send_announcement === "0").map((data, index) => (
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data.id}</td>
                                                            <td>{data.name}</td>
                                                            <td>{data.email}</td>
                                                            <td>{data.mrf_id}</td>
                                                            <td className="letter">{data.mrf_applying_for === "vacancy" ? "New Hiring" : data.mrf_applying_for}</td>
                                                            <td>{data.department}</td>
                                                            <td>{data.Designation}</td>
                                                            <td width={"13%"}>
                                                                <CButton data-toggle="tooltip" title="Preview" className="btn btn-info mr-1" onClick={() => isViewModalOpen(data)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                                                <CButton title="Edit Details" className="btn btn-danger mr-1" onClick={() => isViewModalOpen1(data)}><i className="fa fa-pencil" aria-hidden="true"></i></CButton>
                                                                <CButton title="Send Announcement" className="btn btn-primary mr-1" onClick={() => sendAnnouncement(data)}><i className="fa fa-paper-plane" aria-hidden="true"></i></CButton>

                                                            </td>
                                                        </tr>
                                                    ))}


                                                </tbody>
                                            </table>
                                        </CTabPane>
                                        <CTabPane>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                        <>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Candidate ID</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">MRF ID</th>
                                                            <th scope="col">MRF Applying For</th>
                                                            <th scope="col">Department</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">Action</th>
                                                        </>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {candidateIJP && candidateIJP.filter(candidate => candidate.send_announcement === "1").map((data, index) => (
                                                       <tr>
                                                       <th scope="row">{index + 1}</th>
                                                       <td>{data.id}</td>
                                                       <td>{data.name}</td>
                                                       <td>{data.email}</td>
                                                       <td>{data.mrf_id}</td>
                                                       <td className="letter">{data.mrf_applying_for === "vacancy" ? "New Hiring" : data.mrf_applying_for}</td>
                                                       <td>{data.department}</td>
                                                       <td>{data.Designation}</td>
                                                       <td>
                                                           <CButton data-toggle="tooltip" title="Preview" className="btn btn-info mr-1" onClick={() => isViewModalOpen(data)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>

                                                       </td>
                                                   </tr>
                                                    ))}


                                                </tbody>
                                            </table>
                                        </CTabPane>
                                    </CTabContent>
                                </CTabs>
                                <CCardFooter>
                                    <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" ><i className="fa fa-arrow-left" aria-hidden="true"></i></CButton>{' '}
                                </CCardFooter>
                            </CCardBody>
                        </CCard>

                    </CCol>
                </CRow>

            </div>
            <ViewDetails1 isOpen1={isOpen1} isViewModalOpen3={isViewModalOpen3} empUserID={empUserID} />

            <ViewDetails isOpen={isOpen} isViewModalOpen2={isViewModalOpen2} empUserID={empUserID} />

            <ModalPreview isModalOpen={isModalOpen} message={`Preview IJP Announcement ${candidateDetails && candidateDetails.name}`} isViewModalOpen={isViewModalOpen}
                candidateDetails={candidateDetails} selectedCandidateForijp={selectedCandidateForijp} />


            <ModalUpdate isModalOpen={isModalOpen1} message="Update Information" isViewModalOpen={isViewModalOpen1}
                candidateDetails={candidateDetails}
                generatedEmpId={generatedEmpId} />
            
            <ModalSendingWelcomeMail 
            isModalOpen={sendEmailModal}
            isViewModalOpen={sendWelcomeMailFun}
            setSendEmailModal={setSendEmailModal}
            selectedCandidate={selectedCandidate}/>

            

            <ToastContainer />
        </div>
    )
}
export default IJPAnnouncement