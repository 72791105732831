import {
  take,
  put,
  call,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { attendanceActions as actions, attendanceService } from './index';

function* handleGetAttendanceBasicDetails() {
  const action = yield take(actions.getAttendanceBasicDetailsRequest.getType());
  const dataParams = action.payload;
  let res;
  try {
    res = yield call(attendanceService.getAttendanceBasicDetails, dataParams);

    if (res.isError) {
      yield put(actions.getAttendanceBasicDetailsError(res));
      //history.push('/home');
    } else {
      yield put(actions.getAttendanceBasicDetailsSuccess(res));
    }
  } catch (error) {
    yield put(actions.getAttendanceBasicDetailsError());
  }
}

function* handleGetAttendanceLogsWithDuration() {
  const action = yield take(
    actions.getAttendanceLogsWithDurationRequest.getType(),
  );
  const dataParams = action.payload;
  let res;
  try {
    res = yield call(
      attendanceService.getAttendanceLogsWithDuration,
      dataParams,
    );

    if (res.isError) {
      yield put(actions.getAttendanceLogsWithDurationError(res));
    } else {
      yield put(actions.getAttendanceLogsWithDurationSuccess(res));
    }
  } catch (error) {
    yield put(actions.getAttendanceLogsWithDurationError());
  }
}

function* handleGetAttendanceBasicDetailsWatcher() {
  yield takeEvery(
    actions.getAttendanceBasicDetailsRequest,
    handleGetAttendanceBasicDetails,
  );
}

function* handleGetAttendanceLogsWithDurationWatcher() {
  yield takeEvery(
    actions.getAttendanceLogsWithDurationRequest,
    handleGetAttendanceLogsWithDuration,
  );
}

function* rootAttendanceSaga() {
  yield all([
    handleGetAttendanceBasicDetails(),
    handleGetAttendanceBasicDetailsWatcher(),

    handleGetAttendanceLogsWithDuration(),
    handleGetAttendanceLogsWithDurationWatcher(),
  ]);
}

export default rootAttendanceSaga;
