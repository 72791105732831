import * as yup from 'yup';
import appConstants from '../core/configs/Constants.config';
import { digitsOnly } from './ValidationHelper';

const attendanceCorrectionSchema = yup.object().shape({
  checkIn: yup
    .string()
    .nullable()
    .required(appConstants.validations.fieldReq.replace('$FIELD', 'Checkin')),
  reason: yup
    .string()
    .nullable()
    .required(appConstants.validations.fieldReq.replace('$FIELD', 'Reason'))
    .max(250, appConstants.validations.maxLength.replace('$1', 250)),
  invoiceDueDate: yup
    .date()
    // .default(new Date())
    .required(appConstants.validations.fieldReq.replace('$FIELD', 'Date')),
  // .min(yup.ref('invoiceDate'),"End date can't be before Start date"),
});

export { attendanceCorrectionSchema };
