import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import store from './store';
import { CookiesProvider } from 'react-cookie';
import { getStore } from './core/store';
import { STORAGE_NAME, DB_NAME } from './core/configs/BrowserStorage.config';

store.subscribe(() => console.log(store.getState()));
React.icons = icons;

const renderApp = storeToRestore => {
  const initUrl = new URL(window.location.href);
  const loginUserParam = initUrl.searchParams.get('loginuser');

  if (loginUserParam) {
    window.loginUserParam = loginUserParam;
  }

  ReactDOM.render(
    <Provider store={getStore(storeToRestore)}>
      {/* <CookiesProvider> */}
      <App />
      {/* </CookiesProvider> */}
    </Provider>,
    document.getElementById('root'),
  );
};

// ReactDOM.render(
//   <Provider store={store}>
//     {/* <CookiesProvider> */}
//       <App />
//     {/* </CookiesProvider> */}
//   </Provider>,
//   document.getElementById('root')
// );

if (window.clearStorage) {
  renderApp();
} else {
  const DBRequest = indexedDB.open(DB_NAME);
  DBRequest.onerror = function() {
    renderApp();
  };
  DBRequest.onsuccess = function(event) {
    try {
      const db = event.target.result;
      const transaction = db.transaction('keyvaluepairs');
      const objectStore = transaction.objectStore('keyvaluepairs');
      const DBQuery = objectStore.get(STORAGE_NAME);
      DBQuery.onerror = function() {
        renderApp();
      };
      DBQuery.onsuccess = function(ev) {
        renderApp(ev.target.result);
      };
    } catch (e) {
      renderApp();
    }
  };
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
