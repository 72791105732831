import { createAction } from 'redux-act';

const mqttClientInstanceRequest = createAction('MQTT_CLIENT_INSTANCE_REQUEST');
const mqttClientInstance = createAction('MQTT_CLIENT_INSTANCE', data => data);
const mqttClientConnectStatus = createAction('MQTT_CLIENT_CONNECT_STATUS', data => data);
const mqttClientOnMessage = createAction('MQTT_CLIENT_ON_MESSAGE', data => data);

const mqttPublish = createAction('MQTT_PUBLISH', data => { console.log({data}); return data});
const mqttTopicSubscribe = createAction('MQTT_TOPIC_SUBSCRIBE', data => data);

const clearMqttStore = createAction(
  'CLEAR_MQTT_STORE',
  data => data,
);

export default {
  clearMqttStore,
  mqttClientInstanceRequest,
  mqttClientInstance,
  mqttClientConnectStatus,
  mqttClientOnMessage,
  mqttPublish,
  mqttTopicSubscribe,
};
