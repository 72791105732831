import React, { useState, useEffect } from "react";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  // CModalFooter,
  // CButton,
} from '@coreui/react'
// import {onboardData} from './OnBoardSurvey';
import "./OnBoardingSurvey.css"
// import { AvForm, AvInput } from "availity-reactstrap-validation";
import { config } from 'src/utils/apiUrl'
import API from 'src/utils/apiCalling'
import { getLocalDataAsObject } from "src/utils/CoustomStorage";
import swal from "sweetalert";
import CircularSlider from '@fseehawer/react-circular-slider';

const OnBoardingSurveyModal = (props) => {
  const { employeeName, isOpenOnboardSurvey, surveyId, setIsOpenOnboardSurvey } = props

  let api = new API()
  let emp_id = getLocalDataAsObject("user");




  const [index, setIndex] = useState(0);
  // const [targetId, setTargetId] = useState()
  const [onboardData, setOnboardData] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  let [questionResponse, setQuestionResponse] = useState([])
  let [attemptedQuestion, setAttemptedQuestion] = useState()
  // const [answerArr, setAnswerArr] = useState([])
  const [ratingValue, setRatingValue] = useState(0)
  const [lastIndex, setLastIndex] = useState(0)

  const getSurveyQuestion = async () => {
    let data = {
      "survey_id": surveyId
      // "survey_id":"31"
    }
    let result = await api.get(config.getSurveyDetails, true, data)
    if (result && result.code == 200) {
      // console.log("getsurvey", result.data)
      setOnboardData(result.data)
    }
  }
  const nextQuestionFun = (index) => {
    // let newAnswerArr=answerArr
    // attemptedQuestion["answer"]=answerArr
    setQuestionResponse([...questionResponse, attemptedQuestion])

    // console.log("questionResponse", questionResponse)
    if (onboardData.length > index) {
      setIndex(index + 1)
      setButtonDisabled(false)
      // setAnswerArr([])
    } else {

    }
  }
  const previousQuestion = (index) => {
    if (index > 0) {
      setIndex(index - 1)
      if (index == 1) {
        setButtonDisabled(true)
      }

    }
  }

  useEffect(() => {
    getSurveyQuestion();

  }, [surveyId])
  const onchangeRating = (value, quesId, OptionType) => {
    setRatingValue(value)

    // console.log("nirmalmmm", quesId, OptionType, value)
    setAttemptedQuestion({ question_id: quesId, option_type: OptionType, option_response: [value], text: "211" })

  }
  const onChangeAttempted = (option, quesId, OptionType, index) => {

    // console.log("nirmal", quesId, OptionType, option)
    setAttemptedQuestion({ question_id: quesId, option_type: OptionType, option_response: [option], text: "211" })
    setLastIndex(index)
  }
  const saveSurveyResponse = async () => {
    // console.log("saveResponses", questionResponse)
    let data = {
      "survey_id":surveyId,
      "emp_user_id": emp_id[0].id,
      "question_response": JSON.stringify(questionResponse)
    }
    swal({
      title: "Are you sure?",
      text: "Are you sure to submit your survey",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let result = await api.post(config.addSurveyResponse, data)
        if (result && result.code == 200) {
          setIsOpenOnboardSurvey(false)

          swal("Your survey has been submitted successfully", {
            icon: "success",
          });

        } else {
          swal(result.message, {
            icon: "warning",
          });
        }



      } else {

      }
      // console.log("saveresonse",data)
      // let result =await api.post(config.addSurveyResponse,data)
      // console.log("saveresonse",data)
      // if(result&&result.code==200){
      //     setIsOpenOnboardSurvey(false)
      //     alert(result.message)
      // }
    });
  }



  // let onboardDatas=onboardData;


  return (<>




    <CModal
      show={isOpenOnboardSurvey}

      size="lg"
    >
      {onboardData && onboardData.length > index && <>
        <CModalHeader>
                    {/* <button onClick={console.log("attemp",emp_id)}>ggg</button> */}
                    <CModalTitle>Hello {employeeName} !! 
                    <div>
                     <strong>Welcome to QDegrees Family</strong>
                      </div>
                    </CModalTitle>
                    
                </CModalHeader>

                <CModalBody className="question-body">
                  
                  <div className="newsurvey-container" style={{minHeight: "500px !important;"}}>
                  <div className="newsurvey-containerQ" style={{lineHeight:"6vh",textAlign:"center",fontSize:"2vh",height:"auto"}}>
                      <div className="q-box" style={{padding:"3vh"}}>{onboardData[index].question}</div>
                  </div>
                       <div className="newsurvey-card" style={{padding:"2vh"}}>
                       <div className="body q-body" style={{height:"auto"}}>
  <div className="form-check">
     <form>
                    {onboardData[index].optionType === "textarea" ? <textarea className="form-control" id="exampleFormControlTextarea1" rows="6" placeholder="Type........."></textarea> : onboardData[index].optionType === "Rating" ?
                      <div className="text-center align-middle" style={{ textAlign: "center" }}>
                        {/* {console.log("data",onboardData[index].option.map((item)=>item.option))} */}
  
                        <div>
                          <CircularSlider
                            className=" "
                            onChange={value => { onchangeRating(value, onboardData[index].question_id, onboardData[index].optionType) }}
                            label={ratingValue >= 0 && ratingValue <= 6 ? "Bad" : ratingValue <= 8 && ratingValue > 6 ? "Good" : "Better"}
                            data={onboardData[index].option.map((item) => item.option)}
                            min={0}
                            max={300}
                            progressColorFrom="	#FF0000"
                            progressColorTo="#008000"
                            trackSize={20}
                            width={300}
                            knobSize={50}
                            progressSize={20}
                            labelFontSize={20}
                            hideKnob={true}
                          
  
                            labelColor={ratingValue >= 0 && ratingValue <= 6 ? "#FF0000" : ratingValue <= 8 && ratingValue > 6 ? "#800080" : "#008000"}
                          />
                        </div>
                        <div>
                          {ratingValue >= 0 && ratingValue <= 6 &&
                            // <span className=" server-emoji" >&#128532;</span>
                            <img className="server-emoji" src="images/icons/emoji/sad.gif" height={"70"} width={"70"}/>
                          }
                          {ratingValue > 6 && ratingValue <= 8 &&
                            // <span className=" server-emoji" >&#128522;</span>
                            <img className="server-emoji" src="images/icons/emoji/normal.gif" height={"70"} width={"70"}/>
                          }
                          {ratingValue >= 9 && ratingValue <= 10 &&
                            // <span className=" server-emoji" >&#128516;</span>
                            <img className="server-emoji" src="images/icons/emoji/enthusiastic.gif" height={"70"} width={"70"}/>
                          }
                        </div>
  
  
                      </div>
                      : <> {onboardData[index].option && onboardData[index].option.length > 0 && onboardData[index].option.map((items) => (<>
                        <input className="form-check-input" id={items.option_id} checked={index == lastIndex && items.option == attemptedQuestion.option_response[0] ? true : false} type={onboardData[index].optionType === "Radio" ? "radio" : onboardData[index].optionType} name={onboardData[index].question_id}
                          onClick={(e) => {
                            onChangeAttempted(items.option, onboardData[index].question_id, onboardData[index].optionType, index)
                          }} required />
                        <label className="form-check-label" for={items.option_id} style={{ fontWeight: "bold" }}>{items.option}</label>
                        <br />
  
                        <br />
  
                      </>))
  
                      }
                      </>}
  
  
  
  
  
                  </form>
  </div>
  </div>
  <div className="row">
                      <div className="col text-left">
                      {buttonDisabled===false&&
                <button className="btn btn-outline-info"onClick={()=>previousQuestion(index)}><i className="fa fa-arrow-left"></i> Back</button>}
                      </div>
                      <div className="col text-right">
                      <button className="btn btn-outline-info" type="button" onClick={()=>nextQuestionFun(index)}>Next <i className="fa fa-arrow-right"></i></button>
                      </div>
                      </div>
                       </div>
                     </div>
                  </CModalBody></>}
      {onboardData && onboardData.length > 0 && onboardData.length === index &&

        <div className="card-body q-body " style={{ paddingTop: "5rem", marginTop: "3rem" }} >
          <div className=" text text-center"> <h3>You have compleated your survey</h3>
            <div style={{ paddingTop: "2rem" }}> <button className="btn btn-primary text-center" onClick={saveSurveyResponse}>Submit </button></div>
          </div>




          {/* <img style={{width:"400px",height:"400px"}} src="./images/feedbackImage.jpg"></img> */}

        </div>}



    </CModal>

  </>
  );
};
export default OnBoardingSurveyModal;