import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import {toast, ToastContainer} from 'react-toastify'

const FeedbackModal = ({
  showFeedback,
  handleFeedBackForm,
  mrf_id,
  mrf_applying_for,
  candidate_id,
  candidateDetails,
}) => {
  const api = new API();
  const [feedback, setFeedback] = useState({});
  const getEmployeeFeedback = async () => {
    let result = await api.get(config.getInterviewRoundAssesment, true, {
      mrf_id,
      mrf_applying_for,
      candidate_id,
    });
    if (result.code === 200) {
      setFeedback(result?.data[0]);
    } else {
      setFeedback({});
    }
  };
  useEffect(() => {
    {
      mrf_id && getEmployeeFeedback();
    }
  }, [mrf_id]);

  return (
    <CModal
      show={showFeedback}
      onClose={() => handleFeedBackForm()}
      size="lg
    "
    >
      <CModalHeader closeButton>
        <CModalTitle>Candidate Feedback Form</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <form>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Demonstrates Respect
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.demonstrates_respect == '0' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio1">
                  0
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.demonstrates_respect == '1' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  1
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Demonstrates Integity
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_integrity == '1' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_integrity == '2' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Demonstrates Simplicity
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_simplicity == '1' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_simplicity == '2' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_simplicity == '3' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  3
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_simplicity == '4' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  4
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_simplicity == '5' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  5
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Demonstrates Excellence
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_excellence == '1' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_excellence == '2' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_excellence == '3' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  3
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_excellence == '4' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  4
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.demonstrates_excellence == '5' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  5
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Communication Skill(Spoken)
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.communication_skills == '1' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.communication_skills == '2' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.communication_skills == '3' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  3
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.communication_skills == '4' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  4
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.communication_skills == '5' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  5
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Adaptability
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.adaptability == '1' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.adaptability == '2' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.adaptability == '3' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  3
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.adaptability == '4' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  4
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.adaptability == '5' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  5
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Motivation
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.motivation == '1' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.motivation == '2' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.motivation == '3' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  3
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.motivation == '4' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  4
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.motivation == '5' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  5
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Problem Solving
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.problem_solving == '1' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.problem_solving == '2' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.problem_solving == '3' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  3
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.problem_solving == '4' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  4
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={feedback?.problem_solving == '5' ? true : false}
                />
                <label className="form-check-label" for="inlineRadio2">
                  5
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Attitude For Learning New Skill
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.attitude_for_learning_new_skills == '1'
                      ? true
                      : false
                  }
                />
                <label className="form-check-label" for="inlineRadio1">
                  1
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.attitude_for_learning_new_skills == '2'
                      ? true
                      : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  2
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.attitude_for_learning_new_skills == '3'
                      ? true
                      : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  3
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.attitude_for_learning_new_skills == '4'
                      ? true
                      : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  4
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.attitude_for_learning_new_skills == '5'
                      ? true
                      : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  5
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Technical Skill(If any)
            </label>
            <div className="col-sm-6">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                value={feedback?.technical_skills}
                rows="3"
                disabled
              ></textarea>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Language Known
            </label>
            <div className="col-sm-6">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={feedback?.languages_known}
                disabled
              ></textarea>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Comment
            </label>
            <div className="col-sm-6">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={feedback?.comments}
                disabled
              ></textarea>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
              Overall Evaluation
            </label>
            <div className="col-sm-6">
              <div className=" form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.overall_evaluation == 'strong' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio1">
                  Strong
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.overall_evaluation == 'can_be_consider'
                      ? true
                      : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  Can be considered
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    feedback?.overall_evaluation == 'reject' ? true : false
                  }
                />
                <label className="form-check-label" for="inlineRadio2">
                  Reject
                </label>
              </div>
            </div>
          </div>
        </form>
      </CModalBody>
      <ToastContainer/>
    </CModal>
  );
};

export default FeedbackModal;
