import { data } from 'jquery';
import React, { useEffect, useState } from 'react'
import API from "src/utils/apiCalling";
import { config } from "src/utils/apiUrl";

import moment from "moment";
import { DatePicker, Space } from 'antd';
import DateRangePicker from 'src/views/common/dateRangePicker';


export default function GetAttendence() {
  const api = new API();
  const [inOut, setInOut] = useState()

  const attendencegetData = async () => {

    let AcsEventCond = {
      from_date: "2023-02-01 00:00:00",
      to_date: "2023-02-01 23:59:59",
    }
    let result = await api.get(config.getBOEmployeeAttendanceDetails, true, AcsEventCond)
    if (result && result.code == 200) {
      setInOut(result && result.data)
    }

  }

  useEffect(() => {
    attendencegetData();
  }, [])

  return (
    <div>
      <div><DateRangePicker/></div>
    <div className='card p-5'>
      <div className='container'>
        <div className="row">
          <div className="col-sm-6">
            <div className='card'>
              <div style={{ "border": "solid" }} className="card">
                <h3 style={{ "textAlign": "center", "color": "black" }} className="card-title">Punch In</h3>
                <div className="card-body">
                  <ul>
                    {inOut && inOut.in_device_data.map((user,_index) =>
                      <li>Lable:{user.label} ,Time:{user.time}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className='card'>
              <div style={{ "border": "solid" }} className="card">
                <h3 style={{ "textAlign": "center", "color": "black" }} className="card-title">Punch Out</h3>
                <div className="card-body">
                  <ul>
                    {inOut && inOut.out_device_data.map((user,_index) =>
                      <li>Lable:{user.label} ,Time:{user.time}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    </div>
  )
}

