import React, { useState, useEffect } from 'react'
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CButton} from '@coreui/react'

import { config } from '../../../src/utils/apiUrl'
import API from '../../../src/utils/apiCalling'


const ModalAccessManagement = (props) => {
    let api = new API()
    const { isModalOpen, message, isViewModalOpen, candidateDetails, addEmployeeEmpId, generatedEmpId,generatedEmailType } = props
    const [officialEmpId, setOfficialEmpId] = useState()
    const [branch,setBranch] = useState("")
    const [selectBranch,setSelectBranch] = useState("")
    const [selectedCandidateId, setSelectedCandidateId] = useState("")
    const [generateEmailType, setGenerateEmailType] = useState("")

    useEffect(() => {
            if(candidateDetails && candidateDetails.email === selectedCandidateId){
                setOfficialEmpId(generatedEmpId)
                setGenerateEmailType(generatedEmailType)
            } else {
                setOfficialEmpId("")
                setSelectedCandidateId(candidateDetails && candidateDetails.email)
                setGenerateEmailType("")
            }
        // }
    }, [candidateDetails, generatedEmpId])

    const branches = async () => {
        let result = await api.get(config.branches)
        if (result && result.code === 200) {
            setBranch(result.data)
        } else {

        }
    }
    useEffect(() => {
        branches()
    }, [])

    return (
        <div className="">
            <CModal
                show={isModalOpen}
                onClose={() => isViewModalOpen()}
                size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form style={{width:"90%", margin:"auto"}}>
                        <div className="form-group">
                            <label for="emp_id">Employee Email ID</label>
                            <input type="text" disabled className="form-control" value={candidateDetails && candidateDetails.email} placeholder="" />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Branch Name <b style={{color:"red"}}>*</b></label>
                            <select type="select" className="form-control" value={selectBranch} onChange={(e) => setSelectBranch(e.target.value)} id="selectBranch" required> 
                            <option hidden>Select Branch Name</option>
                            {branch && branch.map((data) => (
                                <option value={data.id}>{data.branch_name}</option>
                            ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Email Type <b style={{color:"red"}}>*</b></label>
                            <select type="select" className="form-control" value={generateEmailType} onChange={(e) => setGenerateEmailType(e.target.value)} id="generateEmailType" required> 
                            <option hidden>Select Email Type</option>
                            <option value=".org">.org</option>
                            <option value=".com">.com</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Emp ID <b style={{color:"red"}}>*</b></label>
                            <input type="text" className="form-control" value={officialEmpId} onChange={(e) => setOfficialEmpId(e.target.value)} id="emp_id" placeholder="Enter Emp ID (Ex. QD1502 OR Q150)" required/>
                        </div>

                    </form>
                </CModalBody>
                <CModalFooter>
                        <button type="button" className="btn btn-success float-right" onClick={() => addEmployeeEmpId(candidateDetails.id,officialEmpId,generateEmailType,selectBranch)}>Submit</button>
                    <CButton color="danger" onClick={() => isViewModalOpen()}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                </CModalFooter>
            </CModal>
            {/* <ToastContainer/> */}
        </div>
    )
}
export default ModalAccessManagement







