import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import swal from 'sweetalert';
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from '@coreui/react';
import { withCookies } from 'react-cookie';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { getLocalDataAsObject, setLocalDataAsObject } from 'src/utils/CoustomStorage';
import { toast, ToastContainer } from "react-toastify";
import { encryptValue } from 'src/utils/common';
const TheHeaderDropdown = props => {
  const api = new API();
  const { cookies } = props;
  const appPort = config.appPort;
  const [profileImageShow, setProfileImageShow] = useState('');

  useEffect(() => {
    getProfileImage();
  }, []);

  const logging_out = () => {
    swal({
      html: true,
      title: 'Are you sure to logout?',
      icon: 'success',
      buttons: ['No, cancel it!', 'Yes'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        window.location.href = '/#/';
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        cookies.remove('token');
        cookies.remove('user');
      }
    });
  };
  const getProfileImage = async () => {
    let result = await api.get(config.getEmployeeProfilePic);
    if (result && result.code === 200) {
      setProfileImageShow(
        result.data.root_path + '/' + result.data.data[0].filename,
      );
    } else {
      // console.log(result && result.message);
    }
  };
  const saveUser = async (email, password) => {
    let data = {
      email: email,
      password: password,
      device_id: '',
    };

    let result = await api.post(config.login, data);
    if (result && result.code === 200) {
      await setLocalDataAsObject('token', result.data.token);
      await setLocalDataAsObject("user", encryptValue(JSON.stringify(result?.data?.user)));
      await sessionStorage.setItem(
        'urlData',
        JSON.stringify(result.data.urlData),
      );
      await sessionStorage.setItem(
        'urlData1',
        JSON.stringify(result.data.urlData1),
      );
      await sessionStorage.setItem('attendanceModal', 1);
      await cookies.set('token', result.data.token);
      await cookies.set('user', result.data.user);
      await setLocalDataAsObject('urlData', result.data.urlData);
      await setLocalDataAsObject('urlData1', result.data.urlData1);
      window.location.reload();
    } else {
      toast.error(result && result.message);
    }
  };
  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={profileImageShow ? profileImageShow : 'avatars/6.jpg'}
            width={'700'}
            height={'600'}
            className="c-avatar-img"
            // alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem> */}
        {/* <CDropdownItem>
           <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-task" className="mfe-2" />
          Tasks
          <CBadge color="danger" className="mfs-auto">42</CBadge>
        </CDropdownItem> */}
        {/* <CDropdownItem>
          <CIcon name="cil-comment-square" className="mfe-2" />
          <Link to="/dashboard/my_info"><strong>My Info</strong></Link>
        </CDropdownItem> */}
        {/* <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
         <strong> Settings</strong>
        </CDropdownItem> */}
        <CDropdownItem>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          <Link to="/dashboard/ChangePassword">
            <strong>Change Password</strong>
          </Link>
        </CDropdownItem>
        {appPort === '3003' && <>
          <CDropdownItem  onClick={(e)=>saveUser('test@qdegrees.com','Admin@1')}>
            <CIcon name="cil-input-power" className="mfe-2" />
              <strong>Super Admin</strong>
          </CDropdownItem>
          <CDropdownItem onClick={(e)=>saveUser('sonam.rara@qdegrees.com','Admin@123')}>
            <CIcon name="cil-input-power" className="mfe-2" />
              <strong>HR Manager</strong>
          </CDropdownItem>
          <CDropdownItem onClick={(e)=>saveUser('abhishek.sharma1@qdegrees.com','Admin@2023')}>
            <CIcon name="cil-input-power" className="mfe-2" />
              <strong>Recruiter</strong>
          </CDropdownItem>
          <CDropdownItem onClick={(e)=>saveUser('onboarding@qdegrees.com','Admin@123')}>
            <CIcon name="cil-input-power" className="mfe-2" />
              <strong>Onboarding Employee</strong>
          </CDropdownItem>
          </>
        }
        <CDropdownItem divider />
        <CDropdownItem onClick={logging_out}>
          <CIcon name="cil-input" className="mfe-2" />
          <strong> Log Out </strong>
        </CDropdownItem>
      </CDropdownMenu>
      <ToastContainer/>
    </CDropdown>
  );
};

export default withCookies(TheHeaderDropdown);