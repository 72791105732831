import React, { useState, useEffect } from 'react'
import AdminDashboardAllSection from './allSectionAdminDashboard'


const AdminDashboard = () => {
  
  useEffect(()=> {

  },[])
  return (
  <div>
    <AdminDashboardAllSection/>
  </div>

  )
}
export default AdminDashboard