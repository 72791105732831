import React, { useState, lazy, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {
  CBadge, CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CProgress,
  CCardGroup,
  CProgressBar,
  CRow,
  CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
  CCallout
} from '@coreui/react'
import {
  CChartDoughnut
} from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { withRouter } from "react-router-dom";

import ChartLineSimple from '../charts/ChartLineSimple';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import MainChartExample from '../charts/MainChartExample.js'
import swal from "sweetalert";
import { ToastContainer } from 'react-toastify';
import { setRef } from '@material-ui/core';
import ChatBotComponent from '../common/chatbot';
import moment from 'moment';
import BirthdayComponent from './birthday_component/birthday_component'
const WidgetsDropdown = lazy(() => import('../widgets/WidgetsDropdown.js'))
const WidgetsBrand = lazy(() => import('../widgets/WidgetsBrand.js'))
// const { milliseconds, seconds, minutes, hours, days } = require('time-convert')
const Dashboard = (props) => {
  const{withRouter}=props
  const api = new API()

  // const test = () => {
  //   let res = msToHms(29817000) 
  //   console.log(res)
  // }
  // const msToHms = ms => (
  //   milliseconds.to(hours, minutes, seconds)(ms)
  //     .map(n => n < 10 ? '0' + n : n.toString()) // zero-pad
  //     .join(':')
  // )
  return (
    <div>
      {/* <button className='btn btn-primary' onClick={test}>test</button> */}
      {/* <BirthdayComponent/> */}
    </div>
  )
}

export default withRouter(Dashboard)