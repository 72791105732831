import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import './idCard.css'
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'

import { config } from 'src/utils/apiUrl'
import API from 'src/utils/apiCalling';
// import { config } from '../../utils/apiUrl';

// import ViewSelectedInProgress from '../In-progress/viewSelectedInProgress';
const ViewDetails = (props) => {
    const { isOpen, isViewModalOpen, empUserID,getAllIDCardRequest} = props
    let api = new API()
    const [iDCardDetails, setIDCardDetails] = useState("")
    const [fileUpload, setFileUpload] = useState("")
    const [profilePic,setProfilePic] = useState()
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [expectedDate,setExpectedDate] = useState("")

    const onChangeFunctionUpload = (e) => {
        let files = e.target.files
        setFileUpload(files[0]);
    }

    const getAppliedAssetsRequest = async () => {
        let data = {
            emp_user_id : empUserID
        }
        let result = await api.get(config.getAllIDCardDetails,true,data)
        if (result && result.code === 200) {
            setIDCardDetails(result.data[0])
            setProfilePic(result.data[0].employee_passport_size_photo)
        }
    }
    useEffect(() => {
        getAppliedAssetsRequest()
    }, [empUserID])
console.log("details",iDCardDetails)
console.log("is",empUserID)


const isAlertToggle = (msg) => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
};

const updateDate = async () => {
    let data = {
        expected_date : expectedDate
    }
    swal({
        html: true,
        title: "Are you sure?",
        text: `Are you sure to update details `,
        icon: "success",
        buttons: ["No, cancel it!", "Update"],
        dangerMode: true,
    }).then(async function (isConfirm) {
        if (isConfirm) {
            let result = await api.post(config.updateDetailsforIDCard,data)
            if (result && result.code === 200) {
                toast.success(result.message)
                getAllIDCardRequest()
            } else {
                isAlertToggle(result.message);
            }
        }
    });
}

    return (
        <div className="">
  
            <CModal 
                show={isOpen}
                onClose={() => isViewModalOpen(!isOpen)}
                size="xs"
            >

                <CModalHeader closeButton>
                    <CModalTitle>Sample ID Card View</CModalTitle>
                </CModalHeader>
                <CModalBody empUserID={empUserID}>
                <div className="">

            <div className="container-fluid" >
            <div className="row">
            <div className="col-sm-2" style={{maxWidth:"50%"}}/>
                <div className="col-sm-2" style={{maxWidth:"100%"}}>
                <div className="man_idcard text-center" style={{backgroundImage:'url(images/cardfront.png)',backgroundSize:"246px"}}>
                    <form action="">
                        <div className="bg-images">
                            <div className="logo_img">
                                <img src="images/Asset 1@4x.png" alt="" width="42px" />
                            </div>
                            <div className="ken">
                                <div className="Profile_img"> </div>
                                <img style={{ width: "110px", height: "110px", borderRadius: "50%",border:"1px black solid"}} src={fileUpload ? URL.createObjectURL(fileUpload) : profilePic?profilePic:"public/images/logo.png"}></img>
                                {fileUpload &&
                                <div className="close-btn">×</div>
                                }
                            </div>
                            <div className="">
                                <h6 className="Name_emp fw-semibold">{iDCardDetails?.employee_name}</h6>
                                {/* <span className="post"><strong>{iDCardDetails?.designation}</strong></span> */}
                                <div className="normal_btm_text">
                                    <h6 className="Employee fw-semibold">Employee ID &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;{iDCardDetails?.emp_id}</h6>
                                    <h6 className="Blood_group fw-semibold">Blood Group &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;{iDCardDetails?.blood_group}</h6>
                                    <h6 className="Employee fw-semibold">Emergency No. : &nbsp;{iDCardDetails?.emergency}</h6>
                                    <h6 className="Employee fw-semibold">Contact No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;{iDCardDetails?.mobile}</h6>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>

</div>


                

            </CModalBody>
            </CModal>
            <ToastContainer />
        </div>

    )
}
export default ViewDetails