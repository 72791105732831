import { createAction } from 'redux-act';

const getAllLeaveDateWithinDateRange = createAction(
  'GET_ALL_LEAVE_DATE',
  data => data,
);
const getAllLeaveDateWithinDateRangeSuccess = createAction(
  'GET_ALL_LEAVE_DATE_SUCCESS',
  data => data,
);

export default {
  getAllLeaveDateWithinDateRange,
  getAllLeaveDateWithinDateRangeSuccess,
};
