

import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
    CCollapse
} from '@coreui/react'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'

import ModalOpsSelect from './modal';
import SendOfferLaterModal from './sendOfferLatterModal';
import moment from 'moment';
import { async } from '@firebase/util';
const OpsSelect = (props) => {
    const { } = props
    let api = new API();

    const [CandidateRecord, setCandidateRecord] = useState()
    const [active, setActive] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pendingRequisition, setPendingRequisition] = useState()
    const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(false)
    const [candidateDetails, setCandidateDetails] = useState()
    const [activeKey, setActiveKey] = useState(1)
    const [large, setLarge] = useState(false)
    const [candidate, setCandidate] = useState()
    const [ops_selected, setOps_Selected] = useState()
    const [ops_Rejected, setOps_Rejected] = useState()
    const [details, setDetails] = useState([]);

    useEffect(() => {
        getCandidateSelectedInInterview()
        getCandidateRejectedInInterview()
    }, [])

    const selectedCandidateFun = () => {
        // console.log("getting api data")
    }

    const getCandidateSelectedInInterview = async () => {

        let result = await api.get(config.selectedCandidateForOps)
        if (result && result.code == 200) {
            // console.log("selected candidate for operation", result)

            setOps_Selected(result.data)
        } else {
         
            
        }
    }
    const getCandidateRejectedInInterview = async () => {

        let result = await api.get(config.rejectedCandidateForOps)
        if (result && result.code == 200) {
          
            

            setOps_Rejected(result.data)
        } else {
          
            
        }
    }
    const isViewModalOpen = (candidate) => {
        setIsModalOpen(!isModalOpen)
        setCandidateDetails(candidate)
    }

    const reCreateCandidateOn = async(candidate)=>{
        console.log("svnsdfjnsjdknjsdnj")
        let data={
            candidate_id:candidate.id
        }
let result = await api.get(config.recreateCandidateOnMrf,true,data);
if(result&&result.code===200){
alert("success")
}
    }

    const sendOfferLatter = (candidate) => {
     
        
        setLarge(!large)
        setCandidate(candidate)
    }

    const fields = [
        { label: "ID", key: "id", _style: { width: "5%" } },
        { label: "Name", key: "name", _style: { width: "10%" } },
        { label: "Email", key: "email", _style: { width: "10%" }, },
        { label: "Designation", key: "designation", _style: { width: "10%" }, },
        { label: "Location", key: "location", _style: { width: "10%" } },
        { label: "MRF ID", key: "mrf_id", _style: { width: "10%" } },
        { label: "Applying For", key: "mrf_applying_for", _style: { width: "10%" } },
        { label: "Offer Letter Sent", key: "is_offer_letter_status", _style: { width: "10%" } },
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
    const fields1 = [
        { label: "ID", key: "id", _style: { width: "5%" } },
        { label: "Name", key: "name", _style: { width: "10%" } },
        { label: "Email", key: "email", _style: { width: "10%" }, },
        { label: "Location", key: "location", _style: { width: "10%" } },
        { label: "Designation", key: "designation", _style: { width: "10%" }, },
        { label: "MRF ID", key: "mrf_id", _style: { width: "10%" } },
        { label: "Applying For", key: "mrf_applying_for", _style: { width: "10%" } },
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
    
    const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };
    const getBadge = (status) => {
        switch (status) {
            case 1:
                return "success";
            case "Inactive":
                return "secondary";
            case 0:
                return "danger";
            case "null":
                return "danger";
            case "Banned":
                return "danger";
            case "Damage":
                return "warning";
            default:
                return "primary";
        }
    };
    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"> <u>Ops Selected Candidate</u></h3>
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol xs="12" >
                                        <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                            <CNav variant="tabs">
                                                <CNavItem>
                                                    <CNavLink>
                                                        OPS Select
                                                    </CNavLink>
                                                </CNavItem>
                                                <CNavItem>
                                                    <CNavLink>
                                                        OPS Reject
                                                    </CNavLink>
                                                </CNavItem>
                                            </CNav>
                                            <CTabContent>
                                            <CTabPane>
                                                <CDataTable
                                                    items={ops_selected && ops_selected.length > 0 && ops_selected.filter(mrf => {
                                                    mrf.assigned_at = moment(mrf.assigned_at).format("DD-MM-YYYY")
                                                        return mrf
                                                    })}
                                                    fields={fields}
                                                    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                                    itemsPerPageSelect
                                                    itemsPerPage={10}
                                                    hover
                                                    sorter
                                                    pagination
                                                    scopedSlots={{
                                                        is_offer_letter_status: (item) => (
                                                            <td>
                                                                <CBadge color={getBadge(item.is_offer_letter_status)}>
                                                                    {item.is_offer_letter_status}
                                                                </CBadge>
                                                            </td>
                                                        ),
                                                        show_details: (item, index) => {
                                                            return (
                                                                <td className="py-2">
                                                                    <CButton
                                                                        color="primary"
                                                                        variant="outline"
                                                                        shape="square"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            toggleDetails(index);
                                                                        }}
                                                                    >
                                                                        {details.includes(index) ? "Hide" : "Show"}
                                                                    </CButton>
                                                                </td>
                                                            );
                                                        },
                                                        details: (item, index) => {
                                                            return (
                                                                <CCollapse show={details.includes(index)}>
                                                                    <CCardBody>
                                                                        <p className="text-muted">
                                                                            MRF Type: {item.mrf_type}
                                                                        </p>
                                                                        <CButton onClick={() => isViewModalOpen(item)}
                                                                            size="sm"
                                                                            color="info"
                                                                            className="ml-1"
                                                                        >
                                                                            View
                                                                        </CButton>
                                                                        {item.is_offer_letter_status === 0 ?
                                                                            <CButton onClick={() => sendOfferLatter(item)} size="sm" color="danger" className="ml-1"> Send Offer Letter</CButton>
                                                                            :
                                                                            <CButton size="sm" color="success" className="ml-1" > Offer Letter Sent </CButton>
                                                                        }
                                                                        <CButton onClick={() => reCreateCandidateOn(item)}
                                                                            size="sm"
                                                                            color="info"
                                                                            className="ml-1"
                                                                        >
                                                                            Candidate DropOut
                                                                        </CButton>
                                                                    </CCardBody>
                                                                </CCollapse>
                                                            );
                                                        },
                                                    }}
                                                />
                                                </CTabPane>
                                                <CTabPane>
                                                <CDataTable
                                                    items={ops_Rejected && ops_Rejected.length > 0 && ops_Rejected.filter(mrf => {
                                                        mrf.assigned_at = moment(mrf.assigned_at).format("DD-MM-YYYY")
                                                        return mrf
                                                    })}
                                                    fields={fields1}
                                                    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                                    itemsPerPageSelect
                                                    itemsPerPage={10}
                                                    hover
                                                    sorter
                                                    pagination
                                                    scopedSlots={{
                                                        overall_interview_status: (item) => (
                                                            <td>
                                                                <CBadge color={getBadge(item.overall_interview_status)}>
                                                                    {item.overall_interview_status}
                                                                </CBadge>
                                                            </td>
                                                        ),
                                                        show_details: (item, index) => {
                                                            return (
                                                                <td className="py-2">
                                                                    <CButton
                                                                        color="primary"
                                                                        variant="outline"
                                                                        shape="square"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            toggleDetails(index);
                                                                        }}
                                                                    >
                                                                        {details.includes(index) ? "Hide" : "Show"}
                                                                    </CButton>
                                                                </td>
                                                            );
                                                        },
                                                        details: (item, index) => {
                                                            return (
                                                                <CCollapse show={details.includes(index)}>
                                                                    <CCardBody>
                                                                        {/* <h4>{item.username}</h4> */}
                                                                        <p className="text-muted">
                                                                            MRF Type: {item.mrf_type}
                                                                        </p>
                                                                        <CButton onClick={() => isViewModalOpen(item)}  size="sm" color="info" className="ml-1">View</CButton>
                                                                    </CCardBody>
                                                                </CCollapse>
                                                            );
                                                        },
                                                    }}
                                                />
                                                </CTabPane>
                                            </CTabContent>
                                        </CTabs>
                                        <CCardFooter>
                                            <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" ><i className="fa fa-arrow-left" aria-hidden="true"></i></CButton>{' '}
                                        </CCardFooter>

                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>

            </div>
            <ModalOpsSelect isModalOpen={isModalOpen} message="Ops Selected Candidate Details" isViewModalOpen={isViewModalOpen}
                candidateDetails={candidateDetails} />
            <SendOfferLaterModal
                selectedCandidateFun={selectedCandidateFun}
                sendOfferLatter={sendOfferLatter}
                large={large} candidate={candidate}
                getCandidateSelectedInInterview={getCandidateSelectedInInterview} />

        </div>

    )
}
export default OpsSelect