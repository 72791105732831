import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import { config } from 'src/utils/apiUrl';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';
import { swal, sweetAlert } from 'sweetalert';

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
// import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';
import API from 'src/utils/apiCalling';
import { isPermission } from 'src/utils/common';
// import CreateNewCondidate from './createNewCondidate';
const api = new API();

const ModalShowCandidate = props => {
  const {
    isModal2,
    isModalOpen2,
    candidate,
    handleInitiateCall,
    handleViewHistory,
  } = props;
  const [remarkBycandidate, setRemarkByCandidate] = useState();
  const [panelRole, setPanelRole] = useState();

  const approvReject = async (user, count) => {
    // if(user.candidate_remark_byManager==null ||user.candidate_remark_byManager=="" ||user.is_candidate_approveBy_manager!=0||user.disabled==true){
    //     return
    // }
    // user.disabled=true

    console.log(user, 'lkfkda');

    let data = {
      candidateID: user.id,
      approveReject: count,
      remark: remarkBycandidate,
    };

    let result = await api.post(config.approveRejectCandidate, data);
    if (result && result.status == 200) {
      sweetAlert(result && result.message);
    }
  };

  const renderDataActionBtn = (user, type) => {
    return (
      <>
        {isPermission('call_actions') && (
          <div className="btn-group float-right">
            <button className="btn btn-light ml-2" data-toggle="dropdown">
              <i className="fa fa-list"></i>
            </button>
            <div className="dropdown-menu">
              {isPermission('new_call') && (
                <CButton
                  color="secondary"
                  className="dropdown-item"
                  onClick={() => {
                    handleInitiateCall(user?.id);
                  }}
                >
                  New Call
                </CButton>
              )}
              <div className="dropdown-divider"></div>
              {isPermission('view_call_history') && (
                <CButton
                  color="secondary"
                  className="dropdown-item"
                  onClick={() => {
                    handleViewHistory(user?.id);
                  }}
                >
                  View Call History
                </CButton>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  // useEffect(()=>{
  // setPanelRole(JSON.parse(getLocalDataAsObject('user'))[0].panel_role)
  // },[])
  return (
    <div className="">
      <CModal show={isModal2} onClose={() => isModalOpen2()} size="xl">
        <CModalHeader closeButton>
          <div className="col-md">
            <strong style={{ fontSize: '18px' }}>
              <b>Candidate Details</b>
            </strong>
          </div>
        </CModalHeader>
        <CModalBody>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Candidate ID</th>
                <th scope="col">Candidate Name</th>
                <th scope="col">Email</th>
                <th scope="col"> Mobile No.</th>
                <th scope="col">Designation</th>
                <th scope="col">Experience</th>
                {isPermission('view_cv') && <th>View CV</th>}
                {/* <th scope="col"  >Reason for No / Slot for interview *</th> */}
                {isPermission('interview_actions') && (
                  <>
                    <th scope="col">OK For Interview</th>
                    <th scope="col"> Not OK For Interview</th>
                  </>
                )}
                {isPermission('call_actions') && <th scope="col"> Action</th>}
              </tr>

              {candidate &&
                candidate.length > 0 &&
                candidate.map((user, index) => (
                  <tr>
                    <td>{user.id} </td>
                    <td>{user.name}</td>
                    <td>{user.email} </td>
                    <td>{user.mobile} </td>
                    <td>{user.designation} </td>
                    <td>{user.experience} </td>
                    {isPermission('view_cv') && (
                      <td>
                        <a
                          className="btn btn-primary"
                          href={user.candidate_resume_filename}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                        </a>
                      </td>
                    )}
                    {/* 
                                    {user?.candidate_remark_byManager && <td>{user.candidate_remark_byManager}</td>}
                                    {user.candidate_remark_byManager == null ? <td><input className='form-control' value={remarkBycandidate} onChange={(e) => setRemarkByCandidate(e.target.value)}></input></td> : user.candidate_remark_byManager == "" ? <input className='form-control' value={remarkBycandidate} onChange={(e) => setRemarkByCandidate(e.target.value)} ></input> : ""} */}
                    {isPermission('interview_actions') && (
                      <>
                        {user.is_candidate_approveBy_manager == 0 && (
                          <>
                            {' '}
                            <td>
                              <button
                                className="btn"
                                onClick={() => approvReject(user, '1')}
                              >
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn"
                                onClick={() => approvReject(user, '2')}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </td>
                          </>
                        )}
                        {user.selected == true ||
                          (user.is_candidate_approveBy_manager ==
                            'OK for interview' && (
                            <>
                              {' '}
                              <td> OK</td>
                              <td></td>
                            </>
                          ))}
                        {user.selected == false ||
                          (user.is_candidate_approveBy_manager ==
                            'Not OK for interview' && (
                            <>
                              {' '}
                              <td> </td>
                              <td>NOT OK</td>
                            </>
                          ))}
                      </>
                    )}
                    <td>{renderDataActionBtn(user)}</td>
                  </tr>
                ))}
            </thead>
          </table>
        </CModalBody>
        <CModalFooter></CModalFooter>
      </CModal>
    </div>
  );
};
export default ModalShowCandidate;
