import React from 'react'
import Styles from "./SliderEmployeeInfo.module.css"
import { decryptValue } from 'src/utils/common'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'

const SliderEmployeeinfo =(props)=>{
  const api=new API()
  const{offcanvasRight,employeeDetail}=props

  const getSalaryDetails=async()=>{
    let payload={
      month:5,
type:"employee" ,
emp_id:employeeDetail?._id,
department_name:"Operation-Excellence- Torrent",
query_type:"salary_details" ,
year:2023 
}
    let result=await api.get(config.generateViewSalarySheet,true,payload)

  }

    return(
        <div>
        {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Toggle right offcanvas</button> */}
        <div className="offcanvas offcanvas-end" tabIndex={-1} id={offcanvasRight} aria-labelledby="offcanvasRightLabel">
          <div className="offcanvas-header">
            <h5 id="offcanvasRightLabel" />
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <div className="p-2 position-sticky top-0" style={{backgroundColor: 'rgb(231, 236, 255)'}}>
              <div className="d-flex justify-content-between ">
                <h5 className="text-primary">{employeeDetail?.employee_name}</h5>
                <h6>Net Pay</h6>
              </div>
              <div className="d-flex justify-content-between ">
                <h6 className="fw-normal">{`Emp. ID: ${employeeDetail?.id}`}</h6>
                {/* <h5>{`₹${employeeDetail?.net_pay&&decryptValue(employeeDetail?.net_pay)}`}</h5> */}
              </div>
            </div>
            <div className="ovr-st fre my-3 scrl" style={{height: '65vh', overflowY: 'auto'}}>
              <div className="d-flex justify-content-between">
                <h6 className="text-black">
                  Payable Days</h6>
                <span>{employeeDetail?.paid_days}</span>
              </div>
              <button className="border-0 bg-transparent my-2"><i className="bi bi-plus-circle-dotted" /> Add LOP</button>
              <div className="midle d-flex align-items-center justify-content-between border-top border-bottom">
                <h6 className="text-success py-2 m-0">(+) EARNINGS</h6>
                <h6 className="m-0">AMOUNT</h6>
              </div>
              <table width="100%" cellSpacing={0} border={0} className="amont">
                <tbody><tr>
                    <th className="ps-2 py-2 fw-normal">Basic</th>
                    <td>₹50,000.00 </td>
                  </tr>
                  <tr>
                    <th className="ps-2 py-2 fw-normal">House Rent Allowance</th>
                    <td>₹50,000.00 </td>
                  </tr>
                  <tr>
                    <th className="ps-2 py-2 fw-normal">Conveyance Allowance</th>
                    <td>₹50,000.00 </td>
                  </tr>
                  <tr>
                    <th className="ps-2 py-2 fw-normal">Fixed Allowance</th>
                    <td>₹50,000.00 </td>
                  </tr>
                </tbody></table>

              <div className="midle d-flex align-items-center justify-content-between border-top border-bottom mt-2">
              <div className="dropdown">
  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Add Earning (+)
  </button>
  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a className="dropdown-item" href="#">HRA</a>
    <a className="dropdown-item" href="#">LTA</a>
    <a className="dropdown-item" href="#">Education Allowance</a>
    <a className="dropdown-item" href="#">Meal Allowance</a>
    <a className="dropdown-item" href="#">Special Allowance</a>
    <a className="dropdown-item" href="#">Petrol Allowance</a>
  </div>
</div>
              </div>
      
              
              {/* <div className="dropdown">
                <a className="btn my-2" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-plus-circle-dotted" /> Add More
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another action</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </div> */}
               <div className="midle d-flex align-items-center justify-content-between border-top border-bottom">
                <h6 className="text-danger py-2 m-0">(-) DEDUCTIONS</h6>
                <h6 className="m-0">AMOUNT</h6>
              </div>
              <table width="100%" cellSpacing={0} border={0} className="amont">
                <tbody><tr>
                    <th className="ps-2 py-2 fw-normal">PF-(Employee Contribution)</th>
                    <td>₹50,000.00 </td>
                  </tr>
                  <tr>
                    <th className="ps-2 py-2 fw-normal">PF-(Employer Contribution)</th>
                    <td>₹50,000.00 </td>
                  </tr>
                  <tr>
                    <th className="ps-2 py-2 fw-normal">ESI-(Employee Contribution)</th>
                    <td>₹50,000.00 </td>
                  </tr>
                  <tr>
                    <th className="ps-2 py-2 fw-normal">ESI-(Employer Contribution)</th>
                    <td>₹50,000.00 </td>
                  </tr>
                </tbody>
                </table>
                <div className="midle d-flex align-items-center justify-content-between border-top border-bottom mt-2">
              <div className="dropdown">
  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Add Deduction (+)
  </button>
  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a className="dropdown-item" href="#"></a>
    <a className="dropdown-item" href="#">PF-Employee Contribution</a>
    <a className="dropdown-item" href="#">PF-Employer Contribution</a>
    <a className="dropdown-item" href="#">ESI-Employee Contribution</a>
    <a className="dropdown-item" href="#">ESI-Employer Contribution</a>
    <a className="dropdown-item" href="#">GMC</a>
    <a className="dropdown-item" href="#">Bonus</a>
  </div>
</div>
              </div>
              
             
             
            </div>
            <div className="d-flex justify-content-between align-items-center position-sticky bottom-0 bg-white">
              <div className="bnt">
                <button className="btn btn-primary" style={{padding: '5px 21px'}}>Save</button>
                <button className="btn ">Cancel</button>
              </div>
              <p className="m-0 font14 text-warning"><i className="bi bi-exclamation-circle " /> Click Save to update Net Pay</p>
            </div>
          </div>
        </div>
      </div>
    );
}
export default SliderEmployeeinfo