import { getFomatedError } from 'src/core/services/ApiService';
import { config } from 'src/utils/apiUrl';
import API from 'src/utils/apiCalling';
const api = new API();

const getShiftDetailApiFun = () => {
  let result = api
    .get(config.getShiftdetailsbyUser)
    .then(res => {
      return res;
    })
    .catch(err => {
      return getFomatedError(err);
    });

  return result;
};

export default {
  getShiftDetailApiFun,
};
