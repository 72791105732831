

import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'

import ModalOpsSelect from './modal';

let ops_selected = [
    {
    "id": 29,
    "name": "vijendra kumar",
    "email": "vija@gmail.com",
    "dob": "12-08-1994",
    "designation": "audiotr",
    "mobile": "09413942211",
    "address": "near jankinath mandir, nayapura kota",
    "pincode": 324001,
    "experience": "0",
    "technology_knowledge": "html",
    "mrf_id": 8,
    "mrf_applying_for": "vacancy",
    "mrf_raised_by": "japsimar@gmail.com",
    "candidate_created_by": "pooja.dadhich@qdegrees.com",
    "created_at": "2021-06-17T11:29:00.000Z",
    "updated_by": null,
    "updated_at": null,
    "send_interview_letter_status": 0,
    "status": "open",
    "overall_interview_status": 1,
    "is_approved_by_hr_manager_for_candidate": "not approved",
    "hr_manager_final_status": 0,
    "is_offer_letter_status": 0
},
{
    "id": 30,
    "name": "vijendra kumar",
    "email": "vi@gmail.com",
    "dob": "12-08-1994",
    "designation": "audiotr",
    "mobile": "09413942211",
    "address": "near jankinath mandir, nayapura kota",
    "pincode": 324001,
    "experience": "0",
    "technology_knowledge": "html",
    "mrf_id": 8,
    "mrf_applying_for": "vacancy",
    "mrf_raised_by": "japsimar@gmail.com",
    "candidate_created_by": "pooja.dadhich@qdegrees.com",
    "created_at": "2021-06-17T11:29:00.000Z",
    "updated_by": null,
    "updated_at": null,
    "send_interview_letter_status": 0,
    "status": "open",
    "overall_interview_status": 1,
    "is_approved_by_hr_manager_for_candidate": "not approved",
    "hr_manager_final_status": 0,
    "is_offer_letter_status": 0
}
]
const Report = (props) => {
    const { } = props
    let api = new API()

    const [CandidateRecord, setCandidateRecord] = useState()
    const [active, setActive] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pendingRequisition, setPendingRequisition] = useState()
    const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(false)
    const [candidateDetails, setCandidateDetails] = useState()
    const [activeKey, setActiveKey] = useState(1)
    const [large, setLarge] = useState(false)
    const [candidate, setCandidate] = useState()
    useEffect(() => {
        getMrfRecord()
    }, [])

    const selectedCandidateFun = ()=>{
        // console.log("getting api data")
    }

    const getMrfRecord = async () => {
        // let data = {
        //     type: "hr",
        //     mrfType : "replacement"
        // }
        let result = await api.get(config.profileBank)
        if (result && result.code == 200) {
            // console.log("pending requisition", result)

            setCandidateRecord(result.data)
        } else {
            alert("something wrong")
        }
    }



    const isViewModalOpen = (candidate) => {
        setIsModalOpen(!isModalOpen)
        setCandidateDetails(candidate)
    }


    const sendOfferLatter = (candidate) =>{
        alert("offer later sending")
        setLarge(!large)
        setCandidate(candidate)
    }
    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"> <u>Reports</u></h3>
                <CRow>
                    <CCol xs="12" >
                        <CCard>
                            <CCardHeader>
                                Reports:
                        </CCardHeader>
                            <CCardBody>
                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink>
                                                OPS Select
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink>
                                                OPS Reject
                    
                                            </CNavLink>
                                        </CNavItem>

                                    </CNav>
                                    <CTabContent>
                                        <CTabPane>
                                        <table className="table">
                        <thead>
                            <tr>
<>
                                <th scope="col">#</th>
                                <th scope="col">Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Designation</th>
                                <th scope="col">MRF_Id</th>
                                <th scope="col">MRF_Applying_For</th>
                                <th scope="col">Overall_Interview_Status</th>
  </>
  

                            </tr>
                        </thead>
                        <tbody>
 
                                {ops_selected && ops_selected.map((data, index) => (
                                <tr>
                                    <th scope="row">{"1"}</th>
                                    <td>{data.id}</td>
                                    <td>{data.name}</td>
                                    <td>{data.email}</td>
                                   <td>{data.designation}</td>
                                    <td>{data.mrf_id}</td>
                                    <td>{data.mrf_applying_for}</td>
                                    <td>{data.overall_interview_status == 1 ? "Selected" : "Not Selected"}</td>

                                    <CRow>
                                        <CCol xs="12" md="6">
                                            <CButton  block shape="pill" className="btn btn-primary mr-0" onClick={()=>isViewModalOpen(data)} >View</CButton>
                                        </CCol>
                                        <CCol xs="12" md="6">
                                        <CButton  block shape="pill" className="btn btn-danger mr-0" onClick={() => sendOfferLatter(data)}>Final Approval</CButton>
                                    </CCol>
                                    
                                    </CRow>
                                </tr>
                                    ))}
                     

                        </tbody>
                    </table>
                                        </CTabPane>
                                        <CTabPane>

                                       
                                         </CTabPane>
                                    </CTabContent>
                                </CTabs>
                                <CCardFooter>
                                    <CButton color="success" >Back</CButton>{' '}

                                </CCardFooter>
           
                            </CCardBody>
                        </CCard>

                    </CCol>
                </CRow>

            </div>
<ModalOpsSelect isModalOpen={isModalOpen} message="Ops Selected Candidate Details" isViewModalOpen={isViewModalOpen} 
candidateDetails={candidateDetails}/>


        
</div>

    )
}
export default Report







