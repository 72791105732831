import { CButton } from '@coreui/react';
import { useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import JustificationModalForHr from 'src/HR-MANAGER-PANEL/received_requisition/justificationModal';
import swal from 'sweetalert';
import { toast, ToastContainer } from 'react-toastify';
const ApproveMrfByManager = ({ item, isAlertToggle, reloadTaskBox }) => {
  const api = new API();
  const [selectedMrfDetails, setSelectedMrfDetails] = useState();
  const [isOpenJustificationHr, setIsOpenJustificationHr] = useState(false);

  const ApprovedMrfByManager = mrf => {
    let data = {
      mrf_id: mrf && mrf.id,
      mrf_applying_for:
        mrf && mrf.applying_for === 'Replacement'
          ? 'replacement'
          : 'New Hiring'
          ? 'vacancy'
          : ' ',
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to approve this MRF`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Approve'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.ApprovedMrfByManager, data);
        if (result && result.code == 200) {
          toast.success('Successfully Approved MRF');
          // isJustificationModalOpen(selectedMrfDetails)
          //   setAssetsDone(!assetsDone)
          reloadTaskBox();
        } else {
          toast.error('Something went wrong');
        }
      }
    });
  };

  const rejectMrf = juctificationComment => {
    let data = {
      mrf_id: selectedMrfDetails && selectedMrfDetails.id,
      mrf_applying_for: selectedMrfDetails && selectedMrfDetails.applying_for,
      hr_rejection_comment: juctificationComment,
    };
    // return
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to reject this MRF`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Reject'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.rejectMrfByHrManager, data);
        if (result && result.code == 200) {
          toast.success('MRF Rejected Successfully');
          isJustificationModalHROpen(selectedMrfDetails);
          // setAssetsDone(!assetsDone)
          reloadTaskBox();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };

  const isJustificationModalHROpen = mrf => {
    setSelectedMrfDetails(mrf);
    setIsOpenJustificationHr(!isOpenJustificationHr);
  };

  return (
    <>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={e => {
            ApprovedMrfByManager(item);
          }}
        >
          Approve
        </CButton>
        <div className="dropdown-divider"></div>
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            isJustificationModalHROpen(item);
          }}
        >
          Reject
        </CButton>
      </div>

      <JustificationModalForHr
        isOpenJustification={isOpenJustificationHr}
        isJustificationModalOpen={isJustificationModalHROpen}
        rejectMrf={rejectMrf}
      />
      <ToastContainer />
    </>
  );
};

export default ApproveMrfByManager;
