


import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";
import {
    CRow,
    CCardFooter,
    CCol,
    CForm,
    CFormGroup,
    CLabel,
    CInput,

} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';

import Moment from "react-moment";



const ViewSelectedComponents = (props) => {
    
    const { candidateDetails } = props
    let api = new API()
    // const [large, setLarge] = useState(false)

    useEffect(() => {
  
    }, [])



      

    return (
        <div className="">

            <CForm action="" encType="multipart/form-data" className="form-horizontal">

                <CRow>
                    <CCol md="6">

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Candidate Id</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled  value={candidateDetails && candidateDetails.id} name="id" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Name</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.name} name="name" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Email</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.email} name="email" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >DOB</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.dob} name="dob" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Designation</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.designation} name="designation" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Mobile</CLabel>
                            </CCol>

                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.mobile} name="mobile" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <hr />
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Address</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.address} name="address" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Pin Code</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.pincode} name="pincode" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Experience</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.experience} name="experience" placeholder="" />
                            </CCol>
                        </CFormGroup>


                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Technology Knowledge</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.technology_knowledge} name="technology_knowledge" placeholder="" />
                            </CCol>
                        </CFormGroup>



                    </CCol>
                    <CCol md="6">



                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Id</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.mrf_id} name="mrf_id" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Applying For</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.mrf_applying_for=="vacancy"?"New Hiring":candidateDetails && candidateDetails.mrf_applying_for} name="mrf_applying_for" placeholder="" />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >MRF Raised By</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.mrf_raised_by} name="mrf_raised_by" placeholder="" />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Candidate Created By</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.candidate_created_by} name="candidate_created_by" placeholder="" />
                            </CCol>
                        </CFormGroup>

                       
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Send Interview Letter Status</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.send_interview_letter_status==0?"No":"Yes"} name="send_interview_letter_status" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Status</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.status==0?"Incomplete":"Complete"} name="status" placeholder="" />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Overall Interview Status</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.overall_interview_status==0?"Incomplete":"Complete"} name="overall_interview_status" placeholder="" />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Offer Letter Status</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput disabled value={candidateDetails && candidateDetails.is_offer_letter_status==0?"Not Send":"Send"} name="is_offer_letter_status" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Created At</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                            <label>
                          {
                        <Moment format="YYYY/MM/DD">
                              {candidateDetails && candidateDetails.created_at}
                            </Moment>} </label>
                               
                            </CCol>
                        </CFormGroup>

                    </CCol>
                </CRow>



                {/* <CCardFooter>
                    {/* <CButton type="button" className="btn btn-primary mr-3 mt-2">Load Details</CButton> */}

                {/* </CCardFooter> */} 

            </CForm>

        </div>

    )
}
export default ViewSelectedComponents







