import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
} from '@coreui/react';

import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ModalCandidate from './modal';
import ModalInterviewAssign from './modalInterviewAssign';
import { isPermission } from 'src/utils/common';

const ViewSelectedHrShortList = props => {
  const { MRFData, view_selected } = props;
  let api = new API();
  const [active, setActive] = useState(0);
  const [mrfRecord, setMrfRecord] = useState();
  const [createdCandidate, setCreatedCandidate] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [candidate, setCandidate] = useState();
  const [isInterviewStatus, setIsInterviewStatus] = useState(false);
  const [InterviewRoundDetails, setInterviewRoundDetails] = useState();

  useEffect(() => {
    getCreatedCandidate();
  }, []);

  const getCreatedCandidate = async () => {
    let data = {
      mrf_id: MRFData && MRFData.mrf_id,
      mrf_applying_for: MRFData && MRFData.applying_for,
    };
    let result = await api.get(config.getCreatedCandidate, true, data);
    if (result && result.code == 200) {
      setCreatedCandidate(result.data);
    } else {
    }
  };
  const condidateFullDetails = candidate => {
    setCandidateDetails(candidate);
    setIsModalOpen(!isModalOpen);
  };

  const sendInterviewLatter = candidate => {
    setCandidateDetails(candidate);
    setIsModalOpen2(!isModalOpen2);
    getInterviewRoundDetails(candidate);
  };
  const getInterviewRoundDetails = async candidate => {
    let data = {
      mrf_id: candidate.mrf_id,
      applying_for: candidate.mrf_applying_for,
    };
    let result = await api.get(config.getInterviewRoundForMrf, true, data);
    if (result && result.code == 200) {
      // alert("successfully getting interview Details")
      setInterviewRoundDetails(result.data);
    } else {
    }
  };

  const selectCondidate = async candidate => {
    let data = {
      candidate_id: candidate.id,
    };
    let result = await api.post(config.selectCandidate, data);
    // console.log("condidate selectd", result)
    if (result && result.code == 200) {
      alert('selected');
      getCreatedCandidate();
    } else {
    }
  };

  const backButton = () => {
    view_selected();
  };

  return (
    <div className="">
      <div className="container">
        <h3 className="text-uppercase text-center mt-3 mb-4">
          {' '}
          <u>HR ShortList Candidate List</u>
        </h3>
        {isInterviewStatus == false ? (
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardHeader>HR Select:</CCardHeader>
                <CCardBody>
                  <CTabs
                    activeTab={active}
                    onActiveTabChange={idx => setActive(idx)}
                  >
                    <CNav variant="tabs">
                      <CNavItem>
                        <CNavLink>HR Select</CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink>HR Reject</CNavLink>
                      </CNavItem>
                    </CNav>

                    <CTabContent>
                      <CTabPane>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Candidate Id</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Mobile No</th>
                              <th scope="col">DOB</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {createdCandidate &&
                              createdCandidate.map((candidate, index) => (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{candidate.id}</td>
                                  <td>{candidate.name}</td>
                                  <td>{candidate.email}</td>
                                  <td>{candidate.mobile}</td>
                                  <td>{candidate.dob}</td>
                                  <td>{candidate.designation}</td>
                                  <CRow>
                                    <CCol xs="12" md="2">
                                      <CButton
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View Details"
                                        className="btn btn-primary "
                                        onClick={() =>
                                          condidateFullDetails(candidate)
                                        }
                                      >
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>
                                      </CButton>
                                    </CCol>

                                    {isPermission('interview_actions') && (
                                      <>
                                        {candidate &&
                                        candidate.overall_interview_status ==
                                          1 ? (
                                          <CCol xs="12" md="10">
                                            <CButton
                                              block
                                              className="btn btn-success"
                                            >
                                              Interview Completed
                                            </CButton>
                                          </CCol>
                                        ) : (
                                          <>
                                            {candidate &&
                                              candidate.send_interview_letter_status ==
                                                0 && (
                                                <CCol xs="12" md="10">
                                                  <CButton
                                                    block
                                                    className="btn btn-danger "
                                                    onClick={() =>
                                                      sendInterviewLatter(
                                                        candidate,
                                                      )
                                                    }
                                                  >
                                                    {' '}
                                                    Assign Interview{' '}
                                                  </CButton>
                                                </CCol>
                                              )}
                                            {candidate &&
                                              candidate.send_interview_letter_status ==
                                                1 && (
                                                <CCol xs="12" md="10">
                                                  <CButton
                                                    block
                                                    className="btn btn-success "
                                                  >
                                                    Interview Assigned
                                                  </CButton>
                                                </CCol>
                                              )}
                                            {/* <CCol xs="12" md="4">
                                            <CButton shape="pill" block shape="pill" className="btn btn-success mr-0" onClick={() => isInterviewStatusFun(candidate)}>View Status</CButton>
                                        </CCol> */}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </CRow>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </CTabPane>

                      <CTabPane>
                        <h5 style={{ textAlign: 'center', color: 'red' }}>
                          {' '}
                          No Rejected Candidate
                        </h5>
                      </CTabPane>
                    </CTabContent>
                  </CTabs>
                  <CCardFooter>
                    <CButton
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Back"
                      color="success"
                      onClick={backButton}
                    >
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>{' '}
                    </CButton>{' '}
                  </CCardFooter>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        ) : (
          <></>
        )
        // <ViewInterviewStatus candidate={candidate}
        // isInterviewStatusFun={isInterviewStatusFun}/>
        }
      </div>
      <ModalCandidate
        condidateFullDetails={condidateFullDetails}
        isModalOpen={isModalOpen}
        message={'Candidate Full Details'}
        candidateDetails={candidateDetails}
      />

      <ModalInterviewAssign
        sendInterviewLatter={sendInterviewLatter}
        isModalOpen2={isModalOpen2}
        message={'Assign Interview'}
        candidateDetails={candidateDetails}
        InterviewRoundDetails={InterviewRoundDetails}
        getCreatedCandidate={getCreatedCandidate}
      />
    </div>
  );
};
export default ViewSelectedHrShortList;
