import { encryptValue, decryptValue } from "./common";

export const setLocalDataAsObject = (keyAsString, value) => {
    if(keyAsString == 'user'){
        localStorage.setItem(keyAsString, value);
    }else{
        localStorage.setItem(keyAsString, JSON.stringify(value));
    }
}

export const getLocalDataAsObject = (keyAsString) => {
    //  alert('getLocalDataAsObject=', keyAsString + '=' + localStorage.getItem(keyAsString))
    if (localStorage.getItem(keyAsString) !== undefined) {
        var objData = undefined;
        try {
            var data;
            if(keyAsString == 'user'){
                let encryptedData = localStorage.getItem(keyAsString);
                data = decryptValue(encryptedData);
            }else{
                data = localStorage.getItem(keyAsString);
            }
            if (typeof data !== "undefined" && data !== "undefined") {
                objData = JSON.parse(data);
            }
        } catch (error) {
            // console.log(' error getLocalDataAsObject : ', error['message']);
        }
        return objData;
    }
}

export const clearLocalData = () => {

    try {
        localStorage.clear();
        

    } catch (error) {
        alert('error ' + error);
        // console.log('logout 2 error ::::: ', error);
        // Error retrieving data
    }

}