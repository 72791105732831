module.exports = {
    whatsappCredentials: {
        instance_id: "626229EB0422E",
        access_token: "57796c86d27a5f5098841165ffa0ae4d"
    },
    whatsappCredentialsMessageBird: {
        channel_id: "7f33ff95-f998-4b95-afb6-6a5eda916e58",
        Authorization: "AccessKey 90FCaSwxf2CfgCtjdpdgTiFfc"
    },
    customeMsg: {
        AttendanceCheckInOutWhatsappMsg: `Hello <%= name %>, You have just Check <%= in_out_status %> attendance at <%= date_time %>`
    }
};



