import { CButton, CCard, CCardBody} from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';

import API from "src/utils/apiCalling";
import { config } from "src/utils/apiUrl";
import { capitalizeFirst } from 'src/utils/common1';
import DropDown from './DropDown';
import { toast, ToastContainer } from 'react-toastify';

const Permissions = () => {
  let api = new API();
  let adminRoleId = "7"
  const [allRoles,setAllRoles] = useState([])
  const [allPermissions,setAllPermissions] = useState([])
  const [roleWiseAllPermission, setRoleWiseAllPermission]= useState([])
  const [activeTab,setActiveTab] = useState("Admin")
  const [roleId, setRoleId] = useState('7');

  const allRolesPermissions = async () => {
    let result = await api.get(config.permissions);
    if (result && result.code === 200) {
      setAllRoles(result.data.roles)
      setAllPermissions(result.data.permissions)
    }
  };

  useEffect(()=>{
    roleWisepermissions(adminRoleId, activeTab)
  },[])

  const roleWisepermissions = async(roleId, role)=> {
    setActiveTab(role)
    let params= {
     role_id:roleId
    }
    setRoleId(roleId);
    let result = await api.get(config.roleWisepermissions, true,params);
    if (result && result.code === 200) {
      setRoleWiseAllPermission(result?.data && result?.data.length>0 && result?.data[0]&& result?.data[0].permissions)

    }
  }

  const changePermissions = (value) => {
    if (roleWiseAllPermission?.includes(parseFloat(value))) {
      let newArray =
      roleWiseAllPermission &&
      roleWiseAllPermission.length > 0 &&
      roleWiseAllPermission.filter(item => item !== parseFloat(value));
      setRoleWiseAllPermission(newArray);
    } else {
      setRoleWiseAllPermission([...roleWiseAllPermission, parseFloat(value)]);
    }
  }

  const updateAccess = async() => {
    let payload = {
      role: roleId, 
      permission: roleWiseAllPermission.toString()
    }
    let result = await api.post(config.updatePermissions, payload);
    if(result && result.code === 200){
      toast.success("Permissions updated successfully");
    }
  }
  useEffect(() => {
    allRolesPermissions()
  }, []);


  const groupedPermissions = permissions => {
    let final;
    permissions.reduce((result, permission) => {
      const existingType = result.find(group => group.type === permission.permission_type);
      if (existingType) {
        existingType.data.push(permission);
      } else {
        result.push({type:permission.permission_type, data: [permission] });
      }
      final = result;
      return result;
    }, []);
    return final;
  };

  const typeWiseData = groupedPermissions(allPermissions);

 
  return (
    <div>
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />

          <div className="c-body">
          <div className="permissionTable mt-3">
                <CCard className={'ats-search-card-sect'}>
                  <CCardBody>
                    <div className="name text-right">
                    <DropDown
                    style={{paddingRight: "10%"}}
                    dropdownItems={allRoles}
                    onClick={roleWisepermissions}
                    activeTab={activeTab}
                    />
                    </div>
                  </CCardBody>
                </CCard>
              </div>
              <div className="permissionTable mt-3">
              <CCard>
                    <CCardBody>
              <div id="accordion">
            {typeWiseData &&
              typeWiseData.length > 0 &&
              typeWiseData.map((item, i) => (
                <div key={i} className=" permissionTable mt-3">
                      <div className="table-fixed-column-outter">
                      <div class="card">
    <div class="card-header">
      <a class={item?.type==='route'?"btn":"collapsed btn"} data-bs-toggle="collapse" href={`#${item?.type}`} style={{textAlign:"left"}}>
        <b>{capitalizeFirst(item?.type)}</b>
      </a>
    </div>
    <div id={item?.type} class={item?.type==='route'?"collapse show":"collapse"} data-bs-parent="#accordion">
      <div class="card-body">
      <table className="table-fixed-column table table-bordered table-striped">
                            <thead>
                              <tr>
                              <th>S.NO</th>
                              <th>Permissions</th>
                                <th>{capitalizeFirst(item?.type)}</th>
                               
                              </tr>
                            </thead>
                            <tbody>

                              {item &&
                                item?.data &&
                                item?.data.length > 0 &&
                                item?.data.map((data, i) => (
                                  <tr key={i}>
                                   
                                    <td>{i+1}</td>
                                    <td>
                                    {/* <CSwitch
                                    value={data?.id}
                                className={"mx-1"}
                                variant={"3d"}
                                color={"success"}
                                checked={
                                  roleWiseAllPermission.includes(data?.id)
                                    ? true
                                    : false
                                }
                                onChange={e => changePermissions(e.target.value)}
                                labelOn="No"
                                labelOff="Yes"
                              /> */}
                                        <input
                                          type="checkbox"
                                          value={data?.id}
                                          checked={
                                            roleWiseAllPermission.includes(data?.id)
                                              ? true
                                              : false
                                          }
                                          onChange={e => changePermissions(e.target.value)}
                                        />
                                      </td>
                                    <td>{data?.title}</td>                                                            
                                  </tr>
                                ))}
                            </tbody>
                          </table>
      </div>
    </div>
  </div>
                        </div>
                      
                </div>
              ))}
              </div>
              <div className="text-right">
                        <CButton className="btn btn-success text-white mt-2" onClick={updateAccess}>
                          Update
                        </CButton>
                      </div>
              </CCardBody>
                  </CCard>
                  </div>
          </div>

          <TheFooter />
        </div>
      </div>
     <ToastContainer />
    </div>
  );
};
export default Permissions;
