import React from 'react';
import { CDataTable } from '@coreui/react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';

const AttendanceCorretionAppliedList = props => {
  const { data, type, renderDataStatus } = props;
  let list = null;
  if (data?.length) {
    list = data.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item?.corrected_check_in_time || ''}</td>
          <td>{item?.corrected_check_out_time || ''}</td>
          <td>{item.correction_reason || item?.requested_check_in_reason || item?.requested_check_out_reason}</td>
          <td>{renderDataStatus(item, type)}</td>
          <td>{convertDate(item?.date, 'DD-MM-YYYY') || ''}</td>
          <td>
            {convertDate(item.correction_request_raised_at, 'DD-MM-YYYY')}
          </td>
        </tr>
      );
    });
  }
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Check In Time</th>
          <th scope="col">Check Out Time</th>
          <th scope="col">Reason</th>
          <th scope="col">Approval Status</th>
          <th scope="col">Date</th>
          <th scope="col">Created Date</th>
        </tr>
      </thead>
      <tbody>{list || <NoRecords tag="tr" colSpan={7} />}</tbody>
    </table>
  );
};

export default AttendanceCorretionAppliedList;