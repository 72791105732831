import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
    CCollapse
} from '@coreui/react'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'
import ModalPopupReceivedRequisition from './modal'

import CondidateModal from '../condidate/modal';

import Moment from "react-moment";
import moment from 'moment';
import HoldMrfModal from './holdMrfModal';
import ModalShowCandidate from './model1'

const PendingRequisition = (props) => {
    const { } = props
    let api = new API()

    const [mrfRecord, setMrfRecord] = useState([])
    const [mrfSelectedRecord, setSelectedMrfRecord] = useState()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pendingRequisition, setPendingRequisition] = useState()
    const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(false)
    const [mrfDetails, setMrfDetails] = useState()
    const [details, setDetails] = useState([]);
    const [sendMrfData,setSendMrfData]=useState()
    const [bodyData,setBodyData] =useState([])
    const [isHoldMRFModalOpen,setIsHoldMRFModalOpen]=useState(false)
    const [candidate,setCandidate] =useState();
    const [isModal2, setIsModal2] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const holdMRF=async(mrf)=>{ 
        setSendMrfData(mrf)
        setIsHoldMRFModalOpen(!isHoldMRFModalOpen)
    }

    const isModalOpen2 = (data) => {
        console.log(data)
        setIsModal2(!isModal2);
        myFunction(data)
    };

    const myFunction = async (value) => {
        let data = {
            mrf_id: value.mrf_id,
            mrf_applying_for:value.applying_for,
        }
    
        let result = await api.get(config.candidateOnMRF,true,data)
        if (result && result.code == 200) {
            setCandidate(result && result.data)
        }
    }

    const getDataForBody=async()=>{
        let result=await api.get(config.all_HRMS_status_details)
        if(result&&result.code==200){
            setBodyData(result&&result.data)   
        }
    }

    useEffect(() => {
        getDataForBody()
        getMrfRecord()
    }, [])

    const getMrfRecord = async () => {
        let result = await api.get(config.showAssignedMrfForRecruiter)
        if (result && result.code == 200) {
            setMrfRecord(result.data)
        } else {
       
        }
    }

    const isViewModalOpen = (mrf) => {
        setIsModalOpen(!isModalOpen)
        setPendingRequisition(mrf)
        getAssignedMrfById(mrf)
    }

    const getAssignedMrfById = async (mrf) => {
        // console.log("start")
        let data = {
            mrf_id: mrf && mrf.mrf_id,
            mrf_type: mrf && mrf.applying_for
        }
        // alert(JSON.stringify(data))
        let result = await api.get(config.getMrfById, true, data)
        if (result && result.code == 200) {
            // console.log("mrf by id ", result.data[0])

            setSelectedMrfRecord(result.data[0])
        } else {
            // console.log("something wrong....")
        }
    }
    const SelectCV =async(value) => {
        console.log(value)
        let data={
          mrf_id:value.mrf_id,
            applying_for:value.applying_for,
        };
        console.log(data)
        let result =await api.get(config.sendMailForApproval,true,data);
        if (result && result.code == 200) {
          alert("Mail Sent")
        }
        
    
      };

    const create_NewCondidate = (mrf) => {
        // console.log("mrf is", mrf)
        setMrfDetails(mrf)
        setIsCreateCondidateModalOpen(!isCreateCondidateModalOpen)
    }



    const fields = [
        // { label: "Assest Code (Host Name)", key: "code", _style: { width: "20%" } },
        { label: "MRF ID", key: "mrf_id", _style: { width: "5%" } },
        { label: "Applying for", key: "applying_for", _style: { width: "10%" } },
        { label: "MRF raised by", key: "created_by_name", _style: { width: "10%" }, },
        { label: "Department", key: "requisition_for_department", _style: { width: "10%" }, },
        { label: "Designation", key: "designation", _style: { width: "10%" }, },
        { label: "Assigned At", key: "assigned_at", _style: { width: "10%" }, },
        { label: "Location", key: "location", _style: { width: "10%" } },
        { label: "status", key: "active_status", _style: { width: "5%" }},
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
    const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };
    const getBadge = (status) => {
        switch (status) {
            case "1":
                return "success";
            case "Inactive":
                return "secondary";
            case "0":
                return "danger";
            case "Inuse":
                return "danger";
            case "Banned":
                return "danger";
            case "Damage":
                return "warning";
            default:
                return "primary";
        }
    };


    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"><u>List Of Pending Requisition</u></h3>
                <CCard>
                    {/* <CCardHeader>

                    </CCardHeader> */}
                    <CCardBody>
                        <CDataTable
                            items={mrfRecord && mrfRecord.length > 0 && mrfRecord.filter(mrf => {
                                mrf.assigned_at = moment(mrf.assigned_at).format("DD-MM-YYYY")
                                mrf.active_status = mrf.active_status == "open" ? "Open" : "Close"
                                return mrf
                            })}
                            fields={fields}
                            tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                            itemsPerPageSelect
                            itemsPerPage={10}
                            hover
                            sorter
                            pagination
                            scopedSlots={{
                                status: (item) => (
                                    <td>
                                        <CBadge color={getBadge(item.active_status)}>
                                            {item.active_status}
                                        </CBadge>
                                    </td>
                                ),
                                show_details: (item, index) => {
                                    return (
                                        <td className="py-2">
                                            <CButton
                                                color="primary"
                                                variant="outline"
                                                shape="square"
                                                size="sm"
                                                onClick={() => {
                                                    toggleDetails(index);
                                                }}
                                            >
                                                {details.includes(index) ? "Hide" : "Show"}
                                            </CButton>
                                        </td>
                                    );
                                },
                                details: (item, index) => {
                                    return (
                                        <CCollapse show={details.includes(index)}>
                                            <CCardBody>
                                                {/* <h4>{item.username}</h4> */}
                                                <p className="text-muted">
                                                    MRF Type: {item.mrf_type}
                                                </p>

                                                <CButton onClick={() => isViewModalOpen(item)}
                                                    size="sm"
                                                    color="info"
                                                    className="ml-1"
                                                >
                                                    View
                                                </CButton>
                                                <CButton onClick={() => create_NewCondidate(item)}
                                                    size="sm"
                                                    color="danger"
                                                    className="ml-1"
                                                >
                                                    Create Candidate
                                                </CButton>
                                                <CButton  onClick={()=>holdMRF(item)}
                                                    size="sm"
                                                    color="primary"
                                                    className="ml-1"
                                                >
                                                    Hold MRF
                                                </CButton>
                                                <CButton
                                color="success"
                                className="ml-1"
                                size='sm'
                                disabled={isDisabled}
                                onClick={() =>{
                                    setIsDisabled(true);
                                    SelectCV(item)
                                }}
                                // onClick={() => 
                                // setIsDisabled(true)}
                              >
                                 Send Mail For Selection
                              </CButton>
                              <CButton
                                
                                
                                color="info"
                                className="ml-1"
                                size='sm'
                                    onClick={() => isModalOpen2(item)}
                                  >
                                     Show Candidates
                                  </CButton>
                                            </CCardBody>
                                        </CCollapse>
                                    );
                                },
                            }}
                        />
                    </CCardBody>
                </CCard>

                {/* <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">MRF ID</th>
                            <th scope="col">Applying For</th>
                            <th scope="col">MRF Raised By</th>
                           
                            <th scope="col">Assigned At</th>
                            <th scope="col">Location</th>
                            <th scope="col">Min Amount</th>
                            <th scope="col">Max Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mrfRecord && mrfRecord.map((mrf, index) => (
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{mrf.mrf_id}</td>
                                <td className="letter">{mrf.applying_for == "vacancy" ? "New Hiring" : mrf.applying_for}</td>
                                <td>{mrf.created_by_name}</td>
                                <td> {
                                    <Moment format="YYYY/MM/DD">
                                        {mrf.assigned_at}
                                    </Moment>}</td>
                                <td>{mrf && mrf.location}</td>
                                <td>{mrf && mrf.minAmount}</td>
                                <td>{mrf && mrf.maxAmount}</td>
                                <CRow>
                                    <CCol xs="12" md="6">
                                        <CButton data-toggle="tooltip" data-placement="top" title="View" block className="btn btn-info mt-2 " onClick={() => isViewModalOpen(mrf)} > <i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                    </CCol>
                                    <CCol xs="12" md="6">
                                        <CButton data-toggle="tooltip" data-placement="top" title="Create Candidate" block className="btn btn-success mt-2" onClick={() => create_NewCondidate(mrf)}  ><i className="fa fa-pencil-square-o" aria-hidden="true"></i></CButton>
                                    </CCol>
                                </CRow>
                            </tr>
                        ))}

                    </tbody>
                </table> */}


                <ModalPopupReceivedRequisition isModalOpen={isModalOpen} message={"Selected Pending Requisition Full Details"}
                    isViewModalOpen={isViewModalOpen}
                    pendingRequisition={pendingRequisition}
                    mrfSelectedRecord={mrfSelectedRecord}
                />

                <CondidateModal create_NewCondidate={create_NewCondidate}
                    isCreateCondidateModalOpen={isCreateCondidateModalOpen}
                    mrfDetails={mrfDetails} />
                    
                    <HoldMrfModal holdMRF={holdMRF}
                    sendMrfData={sendMrfData}
                    isHoldMRFModalOpen={isHoldMRFModalOpen}
                    bodyData={bodyData}
                    />

                    <ModalShowCandidate 
                    isModal2={isModal2}
                    isModalOpen2={isModalOpen2}
                    candidate={candidate}/>
            </div>

        </div>

    )
}
export default PendingRequisition







