import React, { useState, useEffect } from 'react';
import API from 'src/utils/apiCalling';
import People from './People';
import { config } from "src/utils/apiUrl";

const Chat = (props) => {
  const {socket, currentUser}=props
  const api = new API();
  const [showClick,setShowClick]=useState(false)
  const [user, setUser] = useState(null);
  useEffect(() => {
    setUser(currentUser)
  }, [currentUser])
  const hide =()=>{
    setShowClick(!showClick)
  }
  return (
    <>

    {showClick==false && user && (
     
        <People socket={socket} chatUser={user} hide={hide} />
    
    )}
    </>
  )
}

export default Chat