import { CCard, CCardBody } from '@coreui/react';
import React from 'react';
import { List } from './List';
import { SubCategoryList } from './SubCategoryList';
const TaskList = props => {
  const { tabsList, tabData, bodyData, reloadTaskBox } = props;

  const renderTabContent = () => {
    return (
      tabsList?.length &&
      tabsList.map((tabs, index) => {
        return (
          <>
            {index === 0 ? (
              <div
                className="tab-pane fade show active"
                id={tabs.object_key}
                role="tabpanel"
                key={index}
              >
                {tabs.is_sub_categories ? (
                  <SubCategoryList
                    reloadTaskBox={reloadTaskBox}
                    tabs={tabs}
                    content={tabData[tabs.object_key]}
                    bodyData={bodyData}
                  />
                ) : (
                  <List
                    tabs={tabs}
                    content={tabData[tabs.object_key]}
                    bodyData={bodyData}
                    reloadTaskBox={reloadTaskBox}
                  />
                )}
              </div>
            ) : (
              <div
                className="tab-pane fade"
                id={tabs.object_key}
                role="tabpanel"
                key={index}
              >
                {tabs.is_sub_categories ? (
                  <SubCategoryList
                    reloadTaskBox={reloadTaskBox}
                    tabs={tabs}
                    content={tabData[tabs.object_key]}
                    bodyData={bodyData}
                  />
                ) : (
                  <List
                    reloadTaskBox={reloadTaskBox}
                    content={tabData[tabs.object_key]}
                    bodyData={bodyData}
                  />
                )}
              </div>
            )}
          </>
        );
      })
    );
  };

  const renderTab = () => {
    return (
      tabsList?.length &&
      tabsList.map((tabs, index) => (
        <>
          {index === 0 ? (
            <button
              className="nav-link active"
              id={tabs.id}
              data-bs-toggle="pill"
              data-bs-target={`#${tabs.object_key}`}
              type="button"
              role="tab"
              aria-controls={tabs.object_key}
              aria-selected="true"
              style={{ textAlign: 'left' }}
            >
              {tabs.title} ({tabs.total_length})
            </button>
          ) : (
            <button
              className="nav-link"
              id={tabs.id}
              data-bs-toggle="pill"
              data-bs-target={`#${tabs.object_key}`}
              type="button"
              role="tab"
              aria-controls={tabs.object_key}
              aria-selected="true"
              style={{ textAlign: 'left' }}
            >
              {tabs.title} ({tabs.total_length})
            </button>
          )}
        </>
      ))
    );
  };

  return (
    <div>
      <div className="container mt-3">
        <CCard>
          <CCardBody>
            <div>
              <div className="align-items-start">
                <div className="row">
                  <div className="col-sm-3">
                    <div
                      className="nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      {renderTab()}
                    </div>
                  </div>
                  <div className="col-sm-9">
                    <div className="tab-content" id="v-pills-tabContent">
                      {renderTabContent()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

export default TaskList;
