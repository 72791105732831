import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import {toast, ToastContainer} from 'react-toastify';
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CButton} from '@coreui/react'

import { config } from '../../utils/apiUrl'
import API from '../../utils/apiCalling'


const ModalUpdate = (props) => {
    let api = new API()
    const { isModalOpen, message, isViewModalOpen, candidateDetails} = props
    const [fileUpload, setFileUpload] = useState();
    const [name, setName] = useState('');
    const [department, setDepartment] = useState('');
    const [designation, setDesignation] = useState('');

    const onChangeFunctionUploadPicture = e => {
        let files = e.target.files;
        setFileUpload(files[0]);
      };

      const selectedValues = () => {
        setName(candidateDetails ? candidateDetails?.name : '');
        setDepartment(candidateDetails ? candidateDetails?.department : '');
        setDesignation(candidateDetails ? candidateDetails?.Designation : '');
      };
      useEffect(() => {
        selectedValues();
      }, [candidateDetails]);
    
  const updateDetails = async () => {
    const formData = new FormData();
    formData.append('file', fileUpload || '');
    formData.append('name', name);
    formData.append('department', department);
    formData.append('designation', designation);
    formData.append('applying_for', candidateDetails && candidateDetails?.mrf_applying_for);
    formData.append('mrf_id', candidateDetails && candidateDetails?.mrf_id);
    formData.append('candidate_id', candidateDetails && candidateDetails?.id);
    formData.append('emp_user_id', candidateDetails && candidateDetails?.created_by);
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to update details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.putPostFileFun(config.updateIJPData,formData);
        if (result && result.code === 200) {
          toast.success(result.message);
          isViewModalOpen(!isModalOpen);
        } else {
          toast.error(result.message);
        }
      }
    });
  };

    return (
        <div className="">
            <CModal
                show={isModalOpen}
                onClose={() => isViewModalOpen(!isModalOpen)}
                size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form style={{width:"90%", margin:"auto"}}>
                        <div className="form-group">
                            <label for="emp_id">Candidate Name</label>
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="" />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Department</label>
                            <input type="text" className="form-control" value={department} onChange={(e) => setDepartment(e.target.value)}> 
                            </input>
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Designation</label>
                            <input type="text" className="form-control" value={designation} onChange={(e) => setDesignation(e.target.value)}> 
                            </input>
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Profile Photo</label>
                            <input type="file" className="form-control" onChange={onChangeFunctionUploadPicture} > 
                            </input>
                        </div>
                    </form>
                </CModalBody>
                <CModalFooter>
                        <button type="button" className="btn btn-primary float-right" onClick={updateDetails}>Update</button>
                </CModalFooter>
            </CModal>
            <ToastContainer/>
        </div>
    )
}
export default ModalUpdate;