import React, { useEffect, useState, useCallback } from 'react';
import { TheSidebar, TheHeader } from '../containers/index';
import OtpInput from 'react-otp-input';
import { CCard, CCardBody, CCol, CRow } from '@coreui/react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';
import './guest_form.css';
import Select from 'react-select';
import { CustButton } from 'src/reusable';
import swal from 'sweetalert';
import TimeDropdown from '../../src/reusable/CustHourDropdown';
import { debounce } from 'lodash';

const EmployeeForm = props => {
  const api = new API();
  const [sessionID, setSessionID] = useState('');
  const [verified, setVerified] = useState('');
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');
  const [tempCard, setTempCard] = useState('');
  const [checkInBy, setCheckInBy] = useState('emp_id');
  const [empID, setEmpID] = useState('');
  const [empUserId, setEmpUserId] = useState('');
  const [button, setButton] = useState(false);
  const [buttonOTP, setButtonOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [callCount, setCallCount] = useState(0);
  const [visible, setVisible] = useState(false);

  const employeeData = async empID => {
    setEmpID(empID);
    let data = {
      emp_id: empID,
    };
    const result = await api.get(config.getDataByEmpID, true, data);
    if (result.code === 200) {
      setVisible(true);
      setName(result?.data[0]?.employee_name);
      setMobile(result?.data[0]?.mobile);
      setEmpUserId(result?.data[0]?.id);
      checkMobile(result?.data[0]?.mobile);
    } else {
      setVisible(false);
    }
  };

  const verifiedUser = async () => {
    let data = {
      session_id: sessionID,
    };
    let result = await api.get(config.verifiedUser, true, data);
    if (result && result.code === 200) {
      setVerified(result?.data);
      setCallCount(callCount + 1);
    } else {
      toast.error(result.message);
    }
  };

  const checkMobile = async mobile => {
    console.log(mobile);
    let data1 = {
      mobile_email: mobile,
    };
    let result = await api.post(config.sendOTP, data1);
    if (result && result.code === 200) {
      setSessionID(result?.data?.session_id);
      setButton(true);
      toast.success(result.message);
      setCallCount(callCount + 1);
    } else {
      toast.error(result.message);
      setButton(false);
    }
  };

  const checkOtp = async e => {
    setOtp(e);
    console.log(e);
    if (e.length === 6) {
      let data = {
        mobile_email: mobile,
        otp: e,
        session_id: sessionID,
      };
      let result = await api.get(config.verifyOTP, true, data);
      if (result && result.code === 200) {
        toast.success(result.message);
        verifiedUser();
        setButtonOTP(true);
        setCallCount(callCount + 1);
      } else {
        toast.error(result.message);
        setButtonOTP(false);
      }
    }
  };

  const ResetFun = async () => {
    setEmpID('');
    setButton(false);
    setButtonOTP(false);
    setVerified('');
    setTempCard('');
    setOtp('');
    setName('');
    setMobile('');
  };

  const fillEmployeeForm = async () => {
    let data = {
      emp_id: empID,
      otp_verify: verified ? 1 : 0,
      temp_card: tempCard || '',
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to submit form`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.empTempDetails, data);
        if (result && result.code === 200) {
          toast.success('Successfully Saved Attendance !!');
          ResetFun();
          setVisible(false);
          let location;
          navigator.geolocation.getCurrentPosition(function(position) {
            location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            checkInOutFun(location);
          });
        } else {
          toast.error(result.message);
        }
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce(empID => employeeData(empID), 500),
    [],
  );

  // apply  check in check out and break fun
  const checkInOutFun = async location => {
    var otherbr = `00:00:00:00`;
    let data = {
      emp_user_id: empUserId,
      latitude: parseFloat(location.latitude),
      longitude: parseFloat(location.longitude),
      capture: 'no_cam',
      status: 'status',
      attendance_check_status: 'IN',
      attendance_check_location: 'device',
      device: 'web',
      mac_address: 'test',
      attendance_type: 'attendance',
      other_break_time: otherbr,
      comment: 'Temp Card',
    };
    let result = await api.post(config.attendanceCheckInCheckOut, data);
    if (result && result.code === 200) {
    } else {
      toast.error(result.message);
    }
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setTempCard(e.target.value);
    }
  };

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="container mt-3">
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardBody>
                  <div className="p-4">
                    <div className="text-center">
                      <img
                        src="images/qdegreesLogo.png"
                        alt=""
                        height="40px"
                        width="180px"
                      />
                    </div>
                    <br />
                    <div className="header_content text-center pb-5">
                      <strong className="h4">
                        <u>
                          <b>EMPLOYEE FORM</b>
                        </u>
                      </strong>
                    </div>

                    <div className="table">
                      <table className="tableempdetail" cellPadding="3">
                        <tbody>
                          {/* <tr>
                            <th className="tableth1">
                              Check In By <b style={{ color: 'red' }}>*</b>
                            </th>
                            <td className="tabletd1">
                              <input
                                type="radio"
                                name="checkBy"
                                value="emp_id"
                                onClick={e => setCheckInBy(e.target.value)}
                              />
                              &nbsp;&nbsp;
                              <b style={{ color: 'darkblue' }}>Employee ID</b>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <input
                                type="radio"
                                name="checkBy"
                                value="qr_code"
                                onClick={e => setCheckInBy(e.target.value)}
                              />
                              &nbsp;&nbsp;
                              <b style={{ color: 'darkblue' }}>QR Code</b>
                            </td>
                          </tr> */}
                          {checkInBy === 'emp_id' && (
                            <>
                              <tr>
                                <th className="tableth1">
                                  Employee ID <b style={{ color: 'red' }}>*</b>
                                </th>
                                <td className="tabletd1">
                                  <input
                                    required
                                    style={{ width: '100%', color: 'black' }}
                                    onChange={e =>
                                      debouncedOnChange(e.target.value)
                                    }
                                    name="purpose"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Employee ID"
                                  />
                                </td>
                              </tr>
                              {visible && (
                                <>
                                  <tr>
                                    <th className="tableth1">Employee Name </th>
                                    <td className="tabletd1">
                                      <input
                                        disabled
                                        required
                                        style={{
                                          width: '100%',
                                          color: 'black',
                                          backgroundColor: 'lightgray',
                                        }}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        name="purpose"
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Employee Name"
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <th className="tableth1">Mobile No.</th>
                                    <td className="tabletd1">
                                      <input
                                        disabled
                                        required
                                        style={{
                                          width: '100%',
                                          color: 'black',
                                          backgroundColor: 'lightgray',
                                        }}
                                        value={mobile}
                                        onChange={e => checkMobile(mobile)}
                                        name="mobile"
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Mobile No."
                                      />
                                    </td>
                                  </tr>
                                  {button && (
                                    <tr>
                                      <th className="tableth1">
                                        Enter OTP{' '}
                                        <b style={{ color: 'red' }}>*</b>
                                      </th>
                                      <td
                                        style={{
                                          paddingTop: '1.5%',
                                          paddingBottom: '1.5%',
                                        }}
                                      >
                                        <OtpInput
                                          className="otp-spacing"
                                          inputStyle={{
                                            width: '44%',
                                            height: '200%',
                                            color: '#212529',
                                            backgroundClip: 'padding-box',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.375rem',
                                            backgroundColor: buttonOTP
                                              ? 'lightgray'
                                              : '#fff',
                                          }}
                                          isDisabled={buttonOTP}
                                          value={otp}
                                          onChange={e => checkOtp(e)}
                                          numInputs={6}
                                          separator={<span></span>}
                                          isInputNum={true}
                                          renderInput={props => (
                                            <input {...props} />
                                          )}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <th className="tableth1">
                                      Temp Card No.{' '}
                                      <b style={{ color: 'red' }}>*</b>
                                    </th>
                                    <td className="tabletd1">
                                      <input
                                        required
                                        style={{
                                          width: '100%',
                                          color: 'black',
                                        }}
                                        value={tempCard}
                                        onChange={handleChange}
                                        name="tempCard"
                                        className="form-control"
                                        type="tel"
                                        maxLength={3}
                                        placeholder="Enter Temp Card No."
                                      />
                                    </td>
                                  </tr>
                                </>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      {checkInBy === 'emp_id' && (
                        <div className="col-sm-12 text-right mt-3">
                          <CustButton
                            type="reset"
                            name="resetSearch"
                            text="Reset"
                            size="md"
                            color="danger"
                            onClick={ResetFun}
                            className="btn-loading btn-ladda ml-1"
                            icon=""
                          />
                          <CustButton
                            type="button"
                            name="btn"
                            text="Submit Form"
                            size="md"
                            color="primary"
                            onClick={fillEmployeeForm}
                            className="btn-loading btn-ladda ml-1"
                            icon=""
                            isDisabled={tempCard && verified ? false : true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EmployeeForm;
