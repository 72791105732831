import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CFormText,
  CLabel,
  CCardFooter,
  CBadge,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CCollapse,
  CRow,
  CDataTable,
  CModal, CModalHeader, CModalTitle, CModalFooter, CModalBody
} from '@coreui/react';
import { Button } from 'reactstrap';
import { AvForm, AvInput, AvField } from 'availity-reactstrap-validation';
import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';
import { CustMultiSelect } from '../../../reusable';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import { async } from '@firebase/util';

const AddHoliday = () => {
  const api = new API();
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [state, setState] = useState([]);
  const [isBranches, setIsBranches] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [branch, setBranch] = useState([]);
  const [modelButton, setModelButton] = useState("create");
  const [active, setActive] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [details, setDetails] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [visible, setVisible] = useState(false)
  const [holidayList, setHolidayList] = useState([])
  const [holidayData, setHolidayData] = useState({
    holiday_name: '',
    holiday_from_date: '',
    holiday_to_date: '',
    state: 0,
  });
  console.log("Hello holidayData", holidayData)

  const getStates = async () => {
    let result = await api.get(config.getStates);
    if (result && result.code === 200) {
      let temp = [];
      result?.data?.map(data => {
        temp.push({
          label: data?.state,
          value: data?.id,
        });
      });
      setStates(temp);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  const handleOnSelectOptions = async (data, type) => {
    setState(data);
    let temp = [];
    data.map(data => {
      temp.push(data?.value);
    });
    if (temp.length) {
      let payload = {
        stateIds: temp.toString(),
      };
      let result = await api.get(config.getBranches, true, payload);
      if (result && result.code === 200 && result?.data?.length) {
        setIsBranches(true);
        let temp1 = [];
        result?.data?.map(data => {
          temp1.push({
            label: data?.branch_name + ' ( ' + data?.city + ' )',
            value: data?.id,
          });
        });
        setBranches(temp1);
        console.log('result.data', result.data);
      } else {
        setIsBranches(false);
      }
    }
    setSelectedState(temp);
  };

  const handleOnSelectOptions1 = async (data, type) => {
    setBranch(data);
    let temp = [];
    data.map(data => {
      temp.push(data?.value);
    });
    setSelectedBranch(temp);
  };

  const onInputChange = e => {
    let { name, value } = e.target;
    setHolidayData({ ...holidayData, [name]: value });
  };

  const submitHolidayDetails = async () => {
    if(modelButton !== "edit"){
    let payload = {
      holiday_name: holidayData?.holiday_name || '',
      holiday_from_date: holidayData?.holiday_from_date || '',
      holiday_to_date: holidayData?.holiday_to_date || '',
      state:
        states.length === selectedState.length || !selectedState.length
          ? '0'
          : selectedState.toString(),
      branch: selectedBranch.toString() || '0',
    };
    let result = await api.post(config.addHolidays, payload);
    if (result && result.code == 200) {
      setVisible(false)
      toast.success(result?.message);
      getAllHoliDay()
      reset()
    } else {
      console.log('err while saving holiday', result);
    }}else{
      let result = await api.post(config.updateHolidays, holidayData)
      if(result.code === 200){
        toast.success(result?.message);
        setVisible(false)
        getAllHoliDay()
        reset()
      }else{
        toast.error(result?.message);
      }
    }
  };

  const reset = ()=>{
    setVisible(false)
    setHolidayData({
      holiday_name: '',
      holiday_from_date: '',
      holiday_to_date: '',
      state: 0,
    })
    setModelButton("create")
  }

  const isViewModalOpen1 = (item) => {
    setVisible(true)
    setModelButton("edit")
    setHolidayData({
    holiday_name: item.holiday,
    holiday_from_date:item.holiday_from,
    holiday_to_date: item.holiday_to,
    id:item.id
      })
      setIsOpen1(!isOpen1);
  };

  useEffect(() => {
    getAllHoliDay();
  }, []);
  const getAllHoliDay = async () => {
    let result = await api.get(config.getHolidayList);
    if (result && result.code == 200) {
      setHolidayList(result.data.holidays_list)
    }
  };


  const isViewModalOpen = () => {
    setModelButton("create")
    setVisible(!visible)
    setIsOpen(true);
  };
  

  const isViewModalOpen2 = async item => {
    accountStatusChange(item)
    setIsOpen2(!isOpen2);
  };

  const fields = [
    {
      key: 'sn',
      label: 'S.N.',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
    { key: 'holiday_from', _style: { width: '10%' } },
    { key: 'holiday_to', _style: { width: '10%' } },
    { key: 'day', _style: { width: '10%' } },
    { key: 'holiday', _style: { width: '10%' } },
    { key: 'days', _style: { width: '10%' } },

    {
      key: 'update_details',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
    {
      key: 'update_softwares',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    }, 
  ];

  const accountStatusChange = (status) => {
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to delete user ${status.holiday}`,
      icon: 'success',
      buttons: ['No, cancel it!', `Delete`],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.delete(config.delete_holidays, {id:status.id});
        if (result && result.code == 200) {
          getAllHoliDay()
          toast.success(`successfully ${status.holiday} deleted`);
        }else{
          toast.error(result.message);
        }
      }
    });
  };

  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };


  
  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  return (
    <div>
      <div className="c-app c-default-layout">
        <div className="c-wrapper">
          <div className="c-body">

            <div className="container mt-3">
              <div className='text-end'>
              <CButton onClick={() => isViewModalOpen()} className="btn btn-primary mb-3">
                Add Holiday
              </CButton>
              </div>
              <CRow>
                <CCol xs="12">
                  <CCard>
                    <CCardHeader>
                      <strong> Holiday List</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CTabs
                        activeTab={active}
                        onActiveTabChange={idx => setActive(idx)}
                      >
                        <CNav variant="tabs">
                          {/* <CNavItem>
                      <CNavLink>All Accounts Status</CNavLink>
                    </CNavItem> */}
                        </CNav>
                        <CTabContent>
                          <CTabPane>
                            <CDataTable
                              items={holidayList}
                              fields={fields}
                              columnFilter
                              tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                              // footer
                              itemsPerPageSelect
                              itemsPerPage={5}
                              hover
                              sorter
                              pagination
                              scopedSlots={{
                                sn: (item, index) => {
                                  return <td>{index + 1}</td>;
                                },
                                update_details: (item) => {
                                  return (
                                    <td className="py-2">
                                      <CButton
                                        color="primary"
                                        variant="outline"
                                        shape="square"
                                        size="sm"
                                        onClick={() => isViewModalOpen1(item)}
                                      >
                                        Edit
                                      </CButton>
                                    </td>
                                  );
                                },
                                update_softwares: (item) => {
                                  return (
                                    <td className="py-2">
                                      <CButton
                                        color="primary"
                                        variant="outline"
                                        shape="square"
                                        size="sm"
                                        onClick={() => isViewModalOpen2(item)}
                                      >
                                        Delete
                                      </CButton>
                                    </td>
                                  );
                                },
                              }}
                            />
                          </CTabPane>
                        </CTabContent>
                      </CTabs>
                      <CCardFooter>
                      </CCardFooter>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>

              <CModal
                className="modalVis"
                show={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="LiveDemoExampleLabel"
              >
                <CModalHeader onClose={() => setVisible(false)}>
                  <CModalTitle id="LiveDemoExampleLabel">
                    {modelButton === "create" ? "Create New Holiday" : "Edit Holiday"}
                  </CModalTitle>
                </CModalHeader>
                  <CModalBody>
                    <div>
                      <CCardBody>
                        <AvForm
                          action=""
                          method="post"
                          encType="multipart/form-data"
                          className="form-horizontal"
                          onValidSubmit={submitHolidayDetails}
                        >
                          <CFormGroup row>
                            <CCol md="4">
                              <CLabel htmlFor="">Name</CLabel>
                            </CCol>
                            <CCol xs="12" md="8">
                              <AvInput
                                name="holiday_name"
                                id="Holiday Name"
                                value={holidayData.holiday_name}
                                onChange={e => onInputChange(e)}
                                required
                              />
                              <CFormText>Please enter Holiday name</CFormText>
                            </CCol>
                          </CFormGroup>
                          <CFormGroup row>
                            <CCol md="4">
                              <CLabel htmlFor="">From Date</CLabel>
                            </CCol>
                            <CCol xs="12" md="8">
                              <AvInput
                                type="date"
                                name="holiday_from_date"
                                id="Holiday From Date"
                                value={holidayData.holiday_from_date}
                                onChange={e => onInputChange(e)}
                                required
                              />
                              <CFormText>
                                Please enter holiday from date
                              </CFormText>
                            </CCol>
                          </CFormGroup>
                          <CFormGroup row>
                            <CCol md="4">
                              <CLabel htmlFor="">To Date</CLabel>
                            </CCol>
                            <CCol xs="12" md="8">
                              <AvInput
                                type="date"
                                name="holiday_to_date"
                                id="Holiday To Date"
                                value={holidayData.holiday_to_date}
                                onChange={e => onInputChange(e)}
                                required
                              />
                              <CFormText>Please enter holiday to date</CFormText>
                            </CCol>
                          </CFormGroup>
                          {/* <CFormGroup row>
                          <CCol md="4">
                            <CLabel htmlFor="">State</CLabel>
                          </CCol>
                          <CCol xs="12" md="8">
                            <CustMultiSelect
                              onChange={handleOnSelectOptions}
                              values={state}
                              optionsData={states}
                              type="state"
                            />
                            <CFormText>Please select States</CFormText>
                          </CCol>
                        </CFormGroup>
                        {isBranches && (
                          <CFormGroup row>
                            <CCol md="4">
                              <CLabel htmlFor="">Branches</CLabel>
                            </CCol>
                            <CCol xs="12" md="8">
                              <CustMultiSelect
                                onChange={handleOnSelectOptions1}
                                values={branch}
                                optionsData={branches}
                                type="branch"
                              />
                              <CFormText>Please select Branches</CFormText>
                            </CCol>
                          </CFormGroup>
                        )} */}
                        <div className='justify-content-end d-flex gap-2'>
                          <CButton color="secondary" onClick={() => reset()}>
                            Close
                          </CButton>
                          <Button type="submit" size="md" color="primary">
                            Save
                          </Button>
                          </div>
                        </AvForm>
                      </CCardBody>
                      <ToastContainer />
                    </div>
                  </CModalBody>
              </CModal>
            </div>


            <Alert
              isAlert={isAlert}
              isAlertToggle={isAlertToggle}
              alertMessage={alertMessage}
              isAlertToggleClose={isAlertToggleClose}
            />


          </div>
        </div>
      </div>
    </div>
  );
};
export default AddHoliday;
