import React, { useEffect, useState } from 'react';
import { config } from 'src/utils/apiUrl';
import API from 'src/utils/apiCalling';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import ChangePasswordFirstTime from 'src/views/pages/login/modal';
import DailyRatingSurvey from 'src/views/common/dailyRatingSurvey';
import ContributionPopup from 'src/views/common/cantribution-popup/contribution_popup';
import OnBoardingSurveyModal from 'src/views/submissions/OnBoardingSurvey';
import SurveyModal from 'src/survey/SurveyModal';
import CheckInCheckOut from '../../src/modals/CheckInCheckOut';
import NewYearPopup from 'src/views/common/NewYear-popup/newyear_popup'

import { TheContent, TheSidebar, TheFooter, TheHeader } from './index';
import { withCookies } from 'react-cookie';
import moment from 'moment';

const TheLayout = props => {
  let api = new API();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRatingSurvey, setIsOpenRatingSurvey] = useState(false);
  const [isOpenNewYearModal, setIsOpenNewYearModal] = useState(false);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);

  const [isOpenContributionSurvey, setIsOpenContributionSurvey] = useState(
    false,
  );
  const [isOpenOnboardSurvey, setIsOpenOnboardSurvey] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [surveyId, setSurveyId] = useState();
  const [surveyURL, setSurveyURl] = useState();
  const [surveyFilled, setSurveyFilled] = useState();

  useEffect(() => {
    let data = getLocalDataAsObject('user');
    if (data) {
      let isPasswordChanged = data[0].is_password_changed;
      if (isPasswordChanged == 0) {
        setIsOpen(true);
      }
    }
    setTimeout(() => {
      getDailyRatingSurvey();
      getIsContributionPopup();
    }, 5000);
  }, [isOpen]);

  useEffect(() => {
    isTokenExpiried();
    handleSurveyModal(false);
  }, [window.location.href]);

  const isTokenExpiried = async () => {
    let result = await api.get(config.tokenVerify);
    if (result && result.error == true) {
      logging_out();
    }
  };
  const logging_out = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    props.cookies.remove('token');
    props.cookies.remove('user');
    window.location.href = '/';
  };
  const handleSurveyModal = isSurveyModalOpen => {
    setIsSurveyModalOpen(!isSurveyModalOpen);
  };

  const isViewModalOpen = () => {
    setIsOpen(!isOpen);
  };

  const getDailyRatingSurvey = async () => {
    let date = moment().format("YYYY-MM-DD")
    setEmployeeName(getLocalDataAsObject('user')[0]?.first_name)
    console.log(employeeName)
    let result = await api.get(config.findEmployeeRatingSurvey);
    if (result && result.code === 200 && result.data.status === 0) {
      setEmployeeName(result?.data?.emp_name || '');
      setIsOpenRatingSurvey(true);
      if(date <= "2024-01-05"){
      setIsOpenNewYearModal(true);
      }
    } else {
    }
  };
  const getIsContributionPopup = async () => {
    let result = await api.get(config.findEmployeeContribution);
    if (result && result?.code === 200 && result?.data?.status === 0) {
      setIsOpenContributionSurvey(true);
      setEmployeeName(result?.data?.emp_name || '');
    } else {
    }
  };
  const getSurveyStatus = async () => {
    let result = await api.get(config.getSurveyStatus);
    if (
      result &&
      result.code == 200 &&
      result.data &&
      result.data.status == 0
    ) {
      await setIsOpenOnboardSurvey(true);
      await setSurveyId(result && result.data.survey_id);
    } else {
    }
  };
  useEffect(() => {
    getSurveyStatus();
  }, []);
  const getSurveyUrl = async () => {
    let data = {
      qdid:  getLocalDataAsObject('user')[0]?.emp_id
    };
    let result = await api.get(config.esat_api, true, data);
    if (result && result.code === 200) {
      setSurveyFilled(result.data[0].survey_filled);
      setSurveyURl(result.data[0].url);
    }
  };
  useEffect(() => {
    getSurveyUrl();
  }, []);
  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter />
      </div>
      <ChangePasswordFirstTime
        isOpen={isOpen}
        isViewModalOpen={isViewModalOpen}
      />
      <DailyRatingSurvey
        isOpen={isOpenRatingSurvey}
        setIsOpenRatingSurvey={setIsOpenRatingSurvey}
        employeeName={employeeName}
      />
      <NewYearPopup
        isOpen={isOpenNewYearModal}
        setIsOpenRatingSurvey={setIsOpenNewYearModal}
        employeeName={employeeName}
      />
      {/* <ContributionPopup
        isOpen={isOpenContributionSurvey}
        setIsOpenContributionSurvey={setIsOpenContributionSurvey}
        employeeName={employeeName}
      /> */}
      <OnBoardingSurveyModal
        isOpenOnboardSurvey={isOpenOnboardSurvey}
        setIsOpenOnboardSurvey={setIsOpenOnboardSurvey}
        employeeName={employeeName}
        surveyId={surveyId}
      />
      {/* <CheckInCheckOut /> */}
      {surveyFilled && surveyFilled == '0' && (
        <SurveyModal
          isSurveyModalOpen={isSurveyModalOpen}
          handleSurveyModal={handleSurveyModal}
          setIsSurveyModalOpen={setIsSurveyModalOpen}
          surveyURL={surveyURL}
        />
      )}
    </div>
  );
};

export default withCookies(TheLayout);
