import { createReducer } from 'redux-act';
import actions from './leaveDate.Action';

const initialState = {
  isLeaveDataForEmployeePending: false,
  getLeaveDataForEmployeeRes: null,
  getLeaveDataForEmployeeResError: null,
};

const getLeaveDateReaquest = (state, payload) => {
  return {
    ...state,
    ...{
      isLeaveDataForEmployeePending: true,
      getLeaveDataForEmployeeRes: null,
      getLeaveDataForEmployeeResError: null,
    },
  };
};
const getLeaveDateData = (state, payload) => {
  return {
    ...state,
    ...{
      isLeaveDataForEmployeePending: false,
      getLeaveDataForEmployeeRes: payload,
      getLeaveDataForEmployeeResError: null,
    },
  };
};

export default createReducer(
  {
    [actions.getAllLeaveDateWithinDateRange]: getLeaveDateReaquest,
    [actions.getAllLeaveDateWithinDateRangeSuccess]: getLeaveDateData,
  },
  initialState,
);
