
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile
} from '@coreui/react'
import './offer_letter_qspl.css'
import Moment from 'react-moment';


const OfferLetterQSPL = (props) => {
  const { candidate, joiningDate, todayDate } = props

  const [isCTCFullDetails, setIsCTCFullDetails] = useState(false)

  // const [large, setLarge] = useState(false)
  useEffect(() => {

  }, [])

  // const isModalOpen = () => {
  //     setLarge(!large)
  // }
  const ctcFullDetails = () => {
    setIsCTCFullDetails(true)
  }

  return (
    <div className="ad">
      <hr className="line_rating"/>
      <h4 align="center">Offer Letter QSPL</h4>
      <div className="main-div">
        <center>
          <div style={{ maxWidth: "800px", minHeight: "600px", margin: "0 auto", backgroundColor: "white" }} className="email-container">
            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ margin: "auto" }}>
              <tr>
                <td valign="top" className="bg_white" style={{ padding: "1em 2.5em 0 2.5em" }}>
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td className="logo" style={{ textAlign: "center" }}>
                        <img src="images/logo.png" height="60" width="80" alt="" />
                      </td>
                    </tr>
                  </table>
                  <hr className="line_rating" />
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <td>
                  <div className="text" style={{ padding: "0 2.5em", textAlign: "left", width: "618px" }}>
                    <p style={{ fontSize: "15px", fontFamily: "'Work Sans', sans-serif" }}>Date: 4th January 2022 <br />
                      RefNo:QSPL/HR/OL/JPR/2021-22<br />
                      To, <strong className="hr-color" style={{color:"lightblue"}}>{candidate && candidate.name}</strong></p>
                    <h4 style={{ fontSize: "16px", fontWeight: "bolder", fontFamily: "'Work Sans', sans-serif", textAlign: "center" }}><u>Subject:Offer Letter</u></h4>
                    <p style={{ fontFamily: "'Work Sans', sans-serif" }}>This has reference to your application and the subsequent interviews you had with us. We are
                      pleased to make you an offer for the position of “<strong className="hr-color">{candidate && candidate.Role}</strong>” at <strong className="hr-color">{candidate && candidate.Location}</strong> location. You will begiven a
                      Letter ofAppointment on joining.</p>


                    <p style={{ fontFamily: "'Work Sans', sans-serif", fontWeight: "bolder", fontSize: "medium" }}><b>Attached isa copy giving details of your salary package.(Refer Annexure A)</b></p>
                    <p style={{ fontFamily: "'Work Sans', sans-serif" }}>Your appointment is subject to:</p>

                    <ol>
                      <li>You’re joining the services ofthe company on or before <strong className="hr-color"> {joiningDate && joiningDate}</strong></li>
                      <li>Your written acceptance of the offer letter.</li>
                      <li>The verification of your testimonials.</li>
                      <li>Us receiving satisfactory reports from your references</li>
                      <li>You are found medically fit for the</li>
                    </ol>
                    <p style={{ fontFamily: "'Work Sans', sans-serif" }}>job.Copy of police verification certificate.</p>
                    <p style={{ fontFamily: "'Work Sans', sans-serif" }}>30 days of Notice period needs to be served before leaving organization. Any sort of available
                      leaves will not be adjusted against the notice period. <br />
                      In case you leave the organization within six months of your joining, you shall be liable to pay the
                      company sum equivalent to your three months gross salary. <br />
                      You will be onProbation period for six months. <br />
                      <br />
                      Please sign and return to usthe duplicate of thisletter as a token of youracceptance of the above
                      terms andconditions. <br />
                      <br />
                      We look forward to you joining our team and hope itis the beginning of a mutually fulfilling
                      association.</p>
                    <p style={{ fontSize: "12px", fontWeight: "bold", fontFamily: "'Work Sans', sans-serif" }}>Disclaimer: This document is aconfidential and is explicitly reserved to QDegrees. QDegrees is only authorized to
                      modify any clause mentioned in the document at any point oftime.</p>
                    <p style={{ fontFamily: "'Work Sans', sans-serif" }}>ForQDegreesServices Pvt. Ltd.</p>
                    <h4 style={{ fontFamily: "'Work Sans', sans-serif" }}><b>HR-Executive</b></h4>
                    <p style={{ fontFamily: "'Work Sans', sans-serif" }}>I have read and accept the terms and conditions:</p>
                    <p style={{ fontFamily: "'Work Sans', sans-serif" }}><b>QDegrees ServicesPrivate Limited</b> </p>
                    <p style={{ fontFamily: "'Work Sans', sans-serif", fontSize: "small" }}>601-610, IV Floor, Anchor Mall, Civil Lines, Jaipur-302006 CIN No.U27900RJ2017PTC056940 <br />
                      0141-4911882, info@qdegrees.com, www.qdegrees.com</p>
                    <hr className="line_rating" />
                  </div>
                </td>
              </tr>
            </table>

            <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td className="bg_light" style={{ textAlign: "center" }}>

                  <tr>
                    <td className="text-footer1 pb10" style={{ color: "#000", fontFamily: "'Work Sans', sans-serif", fontSize: "14px", lineHeight: "20px", textAlign: "center", paddingBottom: "10px" }}>© Copyright (2021)Powered by <a href="https://www.qdegrees.com/">QDegrees</a></td>
                  </tr>
                  <tr>
                    <td className="text-footer2 pb20" style={{ color: "#000", fontFamily: "'Work Sans', sans-serif", fontSize: "12px", lineHeight: "26px", textAlign: "center", paddingBottom: "20px" }}>B-9, 1st Floor, Mahalaxmi Nagar, Behind WTP South Block, Malviya Nagar, Jaipur, Rajasthan (302017)</td>
                  </tr>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </div>

    </div>
  )
}
export default OfferLetterQSPL







