import React from "react";
import CIcon from "@coreui/icons-react";
import {getPermissions, isPermission} from '../../utils/common';
const permission = getPermissions({permissionType:"menu"});
let navArr=[];
for(let i=0;i<permission.length;i++){
  let result = getPermissions({permissionType:"menu_submenu",id:permission[i]?.id});
  if(result && result.length>0){
    navArr.push({
      _tag: 'CSidebarNavDropdown',
      name: permission[i]?.title || "",
      route: permission[i]?.routes || "",
      icon: permission[i]?.icon || "",
      _children: result
    });
  }else{
    navArr.push({
      _tag: 'CSidebarNavItem',
      name: permission[i]?.title || "",
      to: permission[i]?.routes || "",
      icon: permission[i]?.icon || ""
    });
  }
}
export default navArr;
