import React, { useState, lazy, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {
  CBadge, CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CProgress,
  CCardGroup,
  CProgressBar,
  CRow,
  CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
  CCallout
} from '@coreui/react'
import {
  CChartDoughnut
} from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { withRouter} from "react-router-dom";
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from "sweetalert";
import { ToastContainer } from 'react-toastify';
import { setRef } from '@material-ui/core';
import moment from 'moment';
import './birthday_component.css'


const BirthdayComponent = (props) => {
  const{history}=props
  const api = new API()

  return (
    <div>
      <div width="100%">
        <center style={{width: "100%", backgroundColor: "#f1f1f1"}}>
          <div style={{display: "none", fontSize: "1px", maxHeight: "0px", maxWidth: "0px", opacity: "0", overflow: "hidden", fontFamily: "sans-serif"}}>
          </div>
          <div style={{margin: "0 auto"}} className="email-container">
            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{margin: "auto"}}>
              <tr>
                <td valign="top" className="bg_light">
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>

                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td valign="middle" className="hero bg_white" style={{backgroundImage: "url(images/birthday.gif)", backgroundSize: "cover", height: "600px"}}>
                  <div className="overlay"></div>
                  <table>
                    <tr>
                      <td>
                        <div className="text text-center">
                          <h2>Dear <span style={{color: "#FFD700 !important"}}>CB Rajesh</span><br />Many Many Happy Returns of the Day</h2>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <div className="img" style={{margin: "auto"}}>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bg_white email-section">
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td valign="top" width="50%">
                        
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </div>
    </div>
  )
}

export default withRouter(BirthdayComponent)
