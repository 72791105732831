import React, { useState, lazy, useEffect } from 'react'
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,withRouter
} from "react-router-dom";
import Moment from 'react-moment';


import CIcon from '@coreui/icons-react'
import { DocsLink } from 'src/reusable'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import './scanQR.css'
import { validation } from '../../../../utils/configCommon'
import QrReader from 'react-qr-reader'

const ScanQR = ({ match,history }) => {
    // let {  } = props
    const [qrCode, setQrCode] = useState()
    const [isQrCodeScaned, setIsQrCodeScaned] = useState(false)
    let api = new API()
    


    useEffect(() => {

    }, [])
    const handleScan = data => {
        if (!qrCode) {
            // console.log("scanning", data)
            if (data) {
                setQrCode(data)
                setIsQrCodeScaned(true)
                setTimeout(() => {
                    onChangeUrl(data)
                }, 2000)
            }
        }
    }

    const handleError = err => {
        console.error(err)
    }

    const onChangeUrl = (data) => {
        // alert("redirecting")
        // alert(qrCode)
        // if(qrCode == null || qrCode == undefined || qrCode == ""){
        // alert("please add qr code")
        // } else {
        // history.push(qrCode)
        window.open(data)
    }




    return (

        <div >
            <div className="bg-QR-color custome-container">
                <div className="container Screen-main">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <h5 className="mb-2 text-center text-white mt-0"><ins>Scan the QR code</ins></h5>

                            <div className="cameraSection mt-2">

                                <div className="" style={{ height: "auto", maxWidth: "400px", width: "100%", margin: "auto", border: "2px solid rgb(252, 252, 252)" }}>
                                    <h3 className="mb-0" style={{ color: "white", textAlign: "center" }}>
                                        <QrReader
                                            delay={300}
                                            onError={handleError}
                                            onScan={handleScan}
                                            style={{ width: '100%' }}
                                        />
                                    </h3>
                                </div>
                            </div>

                            <div className="box-" style={{ maxWidth: "400px", width: "100%", margin: "auto", marginTop: "10px" }}>

                                <div className="input-group mb-3" >
                                    <input type="text" className="form-control" placeholder="  Enter URL ..." id="mail" name="email" onChange={(e) => (setQrCode(e.target.value))} value={qrCode} />
                                    <div className="input-group-append">
                                        <a className="input-group-text bg-icon" href={qrCode} target="_blank"><i className="fa fa-arrow-right fa-lg" aria-hidden="true" style={{ width: "100%" }}  ></i></a>
                                    </div>
                                </div>

                                <div className="col-md-12" align="center">
                                    {/* {isQrCodeScaned  ?
                                        <>
                                            <h1 align="center">{}</h1>
                                            <a href={qrCode}>Click to book your meeting room now</a>
                                        </>
                                        :
                                        <h5 className="text-white">Scan QR for book meeting room</h5>
                                    } */}

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* <h3 align="center" className="text-dark">Scan QR code .</h3>
            <div>
                <div className="row">
                    <div className="col-md-6" align="center">

                        <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '80%' }}
                        />
                    </div>
                    <div className="col-md-6" align="center">
                        {qrCode==false ?
                        <>
                        <h1 align="center">{qrCode}</h1>
                            <Link to="/booking-meeting-room/Mini"><button className="btn btn-success mt-4 text-center">Book Your Meeting Room Now</button></Link>
                        </>
                        :
                        <h3 className="text-dark">Scan QR for book meeting room</h3>
                        }

                    </div>
                </div>
            </div> */}
        </div>

    )
}
export default withRouter(ScanQR)