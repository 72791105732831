import React, {useState} from 'react';

const Clearance_form = () => {

    const [visible, setVisible]=useState(false);

  return (
    <>
    <div className='c-body'>
        <div className="row">
            <div className="col-md-12">
            <form style={{padding:"16px", background:"white",  margin:"16px", borderRadius:"9px"}}>
            <table>
              <tr><label>Exit Interview :</label> 
              <td> <input type="text" className='form-control' placeholder='insert company’s name'/><br/></td>
              </tr>
              
              <tr><label>Employee Name :</label> 
              <td> <input type="text" className='form-control' placeholder='insert name '/><br/></td>
              </tr>

              <tr><label>Position/Designation:</label> 
              <td> <input type="text" className='form-control' placeholder='insert position'/><br/></td>
              </tr>

              <tr><label>Date of interview</label> 
              <td> <input type="date" className='form-control' placeholder='insert exit interview date'/><br/></td>
              </tr>
            </table>
            <hr />

            <table>
                <tr>1.	Will you recommend our organization to your friends and family for potential employment opportunities? (On a scale of 0-10 where 0 is the lowest and 10 is the highest) -NPS</tr>
            </table>


            <table>
                <tr>
                    <th>2.	What are the reasons for leaving? (Multiple Text)</th>
                </tr>
            </table>


            <table className='table'>
                <tr>
                    <td><input type="checkbox"/>Higher pay</td>
                    <td><input type="checkbox"/>Better benefits</td>
                    <td><input type="checkbox"/>Better career opportunity</td>
                </tr>
                <tr>
                    <td><input type="checkbox"/>Improved work-life balance</td>
                    <td><input type="checkbox"/>Career change</td>
                    <td><input type="checkbox"/>Travelling Distance  </td>
                </tr>
                <tr>
                    <td><input type="checkbox"/>Conflict with other employees</td>
                    <td><input type="checkbox"/>Conflict with managers</td>
                    <td><input type="checkbox"/>Family and/or personal reasons</td>
                </tr>
                <tr>
                    <td><input type="checkbox"/>Company instability</td>
                    <td><input type="checkbox" onClick={()=>setVisible(!visible)}/>Other (Text Box) </td>

                   
                </tr>
                <tr>
                  { visible &&
                            <td><input type="textarea"/></td>
                    }
                </tr>
            </table>

            <table  className='table'>
                <tr>
                   <th>3.	Please select your feedback against the statements mentioned below-</th>
                </tr>
                <tr>
                Section 1:
                </tr>
            </table>

            <table  className='table'>
            
               
                <tr>
                    <th>a) The Job Itself </th>
                    <th>Strongly Disagree</th>
                    <th>Disagree</th>
                    <th>Agree</th>
                    <th>Strongly Agree</th>
                </tr>
                <tr>
                    <td>Job was challenging</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>There were sufficient opportunities for advancement </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Workload was manageable</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Sufficient resources and staff were available</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Your colleagues listened and appreciated your suggestions </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Your skills were effectively used</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>You had access to adequate training and development programs </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Provided recognition on the job </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><input type="checkbox"/></td>
                </tr>

                <tr>
                    <th colSpan={"5"}>b)	What do you think can be improved about the job? (Free Text)</th>
                </tr>
                <tr>
                Section 2:
                </tr>
                <tr>
                    <th>a)	Remuneration & Benefits </th>
                    <th>Strongly Disagree</th>
                    <th>Disagree</th>
                    <th>Agree</th>
                    <th>Strongly Agree</th>
                </tr>
                <tr>
                    <td>The salary was adequate about responsibilities</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>The salary was paid on time</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Other benefits were good </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Work-life balance was promoted and practiced</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>The company’s superannuation fund returned good results</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>


                <tr>
                    <th colSpan={"5"}>b)	 What improvements and other benefits could the company offer? (Free Text)</th>
                </tr>
                <tr>
                Section 3:
                </tr>
                <tr>
                    <th>a)	The Company</th>
                    <th>Strongly Disagree</th>
                    <th>Disagree</th>
                    <th>Agree</th>
                    <th>Strongly Agree</th>
                </tr>
                <tr>
                    <td>The recruitment process is well defined </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>HR was easily accessible </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>The induction & orientation was informative and beneficial</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>The working culture was positive and energetic</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Had adequate assets to work</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Got on well with other staff in the company</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>There was sufficient staff to cover the work</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>The company was efficient in its offerings</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Internal communication worked well</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>There was no bullying or harassment </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>There was an adequate parking facility</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>



                <tr>
                    <th>b)	What do you think can be improved about the Department and Company? (Free Text)</th>
                </tr>
                <tr>
                Section 4:
                </tr>
                <tr>
                    <th>a)	Supervisor / Line Manager</th>
                    <th>Strongly Disagree</th>
                    <th>Disagree</th>
                    <th>Agree</th>
                    <th>Strongly Agree</th>
                </tr>
                <tr>
                    <td>Had sufficient knowledge of the job</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Is experienced in supervision </td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Recognized and acknowledged the achievements</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Acknowledged employees’ contributions</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Offered and promoted ways to develop skills</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Provided constructive feedback</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Had clear communication</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Maintained professional relationship</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>



                <tr>
                Section 5:
                </tr>
                <tr>
                    <th>a)	Management</th>
                    <th>Strongly Disagree</th>
                    <th>Disagree</th>
                    <th>Agree</th>
                    <th>Strongly Agree</th>
                </tr>
                <tr>
                    <td>Demonstrated fair & equal treatment</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Was available to discuss job-related issues</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Encouraged feedback and suggestions</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Company policies are adequate</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Maintained consistent policies and practices</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Provided recognition for achievements</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Provided constructive feedback</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <td>Communicated management decisions</td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                    <td><input type="checkbox"/></td>
                </tr>
                <tr>
                    <th>b)	What are your suggestions to Management?</th>
                </tr>
                   
            </table>
            <table>
                <tr>4.	Under what conditions would you have stayed? (Free text)</tr>
                <tr>5.	Before making the decision to leave, did you examine the other options that would have enabled you to stay? If so, what are they? (Free Text)</tr>
                <tr>6.	If got an opportunity, would you like to rejoin our organization in the future?</tr>
                <tr>Yes</tr>
                <tr>No(Text box- why?)</tr>    
                <tr>7.	Do you have any suggestions about our company, your role, or factors that contributed to your decision? Free text.</tr>
            
            <tr>Thank you for completing this information. Your responses will be treated with total confidence. </tr>
            <tr>As an employee completes the survey, HR COMMENT box will appear to take HRs input.</tr>
         
            </table>
        </form>
        </div>
        </div>
        </div>
    </>
  )
}

export default Clearance_form