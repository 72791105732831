import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';

import CreateNewCandidateWithoutMRF from './candidateWithoutMRF';

const CandidateModalWithoutMRF = props => {
  const {
    createNewCandidateWithoutMRF,
    isCreateCandidateWithoutMRFModalOpen,
    setIisCreateCandidateWithoutMRFModalOpen,
  } = props;

  useEffect(() => {}, []);
  return (
    <div className="">
      <CModal
        show={isCreateCandidateWithoutMRFModalOpen}
        onClose={() => createNewCandidateWithoutMRF()}
        size="xl"
      >
        <CModalHeader closeButton>
          <CModalTitle>Create New Candidate</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <CreateNewCandidateWithoutMRF
            createNewCandidateWithoutMRF={createNewCandidateWithoutMRF}
            isCreateCandidateWithoutMRFModalOpen={isCreateCandidateWithoutMRFModalOpen}
            setIisCreateCandidateWithoutMRFModalOpen={setIisCreateCandidateWithoutMRFModalOpen}
          />
        </CModalBody>
        <CModalFooter>
          <CButton
            color="danger"
            onClick={() => createNewCandidateWithoutMRF()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>{' '}
          </CButton>{' '}
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default CandidateModalWithoutMRF;
