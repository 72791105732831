import React, { Component } from 'react';
//import Urlify from '../../../../componets/Urlify/Urlify';
//import { ReplyContainer } from '../../index';

const TextType = props => {
  const { message, isHisMsg, fileSize, fileExt, fileMimeType, time } = props;

  const renderMessage = () => {
    return (
      <div>
        <span>{message?.payload?.message || ''}</span>
      </div>
    );
  };

  return <div>{renderMessage()}</div>;
};

export default TextType;
