
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import './contribution_popup.css'
import { config } from 'src/utils/apiUrl'
import API from 'src/utils/apiCalling'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContributionPopup = (props) => {
    const api = new API()
    const { isOpen, isViewModalOpen, setIsOpenContributionSurvey, employeeName } = props

    const [amount, setAmount] = useState("")
    const [type, setType] = useState()
    const [amountShow, setAmountShow] = useState(false)

    useEffect(() => {

    }, [])
    const isModalOpen = () => {

    }

    const submitContribution = async (type) => {
        if (type == "yes" && (!amount || amount < 1)) {
            toast.warning("Amount should be more than 0")
            return
        }
        let data = {
            amount: amount,
            type: type
        }
        if(type == "no"){
            data.amount = 0
        }
        // console.log("**************", data)
        // return 
        let result = await api.post(config.giveContribution, data)
        if (result && result.code == 200) {
        alert(result.message);
            // setTimeout(() => {
            setIsOpenContributionSurvey(false)
            // }, 1000)
        } else {
            // console.log(result && result.message)
        }
    }
    const amountSelectFun = (e) =>{
        setAmount(e.target.value)
        setAmountShow(true)
    }
    return (
        <div className="">
            <CModal style={{ background: "none", border: "none" }}
                show={isOpen}
                // show={true}
                onClose={() => !isOpen}
                size="lg"
            >
                <CModalBody>
                     {/* <div className="justify-content-center">  */}
                         {/* <div className="card contribution_popup mt-5">  */}
                            <div className="d-flex justify-content-center">
                                <div className="contribution_popup main-card mt-5 pb-5">
                                    <div className="mt-2 p-4 text-center">
                                        <h5 className="mb-0" style={{ color: "#6f4e37" }}>Hi {employeeName}</h5>
                                        <h6 className="mb-0" style={{ color: "#6f4e37", textAlign: "justify", fontSize:"17px" }}>It is with a heavy heart that we reach out to you today to share some devastating news. Our dear colleague has suffered the unimaginable loss of his wife who recently passed away.<br></br> <br></br>In times like these, We, as a caring and supportive community, can come together to offer comfort and assistance during his time of need. <br></br> <br></br>Contributions of any size will make a difference and show that he is not alone during this difficult period. </h6>
                                        <div className="d-flex flex-row justify-content-center mt-2">
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {/* <div className="for-img">
                                                        </div> */}
                                                    </div>
                                                    <h6 className="px-3 mt-3 text-center" style={{ color: "#6f4e37" }}>Please Note: You contribution will be deducted from your upcoming month’s salary.</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group remarkInput mt-0">
                                            <button type="submit" onClick={(e) => amountSelectFun(e)} value="100" className="btn-main btnOne">100</button>
                                            <button type="submit" onClick={(e) => amountSelectFun(e)} value="200" className="btn-main btnOne">200</button>
                                            <button type="submit" onClick={(e) => amountSelectFun(e)} value="500" className="btn-main btnOne">500</button>
                                            <button type="submit" onClick={(e) => amountSelectFun(e)} value="1000" className="btn-main btnOne">1000</button>
                                            <button type="submit" onClick={(e) => amountSelectFun(e)} value="2000" className="btn-main btnOne">2000</button>
                                            <button type="submit" onClick={(e) => setAmountShow(true)} value="0" className="btn-main btnOne">Other</button>
                                            <form className="">
                                                <div className="text-center mt-2" style={{ margin: "auto", width: "70%" }}>
                                                    {(amount || amountShow) &&
                                                        <>
                                                            <h6 align="center">OR</h6>
                                                            <div className="form__group field text-center">
                                                                <input type="number" className="form__field text-center" onChange={(e) => setAmount(e.target.value)} value={amount} placeholder="Enter Amount" name="name" id='name' required />
                                                                <label for="name" className="form__label text-center">Enter Amount</label>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <button type="button" className="btn btn-link" onClick={()=>submitContribution("no")}> Don't want to contribue</button><br />
                                                <button type="button" className="btn btn-primary" onClick={()=>submitContribution("yes")}>Submit</button><br />
                                                <small style={{ color: "blue", fontSize:"15px" }}>** It’s not mandatory and is subject to one’s own will. **</small>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </CModalBody>
                {/* <CModalFooter>
                    <CButton color="success" onClick={() => isViewModalOpen()}>Close</CButton>{' '}

                </CModalFooter> */}
            </CModal>
        </div>

    )
}
export default ContributionPopup







