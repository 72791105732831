import React, { useState } from 'react'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'
import { getLocalDataAsObject } from 'src/utils/CoustomStorage'
import {
  CModal,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/react'
import { useEffect } from 'react'
import { async } from '@firebase/util'


const SurveyModal = (props) => {
  const empName = getLocalDataAsObject('user')[0].first_name



 


  const api = new API();
  // const apicallingFun = async (url) => {
  //   var requestOptions = {
  //     method: 'POST',
  //     redirect: 'follow',
  //     body: JSON.stringify()

  //   };

  //   fetch(url, requestOptions)
  //     .then(response => response.text())
  //     .then((result) => {
  //       return result
  //     })
  //     .catch(error => console.log('error', error));
  // }

  
  const { isSurveyModalOpen, handleSurveyModal, setIsSurveyModalOpen,surveyURL } = props


  return (
    <CModal
      show={isSurveyModalOpen}
      onClose={() => handleSurveyModal(!isSurveyModalOpen)}
      size="xl"
    >

      <CModalBody >
        <div className='w-100 text-center py-3'>
          <strong>We invite your opinion to make QDegrees better. </strong>
        </div>
        <div>
          <span>{`Dear ${empName},`}</span>
        </div>
        <div className='py-2'>
          <span>Greetings of the day!</span>

        </div>

        <div className='py-2'>
        It is always an endeavor to reach out to the most valuable asset of our organization. Hence, we invite you to express your views and opinion to make QDegrees a better place to work        </div>
        <div className='py-2'>
        QDegrees wants to act as a responsible employer and wishes to fulfill the career aspirations of all its staff members through a strategic & focused approach. And the biggest source of discovering the opportunities is our people and hence, we want to channel it through an Employee Satisfaction Survey        </div>
        <div className='py-2'>
        Thank you in advance for taking a few minutes out of your day to make QDegrees an ever-better place to work!

        </div>
        <div className='py-2'>
          <i>
          Note- Feel free to answer the questions. No personal information is required to participate in the survey and individual details will be kept anonymous & confidential.  

          </i>
     
        </div>
        <div className='w-100 text-center py-4'>
          <a className='btn btn-success text-center ' href={surveyURL} >Begin Now</a>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="success" onClick={() => setIsSurveyModalOpen(!isSurveyModalOpen)}>Close</CButton>
      </CModalFooter>
    </CModal>
  )
}

export default SurveyModal