import React, { useState } from 'react';
import { CDataTable } from '@coreui/react';
import { convertDate } from 'src/utils/common';
import { isPermission } from 'src/utils/common';
import { FilePreviewModal } from 'src/modals';
import ViewLeaveDateModal from './ViewLeaveDateModal';
import { Base_URI } from 'src/utils/apiUrl';

const LeaveApprovalList = props => {
  const {
    data,
    type,
    renderDataStatus,
    renderDataActionBtn,
    getLeaveForApproval,
  } = props;

  const [viewLaeveModal, setViewLeaveModal] = useState(false);
  const [leaveData, setLeaveData] = useState();
  const [previewFile, setPreviewFile] = useState(false);
  const [fileData, setFileData] = useState('');
  const handleLeaveModal = item => {
    setLeaveData(item);
    setViewLeaveModal(!viewLaeveModal);
  };
  function allLeaveStatus(arr) {

    let status = '';
    let p = 0;
    let a = 0;
    let c = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].is_approve_status === 0 && arr[i].is_rejected_status === 0 && arr[i].is_cancel === 0) {
        p++;
      }else if (arr[i].is_approve_status === 0 && arr[i].is_rejected_status === 0 && arr[i].is_cancel === 1) {
        c++;
      }
      else {
        a++;
      }
    }
    if (arr.length === a) {
      return (
        <span>
          <i
            className="fa fa-circle danger"
            style={{ color: '#96f77c' }}
            aria-hidden="true"
          ></i>{' '}
          Completed{' '}
        </span>
      );
    }
    if (arr.length === c) {
      return (
        <span>
          <i
            className="fa fa-circle danger"
            style={{ color: '#19789e' }}
            aria-hidden="true"
          ></i>{' '}
          Cancelled{' '}
        </span>
      );
    }
    else {
      return (
      <span>
        <i
          className="fa fa-circle danger"
          style={{ color: '#f5d99a' }}
          aria-hidden="true"
        ></i>{' '}
        Pending
      </span>
      );
    }
  }

  const onFilePreview =  (item) => {
    setPreviewFile(true);
    setFileData({
          filename: item?.file_name || '',
          payload: `${Base_URI}/employee_leave_documents/${item?.file_name}`,
          mime: '',
        });
  }

  const fields = [
    {
      label: 'Employee ID',
      key: 'emp_id',
      sorter: false,
      filter: true,
    },
    {
      label: 'Employee Name',
      key: 'employee_name',
      sorter: false,
      filter: true,
    },
    {
      label: 'Leave From',
      key: 'leave_from',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave To',
      key: 'leave_to',
      sorter: true,
      filter: true,
    },

    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },

    {
      label: 'Created Date',
      key: 'raised_date',
      sorter: true,
      filter: true,
    },
    {
      label: 'View File',
      key: 'file',
      sorter: true,
      filter: true,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Action',
      key: 'view',
      sorter: true,
      filter: true,
    },
    // {
    //   label: '',
    //   key: 'action',
    //   sorter: true,
    //   filter: true,
    // },
  ];

  return (
    <>
      <CDataTable
        items={data}
        fields={fields}
        tableFilter={{ label: 'Filter:', placeholder: 'Search' }}
        itemsPerPageSelect
        itemsPerPage={10}
        hover
        sorter
        pagination
        scopedSlots={{
          leave_from: item => (
            <td>
              {convertDate(item?.leaveDateArray[0].leave_date, 'DD-MM-YYYY')}
            </td>
          ),
          leave_to: item => (
            <td>
              {item.leaveDateArray.length > 0
                ? convertDate(
                    item?.leaveDateArray[item.leaveDateArray.length - 1]
                      .leave_date,
                    'DD-MM-YYYY',
                  )
                : convertDate(item?.leaveDateArray[0].leave_date, 'DD-MM-YYYY')}
            </td>
          ),
          status: item => <td>{allLeaveStatus(item?.leaveDateArray)}</td>,
          file: item => <td style={{cursor:'pointer', textAlign:'center'}}>{item?.file_name ? <i className='fa fa-eye' aria-hidden='true' onClick={e=>onFilePreview(item)}></i> : ''}</td>,
          leave_date: item => (
            <td>{convertDate(item.leave_date, 'DD-MM-YYYY')}</td>
          ),
          raised_date: item => (
            <td>{convertDate(item.created_at, 'DD-MM-YYYY')}</td>
          ),
          view: item => (
            <td onClick={() => handleLeaveModal(item)}>
              <i className="fa fa-tasks" aria-hidden="true"></i>
            </td>
          ),
          action: item => (
            <td>
              {isPermission('approve_reject_leave_request') && (
                <>{renderDataActionBtn(item, type)}</>
              )}
            </td>
          ),
        }}
      />
      <ViewLeaveDateModal
        getLeaveForApproval={getLeaveForApproval}
        leaveData={leaveData}
        viewLaeveModal={viewLaeveModal}
        handleLeaveModal={handleLeaveModal}
      />
        <FilePreviewModal
          isOpen={previewFile}
          toggleModal={() => {
            setPreviewFile(false);
          }}
          fileData={fileData}
        />
    </>
  );
};

export default LeaveApprovalList;
