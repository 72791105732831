import React, { Component } from 'react';
//import Urlify from '../../../../componets/Urlify/Urlify';
//import { ReplyContainer } from '../../index';

const ImageType = props => {
  const { message, isHisMsg, fileSize, fileExt, fileMimeType, time } = props;

  const renderMessage = () => {
    const fileUrl =
      message?.payload?.thumbnailUrl || message?.payload?.url || '';
    return (
      <div>
        <a href={message?.payload?.url} target="_blank" rel="noreferrer">
          <img src={fileUrl} height="50px" width="50px" alt="img"></img>
        </a>
        <p className="fw-normal m-0 mt-2" style={{ fontSize: '10px' }}>
          {fileSize}
        </p>
      </div>
    );
  };

  return <div>{renderMessage()}</div>;
};

export default ImageType;
