import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import Hr_Manager_Dashboard from './hr-manager-dashboard'

const HrManagerDashbard = () => {
  
  useEffect(()=> {

  },[])
  return (
    <div>
        {/* <h2> Hr Panel</h2> */}
        <Hr_Manager_Dashboard/>
        
    </div>

  )
}
export default HrManagerDashbard