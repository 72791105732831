import React, { useEffect, useState } from 'react';
import API from 'src/utils/apiCalling';

import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';

import { diffBetweenTimes, comapareTime, timeAdd } from 'src/utils/common';

import { CustButton } from 'src/reusable';
import moment from 'moment';
import { timeGap } from 'src/utils/common';
import swal from 'sweetalert';

const OtApprovalRejectionModal = props => {
  const api = new API();
  const {
    title,
    isShow,
    closeConfirmModal,
    handleOnSubmit,
    data,
    currentSelectedAction,
    isLoading,
  } = props


  console.log( title,
    isShow,
    closeConfirmModal,
    handleOnSubmit,
    data,
    currentSelectedAction,
    isLoading,"kjkj")
  const [comment, setComment] = useState('');
  const [approveForStatus, setApproveStatus] = useState();
  const [commentErrorMsg, setCommentErrorMsg] = useState('');
  const [newTime, setNewTime] = useState(data?.time);

 
  const handleOnChange = e => {
    const { value } = e.target;
    if (value && commentErrorMsg) {
      setCommentErrorMsg('');
    }
    setComment(e.target.value);
  };

  const handleOnRightBtnClick = () => {
    if (data.type === 'over_time') {
      if (currentSelectedAction === 'reject' && !comment) {
        setCommentErrorMsg('Reason field is required');
        return;
      } else {
        if (!approveForStatus&&currentSelectedAction == 'approve') {

          swal({
            html: true,
            title: 'Are you sure?',
            text: `You are going to approval for ${newTime} time`,
            icon: 'warning',
            buttons: ['No, cancel it!', 'Approve'],
            dangerMode: true,
          }).then(async function(isConfirm) {
            if (isConfirm) {
              // setApproveStatus(value);
              console.log(data)
              const params = {
                id:data.otId,
                // type: data.type,
                is_approved: currentSelectedAction,
                comment: comment,
                time:newTime
              };
              handleOnSubmit(params);
            } else {
              setApproveStatus('');
            }
          });
          // alert('Please select Approve For ');
          return;
        } else if (!approveForStatus&&currentSelectedAction === 'reject') {

          swal({
            html: true,
            title: 'Are you sure?',
            text: `You are going to rejection for ${newTime} time`,
            icon: 'warning',
            buttons: ['No, cancel it!', 'Reject'],
            dangerMode: true,
          }).then(async function(isConfirm) {
            if (isConfirm) {
              // setApproveStatus(value);
              console.log(data)
              const params = {
                id:data.otId,
                // type: data.type,
                is_approved: currentSelectedAction,
                comment: comment,
                time:newTime
              };
              handleOnSubmit(params);
            } else {
              setApproveStatus('');
            }
          });
          // alert('Please select Approve For ');
          return;
      }

      const params = {
        id:data.id,
        type: data.type,
        // is_approved: currentSelectedAction,
        comment: comment,
        time:newTime
      };
      handleOnSubmit(params);
    }
  }
}

  const formatValue = val => {
    return `${val < 0 ? '-' : ''} ${
      Math.abs(val) < 10 ? `0${Math.abs(val)}` : Math.abs(val)
    }`;
  };

  const closeModalfun = () => {
    closeConfirmModal();
    setApproveStatus('');
    setComment('');
  };

  return (
    <div>
      <CModal show={isShow} onClose={closeModalfun} size="lg" backdrop={false}>
        <CModalHeader closeButton>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {data && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <td colSpan={2}>
                   
                    </td>
                    
                  </tr>
                  <tr>
                    
                    <th>Applied OT Time</th>
                    <th>Correction Time</th>
              
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    
                    <td>{data.ot_hours}</td>
                    <td><input type='time' className='form-control' 
                    value={(data.ot_hours)} onChange={(e)=>setNewTime(e.target.value)} disabled={currentSelectedAction=='reject'?true:false}/></td>
                 
                 </tr>
                  
                </tbody>
              </table>
            </div>
          )}
          

          {/* {currentSelectedAction === 'reject' && ( */}
          <form>
            <div className="form-group">
              <label htmlFor="formGroupExampleInput">
                Reason <span className="required-field">*</span>
              </label>
              <textarea
                className="form-control"
                name="comment"
                value={comment}
                onChange={handleOnChange}
                id="comment"
                rows="3"
              ></textarea>
              <span className="error-msg">{commentErrorMsg}</span>
            </div>
          </form>

          {/* )} */}
        </CModalBody>
        <CModalFooter>
          {currentSelectedAction=='approve'?<CustButton
            name="save-ar"
            text="Approve"
            color="primary"
            onClick={handleOnRightBtnClick}
            className="btn-loading btn-ladda"
            isDisabled={comment ? false : true}
            isLoading={isLoading}
          />:
          <CustButton
            name="save-ar"
            text="Reject"
            color="danger"
            onClick={handleOnRightBtnClick}
            className="btn-loading btn-ladda"
            isDisabled={comment ? false : true}
            isLoading={isLoading}
          />
          }
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default OtApprovalRejectionModal;
