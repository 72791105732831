import { CButton } from '@coreui/react';
import { useState } from 'react';
import ModalViewSepration from 'src/views/seperation/received_sepration/modal';

const SeparationBtn = ({ item, reloadTaskBox  }) => {
  const [empResignationData, setEmpResignationData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const isViewModalOpen = empResignationDetails => {
    setEmpResignationData(empResignationDetails);
    setIsOpen(!isOpen);
  };



  return (
    <>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={e => {
            isViewModalOpen(item);
          }}
        >
          View
        </CButton>
      </div>
      <ModalViewSepration
        isOpen={isOpen}
        isViewModalOpen={isViewModalOpen}
        empResignationData={empResignationData}
        reloadTaskBox={reloadTaskBox}
        taskBox={true}
      />
    </>
  );
};

export default SeparationBtn;
