import React, { useState, useEffect } from 'react';
import { TheHeader } from 'src/containers';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
// import { SystemCalculatedAmount } from './AddNewTemplateData';
// import { templateData } from './AddNewTemplateData';
import { withRouter } from 'react-router-dom';

const AddNewTemplate = props => {
  const { history } = props;

  let api = new API();
  const [showSideBar, setShowSideBar] = useState(true);
  const [templateName, setTemplateName] = useState();
  const [templateDescription, setTemplateDescription] = useState();
  const{employeeDetail}=props
  const[employeeSalaryDetail,setEmployeeSalaryDetails]=useState()
  const[ctc,setCtc]=useState("")

  const [annualCtc, setAnnualCtc] = useState();
  const [monthlyCtc, setMonthlyCtc] = useState();
  const [basicAmount, setbasicAmount] = useState();
  const [earningArr, setEarningArr] = useState([]);
  const [deductionArr, setDeductionArr] = useState();
  const [selectedEarningArray, setSelectedEarningaAray] = useState([]);
  const [selectedDeductionArr, setSelsctedDeductionArr] = useState([]);
  const [systemCalculated, setSystemCalculated] = useState(true);
  const [systemCalcAmnt, setSystemCalcAmnt] = useState('');
  const [preview, setPreview] = useState(false);
  const [greaterAmount, setGreaterAmount] = useState(false);
  const getAllEarningAndDeduction = async () => {
    let result = await api.get(config.getAllTemplate);
    return result && result.code === 200 && result.data;
  };

  const getEmployeeSalaryDetails=async()=>{
    let data={
      is_include_bonus:1,
      ctc
    }
    let result =await api.post(config.generateOfferLetter,data)
    if(result&&result.code===200){
      setEmployeeSalaryDetails(result.data)
    }else{
      alert(result.message)
    }

  }
  
  useEffect(async () => {
    let templateData = await getAllEarningAndDeduction();

    let newArr = [];
    let newDeductionArr = [];
    templateData &&
      templateData.earning.length > 0 &&
      templateData.earning.map(earn => {
        newArr.push({
          id: earn.id,
          earningName: earn.earning_name,
          amount: earn.amount ? earn.amount : 0,
          is_amount_in_percentage:
            earn.value_type == 'percentage' ? true : false,
          amount_in_percentage:
            earn.value_type == 'percentage' ? earn.value : 0,
          is_used_in_template: earn.is_template_component == '0' ? false : true,
          type: earn.earning_type,
        });
      });
    templateData &&
      templateData.deductions.length > 0 &&
      templateData.deductions.map(deduction => {
        newDeductionArr.push({
          id: deduction.id,
          name: deduction?.name ? deduction?.name : deduction?.name_in_payslip,
          is_amount_in_percentage:
            deduction?.is_amount_in_percentage == 'true' ? true : false,
          amount_in_percentage: deduction?.amount_in_percentage
            ? deduction?.amount_in_percentage
            : 0,
          formated_amount_percentage: deduction?.amount_in_percentage_formatted
            ? deduction?.amount_in_percentage_formatted
            : 0,
          type: deduction.type ? deduction?.type : 'pre-tax',
          is_used_in_template: false,
          amount: deduction?.amount ? deduction?.amount : 0,
          is_active: deduction?.is_active ? deduction?.is_active : 1,
        });
      });
    setEarningArr(newArr);
    setDeductionArr(newDeductionArr);
    setSelsctedDeductionArr(
      newDeductionArr &&
        newDeductionArr.length > 0 &&
        newDeductionArr.filter(item => item.is_used_in_template === true),
    );
    setSelectedEarningaAray(
      newArr &&
        newArr.length > 0 &&
        newArr.filter(item => item.is_used_in_template === true),
    );
  }, []);
  const addComponent = id => {
    let newArray = [...earningArr];
    let index_object = newArray.filter(item => item.id == id);
    let index_no = earningArr.indexOf(index_object[0]);
    let newObject = newArray[index_no];
    newObject.is_used_in_template = !newObject.is_used_in_template;
    newArray[index_no] = newObject;

    setEarningArr([...newArray]);
    calculate();
    setSelectedEarningaAray(
      newArray &&
        newArray.length > 0 &&
        newArray.filter(item => item.is_used_in_template === true),
    );
  };

  const editEarning = (index, e) => {
    let newArr = [...selectedEarningArray];
    let obj = newArr[index];
    obj['amount_in_percentage'] = e.target.value;
    obj['amount'] = (e.target.value * basicAmount) / 100;
    newArr[index] = obj;
    setSelectedEarningaAray([...newArr]);
    calculate();
  };

  const editDeduction = (index, e) => {
    let newArr = [...selectedDeductionArr];
    let obj = newArr[index];
    if (e.target.name == 'percentage') {
      obj['is_amount_in_percentage'] = e.target.value == 'fixed' ? false : true;
    } else {
      if (obj['is_amount_in_percentage'] == true) {
        obj['amount_in_percentage'] = e.target.value;
        obj['amount'] = (e.target.value * basicAmount) / 100;
      } else {
        obj['amount'] = e.target.value * 1;
      }
    }
    newArr[index] = obj;
    setSelsctedDeductionArr([...newArr]);
    calculate();
  };

  const calculate = () => {
    let monthlyCtc = Math.round(annualCtc / 12);

    setMonthlyCtc(monthlyCtc);
    let systemCalculatedAmount = 0;
    let sum_of_all_component = 0;
    let basic_amount = 0;
    let new_Array_after_calculation = [...selectedEarningArray];
    let newDeductionArray = [...selectedDeductionArr];
    new_Array_after_calculation &&
      new_Array_after_calculation.length > 0 &&
      new_Array_after_calculation.map((item, index) => {
        if (item.is_amount_in_percentage === true && item.type === 'basic') {
          let percentage_value = item.amount_in_percentage * 1;
          let obj = new_Array_after_calculation[index];
          obj.amount = Math.floor((monthlyCtc * percentage_value) / 100);
          basic_amount += Math.floor((monthlyCtc * percentage_value) / 100);
          sum_of_all_component += basic_amount;

          new_Array_after_calculation[index] = obj;

          setSelectedEarningaAray(new_Array_after_calculation);
        }
        if (item.is_amount_in_percentage === true && item.type != 'basic') {
          let percentage_value = item.amount_in_percentage * 1;
          let obj = new_Array_after_calculation[index];
          let calculateAmount = Math.round(
            (basic_amount * percentage_value) / 100,
          );
          obj.amount = calculateAmount;
          sum_of_all_component += calculateAmount;
          new_Array_after_calculation[index] = obj;
          setSelectedEarningaAray(new_Array_after_calculation);
        }
        if (item.is_amount_in_percentage === false && item.type == 'fixed') {
          if (newDeductionArray.length > 0) {
            let newSalaryEarning = [...selectedEarningArray];
            let obj1 = newSalaryEarning[index];
            let amount = obj1.amount;
            newDeductionArray.map((item1, index) => {
              let obj = item1;
              if (
                item1.type == 'pre-tax' &&
                item1.is_amount_in_percentage == true
              ) {
                let percentage_value = item1.amount_in_percentage * 1;
                let calculateAmount = Math.round(
                  (basic_amount * percentage_value) / 100,
                );
                obj['amount'] = calculateAmount;
                sum_of_all_component += calculateAmount;
                newDeductionArray[index] = obj;
                setSelsctedDeductionArr(newDeductionArray);
                let newSalaryEarning = [...selectedEarningArray];
                let obj1 = newSalaryEarning[index];
                let amount = obj1['amount'] - calculateAmount;
                obj1.amount = amount;
                newSalaryEarning[index] = obj1;
                setSelectedEarningaAray([...newSalaryEarning]);
              } else if (item1.type != 'pre-tax') {
                sum_of_all_component -= obj.amount;
              } else {
                sum_of_all_component += obj.amount;
                newDeductionArray[index] = obj;
                setSelsctedDeductionArr(newDeductionArray);
                let newSalaryEarning = [...selectedEarningArray];
                let obj1 = newSalaryEarning[index];
                let amount = obj1['amount'] - obj.amount;
                obj1.amount = amount;
                newSalaryEarning[index] = obj1;
                setSelectedEarningaAray([...newSalaryEarning]);
              }
            });
          }
          else {
            let newSalaryEarning = [...selectedEarningArray];
            let obj = newSalaryEarning[index];
            let amount = monthlyCtc - sum_of_all_component;
            obj.amount = amount;
            newSalaryEarning[index] = obj;
            setSelectedEarningaAray([...newSalaryEarning]);
          }
          sum_of_all_component += item.amount * 1;
        }
        if (item.is_amount_in_percentage === false && item.type != 'fixed') {
          sum_of_all_component += item.amount * 1;
        } else {
        }
      });
    console.log(monthlyCtc);
    console.log(sum_of_all_component);
    systemCalculatedAmount += monthlyCtc - sum_of_all_component;
    console.log(systemCalculatedAmount);
    setbasicAmount(basic_amount);
    setSystemCalcAmnt(systemCalculatedAmount);
  };
  const resetDeductionArray = () => {
    console.log(
      'selected Earning Array',
      selectedEarningArray,
      'selected deduction Array',
      selectedDeductionArr,
    );

    selectedDeductionArr &&
      selectedDeductionArr.length > 0 &&
      selectedDeductionArr.map((item, index) => {
        let newSalaryDeduction = [...selectedDeductionArr];
        let deductionObj = newSalaryDeduction[index];
        deductionObj.amount = 0;
        newSalaryDeduction[index] = deductionObj;
        setSelsctedDeductionArr([...newSalaryDeduction]);
      });
    let newSalaryEarning = [...selectedEarningArray];
    let indexOfObj = newSalaryEarning.indexOf(
      newSalaryEarning.filter(item => item.type == 'fixed')[0],
    );
    let obj = newSalaryEarning[indexOfObj];
    obj.amount = 0;
    newSalaryEarning[indexOfObj] = obj;
    setSelectedEarningaAray([...newSalaryEarning]);
    calculate();
  };

  const handleComponentAmount = (e, index) => {
    let newArr = [...selectedEarningArray];
    let obj = newArr[index];
    obj['amount'] = e.target.value;
    newArr[index] = obj;
    setSelectedEarningaAray([...newArr]);
    resetDeductionArray();
    // calculate()
  };

  const OpenSideBarSalarytemp = () => {
    setShowSideBar(!showSideBar);
  };
  const addAndRemoveDeduction = (type, id) => {
    let newDeductionArray = [...deductionArr];
    if (type == 'epf_contribution') {
      let preview;
      newDeductionArray.map((item, index) => {
        if (
          item.type == 'epf_admin_charges' ||
          item.type == 'edli' ||
          item.type == 'epf_contribution'
        ) {
          item.is_used_in_template = !item.is_used_in_template;
          setPreview(item.is_used_in_template);
          newDeductionArray[index] = item;
          setDeductionArr([...newDeductionArray]);
          setSelsctedDeductionArr(
            newDeductionArray.filter(item => item.is_used_in_template === true),
          );
        }
      });
      
    }
    if (type == 'esi') {
      newDeductionArray.map((item, index) => {
        if (item.type == 'esi') {
          item.is_used_in_template = !item.is_used_in_template;
          setPreview(item.is_used_in_template);
          newDeductionArray[index] = item;
          setDeductionArr([...newDeductionArray]);
          setSelsctedDeductionArr(
            newDeductionArray.filter(item => item.is_used_in_template === true),
          );
        }
      });
    }
    if (type == 'pre-tax') {
      newDeductionArray.map((item, index) => {
        if (item.type == 'pre-tax') {
          if (item.id == id) {
            item.is_used_in_template = !item.is_used_in_template;
            newDeductionArray[index] = item;
            setDeductionArr([...newDeductionArray]);
            calculate();
            setSelsctedDeductionArr(
              newDeductionArray.filter(
                item => item.is_used_in_template === true,
              ),
            );
          }
        }
      });
    }
  };
  const CalculateSystemAmount = async () => {
    let calculateEarningArray = [];
    let calculateDeductionArray = [];
    let fixedearning = [];
    selectedEarningArray.map(item => {
      if (item.type != 'fixed') {
        let newObj = {
          id: item.id,
          amount: item.amount,
          is_amount_in_percentage: item.is_amount_in_percentage,
          amount_in_percentage: item.amount_in_percentage,
        };
        calculateEarningArray.push(newObj);
      } else {
        let newObj = {
          id: item.id,
          amount: item.amount,
          is_amount_in_percentage: item.is_amount_in_percentage,
          amount_in_percentage: item.amount_in_percentage,
        };
        fixedearning.push(newObj);
      }
    });
    selectedDeductionArr.map(item => {
      let newDeductionObj = {
        id: item.id,
        amount: item.amount,
        is_amount_in_percentage: item.is_amount_in_percentage,
        amount_in_percentage: item.amount_in_percentage,
        type:item.type
      };
      calculateDeductionArray.push(newDeductionObj);
    });

    setSystemCalculated(false);
    let data = {
      ctc: annualCtc,
      earnings: JSON.stringify(calculateEarningArray),
      fixed: JSON.stringify(fixedearning),
      reimbursements: [],
      fbp_components: [],
      deductions: JSON.stringify(calculateDeductionArray),
      variable_earnings: [],
    };
    let result = await api.post(config.Calculate, data);

    if (result && result.code === 200) {
      let system_calculated_amount = result.data;
      system_calculated_amount.map(items => {
        switch (items.type) {
          case 'Fixed_Allowance':
            let newSalaryEarning = [...selectedEarningArray];
            let indexOfObj = newSalaryEarning.indexOf(
              newSalaryEarning.filter(item => item.type == 'fixed')[0],
            );
            let obj = newSalaryEarning[indexOfObj];
            obj.amount = items.amount;
            newSalaryEarning[indexOfObj] = obj;
            setSelectedEarningaAray([...newSalaryEarning]);
if(obj.amount<0){
  setGreaterAmount(true);
}
            break;
          case 'ESI':
            let newSalaryDeduction = [...selectedDeductionArr];
            let indexOfObj1 = newSalaryDeduction.indexOf(
              newSalaryDeduction.filter(item => item.type == 'esi')[0],
            );
            if (indexOfObj1!=-1){
            let obj1 = newSalaryDeduction[indexOfObj1];
            obj1.amount = items.amount ? items.amount : 0;

            newSalaryDeduction[indexOfObj1] = obj1;
            setSelsctedDeductionArr([...newSalaryDeduction]);
            }
            break;
          case 'admin_Charges':
            let newSalaryDeduction1 = [...selectedDeductionArr];
            let indexOfObj2 = newSalaryDeduction1.indexOf(
              newSalaryDeduction1.filter(
                item => item.type == 'epf_admin_charges',
              )[0],
            );
            let obj2 = newSalaryDeduction1[indexOfObj2];
            obj2.amount = items.amount;
            newSalaryDeduction1[indexOfObj2] = obj2;
            setSelsctedDeductionArr([...newSalaryDeduction1]);

            break;
          case 'EDLI':
            let newEdliSalaryDeduction = [...selectedDeductionArr];
            let indexOfObjEdli = newEdliSalaryDeduction.indexOf(
              newEdliSalaryDeduction.filter(item => item.type == 'edli')[0],
            );
            let objEdli = newEdliSalaryDeduction[indexOfObjEdli];
            objEdli.amount = items.amount;
            newEdliSalaryDeduction[indexOfObjEdli] = objEdli;
            setSelsctedDeductionArr([...newEdliSalaryDeduction]);

            break;
          case 'epf_contribution':
            let newEpfContributionSalaryDeduction = [...selectedDeductionArr];
            let indexOfObjEpfContribution = newEpfContributionSalaryDeduction.indexOf(
              newEpfContributionSalaryDeduction.filter(
                item => item.type == 'epf_contribution',
              )[0],
            );
            let objEpfContribution =
              newEpfContributionSalaryDeduction[indexOfObjEpfContribution];
            objEpfContribution.amount = items.amount;
            newEpfContributionSalaryDeduction[
              indexOfObjEpfContribution
            ] = objEpfContribution;
            setSelsctedDeductionArr([...newEpfContributionSalaryDeduction]);
            break;
        }
      });
    } else {
      alert(result.message && result.message);
    }
    calculate();
  };

  const addTemplateFun = async () => {
    let data = {
      template_name: templateName,
      description: templateDescription,
      annual_ctc: annualCtc,
      earnings: JSON.stringify(selectedEarningArray),
      reimbursements: JSON.stringify([]),
      variable_earnings: JSON.stringify([]),
      fbp_components: JSON.stringify([]),
      deductions: JSON.stringify(selectedDeductionArr),
    };
    let result = await api.post(config.addSalaryTemplate, data);
    if (result && result.code === 200) {
      alert(result.message);
      history.push('/dashboard/payroll-admin/newSalary-template');
    } else {
      alert(result.message);
    }
  };

  return (
    <React.Fragment>
      <TheHeader />

      <div className="row">
        <div className="col-md-2 text-center">
          <div className="">
            <div className="ml-4 mt-3">
              <img
                height="30"
                className="c-sidebar-brand-full mb-4"
                src="../assets/glueple.png"
                role="img"
              ></img>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item abd">
                  <h5 className="accordion-header" id="headingOne">
                    <button
                      className="text-left accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <p>Earning</p>
                      <img
                        className="angle_right_image_salary_template"
                        src="avatars/next.png"
                        alt="Employee Image "
                      />
                    </button>
                  </h5>

                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    {earningArr &&
                      earningArr.map(
                        (earning, value) =>
                          earning.is_used_in_template === false && (
                            <div className="accordion-body">
                              <button
                                className="btn add_new_template_sidebar_button w-100"
                                style={{ display: 'flex' }}
                                onClick={() => addComponent(earning.id)}
                              >
                                {earning.earningName}
                                <i className="fa fa-plus add_new_template_sidebar_button_icon"></i>
                              </button>
                            </div>
                          ),
                      )}
                  </div>
                </div>

                {/* House Rent Allowancae,Conveyance Allowance */}

                <div className="accordion-item">
                  <h5 className="accordion-header" id="headingTwo">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <p>Reimbursements</p>
                      <img
                        className="angle_right_image_salary_template"
                        src="avatars/next.png"
                        alt="Employee Image "
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <h5>reimbursement1</h5>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="headingThree">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <p>Feb Components</p>
                      <img
                        className="angle_right_image_salary_template"
                        src="avatars/next.png"
                        alt="Employee Image "
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <h5>component1</h5>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="headingFour">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <p>Deductions</p>
                      <img
                        className="angle_right_image_salary_template"
                        src="avatars/next.png"
                        alt="Employee Image "
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    {deductionArr &&
                      deductionArr.length > 0 &&
                      deductionArr
                        .filter(
                          item =>
                            item.name == 'ESI - Employer Contribution' ||
                            item.name == 'EPF - Employer Contribution' ||
                            item.type == 'pre-tax',
                        )
                        .map(
                          deduction =>
                            deduction.is_used_in_template === false && (
                              <div className="accordion-body">
                                <button
                                  className="btn add_new_template_sidebar_button w-100"
                                  style={{ display: 'flex', fontSize: '12px' }}
                                >
                                  {deduction.name}
                                  <i
                                    className="fa fa-plus add_new_template_sidebar_button_icon"
                                    onClick={() =>
                                      addAndRemoveDeduction(
                                        deduction.type,
                                        deduction.id,
                                      )
                                    }
                                  ></i>
                                </button>
                              </div>
                            ),
                        )}
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="headingFive">
                    <button
                      className="text-left accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <p>One Time Earnings</p>
                      <img
                        className="angle_right_image_salary_template"
                        src="avatars/next.png"
                        alt="Employee Image "
                      />
                    </button>
                  </h5>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <button
                        className="btn add_new_template_sidebar_button w-100"
                        style={{ display: 'flex' }}
                      >
                        Commission{' '}
                        <i className="fa fa-plus add_new_template_sidebar_button_icon"></i>
                      </button>
                      <button
                        className="btn add_new_template_sidebar_button w-100"
                        style={{ display: 'flex' }}
                      >
                        Bonus{' '}
                        <i className="fa fa-plus add_new_template_sidebar_button_icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-10 p-0 c-wrapper">
          <div className="mainSalarytemplatediv">
            <div
              className="card pt-2"
              style={{ borderLeft: 'none', backgroundColor: 'whitesmoke' }}
            >
              <div className="row ml-2 pr-3">
                <div className="col-md-6">
                  <h3 className="text-left">Edit Salary Template</h3>
                </div>
                <div className="col-md-6">
                  <h3 className="text-right">
                    <button
                      className="btn btn-outline-grey"
                      style={{ borderRadius: '50%' }}
                    >
                      X
                    </button>
                  </h3>
                </div>
                <hr className="mt-3" />
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                  <div className="card">
                    <div className="row p-5">
                      <div className="col-md-5 text-left">
                        <strong className="text">Annual CTC</strong>
                      </div>
                      <div className="col-md-4">
                        <span className="d-flex">
                          <i
                            className="fa fa-rupee pl-3 pr-3 pt-1 fa-2x"
                            style={{
                              border: '1px solid #ced4da',
                              borderRadius: '4px',
                              borderTopRightRadius: '0px',
                              borderBottomRightRadius: '0px',
                            }}
                            aria-hidden="true"
                          />
                          <input
                            style={{
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px',
                            }}
                            className="form-control"
                            type="number"
                            onChange={(e)=>setCtc(e.target.value)}
                            value={ctc}
                            max = {5000000}
                            onBlur={getEmployeeSalaryDetails}
                          />
                        </span>
                      </div>
                      <div className="col-md-3 text-left">
                        <strong className="text">Per Month</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-6 col-sm-6 mt-2">
                  <div className="table">
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th>Salary Components</th>
                          <th>Calculation Type</th>
                          <th>Monthly Amount</th>
                          <th>Yearly Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4} style={{ fontSize: '20px' }}>
                            Earnings
                          </td>
                        </tr>

                        {selectedEarningArray &&
                          selectedEarningArray.length > 0 &&
                          selectedEarningArray.map((item, index) =>
                            item.is_used_in_template == true &&
                            item.is_amount_in_percentage == true ? (
                              <tr>
                                <td>
                                  <strong>{item.earningName}</strong>
                                </td>

                                <td className="d-flex">
                                  <input
                                    style={{
                                      width: '110px',
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 0,
                                    }}
                                    className="form-control"
                                    type="number"
                                    onChange={e => {
                                      editEarning(index, e);
                                    }}
                                    value={item.amount_in_percentage}
                                    onBlur={calculate}
                                  />
                                  {item.type == 'basic' ? (
                                    <input
                                      maxLength={1}
                                      disabled
                                      style={{
                                        width: '110px',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                      }}
                                      placeholder="% Of CTC"
                                      className="form-control"
                                      type="number"
                                    />
                                  ) : (
                                    <input
                                      maxLength={1}
                                      disabled
                                      style={{
                                        width: '110px',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                      }}
                                      placeholder="% Of Basic"
                                      className="form-control"
                                      type="number"
                                    />
                                  )}
                                </td>
                                <td>
                                  <p
                                    style={{ width: '150px' }}
                                    className="form-control"
                                    disabled
                                    type="number"
                                    name={item.type}
                                    id={item.id}
                                  >
                                    {item.amount}
                                  </p>
                                </td>
                                <td className="text-right pr-5">
                                  <strong>{item.amount * 12}</strong>
                                </td>
                                <td className="text-right pr-5">
                                  {item.type != 'basic' && (
                                    <button
                                      style={{ borderRadius: '50px' }}
                                      onClick={() => addComponent(item.id)}
                                      onBlur={calculate}
                                      className="btn ml-3 btn-outline-grey btn-sm"
                                    >
                                      X
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ) : item.is_used_in_template === true &&
                              item.is_amount_in_percentage === false ? (
                              <tr>
                                <td>
                                  <strong>{item.earningName}</strong>
                                </td>

                                <td className="d-flex">
                                  <input
                                    maxLength={1}
                                    disabled
                                    style={{
                                      width: '230px',
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                    }}
                                    placeholder="Fixed"
                                    className="form-control"
                                    type="number"
                                  />
                                  {/* <input maxLength={1} disabled style={{ width: '110px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} placeholder="% Of Basic" value={item.amount} className="form-control" type="number" /> */}
                                </td>
                                {item.type == 'fixed' ? (
                                  <td>
                                    <input
                                      style={{ width: '150px' }}
                                      className="form-control"
                                      disabled
                                      type="number"
                                      value={item.amount}
                                      onChange={e =>
                                        handleComponentAmount(e, index)
                                      }
                                      name={item.type}
                                      id={item.id}
                                      onBlur={calculate}
                                    />
                                  </td>
                                ) : (
                                  <td>
                                    <input
                                      style={{ width: '150px' }}
                                      className="form-control"
                                      value={item.amount}
                                      onChange={e =>
                                        handleComponentAmount(e, index)
                                      }
                                      type="number"
                                      name={item.type}
                                      id={item.id}
                                      onBlur={calculate}
                                    />
                                  </td>
                                )}

                                <td className="text-right pr-5">
                                  <strong>{item.amount * 12}</strong>
                                </td>
                                {item.type != 'fixed' && (
                                  <td className="text-right pr-5">
                                    <button
                                      style={{ borderRadius: '50px' }}
                                      onClick={() => addComponent(item.id)}
                                      onBlur={calculate}
                                      className="btn ml-3 btn-outline-grey btn-sm"
                                    >
                                      X
                                    </button>
                                  </td>
                                )}
                              </tr>
                            ) : (
                              ''
                            ),
                          )}
                        {selectedDeductionArr &&
                          selectedDeductionArr.length > 0 && (
                            <tr>
                              <td colSpan={4} style={{ fontSize: '20px' }}>
                                Deductions
                              </td>
                            </tr>
                          )}
                        {selectedDeductionArr &&
                          selectedDeductionArr.length > 0 &&
                          selectedDeductionArr.map((item, index) => (
                            <tr>
                              <td>
                                <strong>{item.name}</strong>
                              </td>

                              <td className="d-flex">
                                {item.is_amount_in_percentage == true && (
                                  <input
                                    placeholder="0"
                                    name="percent_value"
                                    style={{
                                      width: '100px',
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                    }}
                                    className="form-control"
                                    type="number"
                                    onChange={e => {
                                      editDeduction(index, e);
                                    }}
                                    value={item.amount_in_percentage}
                                    onBlur={calculate}
                                  />
                                )}

                                <select
                                  className="form-control"
                                  name="percentage"
                                  style={{
                                    width: '150px',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                  }}
                                  onChange={e => {
                                    editDeduction(index, e);
                                  }}
                                  value={
                                    item.is_amount_in_percentage == false
                                      ? 'fixed'
                                      : 'basic_percent'
                                  }
                                >
                                  <option value="fixed">Fixed</option>
                                  <option value="basic_percent">
                                    % of Basic
                                  </option>
                                </select>
                                {/* <p style={{ width: '210px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} className="form-control"  >{item.formated_amount_percentage}</p> */}
                              </td>
                              <td>
                                {systemCalculated === true &&
                                item.type != 'pre-tax' ? (
                                  <>
                                    <p
                                      style={{ width: '150px' }}
                                      className="form-control"
                                    >
                                      {systemCalculated === true
                                        ? 'System Calculated'
                                        : item.amount}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    {item.is_amount_in_percentage == false &&
                                    item.type == 'pre-tax' ? (
                                      <>
                                        <input
                                          placeholder="0"
                                          name="amount"
                                          style={{
                                            width: '100px',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                          }}
                                          className="form-control"
                                          type="number"
                                          onChange={e => {
                                            editDeduction(index, e);
                                          }}
                                          value={item.amount}
                                          onBlur={calculate}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <input
                                          placeholder="0"
                                          name="amount"
                                          disabled
                                          style={{
                                            width: '100px',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                          }}
                                          className="form-control"
                                          type="number"
                                          value={item.amount}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="text-right pr-5">
                                {systemCalculated === true &&
                                item.type != 'pre-tax' ? (
                                  <strong>
                                    {systemCalculated === true
                                      ? 'System Calculated'
                                      : item.amount * 12}
                                  </strong>
                                ) : (
                                  <strong>{item.amount * 12}</strong>
                                )}
                              </td>
                              <td className="text-right pr-5">
                                {(item.type == 'epf_contribution' ||
                                  item.type == 'esi' ||
                                  item.type == 'pre-tax') && (
                                  <button
                                    style={{ borderRadius: '50px' }}
                                    onClick={() =>
                                      addAndRemoveDeduction(item.type, item.id)
                                    }
                                    className="btn ml-3 btn-outline-grey btn-sm"
                                  >
                                    X
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        {preview && (
                          <tr className="system-calculated-amount">
                            <td>
                              System Calculated Components' Total Amount.
                              <span
                                style={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => CalculateSystemAmount()}
                              >
                                (Preview)
                              </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                        {(systemCalcAmnt < 0 || greaterAmount) && (
                          <tr className="system-calculated-amount">
                            <td>
                              <i
                                className="fa fa-exclamation-triangle fa-2x"
                                aria-hidden="true"
                                style={{ color: 'red' }}
                              ></i>
                              <span style={{ color: 'red', cursor: 'pointer' }}>
                                System Calculated Components' TotalAmount must
                                be greater than zero. Adjust the CTC or any of
                                the component's amount.
                              </span>
                            </td>
                            <td></td>
                            <td>{systemCalcAmnt}</td>
                            <td>{systemCalcAmnt * 12}</td>
                          </tr>
                        )}

                        <tr style={{ backgroundColor: '#FFFAFA' }}>
                          <td colSpan={2}>
                            <h3>Cost To Company</h3>
                          </td>
                          <td>
                            <h3>
                              <i
                                className="fa fa-rupee  pt-1"
                                aria-hidden="true"
                              />
                              {monthlyCtc ? monthlyCtc : 0}
                            </h3>
                          </td>
                          <td className="text-center">
                            <h3>
                              <i
                                className="fa fa-rupee  pt-1"
                                aria-hidden="true"
                              />
                              {annualCtc ? annualCtc : 0}
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="text-left pl-5">
                <strong>Note</strong>:
                <span>
                  Any changes made to existing components will be applicable
                  only for future association.
                </span>
              </div>
              <hr className=" mt-5" />
              <div className="actions text-left pl-5">
                <button
                  className="btn btn-primary ml-5"
                  onClick={addTemplateFun}
                >
                  Save
                </button>
                <button className="btn btn-outline-dark ml-2">Cancel</button>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddNewTemplate);
