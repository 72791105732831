import React, { useEffect } from 'react';
import { CModal, CModalBody, CModalFooter, CButton } from '@coreui/react';
import { CustButton } from 'src/reusable';
import {
  IframeGoogleDocsViewer,
  IframeMicrosoftDocsViewer,
  IframeTextViewer,
} from 'src/views/messages/Subcomponents/IframeViewers/IframeViewers';

const ModalViewImage = props => {
  const {
    isOpen,
    toggleModal,
    fileData,
    showSendOfferLetterBtn,
    handleSendOfferLetter,
  } = props;

  useEffect(() => {}, []);

  const getDataType = mime => {
    if (mime === 'pdf') {
      return 'application/pdf';
    } else if (mime === 'textPreview') {
      return 'text/plain';
    } else if (mime === 'audio') {
      return 'audio';
    }

    return 'image/svg+xml';
  };
  const fileUrl = fileData?.payload || '';
  const mime = fileData ? fileData.mime : '';
  const dataType = getDataType(mime);
  const isText = dataType === 'text/plain';
  const isAudio = mime === 'audio';
  const isOffice = mime === 'office';
  const isGoogle = mime === 'google';
  const customScrollMaxHeightValue = '80vh';
  const isPdf = !isText && !isOffice && !isAudio && !isGoogle;
  return (
    <div className="">
      <CModal show={isOpen} onClose={() => toggleModal(!isOpen)} size="lg" className={'overlap-modal'} backdrop={false} style={{paddingTop:'2%'}}>
        <CModalBody>
          <CButton
            className="float-right"
            color="danger"
            onClick={() => toggleModal(!isOpen)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton>
          <div>
            {isPdf && (
              <embed src={fileUrl} type={dataType} width="100%" height="600" />
            )}
            {isText && <IframeTextViewer fileURL={fileUrl} />}
            {isOffice && <IframeMicrosoftDocsViewer fileURL={fileUrl} />}
            {isGoogle && <IframeGoogleDocsViewer fileURL={fileUrl} />}
            {/* {isAudio && (
              <AudioFilePreview
                fileURL={fileUrl}
                fileType={dataType}
                mime={mime}
              ></AudioFilePreview>
            )} */}
          </div>
        </CModalBody>
        {showSendOfferLetterBtn && (
          <CModalFooter>
            <CustButton
              type="button"
              name="pdf-view"
              text="Send"
              color="primary"
              onClick={handleSendOfferLetter}
              className="btn-loading btn-ladda mr-3"
              icon="fa-paper-plane"
              isDisabled={false}
              isLoading={false}
            />
          </CModalFooter>
        )}
      </CModal>
    </div>
  );
};
export default ModalViewImage;
