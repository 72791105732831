import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from '../containers/index'
import './all-features.css'
import API from "src/utils/apiCalling";
import { config } from "src/utils/apiUrl";
import {getPermissions, isPermission} from "src/utils/common";

const AllFeaturesHr = (props) => {
  const { selectedTabSet,approvedRequestionCount, pendingRequisitionLenght, countCandidateAccessManagement, profile_banklength, inprogresslength, opsSelectlength, offerIssuedlength, offerAcceptlength, hrShortlistedlength, finalJoinedlength} = props
  const api = new API()
  const [selectedTab, setSelectedTab] = useState("pendingRequisition")
  const [hrmsStaticPermission, setHRMSStaticPermission] = useState(false)
  const [approvedRequestionPermission, setApprovedRequestionPermission] = useState(false)
  const [iJPAnnouncement, setIJPAnnouncement] = useState(false)
  const selectTab = (select_type) => {
    // alert(select_type)
    setSelectedTab(select_type)
    selectedTabSet(select_type)
  }

  useEffect(() => {
    HRMSStaticPermission()
  }, [])


console.log("hey there");
  const HRMSStaticPermission = () => {
    if(isPermission('generate_emp_id_for_new_candidates_by_hr')){
      setHRMSStaticPermission(true);
    }
    if(isPermission('approved_requisition')){
      setApprovedRequestionPermission(true);
    }
  };

  return (
    <div className="">
      <div className="container mt-3 p-3">
        <div className="row">
          {
            approvedRequestionPermission &&
            <div className="col-md card_box">
              <div className="card card_box" style={selectedTab == "approvedRequisition" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("approvedRequisition")}>
                <div className="card-header " style={selectedTab == "approvedRequisition" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                  <h3 className="text-center">{approvedRequestionCount ? approvedRequestionCount : "0"}</h3>
                </div>
                <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white" }}>
                <div className='card-text text-center'>
                  <h5 className="text-white">Approved Requisition</h5>
                </div>
                </div>
              </div>
            </div>
          }
          <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "pendingRequisition" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("pendingRequisition")}>
              <div className="card-header " style={selectedTab == "pendingRequisition" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{pendingRequisitionLenght}</h3>

              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white"}}>
              <div className= "card-text text-center"> 
                <h5 className="text-white">Pending Requisition</h5>
              </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "profile_bank" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("profile_bank")}>
              <div className="card-header " style={selectedTab == "profile_bank" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{profile_banklength}</h3>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
              <div className='card-text text-center'>
                <h5 className="text-white">Profile Bank</h5>
              </div>
              </div>
            </div>
            </div> */}

          <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "hrShortlisted" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("hrShortlisted")}>
              <div className="card-header " style={selectedTab == "hrShortlisted" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{hrShortlistedlength}</h3>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
              <div className='card-text text-center'>
                <h5 className="text-white">HR Shortlist</h5>
              </div>
              </div>
            </div>
          </div>

          <div className="col-md card_box" >
            <div className="card card_box" onClick={() => selectTab("inprogress")} style={selectedTab == "inprogress" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }}>
              <div className="card-header " style={selectedTab == "inprogress" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{inprogresslength}</h3>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
              <div className='card-text text-center'>
                <h5 className="text-white">Interview In-Progress</h5>
              </div>
              </div>
            </div>
            </div>


        </div>
        &nbsp;&nbsp;

        <div className="row">
          <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "opsSelect" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("opsSelect")}>
              <div className="card-header " style={selectedTab == "opsSelect" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{opsSelectlength}</h3>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
              <div className='card-text text-center'>
                <h5 className="text-white">Ops Select</h5>
              </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "offSelectedCondidate" ? { borderRadius: "25px", border: "1px solid", borderColor: "red" } : { borderRadius: "25px" }} onClick={() => selectTab("offSelectedCondidate")}>
              <div className="card-header " style={selectedTab == "offSelectedCondidate" ? { backgroundColor: "gray", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h2 className="text-center">15</h2>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#ADD8E6", color: "white", height: "80px" }}>
                <h4 className="card-text text-center text-uppercase" style={{ color: "black" }}>HR Select</h4>
              </div>
            </div>
          </div> */}
          <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "offerIssued" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("offerIssued")}>
              <div className="card-header " style={selectedTab == "offerIssued" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{offerIssuedlength}</h3>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
              <div className='card-text text-center'>
                <h5 className="text-white">Offer Issued</h5>
              </div>
              </div>
            </div>
            </div>

          <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "offerAccept" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("offerAccept")}>
              <div className="card-header " style={selectedTab == "offerAccept" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{offerAcceptlength}</h3>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
              <div className='card-text text-center'>
                <h5 className="text-white">Offer Accept</h5>
              </div>
              </div>
            </div>
          </div>

          
          <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "finalJoined" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("finalJoined")}>
              <div className="card-header " style={selectedTab == "finalJoined" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h3 className="text-center">{finalJoinedlength}</h3>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
                <div className='card-text text-center'>
                <h5 className="text-white">Joined</h5>
              </div>
              </div>
            </div>
          </div>

          {hrmsStaticPermission &&
            <div className="col-md card_box">
              <div className="card card_box" style={selectedTab == "accessManagment" ? { borderBottomLeftRadius: "28px", borderBottomRightRadius: "28px", border: "2px solid", borderColor: "#eb7c45" } : { borderRadius: "25px" }} onClick={() => selectTab("accessManagment")}>
                <div className="card-header " style={selectedTab == "accessManagment" ? { backgroundColor: "#eb7c45", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                  <h3 className="text-center">{countCandidateAccessManagement}</h3>
                </div>
                <div className="card-body card_box" style={{ backgroundColor: "#2a3c81", color: "white", height: "80px" }}>
                <div className='card-text text-center'>
                  <h5 className="text-white">Access Management</h5>
                </div>
                </div>
              </div>
            </div>
          }








          {/* <div className="col-md card_box">
            <div className="card card_box" style={selectedTab == "reports" ? { borderRadius: "25px", border: "1px solid", borderColor: "red" } : { borderRadius: "25px" }} onClick={() => selectTab("reports")}>
              <div className="card-header " style={selectedTab == "reports" ? { backgroundColor: "gray", color: "white" } : { backgroundColor: "#4863A0", color: "white" }}>
                <h2 className="text-center">17</h2>
              </div>
              <div className="card-body card_box" style={{ backgroundColor: "#ADD8E6", color: "white", height: "80px" }}>
                <h4 className="card-text text-center text-uppercase" style={{ color: "black" }}>Reports</h4>
              </div>
            </div>
          </div> */}

        </div>

      </div>
    </div>
  )
}
export default AllFeaturesHr