import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from '../containers/index'
import AllFeatures from './all-features'
import ListOfSelectedCondidate from './ListOfSelectedCondidate'
import ListOfCloseMrf from './ListOfCloseMrf'
import Clearance_form from 'src/views/seperation/clearance_form/Clearance_form'
import ReceivedCondidate from './received_requisition/receivedRequisition'
import AssignedRequisition from './viewAssignedRequisition'
import ProfileBank from './profile_bank/profile_bank'
import api from '../utils/apiCalling';
import { config } from '../utils/apiUrl';


const Hr_Manager_Dashboard = (props) => {
  const{history}=props

  const [assignedRequisition, setAssignedRequisition] = useState(false)
  const [type, setType] = useState("receivedRequisition")
  const [receivedr_equisition_count, setReceivedr_equisition_count] = useState()
  const [assign_equisition_count, setAssign_equisition_count] = useState()
 

  useEffect(() => {
  }, [])

  const getCountReceivedRequisition = (count) =>{
    setReceivedr_equisition_count(count)
 
  }
  const getCountAssignRequisition = (count) =>{
    // alert(count)
    setAssign_equisition_count(count)
  }

  //  // by ishika 
  //  const profile_bank = async () => {
  //   let result = await api.get(config.profileBank)
  //   if (result && result.code == 200) {
  //     setprofile_banklength((result.data).length)
  //     console.log(result.data.length);
  //   }
  //   else {
  //     // console.log("unable to getting pending requisition")
  //   }
  // }

  const selectedTabSet = (selected_tab)=>{
    setType(selected_tab)
  }
  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">

          <AllFeatures selectedTabSet={selectedTabSet}
           receivedr_equisition_count={receivedr_equisition_count}
           assign_equisition_count={assign_equisition_count}/>
          {type == "receivedRequisition" && <ReceivedCondidate getCountReceivedRequisition={getCountReceivedRequisition}/>}
          {type == "assignedRequisition" && <AssignedRequisition getCountAssignRequisition={getCountAssignRequisition} history={history}/>}
          {type == "" && <ListOfSelectedCondidate/>}
          {type == "profile_bank" && <ProfileBank />}
          {type == "closeMrf" && <ListOfCloseMrf/>}
         
        </div>
        <TheFooter />
      </div>
    </div>

  )
}
export default Hr_Manager_Dashboard