import React, { useState, useEffect } from "react";
import { TheFooter, TheHeader, TheSidebar } from "src/containers";
import { Link } from "react-router-dom";
import {getPermissions, isPermission} from '../utils/common';

const AdminDashboardAllSection = () => {
  useEffect(() => {

  }, []);
  const renderNavs = () => {
    let temp=[];
    if(isPermission('admin_dashboard_accounts')){
      temp.push({"name":"Accounts", "url":"/admin-dashboard/accounts"})
    }
    if(isPermission('dashboard')){
      temp.push({"name":"Dashboard", "url":"/dashboard"})
    }
    if(isPermission('mrf_manager')){
      temp.push({"name":"MRF Form", "url":"/dashboard/mrf_manager"})
    }
    if(isPermission('interview')){
      temp.push({"name":"Interview Panel", "url":"/dashboard/interview"})
    }
    if(isPermission('referral')){
      temp.push({"name":"Employee Referral", "url":"/dashboard/referral"})
    }
    if(isPermission('separation')){
      temp.push({"name":"Separation", "url":"/dashboard/separation"})
    }
    if(isPermission('employee_assets_management')){
      temp.push({"name":"Emp Assets Management", "url":"/dashboard/asset-management/employee-assets-management"})
    }
    if(isPermission('fms_management')){
      temp.push({"name":"FMS", "url":"/dashboard/fms"})
    }
    if(isPermission('feedback_details')){
      temp.push({"name":"Employee Feedback", "url":"/dashboard/feedback-details"})
    }
    if(isPermission('admin_attendance_management')){
      temp.push({"name":"Attendance", "url":"/dashboard/admin/attendance-management"})
    }
    if(isPermission('travel_and_claim')){
      temp.push({"name":"Travel & Claim", "url":"/dashboard/admin/travel-and-claim"})
    }
    if(isPermission('admin_survey')){
      temp.push({"name":"Survey", "url":"/admin-dashboard/survey/index"})
    }
    if(isPermission('reassign_mrf')){
      temp.push({"name":"MRF Admin", "url":"/admin-dashboard/reassignmrf"})
    }
    if(isPermission('mapping')){
      temp.push({"name":"Mapping", "url":"/admin-dashboard/map"})
    }
    if(isPermission('admin_tms')){
      temp.push({"name":"TMS", "url":"/admin-dashboard/tms"})
    }
    if(isPermission('payroll_admin')){
      temp.push({"name":"Pay Roll", "url":"/dashboard/payroll-admin"})
    }
    if(isPermission('manage_entities')){
      temp.push({"name":"Manage Entities", "url":"/admin-dashboard/manage-entities"})
    }
      return temp && temp.map((item,index) => {
      return (<>
        {(index==0 || index==4 || index==8 || index==12) && <div className='row'></div>}
        <div className="col-md card_box-ad text-center">
          <Link className="card-text text-white" to={item.url}>
            <h5 className="text-white">{item.name}</h5>
          </Link>
        </div>
        </>)
      })
  }
  return (
    <div className="container">
      <div className='row'>
      {renderNavs()}
      </div>
    </div>
  );
};
export default AdminDashboardAllSection;
