import React, { useState, lazy, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {
  CBadge, CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup, CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
  CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { DocsLink } from 'src/reusable'
import { TheLayout } from 'src/containers';
import pms_nav from '../../containers/pms_nav'
import {geoLocation} from  '../../common/location'
import {getPermissions, isPermission} from '../../utils/common';
const PmsManagement = () => {
  useEffect(() => {

  }, [])

  const renderNavs = () => {
    if(isPermission('pms_admin')){
      return (
        <div className="col-md card_box-ad text-center">
          <Link className="card-text text-white" to="/dashboard/pms/pms-admin"><h5 className='text-white'>PMS ADMIN</h5></Link>
        </div>
        )
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 card_box-ad text-center">
            <Link className="card-text text-white" to="/dashboard/pms/pms-employee"><h5 className='text-white'>PMS EMPLOYEE</h5></Link>
          </div>
        {renderNavs()}
        </div>
      </div>
    </>
  )
}
export default PmsManagement
