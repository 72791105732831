import { createAction } from 'redux-act';

const toggleChatPopup = createAction('TOGGLE_CHAT_POPUP', data => data);

const getMessagesRequest = createAction('GET_MESSAGE_REQUEST', data => data);
const getMessagesSuccess = createAction('GET_MESSAGE_SUCCESS', data => data);
const getMessagesError = createAction('GET_MESSAGE_ERROR');

const getConnectedUsersRequest = createAction(
  'GET_CONNECTED_USERS_REQUEST',
  data => data,
);
const getConnectedUsersSuccess = createAction(
  'GET_CONNECTED_USERS_SUCCESS',
  data => data,
);
const getConnectedUsersError = createAction('GET_CONNECTED_USERS_ERROR');

const getConnectedGroupsRequest = createAction(
  'GET_CONNECTED_GROUPS_REQUEST',
  data => data,
);
const getConnectedGroupsSuccess = createAction(
  'GET_CONNECTED_GROUPS_SUCCESS',
  data => data,
);
const getConnectedGroupsError = createAction('GET_CONNECTED_GROUPS_ERROR');

const getUnreadMessageCountsRequest = createAction(
  'GET_UNREAD_MESSAGE_COUNTS_REQUEST',
  data => data,
);
const getUnreadMessageCountsSuccess = createAction(
  'GET_UNREAD_MESSAGE_COUNTS_SUCCESS',
  data => data,
);
const getUnreadMessageCountsError = createAction(
  'GET_UNREAD_MESSAGE_COUNTS_ERROR',
);

const handleClearMessageStore = createAction(
  'HANDLE_CLEAR_MESSAGE_STORE',
  data => data,
);

export default {
  toggleChatPopup,
  handleClearMessageStore,
  getMessagesRequest,
  getMessagesSuccess,
  getMessagesError,

  getConnectedUsersRequest,
  getConnectedUsersSuccess,
  getConnectedUsersError,

  getConnectedGroupsRequest,
  getConnectedGroupsSuccess,
  getConnectedGroupsError,

  getUnreadMessageCountsRequest,
  getUnreadMessageCountsSuccess,
  getUnreadMessageCountsError,
};
