import React, { useState } from "react";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CButton,
} from "@coreui/react";
import swal from "sweetalert";
import AadharCardVerify from "./AadharCardVerify";

const ModalAadharCardVerify = (props) => {
  const { isOpen1, isViewModalOpen1 } = props;

  const [aadharNoEntered, setAadharNoEntered] = useState(false);
  const [aadharNumber, setAadharNumber] = useState();
  const [isMobileInAadharCard, setIsMobileInAadharCard] = useState(false);
  const [clientIdGetFormApi, setClientIdGetFormApi] = useState();
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpNumberInput1, setOtpNumbersInput1] = useState();
  const [otpNumberInput2, setOtpNumbersInput2] = useState();
  const [otpNumberInput3, setOtpNumbersInput3] = useState();
  const [otpNumberInput4, setOtpNumbersInput4] = useState();
  const [otpNumberInput5, setOtpNumbersInput5] = useState();
  const [otpNumberInput6, setOtpNumbersInput6] = useState();
  const [userVerifiedDetails, setUserVerifiedDetails] = useState();

  // let res = str1.concat(str2);
  // aadhar  otp generation api

  const generateAadharOTPbtn = async () => {
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to submit details`,
      icon: "success",
      buttons: ["No, cancel it!", "Submit"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let data = {
          id_number: aadharNumber,
        };
        // console.log("inside aadahar verify");
        const response = await fetch(
          "https://sandbox.aadhaarkyc.io/api/v1/aadhaar-v2/generate-otp",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzNDU1NzQ0MSwianRpIjoiMWY4MTA2MzYtNWE4NC00NDNiLTk2NGEtM2M4MTM2NDM1ODFiIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnFkZWdyZWVzQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2MzQ1NTc0NDEsImV4cCI6MTYzNzE0OTQ0MSwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.CzcJ5z0CbvxTkDU_-68FBYFT_SuVzMDmrTVqY_C7sL0",
            },
            body: JSON.stringify(data),
          }
        ).catch((error) => {
          // console.log("Error getting document:", error);
        });

        let result = await response.json();
        // console.log("result document:", result);
        if (
          result &&
          result.data.if_number == true &&
          result.data.otp_sent == true
        ) {
          setClientIdGetFormApi(result.data.client_id);
          setAadharNoEntered(true);
          setIsMobileInAadharCard(true);
        }

        //  if (result.data == null){
        //    setIsMobile(true);

        //   }
      }
    });
  };

  // aadhar  otp verify api

  const verifyAadharOTPbtn = async () => {
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to submit details`,
      icon: "success",
      buttons: ["No, cancel it!", "Submit"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let otpNumbersConcate = otpNumberInput1.concat(
          otpNumberInput2,
          otpNumberInput3,
          otpNumberInput4,
          otpNumberInput5,
          otpNumberInput6
        );
        // console.log(otpNumbersConcate);
        let data = {
          client_id: clientIdGetFormApi,
          otp: otpNumbersConcate,
        };
        // console.log("inside aadahar verify");
        const response = await fetch(
          "https://sandbox.aadhaarkyc.io/api/v1/aadhaar-v2/submit-otp",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzNDU1NzQ0MSwianRpIjoiMWY4MTA2MzYtNWE4NC00NDNiLTk2NGEtM2M4MTM2NDM1ODFiIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnFkZWdyZWVzQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2MzQ1NTc0NDEsImV4cCI6MTYzNzE0OTQ0MSwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.CzcJ5z0CbvxTkDU_-68FBYFT_SuVzMDmrTVqY_C7sL0",
            },
            body: JSON.stringify(data),
          }
        ).catch((error) => {
          // console.log("Error getting document:", error);
        });

        let result = await response.json();
        // console.log("result document:", result);
        //   if (result && result.data.is_mobile == true){setIsMobile(true)}

        if (result && result.success == true) {
          alert(result && result.message_code);
          setUserVerifiedDetails(result.data);
          setIsMobileInAadharCard(false);
          setIsOtpVerified(true);
        } else {
          alert(result && result.message);
        }
      }
    });
  };

  return (
    //   aadhar number enter screen start
    <div className="">
      <CModal
        show={isOpen1}
        onClose={() => isViewModalOpen1(!isOpen1)}
        size="lg"
      >
        <CModalHeader closeButton></CModalHeader>
        <CModalBody>
          {/* {aadharNoEntered == false?<>
            <div className="justify-content-center container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="head text-center">
                    <div className="heading">
                      <span>Aadhaar verification Number</span>
                    </div>
                  </div>
                  <div className="card1 text-center">
                    <h5 className="m-0">
                      <img src="images\touchid440.jpg" width="150px" />
                    </h5>
                    <span className="mobile-text">
                      You need to enter your Aadhaar number and click on proceed
                      Button
                    </span>
                    <div className="text-left m-5"></div>
                    <div className="text-left m-5">
                      <label className="font-weight-bold text-left m-2">
                        Aadhaar Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        autofocus=""
                        onChange={(e)=>setAadharNumber(e.target.value)}
                      />
                    </div>
                    <div className="text-center">
                     
                      <button type="button" className="aadhaar-btn" onClick={generateAadharOTPbtn} >
                        <i className="fa fa-download "></i>Proceed
                      </button>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <></>}
  
          {isMobileInAadharCard == true ? <>
                        <div className="">

                        <div className="justify-content-center container-fluid">
                        <div className="row">
                            <div className="col-12">
                                    <div className="head text-center">
                                        <div className="heading">
                                        <span>Aadhaar verification OTP</span>
                                        </div>
                                    </div> 
                            
                                    <div className="card1 text-center">
                                        
                                        <h5 className="m-0"><img src="images/otpScreen.png" width="150px"/></h5>
                                        <span className="mobile-text">You need to enter OTP received on <b className="text-danger">9660301120</b> and click on proceed Button</span>
                                        <div className="text-center m-2">
                                            <b className="text-center">OTP</b>
                                        </div>
                                        <div className="d-flex flex-row mt-5">
                                                    
                                            <input type="text" className="form-control" autofocus="" required onChange={(e)=>{setOtpNumbersInput1(e.target.value)}}/>
                                            <input type="text" className="form-control" required onChange={(e)=>{setOtpNumbersInput2(e.target.value)}}/>
                                            <input type="text" className="form-control" required onChange={(e)=>{setOtpNumbersInput3(e.target.value)}}/>
                                            <input type="text" className="form-control" required onChange={(e)=>{setOtpNumbersInput4(e.target.value)}}/>
                                            <input type="text" className="form-control" required onChange={(e)=>{setOtpNumbersInput5(e.target.value)}}/>
                                            <input type="text" className="form-control" required onChange={(e)=>{setOtpNumbersInput6(e.target.value)}}/>
                                        
                                        </div>
                                        
                                        
                                        <div className="text-center m-5">
                                            <button type="button" className="aadhaar-btn" onClick={verifyAadharOTPbtn}>Verify OTP</button>
                                        </div>			
                                    
                                    </div>
                            
                            </div>  
                                         
                        </div> 
                        </div> 
                    
                </div>
                 </>:<></>}
          
                 {isOtpVerified == true ? <>
                    <div className="">
                      <div className="justify-content-center container-fluid">

                        <div className="row">
                          <div className="col-12">
                            <div className="head text-center"><div className="heading"><span>Aadhaar Detalis</span></div></div> 
                              <div className="card1 text-center">
                                                        
                                <h5 className="m-0"><img src="images/aadharDetail.png" width="150px"/></h5>
                                <span className="mobile-text">Aadhaar Verification Detalis</span>
                                <div className="text-left m-5"></div>
                                                      
                                <div className="main">
                                  <div className="row">
                                    <div className=" col-sm-4 text-left m-1">
                                      <label className="font-weight-bold text-left m-2">Full Name</label><br/>
                                      <label className="font-weight-bold text-left m-2">Aadhaar Number</label><br/>
                                      <label className="font-weight-bold text-left m-2">Date of Birth </label><br/>             
                                      <label className="font-weight-bold text-left m-2">Address</label><br/>      
                                    </div>
                                                              
                                    <div className="col-sm-4 text-left m-1">
                                      <label className="font-weight-bold text-left m-2">{userVerifiedDetails.full_name}</label><br/>      
                                      <label className="font-weight-bold text-left m-2">{userVerifiedDetails.aadhaar_number}</label><br/>
                                      <label className="font-weight-bold text-left m-2">{userVerifiedDetails.dob}</label><br/>
                                      <label className="font-weight-bold text-left m-2">{userVerifiedDetails.address.landmark}, {userVerifiedDetails.address.loc}, {userVerifiedDetails.address.dist}, {userVerifiedDetails.address.state}-{userVerifiedDetails.zip}, {userVerifiedDetails.address.country}</label><br/>  
                                    </div>
                                  </div>
                                </div>

                                <div className="text-center">
                                  <button className="aadhaar-btn"><i className="fa fa-download"></i>Proceed</button>
                                </div>
                              </div>

                          </div>  
                        </div> 

                      </div>  
                
                    </div>
                    </>:<></>} */}
          <AadharCardVerify />
        </CModalBody>
      </CModal>
    </div>
  );
};
export default ModalAadharCardVerify;
