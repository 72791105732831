import React, { useState, useEffect} from 'react'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
} from '@coreui/react'
import { toast, ToastContainer } from 'react-toastify';
const ModalForEditItDeclaration = (props) => {
  const api = new API()
  const { isOpen, isViewModalOpen, investment1, getAllInvestment } = props
  const [investment, setInvestment] = useState({});
  useEffect(()=>{
    setInvestment(investment1);
  },[investment1])

  const onChangeInvestment = (e, type, index)=>{
    let {name, value} = e.target;
    switch (type)
    {
        case 'category':
            if(name==='is_eligible_for_old'){
              value = (e.target.checked === true ? 1 : 0);
            }
            if(name==='is_eligible_for_new'){
              value = (e.target.checked === true ? 1 : 0);
            }
            if(name==="max_limit"){
              value = (value === "true") ? 1 : 0;
            }
            setInvestment({...investment,[name]:value});
            break;
        case 'subCategory':
            let temp = investment?.sub_category;
            temp[index][name]=value;
            setInvestment({...investment,['sub_category']:temp});
            break;
        default:
            console.log(type);
            break;
    }
  }

  const saveInvestment = async() => {
    let resultData = investment?.sub_category?.filter(item => item.subCategoriesName === '');
    if(resultData && resultData.length){
        toast.error("Sub category name is not allowed to be empty");
        return;
    }
    let payload = {
        data: JSON.stringify(investment)
    };
    let result = await api.post(config.updateItDeclaration, payload);
    if(result && result.code==200){
        isViewModalOpen();
        getAllInvestment();
        toast.success('IT Declaration Updated Successfully');
    }
  }

  const addSubCategory = () => {
    let temp = investment?.sub_category;
    temp.push({
        subCategoriesName: '',
        isMaxLimit: '',
        maxLimit: ''
      })
    setInvestment({...investment,['sub_category']:temp});
  }

  const removeSubCategory = (index) => {
    let temp = investment?.sub_category;
    temp.splice(index,1);
    setInvestment({...investment,['sub_category']:temp});
  }

  return (
<div>
    <CModal
      show={isOpen}
      onClose={() => isViewModalOpen(!isOpen)}
      size="lg"
    >
      <CModalHeader closeButton>
        <CModalTitle><strong>Edit IT Declaration</strong></CModalTitle>
      </CModalHeader>
      <CModalBody>
      <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Name Of IT Declaration</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name of IT declaration" name="category" value={investment?.category} disabled/>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Max. Limit</label>
                  <select className='form-control' type="select" name="max_limit" value = {investment?.max_limit ? true : false} onChange={(e)=>onChangeInvestment(e,'category', 0)}>
                          <option value='' hidden>--Select--</option>
                          <option value={true} >Yes</option>
                          <option value={false}>No</option>
                        </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Enter Max. Limit</label>
                  <input type="number" className="form-control" id="exampleInputEmail1" name='amount' value={investment?.amount} onChange={(e)=>onChangeInvestment(e,'category', 0)}/>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Eligibility for Tax Regime</label>
                  <div>
                    <input type="checkbox" name='is_eligible_for_old' checked={investment?.is_eligible_for_old==1 ? true : false} onClick={(e)=>onChangeInvestment(e,'category', 0)}/>
                    <label>&nbsp;&nbsp;Old Regime</label>
                  </div>
                  <div>
                    <input type="checkbox" name='is_eligible_for_new' checked={investment?.is_eligible_for_new==1 ? true : false} onClick={(e)=>onChangeInvestment(e,'category', 0)}/>
                    <label>&nbsp;&nbsp;New Regime</label>
                  </div>
                </div>
              </form>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">S No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Max. Limit</th>
                    <th scope="col">Amount(Max limit)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    investment?.sub_category && investment?.sub_category?.length && investment?.sub_category.map((item, index) => (

                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td><input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name of Sub Category" name="subCategoriesName" value={item?.subCategoriesName} onChange={(e)=>onChangeInvestment(e,'subCategory', index)} required/>
                        </td>
                        <td><select className='form-control' type="select" name="isMaxLimit" value = {item?.isMaxLimit === 'true' ? true : item?.isMaxLimit === 'false' ? false : ''} onChange={(e)=>onChangeInvestment(e,'subCategory', index)}>
                          <option value=''>--Select--</option>
                          <option value={true} >Yes</option>
                          <option value={false}>No</option>
                        </select></td>
                        <td><input type="number" className="form-control" id="exampleInputEmail1" name='maxLimit' value={item?.maxLimit} onChange={(e)=>onChangeInvestment(e,'subCategory', index)}/></td>
                        <td><i className="fa fa-trash" style={{paddingTop: "50%", color: "tomato", fontSize:"135%"}} onClick={(e)=>removeSubCategory(index)}></i></td>
                      </tr>

                    ))}
                    <i className="fa fa-plus"  style={{paddingTop: "30%", color: "blue", fontSize:"135%"}} onClick={addSubCategory}></i>

                </tbody>
              </table>



      </CModalBody>
      <CModalFooter>
         <CButton type="button" className="btn btn-primary" onClick={saveInvestment}>Update</CButton>
      </CModalFooter>
    </CModal>
    <ToastContainer />
    </div>
  )
}

export default ModalForEditItDeclaration