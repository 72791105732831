
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ViewSelectedComponents from '../common/viewSelectedComponents';


const ModalPreview = (props) => {
    const { isModalOpen, message, isViewModalOpen, candidateDetails, selectedCandidateForijp } = props
    const [popopFor, setPopupFor] = useState("")
    
    
    useEffect(() => {
        selectedCandidateForijp()
    }, [candidateDetails])

    return (
        <div className="">
                 <CModal
                show={isModalOpen}
                onClose={() => isViewModalOpen(!isModalOpen)}
                size="lg"
            >

                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody empUserID={candidateDetails && candidateDetails.id} style={{paddingLeft:"80px"}}>
                <div className="">

            <div className="container-fluid">
            <div className="row" >
                <div>
                <table align="center" style={{fontFamily:'arial, helvetica neue, helvetica, sans-serif'}}>
        <tr>
            <td>
                <table 
                    style={{padding:'0',
                    margin:'0',
                    backgroundImage: 'url(images/bg.png)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    border: '3px solid #ff225f'}}>
                    <tr>
                        <td style={{padding: '30px 30px 10px'}}>
                            <img src="images/logo.svg" width="100" alt=""/>
                        </td>
                    </tr>
                    <tr style={{background :'none'}}>
                        <td>
                            <table>
                                <tr >
                                    <td width="200" style={{
                                    padding: '20px 20px 20px 30px',
                                    backgroundImage: 'url(images/line.png)',
                                    backgroundSize: '86%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'top left -10px'}}>
                                        <figure style={{padding: '15px',
                                        backgroundImage: 'url(images/profileBG.svg)',
                                        backgroundRepeat: 'no-repeat',
                                        height: '250px',
                                        backgroundPosition: 'left top'}}>
                                        <img width="200" height="240" src={candidateDetails && candidateDetails?.employee_passport_size_photo ? candidateDetails?.employee_passport_size_photo : "images/profile.jpg"} alt=''
                                        style={{objectFit: 'cover',maxWidth:"120%"}}/>
                                        </figure>
                                    </td>
                                    <td width="400" style={{padding:'20px 30px 20px 0px',fontSize: '14px',fontWeight: '400'}}>
                                        <span style={{display: 'block',paddingBottom: '25px',fontSize: '28px',fontWeight: '900'}}>
                                        <b style={{color: '#fd166c',display: 'block',fontSize: '18px',paddingBottom: '0px'}}>QDegrees</b> CONGRATULATES </span>
                                        <span style={{display: 'block',paddingBottom: '10px',fontWeight:'900',textTransform: 'uppercase',fontSize: '26px',color: '#004182',lineHeight: '30px'}}>
                                            <b style={{display: 'block',fontWeight:'900'}}>{candidateDetails && candidateDetails?.name}</b>
                                        </span>
                                        <span style={{display: 'block',paddingBottom: '10px',color: '#0f0d0d',fontSize: '13px'}}>
                                            <b>ON {candidateDetails && candidateDetails?.gender} NEW ROLE <b style={{color: 'rgb(202, 69, 8)'}}>{candidateDetails && candidateDetails?.Designation}</b> IN 
                                            <b style={{color: 'rgb(202, 69, 8)'}}> {candidateDetails && candidateDetails?.department}</b> PROCESS THROUGH IJP</b></span>
                                        <span style={{display: 'block',paddingBottom: '20px',color: '#0f0d0d',fontSize: '13px'}}><b>WE WISH YOU ALL THE BEST FOR FUTURE ENDEAVORS</b></span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

    </table>
            </div>
            </div>
        </div>

</div>

                </CModalBody>
            </CModal>
        </div>

    )
}
export default ModalPreview







