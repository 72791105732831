import React, { useState, useEffect } from 'react';
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
} from '@coreui/react';

const CommentModal = props => {
  const {
    isOpenComment,
    isViewModalOpenComment,
    empResignationData,
    declineResignation,
  } = props;
  const [comment, setComment] = useState('');
  useEffect(() => {}, []);

  const isDecline = () => {
    if (comment) {
      declineResignation(comment);
    } else {
    }
  };

  return (
    <div className="">
      <CModal
        show={isOpenComment}
        onClose={() => isViewModalOpenComment(!isOpenComment)}
        size="small"
      >
        <CModalHeader closeButton>
          <CModalTitle>Comment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <form>
            <div className="form-group">
              <label htmlFor="comment">Rejection Comment</label>
              <textarea
                className="form-control"
                id="comment"
                onChange={e => setComment(e.target.value)}
                value={comment}
                rows="3"
              >
                {comment}
              </textarea>
            </div>
          </form>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="success"
            onClick={() => isViewModalOpenComment(!isOpenComment)}
          >
            Close
          </CButton>{' '}
          <CButton color="danger" onClick={isDecline}>
            Decline
          </CButton>{' '}
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default CommentModal;
