const getAllLeaveForEmployeePending = state =>
  state.leaveDate.isLeaveDataForEmployeePending;
const getAllLeaveForEmployeeRes = state =>
  state.leaveDate.getLeaveDataForEmployeeRes;
const getAllLeaveForEmployeeError = state =>
  state.leaveDate.getLeaveDataForEmployeeResError;
export default {
  getAllLeaveForEmployeePending,
  getAllLeaveForEmployeeRes,
  getAllLeaveForEmployeeError,
};
