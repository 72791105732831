import React, { useState, lazy, useEffect } from 'react'
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, withRouter
} from "react-router-dom";
import Moment from 'react-moment';

import {
    CBadge, CNav,
    CInputRadio,
    CNavItem,
    CNavbar,
    CListGroup, CListGroupItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs,
    CButton,
    CTextarea,
    CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
    CWidgetBrand,
    CButtonGroup,
    CWidgetIcon,
    CCard,
    CLink,
    CCardBody,
    CCardFooter,
    CInputFile,
    CCardHeader,
    CCol,
    CSwitch,
    CCollapse,
    CProgress,
    CProgressBar,
    CRow,
    CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
    CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { DocsLink } from 'src/reusable'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import './booking_meeting_room_screen.css'
import { validation } from '../../../../utils/configCommon'
import Clock from '../clock';
import moment from 'moment';
import BookedMeetingSlider from './bookedSlotSlider';

const BookingMeetingRoomScreen = ({ match,history }) => {
    // let {  } = props
    let api = new API()
   
    const [active, setActive] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [bookingRoomName, setBookingRoomName] = useState("")
    const [currentTime, setCurrentTime] = useState(0)

    // const [nextScheduledTime, setNextScheduledTime] = useState("10:00-12:00")
    const [next_available_time, setNext_available_time] = useState("")
    const [meeting_status, setMeeting_status] = useState("")
    const [meeting_room_name, setMeeting_room_name] = useState("")
    const [available_facility, setSvailable_facility] = useState("")
    const [nextScheduledTime, setNextScheduledTime] = useState("")
    const [meetingScheduledHeading, setMeetingScheduledHeading] = useState("")
    const [meetingType, setMeetingType] = useState("")

    const [colorDynamic, setColorDynamic] = useState("green")
    const [meetingRoomLiveStatus, setMeetingRoomLiveStatus] = useState()
    const [getBookedMeeting, setGetBookedMeeting] = useState()
    useEffect(() => {
        let bookingroom = match.params.id
        setBookingRoomName(bookingroom)
        current_time()
        // setBookingRoomName(bookingroom)
        meetingRoomStatus(match.params.id)
        getDetailsForMeetingRoom(match.params.id)
        callingApi()
        alreadyBookedMeeting(match.params.id)
    }, [])

    const callingApi = () => {
        setInterval(() => {
            meetingRoomStatus(match.params.id)
            // console.log("calling api in one minute")
        }, 60000)
    }

    const current_time = () => {
        setInterval(() => {
            let time = Date.now()
            // moment().format("HH:MM")
            // alert(moment().format("hh:mm"))
            setCurrentTime(time)
            // console.log(time)
        }, 1000)
    }

    const getDetailsForMeetingRoom = async (roomname) => {
        let data = {
            meeting_room_name: roomname
        }

        let result = await api.get(config.findRoomDetailsForScreen, true, data)
        if (result && result.code == 200) {
            // console.log("meeting room basic details", result && result.data && result.data.facilities)

            setMeeting_room_name(result.data[0] && result.data[0].room_name)
            setSvailable_facility(result.data[0] && result.data[0].room_detail)
            // console.log("getting", result)
            // setAppliedAssetsRequest(result.data)
        } else {

        }
    }
    const meetingRoomStatus = async (roomname) => {
        let data = {
            meeting_room_name: roomname
        }
        // console.log("data is", data)
        // return
        let result = await api.get(config.getMeetingRoomStatusForScreen, true, data)
        if (result && result.code == 200) {
            // console.log("Facility is", result && result.data && result.data.facilities)
            setColorDynamic(result.data.color)    //"#ff3333"
            // console.log("color Is", result.data.color)
            // setMeeting_status(result.data.message)
            // setNextScheduledTime(result.data.next_scheduled_meeting)
            // setMeetingType(result.data.meeting_type)
            // setMeetingScheduledHeading(result.data.meeting_content)
            setMeetingRoomLiveStatus(result.data)

            // console.log("getting", result)
            // setAppliedAssetsRequest(result.data)
        } else {

        }
    }

    const alreadyBookedMeeting = async (roomname) => {
        // console.log("inside already booked____________________", bookingRoomName)
        let data = {
            meeting_room_name: roomname
        }
        // console.log("data is-----------------------------", data)

        let result = await api.get(config.getCreatedMeetingRecords, true, data)
        if (result && result.code == 200) {
            // alert("Successfully getting booked meeting")
            // console.log("getbooking meeting___________________", result)
            setGetBookedMeeting(result.data)
            // setAppliedAssetsRequest(result.data)
        } else {
            // alert("unable to getting booked meeting")
        }
    }

    return (
        <div>
            <div>

                {/* <div className="container-fluid up-head" style={{ minHeight: "calc(100vh - 40px)" }}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="r-content">
                            <h3>Next Booking Time</h3>
                            <div className="r-btn">
                                <div className="button text-center">
                                    <div className="text-time" style={{ fontSize: "30px", fontWeight: "700" }}>{displayScreenDetails.next_booking_time}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="r-content text-center">
                            <h3>book.as/aJusgN..</h3>
                            <div className="img-my text-center">
                                <img src="images/QD.png" alt="" width="40%" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="r-content">
                                <h1 style={{ color: "white", letterSpacing: "3px" }}><b>{displayScreenDetails.meeting_status}</b></h1>
                                <p style={{ color: "white", fontSize: "22px" }}>Next Available {displayScreenDetails.next_available_time}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid up-head-f">
                <div className="footer">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="my-text pt-3">
                                <h5 style={{ color: "darkslategrey" }}>{displayScreenDetails.meeting_room_name} Conferemce Room</h5>
                                {displayScreenDetails.available_facility.map((facility, index)=>(
                                <span style={{ color: "darkslategrey" }}>{facility.facility}, </span>
                                ))}
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="img-my text-center pt-3">
                                <img src="images/qdegrees.png" alt="" />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="button-my pt-3">
                                <h5 className="button-f text-center">{<Moment format="hh:mm:ss a">{currentTime && currentTime}</Moment>}</h5>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}


                {/* <div className="bg-success-color custome-container" style={{ backgroundColor: "rgb(245, 133, 6)" }}> */}
                    {/* <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" onClick={() => history.goBack()}><i className="fa fa-arrow-left" aria-hidden="true"></i></CButton>{' '} */}
                <div className="custome-container" style={{ backgroundColor: colorDynamic }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12" >
                                <div className="" style={meetingRoomLiveStatus && meetingRoomLiveStatus.next_scheduled_time ? { display: "" } : { display: "none" }}>
                                    <h3 className="mb-4 text-white">{meetingRoomLiveStatus && meetingRoomLiveStatus.meeting_content}</h3>
                                    <div className="bg-trans card-body mb-3">
                                        <h4 className="mb-0 text-white">{meetingRoomLiveStatus && meetingRoomLiveStatus.next_scheduled_time}</h4>
                                        <p className="mb-0 mt-1 text-white font-weight-bold">{meetingRoomLiveStatus && meetingRoomLiveStatus.meeting_type}</p>
                                    </div>
                                </div>

                            </div>
                            {match.params.id == "manthan" &&
                                <div className="col-lg-6 col-md-6 col-12 text-center">
                                    <img src="images/manthanqr.png" alt="" className="img-qr mb-3" />
                                    <h6 className="mb-3 text-white" >https://qdeg.in/DYSPD0CrJ</h6>
                                </div>
                            }
                            {match.params.id == "gurukul" &&
                                <div className="col-lg-6 col-md-6 col-12 text-center">
                                    <img src="images/gurukulqr.png" alt="" className="img-qr mb-3" />
                                    <h6 className="mb-3 text-white" >https://qdeg.in/DYSPF09r2</h6>
                                </div>
                            }
                            {match.params.id == "interview_room_1" &&
                                <div className="col-lg-6 col-md-6 col-12 text-center">
                                    <img src="images/mini1.jpeg" alt="" className="img-qr mb-3" />
                                    <h6 className="mb-3 text-white" >https://qdeg.in/DYSPF03r1</h6>
                                </div>
                            }
                            {match.params.id == "interview_room_2" &&
                                <div className="col-lg-6 col-md-6 col-12 text-center">
                                    <img src="images/interview_room_2.png" alt="" className="img-qr mb-3" />
                                    <h6 className="mb-3 text-white" >https://qdeg.in/DYSPF011j</h6>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="text-white" style={{ fontSize: "40px" }}>
                                    {/* Manthan is available for all day */}
                                    {/* Current meeting organized by Amit Kumar and 5 others joining */}
                                    {/* {meetingRoomLiveStatus && meetingRoomLiveStatus.meeting_status}{meetingRoomLiveStatus && meetingRoomLiveStatus.message}<br /> */}
                                    {meetingRoomLiveStatus && meetingRoomLiveStatus.message} {meetingRoomLiveStatus &&  meetingRoomLiveStatus.current_meeting_time ? "| " + meetingRoomLiveStatus.current_meeting_time : ""}<br /> 
                                    <h5 className="mb-0 text-white mt-2">{meetingRoomLiveStatus && meetingRoomLiveStatus.meetingOrganizer}</h5>
                                </h1>
                            </div>
                        </div>
                        <marquee>
                            <BookedMeetingSlider getBookedMeeting={getBookedMeeting} />
                        </marquee>
                    </div>
                </div>

            </div>


            <div className="bg-light card-body d-flex align-items-center justify-content-between">
                <div className="col-lg-6">
                    <h6 className="mb-0 text-dark text-uppercase">{meeting_room_name.toLowerCase() == "gurukul" ? "Gurukool" : meeting_room_name} conference room</h6>


                    {/* {displayScreenDetails.available_facility.length>0 && displayScreenDetails.available_facility.map((facility, index) => ( */}
                    <span className="font-weight-normal mb-0 text-dark">{available_facility}, </span>
                    {/* ))} */}

                </div>
                <div className="col-lg-4">
                    <img src="images/qdegrees.png" className="img" height="30px" />
                </div>
                <div className="col-lg-2 text-right">
                    <div className="btn  border border-1 text-white clock">{<Moment format="HH:mm:ss">{currentTime && currentTime}</Moment>}</div>
                    {/* <Clock/> */}
                </div>
            </div>
            <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" onClick={() => history.goBack()}><i className="fa fa-arrow-left" aria-hidden="true"></i></CButton>{' '}
        </div>

    )
}
export default withRouter(BookingMeetingRoomScreen)