import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer, rootSaga } from '../resource';
import { browserStorage } from './index';
import { STORAGE_NAME, SAVE_TIME_DELAY } from '../configs/BrowserStorage.config';
import debounce from 'lodash/debounce';

let store;
let isStoring = false;
let hasPendingStoreRequest = false;
let isIntegrationMode = false;

const configureStore = (toRestore) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, info) => {
     
    },
  });

  const middlewares = [sagaMiddleware];
  let initialState;

  try {
    if (toRestore) {
      initialState = JSON.parse(toRestore) || {};
    } else {
      initialState = {};
    }
  } catch (e) {
    
  }

  const composeExtenders = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(rootReducer, initialState, composeExtenders(applyMiddleware(...middlewares)));

  const saveStoreToIndexDB = () => {
    if (isIntegrationMode) {
      return;
    }

    if (isStoring) {
      
      hasPendingStoreRequest = true;
      return;
    }

    isStoring = true;
    
    const toSave = { ...store.getState() };
    delete toSave.globalSetting;
    let preparedStore = JSON.stringify(toSave);
    browserStorage.setItem(STORAGE_NAME, preparedStore).then(() => {
      
      // sessionStorage.clear();
      isStoring = false;
      if (hasPendingStoreRequest) {
        hasPendingStoreRequest = false;
        saveStoreToIndexDB();
      }
    });
    preparedStore = null;
  };

  // Possible fix for the Concierge account
  if (!isIntegrationMode) {
    store.subscribe(debounce(() => {
      saveStoreToIndexDB();
    }, SAVE_TIME_DELAY));
    window.addEventListener('beforeunload', () => {
      // if (window.sessionStorage) {
      //   sessionStorage.clear();
      // }
      
      saveStoreToIndexDB();
      
    });
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

const getStore = toRestore => (store || configureStore(toRestore));

export { configureStore, getStore };
