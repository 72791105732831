/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './asset_undertaking.css';
import moment from 'moment';
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import { CButton } from '@coreui/react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';
import swal from 'sweetalert';

const AssetUndertaking = props => {
  const { open, toggleModal, data, getInventoryList } = props;
  const api = new API();
  const [empData, setEmpData] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');

  // find data
  let dataForAsset = async () => {
    let data1 = {
      user_id: data?.assign_to,
    };
    let result = await api.post(config.dataForAsset, data1);
    if (result && result.code === 200) {
      setEmpData(result?.data[0]);
    }
  };

  useEffect(() => {
    dataForAsset();
  }, [data]);

  const sendOtp = async () => {
    let data1 = {
      user_id: data?.assign_to,
      type: 'asset',
    };
    let result = await api.post(config.sendOtpForAsset, data1);
    if (result && result.code === 200) {
      toast.success(result.message);
      setIsOtpSent(true);
    } else {
      toast.error(result.message);
    }
  };
  const verifyOTP = async () => {
    let data1 = {
      email: data?.email,
      type: 'asset',
      otp: otp,
    };
    let result = await api.post(config.verifyOtp, data1);
    if (result && result.code === 200) {
      toast.success(result.message);
      setIsVerified(true);
      uploadAssetForm();
    } else {
      toast.error(result.message);
    }
  };

  const uploadAssetForm = async () => {
    const formData = new FormData();
    formData.append('user_id', data?.assign_to);
    formData.append('asset_id', data?.id);
    swal({
      content: true,
      title: 'Are you sure?',
      text: `Are you sure to submit asset undertaking form`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.putPostFileFun(config.uploadFormImage, formData);
        if (result && result.code === 200) {
          toast.success(result && result.message);
          toggleModal();
          getInventoryList();
        } else {
        }
      }
    });
  };

  return (
    <div className="">
      <CModal show={open} onClose={toggleModal} size="xl" backdrop={false}>
        <CModalHeader closeButton>
          <CModalTitle>Asset Undertaking</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="p-4">
            <div className="header_content text-center pb-5">
              <strong className="h4">
                <u>
                  <b>IT ASSET UNDERTAKING FORM</b>
                </u>
              </strong>
            </div>

            <div className="body_content text-center pb-4">
              <div className="pl-2 pt-2">
                <span className="h6">
                  Below is the Acknowledgement of equipment held by Employee of
                  QDegrees Services Pvt. Ltd.
                </span>
              </div>
            </div>

            <div className="table">
              <table className="tableempdetail" cellPadding="3">
                <tbody>
                  <tr>
                    <th className="tableth">Employee Joining Date</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        value={moment(data?.joining_date).format(
                          'DD MMMM YYYY',
                        )}
                        name="emp_user_id"
                        className="form-control"
                        type="text"
                        placeholder="Enter Emp. Joining Date"
                      />
                    </td>
                  </tr>

                  {/* <tr>
                    <th className="tableth">Employee Leaving Date</th>
    
                    <td className="tabletd">
                      <input
                        name="emp_name"
                        value={getDataToAdmin&&getDataToAdmin.emp_name}
                        className="form-control"
                        type="date"
                        placeholder="Enter Employee Leaving date"
                      />
                    </td>
                  </tr> */}

                  <tr>
                    <th className="tableth">Employee ID</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        value={data?.assign_to_emp_id}
                        name="assign_to_emp_id"
                        className="form-control"
                        type="text"
                        placeholder="Enter Emp ID"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th className="tableth">Employee Name</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        value={data?.assign_to_name}
                        name="assign_to_name"
                        className="form-control"
                        type="text"
                        placeholder="Enter Emp Name"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th className="tableth">Department</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        value={data?.department}
                        name="department"
                        className="form-control"
                        type="text"
                        placeholder="Department"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">HOD Name</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        value={empData?.hod}
                        name="hod"
                        className="form-control"
                        type="text"
                        placeholder="HOD Name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Location</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        value={data?.location}
                        name="location"
                        className="form-control"
                        type="text"
                        placeholder="Location"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th className="tableth">Company Email ID</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        value={data?.email}
                        name="email"
                        className="form-control"
                        type="text"
                        placeholder="Company Email ID"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Personal Email ID</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="personal_email"
                        value={empData?.personal_email}
                        className="form-control"
                        type="text"
                        placeholder="Personal Email ID"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Mobile Number</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        name="mobile"
                        value={data?.mobile}
                        className="form-control"
                        type="text"
                        placeholder="Enter Mobile No."
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Asset Type</th>

                    <td className="tabletd">
                      <input
                        style={{ color: 'black' }}
                        disabled
                        name="asset_name"
                        value={data?.asset_name}
                        className="form-control"
                        type="text"
                        placeholder="Enter Asset Type"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Asset Company</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="brand"
                        value={data?.brand}
                        className="form-control"
                        type="text"
                        placeholder="Enter Asset Company"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Model</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="model"
                        value={data?.model}
                        className="form-control"
                        type="text"
                        placeholder="Enter Model"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Serial No. </th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="serial_no"
                        value={data?.serial_no}
                        className="form-control"
                        type="text"
                        placeholder="Enter Serial No."
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Asset Issue Date</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="created_at"
                        value={moment(data?.created_at).format('DD MMMM YYYY')}
                        className="form-control"
                        type="text"
                        placeholder="Enter Asset Issue Date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Asset Issued By</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="emp_name"
                        value={data?.asset_assigned_by}
                        className="form-control"
                        type="text"
                        placeholder="Enter Asset Issued By"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Comments</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="remark"
                        value={data?.remark === 'undefined' ? '' : data?.remark}
                        className="form-control"
                        type="text"
                        placeholder="Comment"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Undertaking Date</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="undertaking_date"
                        value={
                          data?.undertaking_date_byIT === null
                            ? ''
                            : moment(data?.undertaking_date_byIT).format(
                                'DD MMMM YYYY',
                              )
                        }
                        className="form-control"
                        type="text"
                        placeholder="Undertaking Date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">IMEI 1/ IMEI 2 Number</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="imei"
                        value={data?.imei === null ? '' : data?.imei}
                        className="form-control"
                        type="text"
                        placeholder="IMEI 1/ IMEI 2 no."
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="tableth">Approximate Asset Value</th>

                    <td className="tabletd">
                      <input
                        disabled
                        style={{ color: 'black' }}
                        name="purchase_cost"
                        value={data?.purchase_cost}
                        className="form-control"
                        type="text"
                        placeholder="Enter approximate asset value"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="body_content pb-4">
                <strong className="h6">Terms & Conditions :</strong>
                <div className="pl-2 pt-3">
                  <span>
                    I , understand the guidelines and policies for QDegrees
                    Services Pvt. Ltd. equipment use . I will only be using
                    QDegrees assests for business purpose only . Any software or
                    hardware to be installed is required to have prior approval
                    before installation . Upon any termination with QDegrees
                    (whether voluntary/In voluntary) ,<br />I will return all
                    above mentioned asset within 48 hours , if failed to do so
                    will pay the penalty equal to asset value mentioned above ,
                    else degrees is liable to take legal action against me . In
                    addition , I also acknowledge that I will not receive the
                    last paycheck , unless I return the above mentioned asset .{' '}
                    <br />
                    QDegrees retains the right to file for recovery of asset
                    through Court Proceedings .
                  </span>
                </div>
              </div>
              <div className="pl-2 pt-3">
                <div className="row">
                  <div className="col-md-3">
                    <span>
                      <strong>Asset Assigned By :</strong>{' '}
                    </span>
                  </div>
                  <div className="col-md-9">
                    <input
                      disabled
                      value={data?.asset_assigned_by}
                      name="Asset Assigned By"
                      style={{
                        borderBottom: '4px solid #354b9c',
                        borderRight: '4px solid #354b9c',
                        borderLeft: '1px solid #354b9c',
                        color: 'black',
                      }}
                      className="form-control"
                      type="text"
                      placeholder="Asset Assigned By"
                      aria-label="default input example"
                    />
                  </div>
                </div>
              </div>
              <div className="pl-2 pt-3">
                <div className="row">
                  <div className="col-md-3">
                    <span>
                      <strong>Handover To :</strong>{' '}
                    </span>
                  </div>
                  <div className="col-md-9">
                    <input
                      disabled
                      name="Asset Handover To"
                      value={data?.assign_to_name}
                      style={{
                        borderBottom: '4px solid #354b9c',
                        borderRight: '4px solid #354b9c',
                        borderLeft: '1px solid #354b9c',
                        color: 'black',
                      }}
                      className="form-control"
                      type="text"
                      placeholder="Asset Handover To"
                      aria-label="default input example"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <strong>Employee's Signature&nbsp; : </strong>
                </div>
                <div className="col-md-8">
                  {data?.undertaking_byEmp === '1' && (
                    <img src={data?.emp_sign || ''} alt="sign" />
                  )}
                  <div className="my-auto" style={{ textAlign: 'right' }}>
                    <>
                      {!isVerified && data?.undertaking_byIT === '0' ? (
                        <>
                          {!isOtpSent && (
                            <CButton
                              onClick={sendOtp}
                              className="btn btn-primary"
                            >
                              Send OTP
                            </CButton>
                          )}
                          {isOtpSent && (
                            <>
                              <div
                                className="row"
                                style={{ marginTop: '10px' }}
                              >
                                <div className="col-md-6"></div>
                                <div
                                  className="col-sm-3"
                                  style={{ textAlign: 'right' }}
                                >
                                  <input
                                    required
                                    style={{ width: '143px' }}
                                    type="number"
                                    max={6}
                                    className="form-control"
                                    value={otp}
                                    onChange={e => setOtp(e.target.value)}
                                    placeholder="Enter OTP "
                                  />
                                </div>
                                <div
                                  className="col-sm-3"
                                  style={{ textAlign: 'right' }}
                                >
                                  <CButton
                                    onClick={verifyOTP}
                                    className="btn btn-info"
                                  >
                                    Verify & Submit
                                  </CButton>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>
      <ToastContainer />
    </div>
  );
};
export default AssetUndertaking;
