import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { toast, ToastContainer} from 'react-toastify';
import {
  CBadge,
  CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup,
  CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel,
  CInput,
  CForm,
  CFormGroup,
  CFormText,
  CSelect,
  CCallout,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert';
import moment from 'moment';
import Alert from 'src/alert/alert';
import { useForceUpdate } from 'src/hooks';

const FamilyDetails = props => {
  const forceUpdate = useForceUpdate();
  let { getEmployeeDetailStatus, empStatus, empDetails, onClickActive } = props;
  const api = new API();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [disable, setDisabled] = useState(false);
  const [disable1, setDisabled1] = useState(false);
  const [fatherAge, setFatherAge] = useState('');
  const [motherAge, setMotherAge] = useState('');
  const [FamilyDetails, setFamilyDetails] = useState({
    emp_father_name: '',
    emp_father_dob: '',
    emp_mother_name: '',
    emp_mother_dob: '',
    emp_father_age: '',
    emp_mother_age: '',
    emp_spouse_name: '',
    emp_spouse_dob: '',
    emp_spouse_aadhar_card_no: '',
    emp_first_child_name: '',
    emp_first_child_dob: '',
    emp_second_child_name: '',
    emp_second_child_dob: '',
  });
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [personalDetails, setPersonalDetails] = useState('')

  useEffect(() => {
    getFamilyDetails();
    getPersonalDetails();
  }, []);

  console.log(FamilyDetails)

  const getPersonalDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id
    }
    if (!data.user_id) {
      delete data.user_id
    }
    let result = await api.get(config.getEmployeePersonalDetails, true, data);
    if (result && result.code === 200) {
      if (result.data.length > 0) {
        setPersonalDetails(result.data[0])
      }
    } else {
      // alert(result && result.message);
    }
  };
  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };
  const getFamilyDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getEmployeeFamilyDetails, true, data);
    if (result && result.code === 200) {
      if (empStatus && empStatus.family_details_status == 1) {
        setIsDisabled(true);
        setIsSubmit(false);
        setFamilyDetails(result.data[0]);
      }
    } else {
      // alert(result && result.message);
    }
  };
  const saveFamilyDetails = async () => {
    // return
    let data = FamilyDetails;
    data.user_id = empDetails && empDetails.id
    if(!data.user_id){
      delete data.user_id
    }
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to add Family Details`,
      icon: "success",
      buttons: ["No, cancel it!", "Submit"],
      dangerMode: true,
  }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.familydetails, data);
    if (result && result.code === 200) {
      toast.success(result.message);
      onClickActive(4);
      getEmployeeDetailStatus()
      setIsSubmit(false);
      getFamilyDetails();
    } else {
      toast.error(result.message);
    }      
      }
  })};
  const updateFamilyDetails = async () => {
    // return
    let data = FamilyDetails;
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to update Family Details`,
      icon: "success",
      buttons: ["No, cancel it!", "Update"],
      dangerMode: true,
  }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updatefamilydetails, data);
    if (result && result.code === 200) {
      toast.success(result.message);
      getEmployeeDetailStatus()
      setIsSubmit(false);
      getFamilyDetails();
    } else {
      toast.error(result.message);
    }      
      }
  })};

  const onChangeInputFamilyDetails = (e) => {
    const { name, value } = e.target;
    if(name === "emp_father_dob"){
    var dob = moment(value).format("YYYYMMDD");
    var year = Number(dob.substr(0, 4));
    var month = Number(dob.substr(4, 2)) - 1;
    var day = Number(dob.substr(6, 2));
    var today = new Date();
    var age = today.getFullYear() - year;
    if (today.getMonth() < month || (today.getMonth() === month && today.getDate() < day)) {
     age--;
    }
    if(value.length > 0){
    setFatherAge(age);
    setDisabled(true);
    let temp=FamilyDetails;
    temp['emp_father_age']=age;
    setFamilyDetails(temp);
    forceUpdate();
    }
    else{
      setFatherAge('');
      setDisabled(false);
    }
    setFamilyDetails({
      ...FamilyDetails,
      [name]: value,
    })}
    else if(name === "emp_mother_dob"){
      var dob = moment(value).format("YYYYMMDD");
      var year = Number(dob.substr(0, 4));
      var month = Number(dob.substr(4, 2)) - 1;
      var day = Number(dob.substr(6, 2));
      var today = new Date();
      var age = today.getFullYear() - year;
      if (today.getMonth() < month || (today.getMonth() === month && today.getDate() < day)) {
       age--;
      }
      if(value.length > 0){
      setMotherAge(age);
      setDisabled1(true);
      let temp=FamilyDetails;
      temp['emp_mother_age']=age;
      setFamilyDetails(temp);
      forceUpdate();
      }
      else{
        setMotherAge('');
        setDisabled1(false);
      }
      setFamilyDetails({
        ...FamilyDetails,
        [name]: value,
      })}
    else{
      setFamilyDetails({
      ...FamilyDetails,
      [name]: value,
    });
  }
  };

  return (
    <div>
    <form>
      <div className="row">

        <div className="col-md-6">

          <div className="form-group">
            <label for="exampleInputEmail1">Father’s Name <span style={{color:'red'}}>*</span></label>
            <input type="text" className="form-control" name="emp_father_name"
              value={FamilyDetails.emp_father_name}
              onChange={onChangeInputFamilyDetails}
              placeholder="Enter Father's Name"
               />
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Father’s Date of Birth</label>
            <input type="date" className="form-control" value={FamilyDetails.emp_father_dob} max={moment()
                  .subtract(18, 'years')
                  .format('YYYY-MM-DD')}
              onChange={onChangeInputFamilyDetails}
              name="emp_father_dob"
              />
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Father's Age </label>
            <input type="text" className="form-control" name="emp_father_age" minLength={2} maxLength={3}
            disabled={disable ? true : false}
              value={fatherAge ? fatherAge : FamilyDetails.emp_father_age}
              onChange={onChangeInputFamilyDetails}
              placeholder="Enter Father's Age"
            />
          </div>
          {personalDetails.emp_marital_status !== 'SINGLE' && <>
          <div className="form-group">
            <label for="exampleInputPassword1">Spouse Name (If Applicable)</label>
            <input type="text" className="form-control" name="emp_spouse_name"
              value={FamilyDetails.emp_spouse_name}
              onChange={onChangeInputFamilyDetails}
              placeholder="Enter Spouse's Name"
              />
          </div>
          
          <div className="form-group">
            <label for="exampleInputPassword1">First Child’s Name (If Applicable)</label>
            <input type="text" className="form-control" 
              name="emp_first_child_name"
              value={FamilyDetails.emp_first_child_name}
              onChange={onChangeInputFamilyDetails}
              placeholder="Enter First Chlid's Name"
              />
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Second Child’s Name (If Applicable)</label>
            <input type="text" className="form-control"
              name="emp_second_child_name"
              value={FamilyDetails.emp_second_child_name}
              onChange={onChangeInputFamilyDetails}
              placeholder="Enter Second Chlid's Name"
              />
          </div>
          </>}
        </div>

        <div className="col-md-6">
        <div className="form-group">
            <label for="exampleInputPassword1">Mother’s Name <span style={{color:'red'}}>*</span></label>
            <input type="text" className="form-control" name="emp_mother_name"
              value={FamilyDetails.emp_mother_name}
              onChange={onChangeInputFamilyDetails}
              placeholder="Enter Mother's Name"
            />
          </div>
          <div className="form-group">
            <label for="exampleInputEmail1">Mother’s Date of Birth</label>
            <input type="date" className="form-control" value={FamilyDetails.emp_mother_dob} max={moment()
                  .subtract(18, 'years')
                  .format('YYYY-MM-DD')}
              onChange={onChangeInputFamilyDetails}
              name="emp_mother_dob"
            
            />
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Mother's Age </label>
            <input type="text" className="form-control" name="emp_mother_age" minLength="2" maxLength="3"
            disabled={disable1 ? true : false}
              value={motherAge ? motherAge : FamilyDetails.emp_mother_age}
              onChange={onChangeInputFamilyDetails}
              placeholder="Enter Mother's Age"
            />
          </div>
          {personalDetails.emp_marital_status !== 'SINGLE' && <>
          <div className="form-group">
            <label for="exampleInputEmail1">Spouse’s Date of Birth (If Applicable)</label>
            <input type="date" className="form-control" value={FamilyDetails.emp_spouse_dob} max={moment()
                  .subtract(18, 'years')
                  .format('YYYY-MM-DD')}
              onChange={onChangeInputFamilyDetails}
              name="emp_spouse_dob"
               />
          </div>
       
          <div className="form-group">
            <label for="exampleInputEmail1">First Child’s Date of Birth (If Applicable)</label>
            <input type="date" className="form-control"
              value={FamilyDetails.emp_first_child_dob}
              onChange={onChangeInputFamilyDetails}
              name="emp_first_child_dob"
             />
          </div>

          <div className="form-group">
            <label for="exampleInputEmail1">Second Child’s Date of Birth (If Applicable)</label>
            <input type="date" className="form-control"
              value={FamilyDetails.emp_second_child_dob}
              onChange={onChangeInputFamilyDetails}
              name="emp_second_child_dob"
              />
          </div>
          </>}
          <>
            {isSubmit ?
                <button className="btn btn-primary float-right mt-3" type="button" onClick={saveFamilyDetails}>Submit</button>
                :
                <>
                <button className="btn btn-success float-right mt-3 ml-1" type="button" onClick={(e) => onClickActive(4)}>Next <i class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                <button className="btn btn-info float-right mt-3" type="button" onClick={updateFamilyDetails}>Update</button>
              </>}
              </>
         

        </div>
      </div>
   
    </form>
    <Alert
      isAlert={isAlert}
      isAlertToggle={isAlertToggle}
      alertMessage={alertMessage}
      isAlertToggleClose={isAlertToggleClose}
    />
<ToastContainer />
  </div>
  );
};
export default FamilyDetails;