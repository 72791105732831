
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import './ctc.css'
import Moment from 'react-moment';

import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
const Ctc = (props) => {
    const { candidate, ctcResult, joiningDate, todayDate } = props
    const api = new API()

    const [isCTCFullDetails, setIsCTCFullDetails] = useState(false)
    // const [large, setLarge] = useState(false)
    useEffect(() => {

    }, [])



    // const isModalOpen = () => {
    //     setLarge(!large)
    // }
    const ctcFullDetails = () => {

        setIsCTCFullDetails(true)
    }
    return (
        <div classNameNameName="">
            <body width="100%" style={{ margin: "0", padding: "0" }}>
                <center style={{ width: "100%", backgroundColor: "#f1f1f1" }}>

                    <div style={{ maxWidth: "950px", margin: "0 auto", backgroundColor: "#fff" }} className="email-container" >
                        <div style={{ maxWidth: "950px", margin: "0 auto" }} className="image-hr" >

                        </div>
                        <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ margin: "auto" }}>

                            <tr>
                                <td valign="top" className="bg_white" style={{ padding: "1em 2.5em" }}>
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td width="40%" className="logo" style={{ textAlign: "right" }}>
                                                <h1><img src="images/qdegrees.png" /></h1>
                                            </td>

                                        </tr>
                                    </table>
                                </td>
                            </tr>




                            <tr>
                                <td className="">
                                    <div className="70%">


                                        <table className="table table-bordered" style={{ border: "1px soild", width: "85%", margin: "auto" }}>
                                            <thead>
                                                <tr>
                                                    <th>Name:</th>
                                                    <td colspan="2" style={{ textAlign: "center" }}><strong className="hr-color">{candidate && candidate.firstName} {candidate && candidate.name}</strong></td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>Function:</th>
                                                    <td colspan="2" style={{ textAlign: "center" }}><strong className="hr-color">{candidate && candidate.designation}</strong></td>

                                                </tr>
                                                <tr>
                                                    <th>Location :</th>
                                                    <td colspan="2" style={{ textAlign: "center" }}><strong className="hr-color">{candidate && candidate.address}</strong></td>
                                                </tr>
                                                <tr>
                                                    <th>Designation</th>
                                                    <td colspan="2" style={{ textAlign: "center" }}><strong className="hr-color">{candidate && candidate.designation}</strong></td>


                                                </tr>
                                                <tr style={{ padding: "10px" }}>
                                                    <th >Compensation Break up</th>
                                                    {/* <tr> */}
                                                    <th style={{ textAlign: "center" }}>Monthly</th>
                                                    <th style={{ textAlign: "center" }}>Annual</th>
                                                    {/* </tr> */}
                                                </tr>
                                                <tr>
                                                    <td>Basic </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Basic}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Basic * 12}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>HRA </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.HRA}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.HRA * 12}</strong></td>
                                                </tr>
                                                {ctcResult&&ctcResult&&ctcResult.LTA&&
                                                <tr>
                                                    <td>LTA Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.LTA}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.LTA * 12}</strong></td>
                                                </tr>
}
                                                {ctcResult&&ctcResult&&ctcResult.Education_Allowance&&
                                                  <tr>
                                                    <td>Education Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Education_Allowance}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Education_Allowance * 12}</strong></td>
                                                </tr>
}
                                                  {ctcResult&&ctcResult.Special_Allowance!==0&&

                                                <tr>
                                                    <td>Special Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Special_Allowance}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Special_Allowance * 12}</strong></td>
                                                </tr>
}
                                                {ctcResult&&ctcResult.Petrol_Allowance&&

                                                <tr>
                                                    <td>Petrol Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Petrol_Allowance}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Petrol_Allowance * 12}</strong></td>
                                                </tr>
                                                 }

                                                {/* <tr>
                                                    <td>Attendance Allowance</td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Attendance_Allowance}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Attendance_Allowance * 12}</strong></td>
                                                </tr> */}
                                                {ctcResult&&ctcResult.Meal_Allowacne&&
                                                <tr>
                                                    <td>Meal Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Meal_Allowacne}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Meal_Allowacne * 12}</strong></td>
                                                </tr>
                                                }
                                                {/* <tr>
                                                    <td>Total Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_Allowance}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_Allowance * 12}</strong></td>
                                                </tr> */}
                                                {/* 
                                                <tr>
                                                    <td>Medical Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                </tr>

                                                <tr>
                                                    <td>LTA</td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Meal Allowance </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Management Allowance</td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Education Allowance</td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                </tr> */}

                                                <tr style={{ backgroundColor: "rgb(228, 228, 228)" }}>
                                                    <td><b>Gross</b></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Gross}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Gross * 12}</strong></td>
                                                </tr>
                                                <td><b>Employee's Contribution</b></td>
                                                {ctcResult&&ctcResult.GMC&&
                                                <tr>
                                                    <td>GMC </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.GMC}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.GMC * 12}</strong></td>
                                                </tr>
                                                }

                                                <tr>
                                                    <td>PF  - Employee Contributions </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employee_PF}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employee_PF * 12}</strong></td>
                                                </tr>
                                                {ctcResult&&ctcResult.Employee_ESI&&
                                                <tr>
                                                    <td>ESI - Employee Contribution  </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employee_ESI}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employee_ESI * 12}</strong></td>
                                                </tr>
}

                                                <tr>
                                                    <td><b>Total Employee's Contribution</b></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_Employee_Contribution}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_Employee_Contribution * 12}</strong></td>
                                                </tr>


                                                <td><b>Employers Contribution</b> </td>


                                                <tr>
                                                    <td>PF  - Employers Contributions </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employer_PF}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employer_PF * 12}</strong></td>
                                                </tr>
                                                {ctcResult&&ctcResult.Employer_ESI&&

                                                <tr>
                                                    <td>ESI  - Employers Contributions </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employer_ESI}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Employer_ESI * 12}</strong></td>
                                                </tr>
                                                }

                                                {/* <tr>
                                                    <td>Total  - Employee Contributions </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.totalEmployerContribution}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.totalEmployerContribution * 12}</strong></td>
                                                </tr> */}
                                               
                                                <tr>
                                                    <td><b>Total Employer's Contribution</b></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_Employer_Contribution}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_Employer_Contribution * 12}</strong></td>
                                                </tr>
                                                {/* <tr>
                                                    <td>CTC [A] </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.basic}</strong></td>
                                                </tr> */}

                                                <tr>
                                                    <td>Performance Bonus (Variable Pay) [B] </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Performance_Bonus}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Performance_Bonus * 12}</strong></td>
                                                </tr>

{/* 
                                                <tr style={{ backgroundColor: "rgb(107, 199, 16)" }}>
                                                    <td><b>Total CTC (A+B+C)</b></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_CTC_A+B+C}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Total_CTC_A+B+C * 12}</strong></td>
                                                </tr> */}

                                                <tr style={{ backgroundColor: "rgb(107, 199, 16)" }}>
                                                    <td><b>Take Home</b> </td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Take_Home}</strong></td>
                                                    <td style={{ textAlign: "center" }}><strong className="hr-color">{ctcResult && ctcResult.Take_Home * 12}</strong></td>
                                                </tr>

                                            </tbody>
                                        </table>


                                        <p style={{ paddingLeft: "2.5em", fontFamily: "Georgia, 'Times New Roman', Times, serif", fontSize: "medium" }}>*Performance Bonus will be paid quarterly basis. (An employee should be live/not in notice in the company during performance bonus payout)</p>
                                        <p style={{ paddingLeft: "2.5em", fontFamily: "Georgia, 'Times New Roman', Times, serif", fontWeight: "600" }}>Disclaimer: This document is a confidential and is explicitly reserved to QDegrees. QDegrees is only authorized to modify any clause mentioned in the document at any point of time.</p>

                                        <p style={{ paddingLeft: "2.5em", fontFamily: "cursive", fontWeight: "700" }}>For QDegrees Services Pvt. Ltd.</p>


                                        <p style={{ paddingLeft: "2.5em", fontWeight: "700" }}>Sonam Rara </p>
                                        <p style={{ paddingLeft: "2.5em", fontWeight: "700" }}>Head – Human Resource</p>
                                        <p style={{ paddingLeft: "2.5em", fontFamily: "monospace" }}>I have read and accept the terms and conditions:</p>

                                    </div>

                                </td>
                            </tr>
                        </table>

                        <tr>
                            <td>
                                <table>
                                    <tr>

                                        <td valign="middle" width="20%">
                                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                <tr>
                                                    <td className="counter-text">

                                                        <span className="name">Date of Joining</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td valign="middle" width="20%">
                                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                <tr>
                                                    <td className="counter-text">

                                                        <span className="name"><strong className="hr-color">{joiningDate && joiningDate}</strong> </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td valign="middle" width="20%">
                                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                <tr>
                                                    <td className="counter-text">

                                                        <span className="name">Signature</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td valign="middle" width="20%">
                                            <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                                <tr>
                                                    <td className="counter-text">

                                                        <span className="name"><strong className="hr-color">-------</strong> </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <td valign="middle" width="20%">
                                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                            <tr>
                                                <td className="counter-text">

                                                    <span className="name">Date </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>

                                    <td valign="middle" width="20%">
                                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                                            <tr>
                                                <td className="counter-text">

                                                    <span className="name"><strong className="hr-color">     <td><Moment format="YYYY/MM/DD">
                                                        {todayDate}
                                                    </Moment></td></strong> </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>


                                </table>
                            </td>
                        </tr>

                    </div>
                    {/* </div> */}
                </center >
            </body >
        </div >


    )
}
export default Ctc







