
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import Ctc from './ctc'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import OfferLater from './offer_later';

const SendOfferLaterModal = (props) => {
    const { isModalOpen, large, condidate } = props
    let api = new API()
    const [isCTCFullDetails, setIsCTCFullDetails] = useState(false)
    const [ctc, setCtc] = useState()
    const [ctcResult, setCtcResult] = useState()
    const [joiningDate, setJoiningDate] = useState()
    const [todayDate, setTodayDate] = useState()
    // const [large, setLarge] = useState(false)
    useEffect(() => {
        DateNow()
    }, [])

    // const isModalOpen = () => {
    //     setLarge(!large)
    // }
    const ctcFullDetails = () => {
        setIsCTCFullDetails(true)
        ctcCalculator()
    }

    const ctcCalculator = async () => {
        let data = {
            ctc: ctc
        }
        let result = await api.post(config.ctcGenerator, data)
        if (result && result.code == 200) {
            // console.log("ctc result is", result)

            setCtcResult(result.data.data)
        } else {
            // alert("something wrong")
            // console.log("something went wrong")
        }
    }
    const DateNow = () => {
        setTodayDate(Date.now())
        
    }

    return (
        <div className="">
            <CModal
                show={large}
                onClose={() => isModalOpen()}
                size="lg"
            >

                <CModalHeader closeButton>
                    <CModalTitle>CTC Calculator</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CForm action="" encType="multipart/form-data" className="form-horizontal">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Enter Gross</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={ctc} onChange={(e) => setCtc(e.target.value)} name="ctc" placeholder="Enter Gross" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Enter Joining Date</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} name="ctc" placeholder="Enter Joining Date" />
                            </CCol>
                        </CFormGroup>



                        {isCTCFullDetails &&
                            <div>
                                <OfferLater condidate={condidate} joiningDate={joiningDate} todayDate={todayDate}/>
                                <Ctc condidate={condidate} ctcResult={ctcResult} joiningDate={joiningDate} todayDate={todayDate}/>
                            </div>
                        }
                        {isCTCFullDetails == false &&
                            <CCardFooter>
                                <CButton type="button" onClick={ctcFullDetails} className="btn btn-primary mr-3 mt-2">Submit</CButton>

                            </CCardFooter>
                        }
                    </CForm>

                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={() => isModalOpen(!large)}>View</CButton>{' '}

                </CModalFooter>
            </CModal>
            {/* <button onClick={()=>setLarge(!large)}>modal</button> */}
        </div>

    )
}
export default SendOfferLaterModal







