import React from 'react';
import {
  convertDate,
  getFileExtension,
  getFileSize,
  getMessageType,
  getPreviewSupportStatus,
} from 'src/utils/common';
import appConstants from 'src/core/configs/Constants.config';
import { TextType, ImageType, VideoType, FileType } from './Subcomponents';

const Distributor = props => {
  const { message, user, toggleFilePreviewModal } = props;
  const isHisMsg = message.sender_id == user.id;
  let tmp;

  // if (data.from !== currentUserId) {
  //   tmp = user;
  // }

  const renderMessages = () => {
    const { payload } = message;
    const time = convertDate(message.createdAt, 'hh:mm A');
    const fileExt = getFileExtension(payload?.name || '');
    const fileSize = getFileSize(payload);
    const fileMimeType =
      payload?.type === 'text' ? 'MESSAGE_TEXT' : getMessageType(fileExt);
    const previewSupport = getPreviewSupportStatus(fileExt);

    const propsData = {
      message,
      user,
      isHisMsg,
      fileSize,
      fileExt,
      fileMimeType,
      time,
      previewSupport,
      toggleFilePreviewModal,
    };
    let element = null;
    console.log({ fileMimeType, fileExt, previewSupport });
    switch (fileMimeType) {
      case 'MESSAGE_TEXT':
        element = <TextType {...propsData} />;
        break;
      case 'IMAGE':
        element = <ImageType {...propsData} />;
        break;
      // case 'VIDEO':
      //   element = <VideoType {...propsData} />;
      //   break;
      default:
        element = <FileType {...propsData} />;
    }

    return element;
  };

  return (
    <div className={`msgContent ${isHisMsg ? 'message-out' : 'message-in'}`}>
      {!isHisMsg && (
        <div className="userimg">
          <img src="/images/logo.png" alt="" />
        </div>
      )}
      <div className="msgtextGroup">
        <div className="msgTime">
          {!isHisMsg && message?.conversation_id && (
            <span className="mr-2">{message?.sender?.name || ''}</span>
          )}
          <span>{convertDate(message.createdAt, 'hh:mm A')}</span>
        </div>
        <div className="msgText">{renderMessages()}</div>
      </div>
    </div>
  );
};

export default Distributor;
