import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import swal from 'sweetalert';
import {toast, ToastContainer} from 'react-toastify';

import {
    CRow,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CButton,
    CCol,
    CForm,
    CFormGroup,
    CLabel,
    CInput} from '@coreui/react'
import { City } from "country-state-city";
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';

const ModalUpdateCandidateInfo = (props) => {
  let api = new API();
    const { isModalOpen1, message, isViewModalOpen1, candidateDetails, setCandidateDetails, getCandidateSelectedInInterview } = props
    const [allCity, setAllCity] = useState();
    const [location, setLocation] = useState('');

    const getAllCity = () => {
        let allCity = City.getCitiesOfCountry("IN");
        setAllCity(allCity);
    };

    useEffect(() => {
        getAllCity();
      }, []);

      
    const onChangeInput = e => {
          const {name, value} = e.target;
          setCandidateDetails({
              ...candidateDetails,
              [name]: value,
            });
    }
        
    const saveLocation = e => {
            setLocation(e.value || candidateDetails?.location);
    };

    const updatecandidateDetail = async candidate => {
        let data = {
          candidate_id: candidate.id,
          mrf_id: candidate.mrf_id,
          mrf_applying_for: candidate.mrf_applying_for,
          name: candidate.name,
          email: candidate.email,
          mobile: candidate.mobile,
          location: location || candidate.location,
        };
        console.log(data)
        swal({
          html: true,
          title: 'Are you sure?',
          text: `To update candidate details`,
          icon: 'success',
          buttons: ['No, cancel it!', `Update`],
          dangerMode: true,
        }).then(async function(isConfirm) {
          if (isConfirm) {
            let result = await api.post(config.updateCandidateDetails, data);
            if (result && result.code === 200) {
              toast.success(result && result.message);
              getCandidateSelectedInInterview();
            }
          }
        });
      };

    return (
        <div className="">

            <CModal
                show={isModalOpen1}
                onClose={() => isViewModalOpen1()}
                size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                <CForm action="" encType="multipart/form-data" className="form-horizontal">

<CRow>
    <CCol md="12">

        <CFormGroup row>
            <CCol md="3">
                <CLabel >Candidate ID</CLabel>
            </CCol>


            <CCol xs="12" md="9">
                <CInput disabled  value={candidateDetails && candidateDetails.id} name="id" placeholder="" />
            </CCol>
        </CFormGroup>
        <CFormGroup row>
            <CCol md="3">
                <CLabel >Name</CLabel>
            </CCol>


            <CCol xs="12" md="9">
                <CInput value={candidateDetails && candidateDetails.name} onChange={(e) => onChangeInput(e)} name="name" placeholder="" />
            </CCol>
        </CFormGroup>
        <CFormGroup row>
            <CCol md="3">
                <CLabel >Email</CLabel>
            </CCol>


            <CCol xs="12" md="9">
                <CInput value={candidateDetails && candidateDetails.email} onChange={(e) => onChangeInput(e)} name="email" placeholder="" />
            </CCol>
        </CFormGroup>
        {/* <CFormGroup row>
            <CCol md="3">
                <CLabel >Designation</CLabel>
            </CCol>
            <CCol xs="12" md="9">
                <CInput value={candidateDetails && candidateDetails.designation} onChange={(e) => onChangeInput(e)} name="designation" placeholder="" />
            </CCol>
        </CFormGroup> */}
        <CFormGroup row>
            <CCol md="3">
                <CLabel >Mobile</CLabel>
            </CCol>

            <CCol xs="12" md="9">
                <CInput value={candidateDetails && candidateDetails.mobile} onChange={(e) => onChangeInput(e)} name="mobile" placeholder="" />
            </CCol>
        </CFormGroup>
        <CFormGroup row>
            <CCol md="3">
                <CLabel >Location</CLabel>
            </CCol>
            <CCol xs="12" md="9">
            {candidateDetails && (
            <Select
                    placeholder="Select Location..."
                    defaultValue={{
                        value: candidateDetails?.location,
                        label:
                       candidateDetails?.location ? (
                            candidateDetails?.location)
                           : 
                            (<div style={{ color: 'gray' }}>
                              --Select Location--
                            </div>)
                      }}
                      onChange={saveLocation}
                    options={allCity && allCity.length > 0 && allCity.map((city) => {
                        city.label = city.name
                        city.value = city.name
                        return city
                    })}
            />)}
            </CCol>
        </CFormGroup>



    </CCol>
</CRow>
</CForm>

                </CModalBody>
                <CModalFooter>
                    <CButton color="info" onClick={(e) => updatecandidateDetail(candidateDetails)}>Update</CButton>{' '}
                </CModalFooter>
            </CModal>




        </div>

    )
}
export default ModalUpdateCandidateInfo