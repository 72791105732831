/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { CModal, CModalBody } from '@coreui/react';
import { toast, ToastContainer } from 'react-toastify';
import appConstants from 'src/core/configs/Constants.config';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};
const ModalCaptureImage = props => {
  const { modalOpen, toggleModal, setImage } = props;
  const [captureImage, setCaptureImage] = useState('');
  const [isCamera, setIsCamera] = useState(true);
  const [isWebCamAvailable, setIsWebCamAvailable] = useState(false);

  useEffect(() => {
    checkMediaAndAccess();
  }, []);

  useEffect(() => {
    setCaptureImage(isWebCamAvailable ? '' : appConstants.noCam);
  }, [isWebCamAvailable]);

  const checkMediaAndAccess = () => {
    navigator.mediaDevices.enumerateDevices().then(devices =>
      devices.forEach(device => {
        console.log({ device });
        if (device.kind === 'videoinput') {
          setIsWebCamAvailable(true);
          setCaptureImage('');
        }
      }),
    );

    navigator.getMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    navigator.mediaDevices.getUserMedia(
      { video: true },
      function() {
        setIsCamera(true);
      },
      function(err) {
        setIsCamera(false);
        toast.warning('webcam is not available');
      },
    );
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptureImage(imageSrc);
    setImage(imageSrc);
  }, [webcamRef]);

  const captureBtn = async () => {
    capture();
    toggleModal(!modalOpen);
  };

  return (
    <div className="">
      <CModal
        show={modalOpen}
        onClose={() => toggleModal(!modalOpen)}
        size="md"
      >
        <CModalBody>
          {!isWebCamAvailable && (
            <div className="row">
              <div className="row text-center mt-3">
                <div className="col-md-12 mb-5">
                  <h3>Unable to detect your webcam</h3>
                </div>
              </div>
            </div>
          )}
          {isWebCamAvailable && (
            <>
              <div className="row">
                {!captureImage && (
                  <div className="col-md">
                    <div className="text-center" style={{ margin: 'auto' }}>
                      <h5 align="center" style={{ color: '#6f4e37' }}>
                        CAPTURE IMAGE
                      </h5>
                      {modalOpen && (
                        <>
                          {isCamera === true ? (
                            <Webcam
                              audio={false}
                              height={250}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              width={300}
                              videoConstraints={videoConstraints}
                            />
                          ) : (
                            <div className="mt-5">
                              <img
                                src={'images/cameranotavailable.png'}
                                alt="User Image"
                                height="172"
                                width="280"
                              ></img>
                              <h3 className="mt-2">Camera not available</h3>
                              <h5 className="mt-2">Login with your mobile</h5>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {captureImage && isCamera === true && (
                  <div className="col-md">
                    <div className="text-center" style={{ margin: 'auto' }}>
                      <h5 align="center" style={{ color: '#6f4e37' }}>
                        CAPTURE IMAGE
                      </h5>
                      <div className="mt-5">
                        <img
                          src={
                            captureImage && captureImage !== appConstants.noCam
                              ? captureImage
                              : 'images/capture.jpeg'
                          }
                          alt="User Image"
                          height="172"
                          width="280"
                        ></img>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row text-center mt-3">
                <div className="col-md-12">
                  <button
                    type="button"
                    align="center"
                    className="btn btn-link"
                    onClick={captureImage ? setCaptureImage('') : captureBtn}
                  >
                    {!captureImage ? (
                      <i className="fa fa-camera" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-repeat" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </CModalBody>
        {/* <CModalFooter>
          <CButton color="success" onClick={() => toggleModal(!modalOpen)}>Close</CButton>{' '}
        </CModalFooter> */}
      </CModal>
      <ToastContainer />
    </div>
  );
};
export default ModalCaptureImage;
