import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalTitle,
  CModalHeader,
  CModalFooter,
  CButton,
} from '@coreui/react';
import { convertDate } from 'src/utils/common';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast } from 'react-toastify';

const ViewLeaveDateModal = ({
  viewLaeveModal,
  handleLeaveModal,
  leaveData,
  getLeaveForApproval,
}) => {
  const api = new API();
  const [leaveIds, setLeaveIds] = useState();
  const [reason, setReason] = useState('');
  // const [selectAll, setSelectAll] = useState(false);
  const handleLeaveIds = (e, item) => {
    let newArr = leaveIds && leaveIds.length > 0 ? [...leaveIds] : [];
    if (e.target.checked) {
      newArr.push({ id: item?.id });
      setLeaveIds(newArr);
    } else {
      let arr =
        newArr &&
        newArr.length > 0 &&
        newArr.filter(leave => leave?.id != item?.id);
      setLeaveIds(arr);
    }
  };
  const approveAndRejectLeave = async type => {
    if (reason) {
      let payload = {
        type: 'leave',
        is_approved: type === 'approve' ? 'approve' : 'reject',
        id: 1, //id is fixed not in used
        comment: reason,
        correction_data: JSON.stringify(leaveIds),
      };

      let result = await api.post(config.approveRejectAttendance, payload);
      if (result && result.code === 200) {
        handleLeaveModal();
        getLeaveForApproval();
        setReason('');
        toast.success(result && result?.message);
      } else {
        toast.error(result && result?.message);
      }
    } else {
      toast.error('Please enter a reason');
    }
  };
  function allLeaveStatus(arr) {
    let status = '';
    let p = 0;
    let a = 0;
    let c = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].is_approve_status === 0 && arr[i].is_rejected_status === 0 && arr[i].is_cancel === 0) {
        p++;
      } else if (arr[i].is_approve_status === 0 && arr[i].is_rejected_status === 0 && arr[i].is_cancel === 1) {
        c++;
      } else {
        a++;
      }
    }
    if (arr.length === a) {
      return (status = 'Approved');
    } else if (arr.length === c) {
      return (status = 'Cancelled');
    } else {
      return (status = 'Pending');
    }
  }

  let status =
    leaveData &&
    (allLeaveStatus(leaveData && leaveData?.leaveDateArray) === 'Approved' || allLeaveStatus(leaveData && leaveData?.leaveDateArray) === 'Cancelled')
      ? true
      : false;
  const closeModal = () => {
    handleLeaveModal();
    setReason('');
  };
  return (
    <CModal
      show={viewLaeveModal}
      onClose={() => closeModal()}
      size="lg"
      className="mt-2"
    >
      <CModalHeader closeButton>
        <CModalHeader>Approve or Reject Leave(s)</CModalHeader>
      </CModalHeader>
      <CModalBody>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Leave Date</th>
              <th scope="col">Leave Type</th>
              <th scope="col">Leave Status</th>
              <th scope="col">
                {/* <input
                  type="checkbox"
                  onChange={() => setSelectAll(!selectAll)}
                ></input> */}
              </th>
            </tr>
          </thead>
          <tbody>
            {leaveData?.leaveDateArray.map((item, index) => (
              <tr key={item?.id}>
                <th scope="row">{index + 1}</th>
                <th>{leaveData?.employee_name}</th>
                <td>{convertDate(item?.leave_date, 'DD-MM-YYYY')}</td>
                <td>{item?.leave_type}</td>
                <td>{item?.leave_status}</td>
                <td>
                  {item?.is_approve_status == 1 ? (
                    <i
                      className="fa fa-check"
                      style={{ color: 'green' }}
                      aria-hidden="true"
                    ></i>
                  ) : item?.is_rejected_status == 1 ? (
                    <i
                      className="fa fa-times"
                      style={{ color: 'red' }}
                      aria-hidden="true"
                    ></i>
                  ) : item?.is_cancel == 1 ? (
                    <i
                      className="fa fa-times"
                      style={{ color: '#19789e' }}
                      aria-hidden="true"
                    ></i>
                  ) 
                  
                  : (
                    <input
                      type="checkbox"
                      onChange={e => handleLeaveIds(e, item)}
                      required
                    ></input>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <form>
          <div className="form-group">
            <label for="exampleFormControlTextarea1">Comment <span style={{color:"red"}}>*</span></label>
            <textarea
              onChange={e => setReason(e.target.value)}
              disabled={status}
              value={reason}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
        </form>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={status}
          className="btn btn-primary"
          onClick={() => approveAndRejectLeave('approve')}
        >
          Approve
        </CButton>
        <CButton
          disabled={status}
          onClick={() => approveAndRejectLeave('reject')}
          className="btn btn-danger"
        >
          Reject
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ViewLeaveDateModal;