import React, { useRef, useState, useEffect } from 'react';
import { CustLoader } from 'src/reusable';

const initialState = { loading: false };
let iframeInterval;

/**
 * @param {String} url
 * @returns {String}
 */
const getGoogleDocsViewerUrl = url => {
  return `https://docs.google.com/viewer?url=${url}&embedded=true`;
};

/**
 * @param {String} url
 * @returns {String}
 */
const getMSOfficeViewerUrl = url => {
  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
};

const IframeGoogleDocsViewerBase = props => {
  const [state, setState] = useState(initialState);
  const googleFrame = useRef(null);
  const { fileURL } = props;
  const { loading } = state;

  if (!googleFrame.current && !loading) {
    setState({ loading: true });
  }

  useEffect(() => {
    iframeInterval = setInterval(() => {
      updateIframeSrc();
    }, 4000);

    return () => {
      clearInterval(iframeInterval);
    };
  }, []);

  const updateIframeSrc = async () => {
    if (googleFrame && googleFrame.current) {
      googleFrame.current.src = getGoogleDocsViewerUrl(fileURL);
    }
  };

  const onFrameLoad = () => {
    clearInterval(iframeInterval);
    setState({ loading: false });
  };

  const onFrameError = err => {
    console.log('[GOOGLE-FRAME-ERROR]', err, JSON.stringify(err));
    updateIframeSrc();
  };

  return (
    <div>
      <iframe
        title="Google"
        ref={googleFrame}
        src={getGoogleDocsViewerUrl(fileURL)}
        onLoad={onFrameLoad}
        onError={onFrameError}
        style={{
          width: '45vw',
          height: '70vh',
          border: '0px',
        }}
      ></iframe>
      <CustLoader size="lg" isShow={loading} />
    </div>
  );
};

const IframeMicrosoftDocsViewerBase = props => {
  const [state, setState] = useState(initialState);
  const officeFrame = useRef(null);
  const { fileURL } = props;
  const { loading } = state;

  if (!officeFrame.current && !loading) {
    setState({ loading: true });
  }
  const onFrameLoad = () => {
    setState({ loading: false });
  };

  const onFrameError = err => {
    console.log('[MS-FRAME-ERROR]', err, JSON.stringify(err));
    setState({ loading: false });
  };
  return (
    <div>
      <iframe
        title="MS"
        ref={officeFrame}
        src={getMSOfficeViewerUrl(fileURL)}
        onLoad={onFrameLoad}
        onError={onFrameError}
        style={{
          width: '45vw',
          height: '70vh',
          border: '0px',
        }}
      ></iframe>
      <CustLoader size="lg" isShow={loading} />
    </div>
  );
};

const IframeTextViewerBase = props => {
  const [state, setState] = useState(initialState);
  const textFrame = useRef(null);
  const { fileURL } = props;
  const { loading } = state;

  if (!textFrame.current && !loading) {
    setState({ loading: true });
  }
  const onFrameLoad = () => {
    setState({ loading: false });
  };

  const onFrameError = err => {
    console.log('[TEXT-FRAME-ERROR]', err, JSON.stringify(err));
    setState({ loading: false });
  };
  return (
    <div>
      <iframe
        title="TEXT"
        ref={textFrame}
        src={fileURL}
        onLoad={onFrameLoad}
        onError={onFrameError}
        style={{
          width: '45vw',
          height: '70vh',
          border: '0px',
        }}
      ></iframe>
      <CustLoader size="lg" isShow={loading} />
    </div>
  );
};

const IframeGoogleDocsViewer = IframeGoogleDocsViewerBase;
const IframeMicrosoftDocsViewer = IframeMicrosoftDocsViewerBase;
const IframeTextViewer = IframeTextViewerBase;

export { IframeGoogleDocsViewer, IframeMicrosoftDocsViewer, IframeTextViewer };
