
import React, { useState, useEffect } from 'react'
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CButton} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'

import ViewSelectedCondidateDetails from '../viewSelectedCondidateData'
import ViewSelectedAssignedRequisition from '../viewSelectedAssignedRequisition';
const ModalPopup = (props) => {
    const { isOpen, candidate, isViewModalOpen, isPopupOpenViewAssignedRequisition, popupFor, assignedRequisition, modalHeader, mrfGetById} = props
 
    return (
        <div className="">
            {popupFor==="selectedCondidate" &&
            <CModal
                show={isOpen}
                onClose={() => isViewModalOpen()}
                size="xl"
            >

                <CModalHeader closeButton>
                    <CModalTitle>{modalHeader && modalHeader}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <ViewSelectedCondidateDetails candidate={candidate}  />
                </CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={() => isViewModalOpen(!isOpen)}><i className="fa fa-times" aria-hidden="true"></i>   </CButton>{' '}

                </CModalFooter>
            </CModal>
        }


{popupFor==="AssignedRequisition" &&
            <CModal
                show={isPopupOpenViewAssignedRequisition}
                onClose={() => isViewModalOpen()}
                size="xl"
            >

                <CModalHeader closeButton>
                    <CModalTitle>{modalHeader && modalHeader}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    < ViewSelectedAssignedRequisition assignedRequisition={assignedRequisition} mrfGetById={mrfGetById}/>
                </CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={() => isViewModalOpen(!isPopupOpenViewAssignedRequisition)}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}

                </CModalFooter>
            </CModal>
            }
        
            
        </div>

    )
}
export default ModalPopup







