
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile
} from '@coreui/react'
import './agreement_letter.css'
import Moment from 'react-moment';
import moment from 'moment';


const AgreementLetter = (props) => {
  const { candidate, joiningDate, todayDate } = props
  const [isCTCFullDetails, setIsCTCFullDetails] = useState(false)

  // const [large, setLarge] = useState(false)
  useEffect(() => {

  }, [])

  // const isModalOpen = () => {
  //     setLarge(!large)
  // }
  const ctcFullDetails = () => {
    setIsCTCFullDetails(true)
  }

  return (


    <div>
      <div className="main-div_aggregament_letter">
        <center>

          <div style={{maxWidth: "800px", minHeight:"600px", margin: "0 auto", backgroundColor: "white"}} className="email-container">
            <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{margin: "auto"}}>
              <tr>
                <td valign="top" className="bg_white" style={{padding: "1em 2.5em 0 2.5em"}}>
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td className="logo" style={{textAlign: "center"}}>
                        <img src="images/qdegrees.png" alt="" />
                      </td>
                    </tr>
                  </table>
                  <hr NameName="line_rating" />
                </td>
              </tr>
            </table>
            <table style={{fontSize: "13px", color: "#000", fontFamily: "'Work Sans', sans-serif"}}>
              <tr>
                <td>
                  <div className="text" style={{padding: "0 2.5em", textAlign:"left", padding: "10px", margin: "10px"}}>
                    <ol style={{fontSize: "15px", fontFamily: "'Work Sans', sans-serif"}}>

                      <li><b>THIS AGREEMENT</b> made as of the 3rd May, 2021, which will be valid from 3rd May 2021 to 6th December 2021 between QDegrees Services Private Limited, a company incorporated under the Companies Act, 2013, and having its office at B-9, 1stFloor, Mahalaxmi Nagar, Behind WTP South Block, Jaipur, Rajasthan, India - 302017</li>
                      <li>(here in after referred to as “QDegrees”, which expression shall, unless it be repugnant to the context or meaning there of, be deemed to mean and include its successors and permitted assigns) of the First Part AND Mr. <span style={{color:"lightblue"}}>{candidate && candidate.name}</span>,an individual residing at Address<br />
                        <br />
                        Both QDegrees and Consultantare individually referred to as “party” and collectively as “parties”. </li>
                      <li>3.	WHERE AS QDegrees desires to obtain the benefit of the services of ............., and ............. desires to render such services on the terms and conditions set forth.</li>
                      <li>IN CONSIDERATION of the promises and other good and valuable consideration (the sufficiency and receipt of which are hereby acknowledged) the parties agree as follows:</li>
                      <ol type="a">

                        <li>Services:

                          .............agrees that he will at all times faith fully, industriously, and to the best of his skill, ability, experience and talents, perform all of the duties expected from his engagement. In carrying out these duties and responsibilities, ............. shall comply with all QDegrees policies, procedures, rules and regulations, both written and oral, as are announced by QDegrees from time to time. It is also understood and agreed bythat .............’s section, department, location, assignment, duties and responsibilities and reporting arrangements may be changed by QDegrees in its sole discretion without causing termination of this agreement.
                        </li>
                        <li>Position /Title:
                          As a Consultant, ............. is required to perform the duties and responsibilities (KRA/KPI) given by his reporting manager in a professional manner with high standard of efficiency and effectiveness.
                        </li>
                        <li>Compensation</li>
                        <ol type="i" >
                          <li>As full compensation for all services provided, ............. shall be paid at the rate of Indian Rupees Forty Two Thousand only every month (INR 42000 per month). Such payments shall be subject to statutory deductions by QDegrees, as applicable from time to time</li>
                          <li>The pay-out, as mentioned in paragraph (c)(i) shall be reviewed on an annual basis.</li>
                          <li>.............shall furnish all relevant documents / papers like PAN, Aadhar, previous organization’s relieving / clearance letters, etc. as required by QDegrees from time to time for legally validating, enforcing and abiding by thisagreement.</li>
                        </ol>
                        <li>Termination</li>
                        <ol type="i" >
                          <li>.............may at any time terminate this agreement and his services by giving not less than one month written notice or payment in lieu of notice to QDegrees.</li>
                          <li>QDegrees may terminate this Agreement and the .............’s engagement at any time, with one month notice or payment in lieu of notice, for sufficient cause.</li>
                          <li>.............agrees to return any property of QDegrees at the time of termination of this contract, which includes proprietary information which he may have acquired during the assignment.</li>
                        </ol>
                        <li>Code ofConduct <br />
                          This agreement is governed by the Code of Conduct policy as laid down in Annexure A attached here with.
                        </li>
                        <li>Non-Competition</li>
                        <ol type="i">
                          <li>It is further acknowledged and agreed that following termination of the .............’s engagement with QDegrees for any reason, ............. shall not hire or attempt to hire any current employee(s) of QDegrees.</li>
                          <li>It is further acknowledged and agreed that following termination of .............’s engagement with QDegrees for any reason, .............shall not solicit business from QDegrees current clients or clients whohave
                            <br />
                            retained............. in the six month period immediately preceding this agreement’s termination.
                          </li>
                        </ol>

                        <li>This agreement shall be governed by Laws of India and be subject to jurisdiction of Jaipur courtsonly.</li>
                        <li>Independent Legal Advice
                          <br />
                          .............acknowledges that QDegrees has provided ............. with a reasonable opportunity to obtain independent legal advice with respect to this agreement, and that either:
                        </li>
                        <ol type="a" >
                          <li>............. has had such independent legal advice prior to executing this agreement,or;</li>
                          <li>............. has willingly chosen not to obtain such advice and to execute this agreement without having obtained such advice</li>
                        </ol>
                      </ol>
                      <ol type="i" >
                        <li>Entire Agreement <br />
                          This agreement contains the entire agreement between the parties, superseding in all respects any and all priororal or written agreements or understandings pertaining to the engagement of ............. by QDegrees and shall be amended or modified only by written instrument signed by both of the parties here to. </li>
                        <ol type="a">
                          <li>Sever ability <br />
                            The parties here to agree that in the event any article or part thereof of this agreement is held to be unenforceable or invalid then said article or part shall be struck and all remaining provision shall remain in full force and effect.</li>
                        </ol>
                        <p>IN WITNESS WHERE OF QDegrees has caused this agreement to be executed by its duly authorized officers / signatories and ............. has set his hand as of the date first above written.

                          SIGNED, SEALED AND DELIVERED in the presence of: For QDegrees Services Pvt. Ltd.
                          [Saurabh Sharma -Director]

                          [.............]
                        </p>
                      </ol>
                    </ol>
                    <h4 style={{fontSize: "14px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif", textAlign: "center"}}><u>ANNEXURE – A – CODE OF CONDUCT</u></h4>

                    <p style={{fontSize: "13px", fontWeight: "bolder", fontFamily: "'Work Sans', sans-serif"}}>Index</p>
                    <ul>
                      <li>Accountability</li>
                      <li>HSW Policy</li>
                      <li>Conflict of Interest.</li>
                      <li>Information Confidentiality.</li>
                      <li>Company Assets</li>
                      <li>Expenses Report.</li>
                      <li>General Code of Conduct.</li>
                      <li>Sexual Harassment.</li>
                      <li>Mis conduct</li>
                      <li>Abandonment of Service</li>
                      <li>Other Business</li>
                      <li>Non Performance</li>
                      <li>Dress-Code Policy.</li>
                      <li>Channels of Communication.</li>
                      <li>Breach of theCode.</li>
                    </ul>

                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Accountability- <br />
                      We at QDegrees believe to operate our business in the most ethical and professional way and expect our consultant to-</p>
                    <ul>
                      <li>Deal on behalf of the Company with professionalism, honesty, integrity as well as high moral and ethical standards.</li>
                      <li>Never compromise our values, no matter how strong the internal or external pressure may be to perform, meetgoals.</li>
                      <li>Be a role model by communicating with others and acting in a manner consistent with our corevalues.</li>
                      <li>Adhere, Adopt, and follow the practices in business ethics. Many of these practices attract legal requirements.</li>
                      <li>Violation of laws can cause significant damage to an individual or the company thus a consultant should seriously carry accountability and comply.</li>
                    </ul>

                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>HSW Policy- </p>
                    <p>This is the statement of general policy and arrangements for all our consultant emphasizing in their health and safety. This will be a Day-to-day responsibility for ensuring this policy is put into practice, and non-adherence to the HSW policy would lead to strict action and might call for an end of the relationship with immediate effect.</p>
                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Essentials while driving/riding-</p>
                    <ul>
                      <li>Every consultant is required to wear helmet if he/she is on a two-wheeler.</li>
                      <li>Every consultant is required to have the seat belt of the car fasten while driving.</li>
                      <li>Avoid the use of cell phones while driving/riding a vehicle.</li>
                      <li>Avoid over speeding ofvehicles.</li>
                      <li>Follow the traffic rules while driving/riding vehicle.</li>
                    </ul>

                    <p style={{fontSize: "13px", fontweight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Conflict of Interest- A conflict of interest occurswhen-</p>
                    <ul>
                      <li><b>Personal interest-</b>The personal interest of any member of the Board of Directors or the Senior Management / consultant interferes or appears to interfere in any way with the interest of the Company. Although this duty does not prevent them from engaging in personal transactions and investments, it does demand that they avoid situations where conflict of interest might occur or appear tooccur.</li>
                      <li><b>Involvement/Interference-</b>The Non-Executive Directors and the Senior Management / Consultant are expected to devote their attention to the business interest of the Company. They are prohibited from engaging in any activity that interferes with their performance or responsibilities to the Company or otherwise is in conflict with or prejudicial to theCompany.</li>
                      <li><b>Business Interests-</b>If any member of the Board of Directors or the Senior Management / Consultant considers investing in securities issued by the Company's customer, supplier or competitor, they should ensure that these investments do not compromise their responsibilities to the Company. Many factors including the size and nature of the investment; their ability to influence the Company's decisions, their access to confidential information of the Company, or of the other entity, and the nature of the relationship between the Company and the customer, supplier or competitor should be considered in determining whether a conflict exists. Additionally, they should disclose to the Company any interest that they have which may conflict with business of theCompany.</li>
                    </ul>

                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Information Confidentiality-</p>
                    <ul>
                      <li>Consultant come across a number of confidential information which may take many forms. They must take proper care of such information and ensure that it is not misused in any way which is detrimental to the Company’s business or used for own commercial benefit. They must also not disclose actions or activities relating to company’s business operations to outsiders, unless they concern or impact them.</li>
                      <li>Not to disclose business strategies / operations to any one.</li>
                      <li>Not to take away critical document/information either physically or through emails.</li>
                      <li>Company's Email, Internal and Intranet are primarily use for company's business and not for personalgains.</li>
                      <li>o	The Company has procedure for entering into confidentiality agreements with various vendors, which shall be executed before any information is shared with them. A Consultant is expected to accept only such information that is necessary to accomplish the purpose of receiving it, and not for any otherpurpose.</li>
                    </ul>
                    <p style={{fontSize: "13px", fontWeight:"bolder",  fontFamily: "'Work Sans', sans-serif"}}>Company’s Assets-</p>
                    <ul>
                      <li>Ensure use of facilities and amenities provided to him/her by the company with care.</li>
                      <li>Return the company property while disassociating with the company</li>
                    </ul>

                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Expenses Report-</p>
                    <ul>
                      <li>Each Consultant will conduct Company's business with integrity, in compliance with applicable laws and rules, and in a manner, that excludes personalgain.</li>
                      <li>As per policy, a Consultant would be reimbursed for expenses that are necessary and incurred when travelling and/or entertaining on authorized Company business.</li>
                      <li>It is the responsibility of the consultant to submit timely and accurate expense reports in compliance with applicable policy.</li>
                      <li>Those authorized to approve expense reports are charged with ensuring that expenses submitted are in full accordance with the Company's policies, procedures and guidelines.</li>
                      <li>Failure to file an expense report to account for Company funds or submitting or approving false information can result in strict disciplinary action.</li>
                    </ul>

                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>General Code of Conduct-</p>
                    <ul>
                      <li>Good judgment, to ensure the interests, safety and welfare of customers, dealers, employees, consultant, and other stake holders and to maintain a co-operative, efficient, positive, harmonious and productive work environment.</li>
                      <li>While discharging duties a consultant must act honestly and with due diligence, which an ordinary person is expected to behave in his/her own business.</li>
                      <li>These standards need to be applied while working in the premises of the Company, at off-site locations where the business is being conducted whether in India or abroad, at Company-Sponsored business and social events, or at any other place whether they act as representatives of the Company.</li>
                      <li>To meet our responsibilities to employees, consultants, customers and investors, the Company must maintain a healthy and productive work environment. Substance abuse, or selling, manufacturing, distributing, possessing, using or being under the influence of illegal drugs and alcohol while at work is absolutely prohibited.</li>
                      <li>Maintain and help the Company in maintaining highest degree of Corporate Governance Practices.</li>
                      <li>Shall not communicate with any member of press or publicity media or anyother outside agency on matters concerning the Company, except through the designated spokes persons or otherwise authorized.
                      </li>
                    </ul>
                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Policy for bribery-</p>

                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>It is our policy to conduct all our business in an honest and ethical manner. We are committed to acting professionally, fairly & with integrity. This includes a prohibition against both direct bribery and indirect bribery.</p>
                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Definition of bribery- Payments in-kind including any non-cash items of value such as travel, hospitality, entertainment, employment opportunities, and gifts are considered as bribe.</p>
                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Consultants must ensure that they have read and understood this policy and, must at all times comply with the terms and conditions of this policy.</p>

                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Sexual Harassment- Sexual harassment would mean and include any of the following-</p>
                    <ul>
                      <li>Unwelcome sexual advances, requests or demand for sexual favors, either explicitly or implicitly, in return for employment, promotion, examination or evaluation of a person towards any company activity.</li>
                      <li>Eve teasing, innuendos and taunts, physical confinement against one’s will and likely to intrude upon one’s privacy</li>
                      <li>Act or conduct by a person in authority which creates the environment at workplace hostile or intimidating to a person belonging to the other sex.</li>
                    </ul>
                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Misconduct--</p>
                    <ul>
                      <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>&nbsp;&nbsp;If, at any time, an consultant is found to be guilty of any misconduct, the Management may impose the following, singly or in combination.</p>
                      <li>Issuing warning letter</li>
                      <li>Disassociation – Relationship with the consultant shall come to end and stand terminated with effect from the date of disassociation.</li>
                    </ul>
                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Abandonment of Service</p>
                    <ul><p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>If a consultant absents himself without prior permission and / or prior sanction of leave or overstays beyond the period of leave originally grantedor subsequently extended for 10 or more working days, he will be deemed to have voluntarily abandoned service. The consultant will be given an opportunity to explain hisabsence
                      / overstay. The decision taken by the Management subsequently will be Communicated to the consultant.
                    </p>
                      <p>You must inform the management regarding your intention of disassociation 60 days in advance and have to serve the transition period for 60 days.Failure to do so will initiate a legal action and other process as appropriate.</p>
                    </ul>
                    <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>Other Business, etc.</p>
                    <ul>
                      <p style={{fontSize: "13px", fontWeight: "bolder",  fontFamily: "'Work Sans', sans-serif"}}>No consultant, while consultant with the company, can accept any work or service, direct or indirect, honorary or otherwise, without previous written permission from the Management. No consultant shall carry on any business on his own account while he/she is consultant with the Company. <br />
                        No consultant shall have any trade or business interest with anyone, who in turn have connection with the Company as suppliers, dealers, agents or contractors, without prior consent or approval in writing by the management.
                      </p>
                    </ul>

                    <p style={{fontSize: "13px", color: "#000", fontFamily: "'Work Sans', sans-serif"}}><b>Conflict of Interest- A conflict of interest occurswhen-</b></p>
                    <p>If you fail, refuse or neglect to carry out and perform your duties assigned to you by the company.</p>
                    <p>For loss of confidence in you by the company for any of the act committed by you</p>
                    <p>If you are found to be guilty of fraud, insubordination or Misconduct whether in course of performance of duties entrusted to you or otherwise.</p>
                    <p>If you are found unfit for being entrusted with the responsible work commensurate with your position in consequences of any misconduct, moral turpitude.</p>
                    <p>If you commit any act prejudicial to the continuing good relationship between you and the company.</p>
                    <p>If you become physically in capacitated for the performance of your duties. If you commit breach of any of the terms of this letter of appointment.</p>
                    <p style={{fontSize: "13px", color: "#000", fontFamily: "'Work Sans', sans-serif"}}>Dress Code Policy-</p>

                    <div className="dresscodet">
                      <table className="derssc">
                        <tr className="tablerow">
                          <th className="tablehead" >Days</th>
                          <th className="tablehead">Dress Code For Male Consultants</th>
                          <th className="tablehead">Dress Code For Female Consultants</th>
                        </tr>
                        <tr className="tablerow">
                          <td className="tabledata">Monday-Thursday</td>
                          <td className="tabledata">Friday & Saturday</td>
                          <td className="tabledata">Not Allowed</td>
                        </tr>
                        <tr className="tablerow">
                          <td className="tabledata">Business Formals, Smart Casuals, Denims.</td>
                          <td className="tabledata">Round Neck T-shirts, Smart Casuals, Denims.</td>
                          <td className="tabledata">T-Shirts with obscene or provoking slogans, Sleeveless T- shirts, Rubber slippers, Floaters.</td>
                        </tr>
                        <tr className="tablerow">
                          <td className="tabledata">Business Formals, Smart Casuals, Denims.</td>
                          <td className="tabledata">Round Neck T-shirts, Capree, Smart Casuals, Denims.</td>
                          <td className="tabledata">T-Shirts with obscene or provoking slogans, No Noddle strap, sheer tops, crop tops, racer tops, Rubber slippers, Bathroom slippers</td>
                        </tr>
                      </table>
                    </div>
                    <p style={{fontSize: "13px", color: "#000", fontFamily: "'Work Sans', sans-serif"}}>Channels of Communications-</p>
                    <p>To assist you with seeking advice or reporting concerns, the firm has established several “channels of communication.” These are formal systems designed to ensure that your question or concern is addressed completely and accurately. You are free to choose the channel you are most comfortable with while raising a concern or seeking an advice.</p>

                    <ul>
                      <li>HOD's</li>
                      <li>Director</li>
                    </ul>

                    <p style={{fontSize: "13px", color: "#000", fontFamily: "'Work Sans', sans-serif"}}>Breach of Code-</p>
                    <p>The Company intends to prevent the occurrence of conduct not in compliance with this Code of Conduct, applicable laws and other policies, procedures, and guidelines prepared by the Company. The allegations of non-compliance or reported violations with the Code of Conduct will be investigated whenever necessary and evaluated at proper level(s). Those found to be in violation of this Code of Conduct are subject to appropriate actions up to and including immediate disassociation / future business with the company. Criminal misconduct may be referred to the appropriate legal authorities as per Law.</p>

                    <li>Name of the consultant: <span style={{color:"lightblue"}}>{candidate && candidate.name}</span></li>
                    <li>Signature:_______________</li>
                    <li>Date: <span style={{color:"lightblue"}}>{moment().format("DD-MM-YYYY")}</span></li>
                    <li>Place: <span style={{color:"lightblue"}}>{candidate && candidate.Location}</span></li>

                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                      <hr className="line_rating" />
                      <tr>
                        <td className="bg_light" style={{textAlign: "center"}}>

                          <tr>
                            <td className="text-footer1 pb10" style={{color:"#000", fontFamily: "'Work Sans', sans-serif", fontSize:"14px", lineHeight:"20px", textAlign:"center", paddingBottom:"10px"}}>© Copyright (2021)Powered by<a href="https://www.qdegrees.com/">QDegrees</a></td>
                          </tr>
                          <tr>
                            <td className="text-footer2 pb20" style={{color:"#000", fontFamily: "'Work Sans', sans-serif", fontSize:"12px", lineHeight:"26px", textAlign:"center", paddingBottom:"20px"}}>B-9, 1st Floor, Mahalaxmi Nagar, Behind WTP South Block, Malviya Nagar, Jaipur, Rajasthan (302017)</td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </div>
    </div>



  )
}
export default AgreementLetter







