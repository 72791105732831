
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import ViewSelectedComponents from '../common/viewSelectedComponents'




const ModalCandidate = (props) => {
    const { condidateFullDetails, isModalOpen, message, candidateDetails } = props

    // const [isModalOpen, isModalOpen] = useState(false)
    useEffect(() => {

    }, [])
    // const isModalOpen = () => {

    // }

    return (
        <div className="">

            <CModal
                show={isModalOpen}
                onClose={() => condidateFullDetails()}
                size="xl"
            >

                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>

                    
                    <ViewSelectedComponents 
                    candidateDetails={candidateDetails}/>
                    
                



                </CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={() => condidateFullDetails()}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}

                </CModalFooter>
            </CModal>




        </div>

    )
}
export default ModalCandidate







