import swal from 'sweetalert';
export const sweetAlertFun = async (text, icon, title = 'Are you sure?') => {
  let isConfirm = await swal({
    html: true,
    title: title,
    text: text,
    icon: icon,
    buttons: ['No, cancel it!', 'Submit'],
    dangerMode: true,
  });
  let finalResponse = isConfirm ? true : false;
  return finalResponse;
};

export const swalWithTextarea = async (text, icon, title = 'Are you sure?') => {
  var textarea = document.createElement('textarea');
  textarea.rows = 5;
  textarea.className = 'swal-content__textarea';

  // Set swal return value every time an onkeyup event is fired in this textarea
  textarea.onkeyup = function() {
    swal.setActionValue({
      confirm: this.value,
    });
  };

  const res = await swal({
    content: textarea,
    title: title,
    text: text,
    icon: icon,
    buttons: {
      cancel: {
        text: 'Cancel',
        visible: true,
      },
      confirm: {
        text: 'Submit',
        closeModal: true,  
      },
    },
  });
  return typeof res === 'boolean' ? '' : res;
};
