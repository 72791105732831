import React, { useEffect } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
} from '@coreui/react';
import { CustButton } from 'src/reusable';

const InitCallInfoModal = props => {
  const { open, handleCancel, callInfoData } = props;

  useEffect(() => {}, []);

  return (
    <div className="">
      <CModal show={open} onClose={handleCancel} size="md">
        <CModalHeader>
          <CModalTitle>Call Information</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {/* <CButton
            className="float-right"
            color="danger"
            onClick={() => handleCancel()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton> */}
          <div>
            <p>
              The call to the candidate has been successfully initiated, and you
              need to dial the number that is provided in order to connect with
              them
            </p>
            <p>
              Dial the Number <b>{callInfoData?.access_number || 'N/A'}</b> from{' '}
              <b>{callInfoData?.agent_number || 'N/A'}</b> mobile number
            </p>
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Close"
            color="danger"
            onClick={handleCancel}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={false}
            isLoading={false}
          />
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default InitCallInfoModal;
