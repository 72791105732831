import {
  take,
  put,
  call,
  all,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { mqttActions as actions, mqttService, mqttSelectors } from './index';
import { messageActions, messageSelectors } from '../messages';
import { getStore } from 'src/core/store';
import {
  getLocalDataAsObject,
  setLocalDataAsObject,
} from 'src/utils/CoustomStorage';
// import { playSoundByName, removeAudio } from 'src/core/managers/soundsManager';

// const socket = mqttService.socketConnect();

function* handleMqttClientInstanceRequest() {
  const action = yield take(actions.mqttClientInstanceRequest.getType());
  const dataParams = action.payload;

  try {
    const store = getStore();
    const user = getLocalDataAsObject('user');
    // const client = yield call(mqttService.mqttConnect);
    // yield put(actions.mqttClientInstance(client));

    //const client = yield select(mqttSelectors.getMqttClientInstance);

    const socket = yield call(mqttService.socketConnect);
    console.log('--handleMqttClientInstanceRequest--', socket);

    socket.on('connect', reason => {
      console.log('--socket connect--', socket.connected);
      if (socket.connected) {
        store.dispatch(
          actions.mqttClientConnectStatus({ status: 200, text: 'Connected' }),
        );

        if (user) {
          // get unread msg counts
          store.dispatch(
            messageActions.getUnreadMessageCountsRequest({
              user_id: user?.length ? user[0].id : null,
            }),
          );

          // subscribe socket topics
          const stateData = store.getState();
          const friendsList =
            stateData?.messages?.getConnectedUsersRes?.data || [];
          const groupssList =
            stateData?.messages?.getConnectedGroupsRes?.data || [];
          console.log({ friendsList, groupssList, stateData });
          if (friendsList?.length) {
            friendsList.map((item, key) => {
              store.dispatch(
                actions.mqttTopicSubscribe({ topic: item._id, qos: 1 }),
              );
            });
          }
          if (groupssList?.length) {
            groupssList.map((item, key) => {
              store.dispatch(
                actions.mqttTopicSubscribe({ topic: item._id, qos: 1 }),
              );
            });
          }
        }
      }
    });

    socket.on('disconnect', reason => {
      console.log('--socket disconnect--', reason);
      store.dispatch(
        actions.mqttClientConnectStatus({
          status: 201,
          text: 'Reconnecting',
        }),
      );

      const reJoinReasons = ['transport close'];
      if (reJoinReasons.indexOf(reason) !== -1) {
      }
      //window.location.reload();
    });
    socket.on('message', message => {
      console.log('--on message--', message);

      let parsedData = message?.payload;
      if (parsedData) {
        const user = getLocalDataAsObject('user');
        //parsedData = JSON.parse(message.toString());

        // set latest msg paylod
        const userId = user?.length ? user[0].id : null;
        console.log({ parsedData, userId }, parsedData?.sender_id != userId);
        if (parsedData?.data.sender_id != userId) {
          store.dispatch(actions.mqttClientOnMessage(parsedData));
        }

        // get unread msg counts
        store.dispatch(
          messageActions.getUnreadMessageCountsRequest({
            user_id: user?.length ? user[0].id : null,
          }),
        );
      }
    });
    // if (client) {
    //   // https://github.com/mqttjs/MQTT.js#event-connect
    //   client.on('connect', res => {
    //     console.log('[mqtt] Connection successfull', res);
    //     store.dispatch(
    //       actions.mqttClientConnectStatus({ status: 200, text: 'Connected' }),
    //     );

    //     // get unread msg counts
    //     const user = getLocalDataAsObject('user');
    //     store.dispatch(
    //       messageActions.getUnreadMessageCountsRequest({
    //         user_id: user?.length ? user[0].id : null,
    //       }),
    //     );
    //   });

    //   // https://github.com/mqttjs/MQTT.js#event-error
    //   client.on('error', err => {
    //     console.log('[mqtt] Connection error: ', err);
    //     store.dispatch(
    //       actions.mqttClientConnectStatus({
    //         status: 204,
    //         text: 'Connection Error',
    //       }),
    //     );
    //     client.end();
    //   });

    //   // https://github.com/mqttjs/MQTT.js#event-reconnect
    //   client.on('reconnect', () => {
    //     console.log('[mqtt] Reconnection');
    //     store.dispatch(
    //       actions.mqttClientConnectStatus({
    //         status: 201,
    //         text: 'Reconnecting',
    //       }),
    //     );
    //   });

    //   // https://github.com/mqttjs/MQTT.js#event-message
    //   client.on('message', (topic, message) => {
    //     console.log('--mqtt on saga---', {
    //       topic,
    //       message,
    //       s: message.toString(),
    //     });
    //     let parsedData = null;
    //     if (message?.length) {
    //       const user = getLocalDataAsObject('user');
    //       parsedData = JSON.parse(message.toString());

    //       // set latest msg paylod
    //       const userId = user?.length ? user[0].id : null;
    //       if (parsedData?.data.sender_id != userId) {
    //         store.dispatch(actions.mqttClientOnMessage(parsedData));
    //       }

    //       // get unread msg counts
    //       store.dispatch(
    //         messageActions.getUnreadMessageCountsRequest({
    //           user_id: user?.length ? user[0].id : null,
    //         }),
    //       );
    //     }
    //   });
    // }
  } catch (error) {
    yield put(actions.mqttClientInstance(null));
  }
}

function* mqttDisconnect() {
  const client = yield select(mqttSelectors.getMqttClientInstance);
  if (client) {
    try {
      client.end(false, () => {
        //setConnectStatus('Connect');
        put(actions.mqttClientInstance('disconected'));
        console.log('disconnected successfully');
      });
    } catch (error) {
      console.log('disconnect error:', error);
    }
  }
}

// publish message
function* handleMqttPublish() {
  const action = yield take(actions.mqttPublish.getType());
  const dataParams = action.payload;

  //const client = yield select(mqttSelectors.getMqttClientInstance);
  const client = window.gluepleSocket;
  console.log('--handleMqttPublish--', { client, dataParams });

  if (client) {
    client.emit('publishMessage', {
      payload: dataParams,
      topic: dataParams.topic,
    });

    // // topic, QoS & payload for publishing message
    // const { topic, qos, payload } = dataParams;
    // client.publish(topic, payload, { qos }, error => {
    //   console.log('Publish Res: ', error);
    //   if (error) {
    //     console.log('Publish error: ', error);
    //   }
    // });
  }
}

function* handleMqttTopicSubscribe() {
  const action = yield take(actions.mqttTopicSubscribe.getType());
  const dataParams = action.payload;

  //const client = yield select(mqttSelectors.getMqttClientInstance);
  const client = window.gluepleSocket;

  if (client) {
    client.emit('subscribeTopic', dataParams.topic);

    // // bulk topics subscribe
    // client.subscribe({...dataParams.topics}, (error, granted) => {
    //   console.log(`[saga] Subscribe to topics:`, granted)
    //   if (error) {
    //     console.log('[saga] Subscribe to topics error', error);
    //     return;
    //   }
    // });

    // // single topics subscribe
    // const { topic, qos } = dataParams;
    // // subscribe topic
    // client.subscribe(topic, { qos }, error => {
    //   if (error) {
    //     console.log('[saga] Subscribe to topics error', error);
    //     return;
    //   }
    //   console.log(`[saga] Subscribe to topics: ${topic}`);
    // });
  }
}

// unsubscribe topic
function* mqttUnSub(subscription) {
  const client = yield select(mqttSelectors.getMqttClientInstance);
  if (client) {
    const { topic, qos } = subscription;
    client.unsubscribe(topic, { qos }, error => {
      if (error) {
        console.log('Unsubscribe error', error);
        return;
      }
      console.log(`unsubscribed topic: ${topic}`);
    });
  }
}

function* handleMqttClientInstanceRequestWatcher() {
  yield takeEvery(
    actions.mqttClientInstanceRequest,
    handleMqttClientInstanceRequest,
  );
}

function* handleMqttPublishWatcher() {
  yield takeEvery(actions.mqttPublish, handleMqttPublish);
}

function* handleMqttTopicSubscribeWatcher() {
  yield takeEvery(actions.mqttTopicSubscribe, handleMqttTopicSubscribe);
}

function* rootMqttSaga() {
  yield all([
    handleMqttClientInstanceRequest(),
    handleMqttClientInstanceRequestWatcher(),
    handleMqttPublish(),
    handleMqttPublishWatcher(),
    handleMqttTopicSubscribe(),
    handleMqttTopicSubscribeWatcher(),
  ]);
}

export default rootMqttSaga;
