import React from 'react';

const TimeDropdown = ({ selectedHour, selectedMinute, onSelectTime }) => {
  const hours = Array.from({ length: 10 }, (_, i) => i);
  const minutes = Array.from({ length: 60 / 15 }, (_, i) => i * 15);

  return (
    <div>
      <select
        style={{
          padding: '0.375rem 0.75rem',
          width: '92px',
          border: '1px solid #ced4da',
          borderRadius: '0.375rem',
          height: 'calc(1.5em + 0.75rem + 2px)',
          fontSize: '15px',
        }}
        value={selectedHour}
        onChange={onSelectTime('hour')}
      >
        <option hidden>Hours</option>
        {hours.map(hour => (
          <option key={hour} value={hour}>
            {hour < 10 ? `0${hour}` : hour}
          </option>
        ))}
      </select>
      &nbsp;
      <select
        value={selectedMinute}
        onChange={onSelectTime('minute')}
        style={{
          padding: '0.375rem 0.75rem',
          width: '104px',
          border: '1px solid #ced4da',
          borderRadius: '0.375rem',
          height: 'calc(1.5em + 0.75rem + 2px)',
          fontSize: '15px',
        }}
      >
        <option hidden>Minutes</option>
        {minutes.map(minute => (
          <option key={minute} value={minute}>
            {minute < 10 ? `0${minute}` : minute}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeDropdown;
