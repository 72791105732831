import { take, put, call, all, takeEvery } from 'redux-saga/effects';
import { leaveDateAction as actions, leaveDateServices } from './index';
import moment from 'moment';

function* handleLeaveDetailForEmployee() {
  // const action = yield take(actions.getAllLeaveDateWithinDateRange.getType());

  // const dataParams = action.payload;
  const dataParams = { 
    from: moment().startOf('month').format("YYYY-MM-DD"), 
    to: moment().endOf('month').format("YYYY-MM-DD") };
  let res;
  try {
    res = yield call(leaveDateServices.getAllLeaveDate, dataParams);

    if (res.isError) {
    } else {
      yield put(actions.getAllLeaveDateWithinDateRangeSuccess(res));
    }
  } catch (error) {
    //   yield put(actions.getAttendanceBasicDetailsError());
  }
}

function* handleGetLeaveDatesWatcher() {
  yield takeEvery(
    actions.getAllLeaveDateWithinDateRange,
    handleLeaveDetailForEmployee,
  );
}

function* rootLeaveData() {
  yield all([handleLeaveDetailForEmployee(), handleGetLeaveDatesWatcher()]);
}

export default rootLeaveData;
