import React from 'react';

const NoRecords = props => {
  const { message, colSpan } = props;
  return (
    <tr>
      <td className="text-center" colSpan={colSpan || ''}>
        {message || <b style={{color:"red",fontSize:"20px"}}>No Records Found</b>}
      </td>
    </tr>
  );
};

export default NoRecords;
