import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody
} from '@coreui/react'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'
import ViewSelectedInProgress from './viewSelectedInProgress';
import Moment from "react-moment";
import {StatusDropdownMenu} from 'src/subcomponents';

const InProgress = (props) => {
    const { } = props
    let api = new API()
    const [mrfRecord, setMrfRecord] = useState()
    const [isSelectedView, setIsSelectedView] = useState(false)
    const [MRFData, setMRFData] = useState()
    const [dropdownFilterList, setDropdownFilterList] = useState([
        { value: 'all', label: 'All MRF' },
        { value: 'open', label: 'Open MRF' },
        { value: 'close', label: 'Close MRF' },
        { value: 'hold', label: 'Hold MRF' },
      ]);
    const [selectedDropdownFilter, setSelectedDropdownFilter] = useState(
        'all',
      );
    const [selectedDropdownFilterType, setSelectedDropdownFilterType] = useState(
        '',
      );

    useEffect(() => {
        getMrfRecord()
    }, [])

    const handleOnDropdownItemClick = e => {
        const status = e.currentTarget.getAttribute('data-status');
        const type = e.currentTarget.getAttribute('data-type');
        setSelectedDropdownFilter(status);
        setSelectedDropdownFilterType(type);
    };

    useEffect(() => {
        getMrfRecord(selectedDropdownFilterType);
      }, [selectedDropdownFilter]);

    const getMrfRecord = async () => {
        let data = {
            status: selectedDropdownFilter
          };
        let result = await api.get(config.showAssignedMrfForRecruiter,true,data)
        if (result && result.code == 200) {
            setMrfRecord(result.data)
        } else {
        }
    }

    const view_selected = (mrf) => {
        setMRFData(mrf)
        setIsSelectedView(!isSelectedView)
    }

    const fields = [
        { label: "MRF ID", key: "mrf_id", _style: { width: "5%" } },
        { label: "Applying For", key: "mrf_type", _style: { width: "10%" } },
        { label: "Department", key: "department", _style: { width: "10%" }, },
        { label: "Designation", key: "designation", _style: { width: "10%" }, },
        { label: "Location", key: "location", _style: { width: "10%" } },
        { label: "MRF Assigned At", key: "assigned_at", _style: { width: "10%" }, },
        { label: "MRF Status", key: "status", _style: { width: "5%" } },
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];

    const getBadge = (status) => {
        switch (status) {
            case "Close":
                return "danger";
            case "Hold":
                return "warning";
            default:
                return "info";
        }
    };

    return (
        <div className="">
              {isSelectedView == false &&
                <div className="container">
                    <h3 className="text-uppercase text-center mt-3 mb-4"> <u>Candidate Interview In-Progress</u></h3>
                    <CCard>
                        <CCardBody>
                        <div className="col-md-12 mb-3 text-right">
                    <StatusDropdownMenu
                          onClick={handleOnDropdownItemClick}
                          dropdownItems={dropdownFilterList}
                          toggleBtnColor="primary"
                          toggleBtnText={selectedDropdownFilter}
                          activeTab="interview_inprogress"
                          isShowCounts={true}
                          totalRecords={mrfRecord?.length || 0}
                        />
                        </div>
                            <CDataTable
                                items={mrfRecord}  
                                fields={fields}
                                tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                                itemsPerPageSelect
                                itemsPerPage={10}
                                hover
                                sorter
                                pagination
                                scopedSlots={{
                                    status: (item) => (
                                        <td>
                                            <CBadge color={getBadge(item.active_status)}>
                                                {item.active_status}
                                            </CBadge>
                                        </td>
                                    ),
                                    show_details: (item, index) => {
                                        return (
                                            <td className="py-2">
                                                 <CButton  data-toggle="tooltip" 
                                                 data-placement="top" 
                                                 title="View"  
                                                 className="btn btn-primary mr-0 " 
                                                 onClick={() => view_selected(item)}>
                                                <i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                            </td>
                                        );
                                    
                                    },
                                }}
                            />
                        </CCardBody>
                    </CCard>



                </div>
            }
            { isSelectedView &&
                <div className="container">
                    <ViewSelectedInProgress MRFData={MRFData} view_selected={view_selected}/>
                </div>
            }

        </div>

    )
}
export default InProgress