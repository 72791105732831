import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CDataTable,
} from '@coreui/react';
import { CustButton } from 'src/reusable';
import '../views/messages/style.css';

const AddConnectionModal = props => {
  const {
    open,
    handleCancel,
    handleConnect,
    empList,
    connectingToUserData,
    isLoadingAddConnection,
  } = props;

  const fields = [
    {
      label: 'S.No.',
      key: 'sno',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
    { label: 'Name', key: 'fullname', _style: { width: '10%' } },
    { label: 'Emp Id', key: 'emp_id', _style: { width: '10%' } },
    {
      label: 'Action',
      key: 'action',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
  ];

  return (
    <div className="">
      <CModal
        show={open}
        onClose={handleCancel}
        size="lg"
        className={'overlap-modal'}
        backdrop={false}
      >
        <CModalHeader closeButton>
          <CModalTitle>Select User</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <CDataTable
              items={empList}
              fields={fields}
              loading={false}
              hover={false}
              columnFilter
              sorter
              pagination
              scopedSlots={{
                sno: (item, index) => <td>{index + 1}</td>,
                action: (item, index) => (
                  <td>
                    <CustButton
                      text="Connect"
                      type="button"
                      name="connect"
                      size="sm"
                      color={'primary'}
                      onClick={() => handleConnect(item)}
                      className={'btn-loading btn-ladda'}
                      icon=""
                      isDisabled={
                        connectingToUserData?.id === item.id &&
                        isLoadingAddConnection
                          ? isLoadingAddConnection
                          : false
                      }
                      isLoading={
                        connectingToUserData?.id === item.id &&
                        isLoadingAddConnection
                          ? isLoadingAddConnection
                          : false
                      }
                    />
                  </td>
                ),
              }}
            />
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Close"
            color="danger"
            onClick={handleCancel}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={false}
            isLoading={false}
          />
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default AddConnectionModal;
