import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { CustButton, CustLoader } from 'src/reusable';
export default function TextEditor(props) {
  let { setDescription, description, descr,handleOnPreviewOfferLetter, isGettingOfferLetter } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    let description = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    setDescription(description);
  }, [editorState]);

  useEffect(() => {
    if (description) {
      const contentBlock = htmlToDraft(
        description || '<p>Kindly complete it in due date.</p>',
      );
      const { contentBlocks, entityMap } = contentBlock;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [descr]);

  return (
    <div>
      <div
        style={{
          display: 'block',
          width: '100%',
          padding: '0.375rem 0.75rem',
          fontSize: '1rem',
          fontWeight: '400',
          lineHeight: '1.5',
          color: '#212529',
          backgroundColor: '#fff',
          backgroundClip: 'padding-box',
          border: '1px solid #ced4da',
          '-webkit-appearance': 'none',
          appearance: 'none',
          borderRadius: '0.375rem',
          transition:
            'border-color .15s ease-in-out,box-shadow .15s ease-in-out;',
        }}
      >
        <Editor
          // defaultContentState={description}
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </div>
      <div className="text-right" style={{paddingTop:'2%'}}>
      <CustButton
              type="button"
              name="pdf-view"
              text="Preview"
              color="primary"
              onClick={(e)=>handleOnPreviewOfferLetter({})}
              className="btn-loading btn-ladda mr-3"
              icon="fa-paper-plane"
              isDisabled={isGettingOfferLetter}
              isLoading={isGettingOfferLetter}
            />
            </div>
    </div>
  );
}
