import React, { useState, useEffect } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CDataTable,
    CBadge,
    CForm,
    CFormGroup,
    CFormText,
    CInput,
    CInputFile,
    CInputRadio,
    CLabel,
    CSelect,
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import ModalDocuments from './document_modal';
import Alert from "src/alert/alert";
import swal from 'sweetalert'
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import { isPermission } from 'src/utils/common';
// import ModalOnboardingDocuments from './modalOnboardingDocuments';


const UserInfoDoc = (props) => {
    let { candidateFullDocumentDetails, selectedCandidateData } = props
    const api = new API()
    const [userPersonalInfo, setUserPersonalInfo] = useState();
    const [selectedViewDocument, setSelectedViewDocument] = useState();
    const [isOpen_Doc, setIsOpen_Doc] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [fileUpoad, setFileUpoad] = useState()
    const [docName, setDocName] = useState()
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [profileImageShow, setProfileImageShow] = useState()
    useEffect(() => {
        getProfileImage()
        userInfo()
    }, [])

    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert);
        setAlertMessage(msg);
    };

    const isAlertToggleClose = () => {
        setIsAlert(false);
    }

    const userInfo = async () => {
        // console.log("inside user profile")

        let result = await api.get(config.profile)
        if (result && result.data && result.data.length > 0) {
            setUserPersonalInfo(result.data[0])
        }
    }
    const viewDocument = (data) => {
        setSelectedViewDocument(data)
        isViewModalOpen_Doc()
    }
    const isViewModalOpen_Doc = () => {
        setIsOpen_Doc(!isOpen_Doc)
    }




    const getProfileImage = async () => {
        let result = await api.get(config.getEmployeeProfilePic)
        if (result && result.code == 200) {
            setProfileImageShow(result.data.root_path + "/" + result.data.data[0].filename)
        } else {
        }
    }

    const onChangeInput = async (e, doc_name) => {
        let files = e.target.files;
        setFileUpoad(files[0]);
        setDocName(doc_name)
        updateProfileImage(files[0])
    }
    const updateProfileImage = async (files) => {
        if (files == "" || files == undefined) {
            isAlertToggle("Please select File");
        } else {

            const formData = new FormData()
            formData.append('file', files)
            swal({
                content: true,
                title: "Are you sure?",
                text: `Are you sure to upload Profile Image`,
                icon: "success",
                buttons: ["No, cancel it!", "Submit"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    let result = await api.putPostFileFun(config.uploadEmployeeProfilePic, formData, true)

                    if (result && result.code == 200) {
                        setFileUpoad("")

                        isAlertToggle(result && result.message);
                        getProfileImage()

                    } else {


                        isAlertToggle(result && result.message);
                        setFileUpoad("")
                        // alert(result && result.message)
                    }
                }
            })
        }
    }

    const deleteEmployeeProfilePicture = async (token) => {
        let user = getLocalDataAsObject("user");
        console.log(user)
        let emp_user_id = user[0].id;
        swal({
            content: true,
            title: "Removing Profile !",
            text: `Are you sure you want to delete Profile Picture`,
            icon: "success",
            buttons: ["No,Cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if(isConfirm){
                let result = await api.post(config.deleteEmployeeProfilePic, emp_user_id)
                result && result.code === 200 ? isAlertToggle(result.message) : isAlertToggle(result.message)
            }

        })
    }
    
    return (
            <>
                {/* <MyInfoTopSection /> */}
                <CCard className="mt-3">
                    <CRow>
                        <CCol md="3">
                            <div className="bg-c-lite-green">
                                <div className="card-block text-center text-white">
                                    <div className="profile-img">
                                        <img src={profileImageShow ? profileImageShow : "images/logo.png"} alt="" />
                                        <div className="file btn btn-primary">
                                            Upload Photo
                                            <input onChange={onChangeInput} type="file" name="file" />
                                        </div>

                                        <strong>{selectedCandidateData && selectedCandidateData.name}</strong><br></br><br></br>
                                        <span>{selectedCandidateData && selectedCandidateData.designation}</span>

                                        <i className=" mdi mdi-square-edit-outline feather icon-edit"></i>
                                        <p></p>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-c-lite-green">
                                <div className="card-block text-center text-white">
                                    <div className="profile-img">
                                        <div className="file btn btn-primary">
                                            Remove
                                            <input onClick={deleteEmployeeProfilePicture} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>

                        <CCol md="9">

                            <div className="card-block">
                                <strong className="mt-4"> Employee Information</strong>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <strong  >Employee ID</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.emp_id}</h6>
                                    </div>
                                    <div className="col-sm-3">
                                        <strong  >Name</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.first_name + " " + selectedCandidateData.last_name}</h6>
                                    </div>
                                    <div className="col-sm-3">
                                        <strong  >Joining Date</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.joining_date}</h6>
                                    </div>

                                    <div className="col-sm-3">
                                        <strong  >Mobile No.</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.mobile}</h6>
                                    </div>
                                </div>
                                {/* <h6 className="mt-3">Projects</h6> */}
                                <hr></hr>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <strong >Email</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.email}</h6>
                                    </div>
                                    <div className="col-sm-3">
                                        <strong  > Department</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.employeee_department} </h6>
                                    </div>
                                    <div className="col-sm-3">
                                        <strong  >Designation</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.employee_designation}</h6>
                                    </div>
                                    <div className="col-sm-3">
                                        <strong  > Reporting Manager</strong>
                                        <h6 className="text-muted f-w-400">{selectedCandidateData && selectedCandidateData.reporting_manager_name}</h6>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CCard>

                {/* <CCard className="mt-3"> */}
                <CRow>
                    {candidateFullDocumentDetails && candidateFullDocumentDetails.length > 0 && candidateFullDocumentDetails.map((details, index) => (
                        <CCol md="3">
                            <CCard key={index}>
                                <CCardHeader className="text-center">
                                    <strong>{details.tag_name}</strong>
                                </CCardHeader>
                                <CCardBody className="text-center">
                                    <span> Status :</span> <b style={details.status == 0 ? { color: "red" } : { color: "green" }}>{details.status == 0 ? "Pending" : "Complete"}</b>
                                </CCardBody>
                                {details.status == 1 &&
                                    <button className="btn btn-info btn-block" onClick={() => viewDocument(details)}>View</button>}
                                {details.status == 0 &&
                                    <button className="btn btn-danger btn-block" disabled>Detail Not Added</button>}
                            </CCard>
                        </CCol>
                    ))}


                </CRow>
                <ModalDocuments isViewModalOpen_Doc={isViewModalOpen_Doc}
                    isOpen_Doc={isOpen_Doc}
                    selectedViewDocument={selectedViewDocument} />

                <Alert
                    isAlert={isAlert}
                    isAlertToggle={isAlertToggle}
                    alertMessage={alertMessage}
                    isAlertToggleClose={isAlertToggleClose}
                />
            </>
        )
    }
    export default UserInfoDoc
