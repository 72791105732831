import React, { useState } from 'react';
import { CDataTable, CButton, CCollapse, CCardBody } from '@coreui/react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';
import { isPermission } from 'src/utils/common';
import moment from 'moment';
import SelectedApproveAndRejectModal from './SelectedApproveAndRejectModal';
const AttendanceCorretionApprovalList = props => {
  const {
    data,
    type,
    renderDataStatus,
    renderDataActionBtn,
    getCorrectionApproval,
  } = props;

  const [details, setDetails] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [heading, setHeading] = useState('');
  const [correctionType, setCorrectionType] = useState('');
  const isViewModalOpen = type => {
    if (type === 'Approve') {
      setHeading('Approve Correction');
    }
    if (type === 'Reject') {
      setHeading('Reject Correction');
    } else {
      setHeading('');
    }
    setCorrectionType(type);

    setIsOpen(!isOpen);
  };

  const toggleDetails = index => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const handleSelectedIds = (e, items) => {
    let newSelectedData = [...selectedData];
    if (e.target.checked) {
      newSelectedData.push(items);

      setSelectedData(newSelectedData);
    } else {
      let arr = newSelectedData.filter(item => item?.id != items?.id);

      setSelectedData(arr);
    }
  };
  const allSelectedApprove = type => {
    isViewModalOpen(type);
  };

  const renderDeviceCheckinList = () => {
    const fields = [
      {
        label: 'Select',
        key: 'select',
        //_style: { width: '5%' },
        sorter: true,
        filter: true,
      },
      {
        label: 'Employee ID',
        key: 'emp_id',
        //_style: { width: '5%' },
        sorter: true,
        filter: true,
      },
      {
        label: 'Employee Name',
        key: 'employee_name',
        //_style: { width: '10%' },
        sorter: true,
        filter: true,
      },
      {
        label: 'Check In Time',
        key: 'corrected_check_in_time',
        //_style: { width: '10%' },
        sorter: false,
        filter: false,
      },
      {
        label: 'Check Out Time',
        key: 'corrected_check_out_time',
        //_style: { width: '10%' },
        sorter: false,
        filter: false,
      },
      {
        label: 'Check In Reason',
        key: 'requested_check_in_reason',
        //_style: { width: '10%' },
        sorter: false,
        filter: false,
      },
      {
        label: 'Check Out Reason',
        key: 'requested_check_out_reason',
        //_style: { width: '10%' },
        sorter: false,
        filter: false,
      },

      {
        label: 'Approval Status',
        key: 'status',
        //_style: { width: '5%' },
        sorter: false,
        filter: true,
      },
      {
        label: 'Date',
        key: 'date',
        //_style: { width: '10%' },
        sorter: false,
        filter: false,
      },
      {
        label: 'Created Date',
        key: 'raised_date',
        //_style: { width: '10%' },
        sorter: true,
        filter: true,
      },
      {
        label: '',
        key: 'action',
        sorter: false,
        filter: false,
      },
    ];

    return (
      <div>
        <CDataTable
          items={data}
          fields={fields}
          tableFilter={{label: 'Filter:', placeholder: 'Search'}}
          itemsPerPageSelect
          itemsPerPage={10}
          hover
          sorter
          pagination
          scopedSlots={{
            status: item => <td>{renderDataStatus(item, type)}</td>,
            select: (item, index) =>
              item?.is_rejected == 0 && item.is_approved == 0 ? (
                <td>
                  <input
                    key={item?.id}
                    type="checkbox"
                    onChange={e => handleSelectedIds(e, item)}
                  ></input>
                </td>
              ) : (
                <td></td>
              ),
            raised_date: item => (
              <td>
                {convertDate(item.correction_request_raised_at, 'DD-MM-YYYY')}
              </td>
            ),
            requested_check_in_reason: item => (
              <td>
                {item?.requested_check_in_reason
                  ? item?.requested_check_in_reason
                  : 'N/A'}
              </td>
            ),
            requested_check_out_reason: item => (
              <td>
                {item?.requested_check_out_reason
                  ? item?.requested_check_out_reason
                  : 'N/A'}
              </td>
            ),
            action: item => (
              <td>
                {isPermission('approve_reject_leave_request') && (
                  <>{renderDataActionBtn(item, type)}</>
                )}
              </td>
            ),
            date: item => (
              <td>
                <>{moment(item?.date).format('DD-MM-YYYY')}</>
              </td>
            ),
          }}
        />
        {selectedData && selectedData.length > 0 && (
          <div className="text-start">
            <div className="p-2 mx-2">
              <button
                className="btn btn-primary mr-2"
                onClick={() => allSelectedApprove('Approve')}
              >
                {` Approve Selected (${selectedData.length})`}
              </button>
              <button
                className="btn btn-danger"
                onClick={() => allSelectedApprove('Reject')}
              >
                {`  Reject Selected (${selectedData.length}) `}
              </button>
            </div>
          </div>
        )}

        <SelectedApproveAndRejectModal
          isOpen={isOpen}
          isViewModalOpen={isViewModalOpen}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          heading={heading}
          correctionType={correctionType}
          getCorrectionApproval={getCorrectionApproval}
        />
      </div>
    );
  };

  const renderWebCheckinList = () => {
    const fields = [
      {
        label: 'Employee ID',
        key: 'emp_id',
        sorter: true,
        filter: true,
      },
      {
        label: 'Employee Name',
        key: 'employee_name',
        sorter: true,
        filter: true,
      },
      {
        label: 'Date',
        key: 'time',
        sorter: false,
        filter: false,
      },
      {
        label: 'Reason',
        key: 'comment',
        sorter: false,
        filter: false,
      },
      {
        label: 'Status',
        key: 'status',
        sorter: false,
        filter: false,
      },
      {
        label: '',
        key: 'show_details',
        sorter: false,
        filter: false,
      },
      {
        label: '',
        key: 'action',
        sorter: false,
        filter: false,
      },
    ];

    return (
      <div>
        <CDataTable
          items={data}
          fields={fields}
          tableFilter={{label: 'Filter:', placeholder: 'Search'}}
          itemsPerPageSelect
          itemsPerPage={10}
          hover={false}
          sorter
          pagination
          scopedSlots={{
            status: item => <td>{renderDataStatus(item, type)}</td>,
            time: item => <td>{convertDate(item.date, 'DD-MM-YYYY')}</td>,
            show_details: (item, index) => {
              return (
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => {
                      toggleDetails(index);
                    }}
                  >
                    {details.includes(index) ? 'Hide' : 'Show'}
                  </CButton>
                </td>
              );
            },
            details: (item, index) => {
              return (
                <CCollapse show={details.includes(index)}>
                  <CCardBody>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>In/Out</th>
                          <th>Datetime</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.attendance &&
                          item?.attendance.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{val.attendance_check_status}</td>
                                <td>
                                  {convertDate(val.time, 'DD-MM-YYYY hh:mm a')}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </CCardBody>
                </CCollapse>
              );
            },
            action: item => (
              <td>
                {isPermission('approve_reject_leave_request') && (
                  <>{renderDataActionBtn(item, type)}</>
                )}
              </td>
            ),
          }}
        />
      </div>
    );
  };

  return type === 'web_checkin_without_camera'
    ? renderWebCheckinList()
    : renderDeviceCheckinList();
};

export default AttendanceCorretionApprovalList;
