import React, { useState, useEffect } from 'react'
import {
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCollapse
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import ModalPopup from './common/modal';
import {StatusDropdownMenu} from 'src/subcomponents';
import { isPermission } from 'src/utils/common';

const AssignedRequisition = (props) => {
    const { getCountAssignRequisition, history } = props;
    let api = new API()

    const [mrfRecord, setMrfRecord] = useState()
    const [assignedRequisition, setAssignedRequisition] = useState()
    const [isPopupOpenViewAssignedRequisition, setIsPopupOpenViewAssignedRequisition] = useState(false)
    const [mrfGetById, setMrfGetById] = useState()
    const [details, setDetails] = useState([]);
    const [dropdownFilterList, setDropdownFilterList] = useState([
        { value: 'all', label: 'All MRF' },
        { value: 'open', label: 'Open MRF' },
        { value: 'close', label: 'Close MRF' },
        { value: 'hold', label: 'Hold MRF' },
      ]);
      const [selectedDropdownFilter, setSelectedDropdownFilter] = useState(
        'all',
      );
      const [selectedDropdownFilterType, setSelectedDropdownFilterType] = useState(
        '',
      );

    useEffect(() => {
        totalAssignedMrfToRecruiter()
    }, [])

    const sendCountAssignRequisition = (count) => {
        getCountAssignRequisition(count)
    }
   
    const handleSubmit = () => {
        console.log("doing something");
        history.push("admin-dashboard/reassignmrf") 
      }
    const totalAssignedMrfToRecruiter = async () => {
        let data = {
            status: selectedDropdownFilter
          };
        let result = await api.get(config.totalAssignedMrfToRecruiter,true,data)
        if (result && result.code === 200) {
            sendCountAssignRequisition((result.data).length)
            setMrfRecord(result.data)
        } else {
           
        }
    }

    const isViewModalOpen = (assigned_Requisition) => {
        setIsPopupOpenViewAssignedRequisition(!isPopupOpenViewAssignedRequisition)
        setAssignedRequisition(assigned_Requisition)
        getAssignedMrfById(assigned_Requisition)
    }

    const getAssignedMrfById = async (assigned_Requisition) => {
        let data = {
            mrf_id: assigned_Requisition.mrf_id,
            mrf_type: assigned_Requisition.applying_for === "New Hiring" ? "vacancy" : "replacement"
        }
        let result = await api.get(config.getMrfById, true, data)
        if (result && result.code === 200) {
            setMrfGetById(result.data[0])
        } else {
            
        }
    }

    const fields = [
        { label: "MRF ID", key: "mrf_id", _style: { width: "5%" } },
        { label: "Applying For", key: "applying_for", _style: { width: "10%" } },
        { label: "MRF Assigned To", key: "assigned_to", _style: { width: "10%" }, },
        { label: "Department", key: "department", _style: { width: "10%" } },
        { label: "Location", key: "location", _style: { width: "10%" } },
        { label: "MRF Status", key: "active_status", _style: { width: "10%" }, },
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
    const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };

    const handleOnDropdownItemClick = e => {
        const status = e.currentTarget.getAttribute('data-status');
        const type = e.currentTarget.getAttribute('data-type');
        setSelectedDropdownFilter(status);
        setSelectedDropdownFilterType(type);
    };

    useEffect(() => {
        totalAssignedMrfToRecruiter(selectedDropdownFilterType);
      }, [selectedDropdownFilter]);

      const getBadge = (status) => {
        switch (status) {
            case "Close":
                return "danger";
            case "Hold":
                return "warning";
            default:
                return "info";
        }
    };


    return (
        <div className="">
            <div className="container">
            <h3 className="text-uppercase text-center mt-3 mb-4"><u>List Of Assigned Requisition</u></h3>
                <CCard>
                    <CCardBody>
                    <div className="col-md-12 mb-3 text-right">
                    <StatusDropdownMenu
                          onClick={handleOnDropdownItemClick}
                          dropdownItems={dropdownFilterList}
                          toggleBtnColor="primary"
                          toggleBtnText={selectedDropdownFilter}
                          activeTab="assigned_requistion"
                          isShowCounts={true}
                          totalRecords={mrfRecord?.length || 0}
                        />
                        </div>
                        <CDataTable
                            items={mrfRecord}
                            fields={fields}
                            tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                            itemsPerPageSelect
                            itemsPerPage={10}
                            hover
                            sorter
                            pagination
                            scopedSlots={{
                                active_status: (item) => (
                                    <td>
                                        <CBadge color={getBadge(item.active_status)}>
                                            {item.active_status}
                                        </CBadge>
                                    </td>
                                ),
                                show_details: (item, index) => {
                                    return (
                                        <td className="py-2">
                                            <CButton
                                                color="primary"
                                                variant="outline"
                                                shape="square"
                                                size="sm"
                                                onClick={() => {
                                                    toggleDetails(index);
                                                }}
                                            >
                                                {details.includes(index) ? "Hide" : "Show"}
                                            </CButton>
                                        </td>
                                    );
                                },
                                details: (item, index) => {
                                    return (
                                        <CCollapse show={details.includes(index)}>
                                            <CCardBody>
                                                <p className="text-muted"><b style={{color:"black"}}>MRF ID : </b><b style={{color:"darkblue"}}>{item.mrf_id}</b></p>
                                                <p className="text-muted"><b style={{color:"black"}}>Designation : </b><b style={{color:"darkblue"}}>{item.designation}</b></p>
                                                <p className="text-muted"><b style={{color:"black"}}>MRF Created By : </b><b style={{color:"darkblue"}}>{item.created_by_name}</b></p>
                                                <p className="text-muted"><b style={{color:"black"}}>MRF Assigned At : </b><b style={{color:"darkblue"}}>{item.assigned_at}</b></p>
                                                {isPermission('view_assigned_reqs') &&
                                                <CButton onClick={() => isViewModalOpen(item)}
                                                    size="sm"
                                                    color="primary"
                                                    className="ml-1"
                                                >
                                                    View
                                                </CButton>
                                                }
                                                {/* {isPermission('reassign_mrf') &&
                                                <CButton onClick={handleSubmit}
                                                 size="sm"
                                                 color="success"
                                                 className="ml-1">
                                                    Re-Assign MRF
                                                </CButton>
                                                } */}
                                            </CCardBody>
                                        </CCollapse>
                                    );
                                },
                            }}
                        />
                    </CCardBody>
                </CCard>
                {/* <CCardHeader>

                    </CCardHeader> */}
                {/* <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">MRF ID</th>
                            <th scope="col">Applying For</th>
                            <th scope="col">Assigned To</th>
                            <th scope="col">Department</th>
                            <th scope="col">Designation</th>

                      
                            {<th scope="col">Created By</th>}
                            <th scope="col">Assigned At</th>
                            <th scope="col">MRF Final Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mrfRecord && mrfRecord.map((mrf, index) => (

                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{mrf.mrf_id}</td>
                                <td className="letter">{mrf.applying_for == "vacancy"
                                        ? "New Hiring"
                                        : mrf.applying_for}</td>
                               
                       
                                <td>{mrf.assigned_to}</td>
                                <td>{mrf.department}</td>
                                <td>{mrf.designation}</td>
                           
                                <td>{mrf.created_by_name}</td>
                                <td>{moment(mrf.assigned_at).format("DD MM YYYY")}</td>
                                <td>{mrf.mrf_final_status == 0 ? <span style={{ color: "red" }}>Pending</span> : <span style={{ color: "blue" }}>Completed</span>}</td>

                   
                                <CRow>

                                    <CCol >
                                        <CButton  data-toggle="tooltip"  data-placement="top" title="View MRF Status"  className="btn btn-info  " onClick={() => isViewModalOpen(mrf)} ><i className="fa fa-eye " aria-hidden="true"></i></CButton>
                                    </CCol>
                                 
                                </CRow>
                            </tr>
                        ))}

                    </tbody>
                </table> */}

                <ModalPopup isPopupOpenViewAssignedRequisition={isPopupOpenViewAssignedRequisition} popupFor={"AssignedRequisition"}
                    assignedRequisition={assignedRequisition}
                    modalHeader="Assigned Requisition"
                    isViewModalOpen={isViewModalOpen}
                    mrfGetById={mrfGetById} />
                {/* <Ctc/> */}
            </div>

        </div >

    )
}
export default AssignedRequisition







