import React from 'react';
import { convertDate } from 'src/utils/common';
import { CDataTable } from '@coreui/react';
import { NoRecords } from 'src/reusable';

const LeaveAppliedList = props => {
  const { data, type, renderDataStatus, renderDataActionCancelBtn } = props;
  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave Date',
      key: 'leave_date',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave Status',
      key: 'leave_status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Leave Type',
      key: 'leave_key',
      sorter: false,
      filter: false,
    },
    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Created Date',
      key: 'raised_date',
      sorter: true,
      filter: true,
    },
    {
      label: '',
      key: 'action',
      sorter: true,
      filter: true,
    },
  ];

  return (
    <CDataTable
      items={data}
      fields={fields}
      tableFilter={{label: 'Filter:', placeholder: 'Search'}}
      itemsPerPageSelect
      itemsPerPage={5}
      hover
      sorter
      pagination
      scopedSlots={{
        status: item => <td>{renderDataStatus(item, type)}</td>,
        leave_date: item => (
          <td>{convertDate(item.leave_date, 'DD-MM-YYYY')}</td>
        ),
        raised_date: item => (
          <td>{convertDate(item.created_at, 'DD-MM-YYYY')}</td>
        ),
        s_no: (item, index) => (
          <td>
            <b>{index + 1}</b>
          </td>
        ),
        reason: (item, index) => (
          <td>
            {item.is_cancel == 1
              ? item.cancel_reason
              : item.is_rejected_status == 1
              ? item.rejection_comment
              : item.reason}
          </td>
        ),
        leave_status: (item, index) => (
          <td>
            {item.leave_status === "full_day" ? "Full Day" 
            : item.leave_status === "first_half_day" ? "First Half Day"
            : item.leave_status === "second_half_day" ? "Second Half Day" : item.leave_status}
          </td>
        ),
        action: item => <td>{renderDataActionCancelBtn(item, type)}</td>,
      }}
    />
  );
};

export default LeaveAppliedList;
