import React, { useState, lazy, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {
    CBadge, CNav,
    CInputRadio,
    CNavItem,
    CNavbar,
    CListGroup, CListGroupItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs,
    CButton,
    CTextarea,
    CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
    CWidgetBrand,
    CButtonGroup,
    CWidgetIcon,
    CCard,
    CLink,
    CCardBody,
    CCardFooter,
    CInputFile,
    CCardHeader,
    CCol,
    CSwitch,
    CCollapse,
    CProgress,
    CProgressBar,
    CRow,
    CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
    CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert'
import Alert from "src/alert/alert";

// import API from 'src/utils/apiCalling';
// import { config } from 'src/utils/apiUrl';
import './onboarding_dashobard.css'
import OfferLatterDetails from '../document_form/offer_latter_onboarding_section';
import PersonalDetails from '../document_form/personal_details';
import FamilyDetails from '../document_form/family_details';
import EducationDetails from '../document_form/education_details';
import BankDetails from '../document_form/bank_details';
import SocialDetails from '../document_form/social_details';
import ReferenceDetails from '../document_form/reference_details';
import WorkExperienceDetails from '../document_form/work_experience_details';
import PolicyDetails from '../document_form/policy_details';
import DocumentUpload from '../document_form/document_upload';

const OnboaridngDashboard = (props) => {
    const {empDetails, hideSection } = props
    const api = new API()
    const [activeValue, setActiveValue] = useState(hideSection ? 0 : 1)
    const [empStatus, setEmpStatus] = useState()
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [bgvdata,setBGVdata] = useState("")

    const showBGVDetailsFun=async()=>{
        let data={
            emp_user_id:empDetails?.id
        }
        let result =await api.get(config.showBGVDetails,true,data)
        if (result && result.code == 200) {
            setBGVdata(result.data[0])
        }
        else {
            setBGVdata("")
        }

    }

    useEffect(() => {
        showBGVDetailsFun()
        getEmployeeDetailStatus()
    }, [empDetails])

    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert);
        setAlertMessage(msg);
    };
    const isAlertToggleClose = () => {
        setIsAlert(false);
    }
    const onClickActive = (val) => {
            setActiveValue(val)
    }
    const onClickActiveOnboarding = (val) => {
        if ((empStatus && empStatus.offer_letter_status == 1) ) {
            setActiveValue(val)
        } else {
            isAlertToggle("Please accept the offer letter to proceed further with the onboarding process");
        }
    }
    
    const getEmployeeDetailStatus = async () => {
        let data = {
            user_id: empDetails && empDetails.id
        }
        if (!data.user_id) {
            delete data.user_id
        }
        let result = await api.get(config.getEmployeeProfileDetailStatus, true, data)
                if (result && result.code == 200) {
            setEmpStatus(result.data[0])
        }
        else {
            setEmpStatus("")
        }
    }

 

    return (
        <>
            <div className="container-fluid" id="grad1">
                <div className="row justify-content-center mt-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <div className="card px-0 pt-2 pb-2">
                            <h3><strong>Document Details User Account</strong></h3>
                            <p>Please fill out all form fields to proceed to the next step.</p>
                            <div className="row">
                                <div className="col-md-12 col mx-0 mb-2">
                                    <form id="msform">
                                        <ul id="progressbar">
                                            {hideSection == true ?
                                                <>
                                                    <li onClick={() => onClickActive(1)} style={activeValue == 1 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.offer_letter_status == 1 ? "active" : ""} id="accept_offer_latter"><strong>Accept Offer Letter</strong></li>
                                                    <li onClick={() => onClickActive(2)} style={activeValue == 2 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.personal_details_status == 1 ? "active" : ""} id="personal_details"><strong>Personal Details </strong></li>
                                                    <li onClick={() => onClickActive(3)} style={activeValue == 3 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.family_details_status == 1 ? "active" : ""} id="family_details"><strong>Family Details</strong></li>
                                                    <li onClick={() => onClickActive(4)} style={activeValue == 4 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.education_detail_status == 1 ? "active" : ""} id="education_details"><strong>Education Details</strong></li>
                                                    <li onClick={() => onClickActive(5)} style={activeValue == 5 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.bank_details_status == 1 ? "active" : ""} id="bank_details"><strong>Bank Details</strong></li>
                                                    <li onClick={() => onClickActive(6)} style={activeValue == 6 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.social_detail_status == 1 ? "active" : ""} id="social_details"><strong>Social Details</strong></li>
                                                    <li onClick={() => onClickActive(7)} style={activeValue == 7 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.reference_detail_status == 1 ? "active" : ""} id="reference_details"><strong>Reference Details</strong></li>
                                                    <li onClick={() => onClickActive(8)} style={activeValue == 8 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.work_experience_detail_status == 1 ? "active" : ""} id="experience_details"><strong>Work Experience Details</strong></li>
                                                    <li onClick={() => onClickActive(9)} style={activeValue == 9 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.documents_details_status == 1 ? "active" : ""} id="upload"><strong>Upload Documents</strong></li>
                                                    <li onClick={() => onClickActive(10)} style={activeValue == 10 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.emp_policy_status == 1 ? "active" : ""} id="policy"><strong>Policy</strong></li> 
                                                </>
                                                :
                                                <>
                                                    <li onClick={() => onClickActiveOnboarding(1)} style={activeValue == 1 ? { color: "#354b9c" } : { color: "#354b9c" }} className={empStatus && empStatus.offer_letter_status == 1 ? "active" : ""} id="accept_offer_latter"><strong>Accept Offer Letter</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(2)} style={activeValue == 2 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.personal_details_status == 1 ? "active" : ""} id="personal_details"><strong>Personal Details</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(3)} style={activeValue == 3 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.family_details_status == 1 ? "active" : ""} id="family_details"><strong>Family Details</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(4)} style={activeValue == 4 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.education_detail_status == 1 ? "active" : ""} id="education_details"><strong>Education Details</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(5)} style={activeValue == 5 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.bank_details_status == 1 ? "active" : ""} id="bank_details"><strong>Bank Details</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(6)} style={activeValue == 6 ? {color : "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.social_detail_status == 1 ? "active" : ""} id="social_details"><strong>Social Details</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(7)} style={activeValue == 7 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.reference_detail_status == 1 ? "active" : ""} id="reference_details"><strong>Reference Details</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(8)} style={activeValue == 8 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.work_experience_detail_status == 1 ? "active" : ""} id="experience_details"><strong>Work Experience Details</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(9)} style={activeValue == 9 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.documents_details_status == 1 ? "active" : ""} id="upload"><strong>Upload Documents</strong></li>
                                                    <li onClick={() => onClickActiveOnboarding(10)} style={activeValue == 10 ? { color: "blue" } : { color: "#354b9c" }} className={empStatus && empStatus.emp_policy_status == 1 ? "active" : ""} id="policy"><strong>Policy</strong></li>
                                                </>
                                            }
                                        </ul>
                                        <fieldset>
                                            <div className="form-card">
                                                {activeValue == 1 && <OfferLatterDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} 
                                                    empDetails={empDetails} />}
                                                {activeValue == 2 && <PersonalDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 3 && <FamilyDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 4 && <EducationDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 5 && <BankDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 6 && <SocialDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 7 && <ReferenceDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 8 && <WorkExperienceDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 9 && <DocumentUpload getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                                {activeValue == 10 && <PolicyDetails getEmployeeDetailStatus={getEmployeeDetailStatus} onClickActive={onClickActive}
                                                    empStatus={empStatus} empDetails={empDetails} />}
                                            </div>
                                            {/* <input type="button" name="previous" className="previous action-button-previous" value="Previous" />
                                            <input type="button" name="next" className="next action-button" value="Next Step" /> */}
                                        </fieldset>
                                    </form>
                                </div>
                                {/* <div className="row">

                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                    <ul id="progressbar">
                                        <li onClick={() => onClickActive(1)} style={activeValue == 1 ? { color: "#354b9c" } : { color: "#354b9c" }} className={empStatus && empStatus.offer_letter_status == 1 ? "active" : ""} id="accept_offer_latter"><strong><b>Accept Offer Latter</b></strong></li>
                                    </ul>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-4">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-3">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-3">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-3">
                                        <h2> section first calling</h2>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 col-3">
                                        <h2> section first calling</h2>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Alert
                isAlert={isAlert}
                isAlertToggle={isAlertToggle}
                alertMessage={alertMessage}
                isAlertToggleClose={isAlertToggleClose}
                type="info"
            />
        </>
    )
}
export default OnboaridngDashboard
