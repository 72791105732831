import React, { useState, lazy, useEffect } from 'react'
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Moment from 'react-moment';
import './bookedSlotSlider.css'
import {
    CBadge, CNav,
    CInputRadio,
    CNavItem,
    CNavbar,
    CListGroup, CListGroupItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs,
    CButton,
    CTextarea,
    CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
    CWidgetBrand,
    CButtonGroup,
    CWidgetIcon,
    CCard,
    CLink,
    CCardBody,
    CCardFooter,
    CInputFile,
    CCardHeader,
    CCol,
    CSwitch,
    CCollapse,
    CProgress,
    CProgressBar,
    CRow,
    CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
    CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { DocsLink } from 'src/reusable'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import './booking_meeting_room_screen.css'
import { validation } from '../../../../utils/configCommon'
import Clock from '../clock';
import moment from 'moment';

const BookedMeetingSlider = (props) => {
    let { getBookedMeeting } = props
    let api = new API()
    


    useEffect(() => {
        // console.log("bookingRoomName---------------", getBookedMeeting)
        // alreadyBookedMeeting()
    }, [])









    return (
        <div>
            {/* <div className=" "> */}

            {/* <h3>Booked meeting slider</h3> */}
            <div>
                {/* <marquee> */}
                {getBookedMeeting && getBookedMeeting.length > 0 ?
                    <div className="row marquree-line" >
                        {getBookedMeeting && getBookedMeeting.length > 0 && getBookedMeeting.map((meeting, index) => (
                            <div className="col marquree-line" style={{ padding: "10px", margin: "10px", backgroundColor: "#eee", borderRadius: "10px" }}>

                                <h6 className="text-time">{meeting.meeting_type_name}</h6>
                                <h4 className="text-time1">{meeting.actual_room_booked}</h4>
                                <h6>{meeting.meeting_agenda}</h6>
                                <small>Organizer :{meeting.organizer_email}</small>

                                
                            </div>
                        ))}
                    </div>
                    :
                    <>
                     {/* <div className="meeting-not"> */}
                        {/* <h2>Conference room is available for rest of the day</h2> */}
                     {/* </div>  */}
                    
                    </>
                }
                {/* </marquee> */}
                {/* </div> */}
                {/* <h2>meeting name</h2> */}
            </div>
        </div>

    )
}
export default BookedMeetingSlider