
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import ChangePassword from 'src/views/changepassword/ChangePassword'


const ChangePasswordFirstTime = (props) => {
    const { isOpen, isViewModalOpen } = props

    const [popopFor, setPopupFor] = useState("")
    // const [isModalOpen, isModalOpen] = useState(false)
    useEffect(() => {

    }, [])
    const isModalOpen = () => {

    }

    return (
        <div className="">

            <CModal
                show={isOpen}
                onClose={() => isViewModalOpen()}
                size="lg"
            >

                {/* <CModalHeader closeButton>
                    <CModalTitle>{"Change Password"}</CModalTitle>
                </CModalHeader> */}
                <CModalBody>

                    <ChangePassword isViewModalOpen={isViewModalOpen}/>

                </CModalBody>
                {/* <CModalFooter>
                    <CButton color="success" onClick={() => isViewModalOpen()}>Close</CButton>{' '}

                </CModalFooter> */}
            </CModal>
        </div>

    )
}
export default ChangePasswordFirstTime







