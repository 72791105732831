import React, { useState } from 'react'
import {
  
    CModal,

    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CInput,
 
    CButton,
 
} from '@coreui/react'

const ModalExtendShiftTime = () => {
    const[isOpen,setIsOpen]=useState(false)
    const[showModal,setShowModal]=useState(true)
    const[showInput,setShowInput]=useState(false)
    const isViewModalOpen=()=>{
        setIsOpen(!isOpen)
    }
//     const ExtendShiftTime=setInterval(function () {
//       var date1=new Date();
//   var date2 =new Date("06/15/2022 15:05:00");
//   var diff =date2.getTime()-date1.getTime()
//   var hh = Math.floor(diff/ 1000 / 60 );
//   if(hh<=2&&showModal==true){
//     setIsOpen(true)
  
//   }else{}
     
      
//     }, 5000);

    const stopExtendTime=()=>{
      setShowModal(false)
    //   clearInterval(ExtendShiftTime)
      setIsOpen(false)
    }
    
   
  return (
    <div>
         <CModal
                show={isOpen}
                onClose={() => isViewModalOpen(!isOpen)}
                size="sm"
            >
                <CModalHeader closeButton>
                    <CModalTitle>Do you want to extend your Shift time.</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {/* <div>
                    <CInput type="date" className='form-control'> </CInput> 

                    </div> */}
            
                     
                    
                
                    
                </CModalBody>
                <CModalFooter>
                <button className='btn btn-success' onClick={()=>setShowInput(true)}>Yes</button>
                    <button className='btn btn-warning' onClick={stopExtendTime}>NO</button>  
                     <CButton color="danger" onClick={() => isViewModalOpen(!isOpen)}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                </CModalFooter>
            </CModal>
    </div>
  )
}

export default ModalExtendShiftTime