import React, { Suspense, useState } from 'react'
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'

// routes config
import routes from '../routes'
import Loader from 'src/views/common/loader'
import AddITDeclaration from 'src/admin-dashboard/payroll-admin/AddITDeclaration'
import PaySchedule from 'src/admin-dashboard/payroll-admin/PaySchedule'
import SubmittedPayRun from 'src/admin-dashboard/payroll-admin/SubmittedPayRun'

const TheContent = () => {


  return (
    <main className="c-main">
      <CContainer fluid style={{padding:"0px",height:"100%"}}>
        <Suspense fallback={<Loader />}>
          <Switch>
            
          <Route
                  path="/admin-dashboard/add_it_declaration"
                  name="AddITDeclaration"
                  render={props => <AddITDeclaration {...props} />}
                />
                  <Route
                  path="/admin-dashboard/pay-run-details/:month?/:year?/:button?"
                  name="PayRunDetails"
                  render={props => <SubmittedPayRun {...props} />}
                />
                <Route
                  path="/admin-dashboard/add_pay_schedule"
                  name="AddPaySchedule"
                  render={props => <PaySchedule {...props} />}
                />
            {routes.map((route, idx) => {
             
              return route?.component && route?.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )} />
              )
            })}
            
            <Redirect from="/" to="/dashboard" />
          </Switch>
       
        </Suspense>
   
      </CContainer>
 
    </main>
  )
}

export default React.memo(TheContent)
