import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import {
  CBadge,
  CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup,
  CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel,
  CInput,
  CForm,
  CFormGroup,
  CFormText,
  CSelect,
  CCallout,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';

import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import { validateEmail, validateMobile2, validateName } from 'src/utils/ValidationHelper';

const ReferenceDetails = props => {
  let { getEmployeeDetailStatus, empStatus, empDetails, onClickActive } = props;
  const api = new API();
  const [isSubmit, setIsSubmit] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [empFirstRefMsg, setEmpFirstRefMsg] = useState()
  const [empFirstRefIsValid, setEmpFirstRefIsValid] = useState(false)
  const [isCancelBtn, setIsCancelBtn] = useState(false)
  const [ReferenceDetails, setReferenceDetails] = useState({
    emp_first_reference_name: '',
    emp_first_reference_contact: '',
    emp_first_reference_email: '',
    emp_second_reference_name: '',
    emp_second_reference_contact: '',
    emp_second_reference_email: '',
  });
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [empSecRefIsValid, setEmpSecRefIsValid] = useState(false)
  const [empValidNumber, setEmpValidNumber] = useState(false)
  const [empValidNumber2, setEmpValidNumber2] = useState(false)
  const [empValidName, setEmpValidName1] = useState(false);
  const [empValidName2, setEmpValidName2] = useState(false)

  const [isDisabledbutton, setIsDisabledButton] = useState(false)
  const [isSubmitBtn, setIsSubmitBtn] = useState(false)

  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };

  useEffect(() => {
    getRefrenceDetails();
  }, []);
  const getRefrenceDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getEmployeeReferenceDetails, true, data);
    if (result && result.code === 200) {
      if (empStatus && empStatus.reference_detail_status == 1) {
        setIsDisabled(false);
        setIsSubmit(false);
        setReferenceDetails(result.data[0]);
      }
    } else {
      setIsSubmit(true);
      // alert(result && result.message);
    }
  };
  const saveReferenceDetails = async (e) => {
    e.preventDefault()

 
          let data = ReferenceDetails;
          data.user_id = empDetails && empDetails.id;
          if (!data.user_id) {
            delete data.user_id;
          }
          
          swal({
            html: true,
            title: 'Are you sure?',
            text: `Are you sure to add Reference Details`,
            icon: 'success',
            buttons: ['No, cancel it!', 'Submit'],
            dangerMode: true,
          }).then(async function (isConfirm) {
            if (isConfirm) {
              let result = await api.post(config.referencedetails, data);
              if (result.code === 200) {
                toast.success('Reference Details Added Successfully');
                onClickActive(8);
                setIsSubmit(false);
                getEmployeeDetailStatus();
                getRefrenceDetails();
              } else {
                toast.error(result && result.message);
              }
            }
          });
  
  };
  const onChangeInputReferenceDetails = e => {

    const { name, value } = e.target;
    checkValidationMsg(name, value)
    setReferenceDetails({
      ...ReferenceDetails,
      [name]: value,
    });
  };

  const checkValidationMsg = (name, value) => {
    //  const {msg,isValid} =validateEmail(ReferenceDetails.emp_first_reference_email)
    //  setEmpFirstRefMsg(msg)
    //  setEmpFirstRefIsValid(isValid)
    //   console.log(msg ,"isValid",isValid)

    switch (name) {
      case "emp_first_reference_email":
        let { msg, isValid } = validateEmail(value)
        setEmpFirstRefMsg(msg)
        setEmpFirstRefIsValid(!isValid)

        break;
      case "emp_second_reference_email":
        let validationResult = validateEmail(value)
        setEmpSecRefIsValid(!validationResult.isValid)

        break;
      case "emp_first_reference_contact":
        let validationResult2 = validateMobile2(value)

        setEmpValidNumber(!validationResult2)


        break;
      case "emp_second_reference_contact":
        let validationResult3 = validateMobile2(value)
        setEmpValidNumber2(!validationResult3)

        break;
      case "emp_first_reference_name":
        let validationResult4 = validateName(value)
        setEmpValidName1(!validationResult4)

        break;
      case "emp_second_reference_name":
        let validationResult5 = validateName(value)
        setEmpValidName2(!validationResult5)

        break;
      default:
      // code block
    }
  }
  useEffect(() => {
    let userDetails = ReferenceDetails
    for (const key in userDetails) {
      if (userDetails.hasOwnProperty(key)) {
        const value = userDetails[key];
        // Check for both empty string and null (or undefined if required)
        if (value !== "" && value !== null && value !== undefined) {
          // return false;
          setIsSubmitBtn(true)
          return
        }
      }
    }
    setIsSubmitBtn(false)
    return;
  }, [onChangeInputReferenceDetails])

 


  const updateRefrenceDetails = async () => {
    // return
    let data = ReferenceDetails;
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to update Reference Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updatereferencedetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          onClickActive(8);
          
        } else {
          toast.error(result.message);
        }
      }
    });
  };
  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <label style={{ color: 'darkorange', fontWeight: 'bold' }}>
              FIRST REFERENCE
            </label>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name </label>
              <input
                disabled={isDisabled ? true : false}
                type="text"
                placeholder="Enter Full Name"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_first_reference_name"
                value={ReferenceDetails.emp_first_reference_name}
                onChange={onChangeInputReferenceDetails}
              />
              {
                empValidName && ReferenceDetails.emp_first_reference_name !=='' &&
                <span className='text-danger'>Name field should be allowed alphabetic characters.</span>
              }
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Contact No.</label>
              <input
                disabled={isDisabled ? true : false}
                type="mobile"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Enter Contact No."
                aria-describedby="emailHelp"
                name="emp_first_reference_contact"
                value={ReferenceDetails.emp_first_reference_contact}
                onChange={onChangeInputReferenceDetails}
                maxLength={10}
              />
              {
                empValidNumber && ReferenceDetails.emp_first_reference_contact!=='' && 
                <span className='text-danger'>Please enter a valid phone number, it must be of 10 digits and begins with 6, 7, 8 or 9.</span>
              }
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email ID</label>
              <input
                disabled={isDisabled ? true : false}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Email ID"
                name="emp_first_reference_email"
                value={ReferenceDetails.emp_first_reference_email}
                onChange={onChangeInputReferenceDetails}
              />

              {
                empFirstRefIsValid && ReferenceDetails.emp_first_reference_email !=='' &&
                <span className='text-danger'>Please enter valid email formate  </span>
              }
            </div>
          </div>

          <div className="col-md-6">
            <label style={{ color: 'darkorange', fontWeight: 'bold' }}>
              SECOND REFERENCE
            </label>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input
                disabled={isDisabled ? true : false}
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Full Name"
                name="emp_second_reference_name"
                value={ReferenceDetails.emp_second_reference_name}
                onChange={onChangeInputReferenceDetails}
              />
              {
                empValidName2 && ReferenceDetails.emp_second_reference_name !=='' &&
                <span className='text-danger'>Name field should be allowed alphabetic characters.</span>
              }
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Contact No.</label>
              <input
                disabled={isDisabled ? true : false}
                type="mobile"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Enter Contact No."
                aria-describedby="emailHelp"
                name="emp_second_reference_contact"
                value={ReferenceDetails.emp_second_reference_contact}
                // max={10}
                maxLength={10}
                onChange={onChangeInputReferenceDetails}
              />
              {
                empValidNumber2 && ReferenceDetails.emp_second_reference_contact!=='' &&
                <span className='text-danger'>Please enter a valid phone number, it must be of 10 digits and begins with 6, 7, 8 or 9.</span>
              }
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email ID</label>
              <input
                disabled={isDisabled ? true : false}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Email ID"
                name="emp_second_reference_email"
                value={ReferenceDetails.emp_second_reference_email}
                onChange={onChangeInputReferenceDetails}
              />
              {
                empSecRefIsValid && ReferenceDetails.emp_second_reference_email !=='' &&
                <span className='text-danger'>Please enter valid email formate  </span>
              }
            </div>

            {/* {isDisabled == false && (
              <button
                className="btn btn-primary float-right"
                onClick={saveReferenceDetails}
                // disabled={isDisabledbutton ? true : false}
                disabled={!isSubmitBtn}
              >
                Submit
              </button>
            )} */}
            {/* {isSubmit && (
              <button
                className="btn btn-success float-right ml-1"
                type="button"
                onClick={e => onClickActive(8)}
              >
                Next <i class="fa fa-angle-double-right" aria-hidden="true"></i>
              </button>
            )}
            {isDisabled && <button
              className="btn btn-info float-right ml-1"
              type="button"
              onClick={e => onEdit()}
            >
              Edit {' '}
            </button>}
            {isCancelBtn && <button
              className="btn btn-danger float-right ml-1 ms-1 me-1"
              type="button"
              onClick={e => onCancel()}
            >
              Cancel {' '}
            </button>} */}
          {isSubmit? (
              <button
                className="btn btn-primary float-right mt-3"
                type="button"
                onClick={saveReferenceDetails}
                disabled={!isSubmitBtn}
              >
                Submit
              </button>
            ) : (
              <>
                <button
                  className="btn btn-success float-right mt-3 ml-1"
                  type="button"
                  onClick={e => onClickActive(8)}
                >
                  Next{' '}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
                <button
                  className="btn btn-info float-right mt-3"
                  type="button"
                  onClick={updateRefrenceDetails}
                >
                  Update
                </button>
              </>
            )}   


          </div>
        </div>
      </form>
      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
      <ToastContainer />
    </div>
  );
};
export default ReferenceDetails;