//External Imports
let moment = require("moment");
let crypto = require("crypto");
let _ = require("lodash");
const constants = require("./constantWhatsApp");
const fs = require("fs");


/**
 * @description To generate random number
 * @param {*} length
 */

export const generateRandomNumber = (length = 4) => {
    let text = "";
    let possible = "123456789";
    for (let i = 0; i < length; i++) {
        let sup = Math.floor(Math.random() * possible.length);
        text += i > 0 && sup == i ? "0" : possible.charAt(sup);
    }
    return text;
};

/**
 * @description To generate random Key
 * @param {*} length
 */
export const generateRandomKey = (length = 8) => {
    return crypto.randomBytes(length).toString("hex").toUpperCase();
};

/**
 * @description To generate random string
 * @param {*} length
 */
export const generateRandomString = (length = 5) => {
    let text = "";
    let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_0123456789";

    for (let i = 0; i < length; i++) {
        let sup = Math.floor(Math.random() * characters.length);
        text += characters.charAt(sup);
    }

    return text;
};


/**
 * @description To check is empty
 */
export const isEmpty = (str) => {
    if (!str) return true;

    str = str.replace(" ", "");
    return _.isEmpty(str);
};

/**
 * @public
 * @description To encrypt and decrypt code
 */
export const encryptAndDecrypt = (data, type) => {
    //
    switch (type) {
        case "e":
            return Buffer.from(`${constants.secretKey}key:${data}`).toString(
                "base64"
            );
            break;
        case "d":
            return Buffer.from(data, "base64").toString("ascii");
            break;
    }
};
/**
 * @description To get correct date
 * @param {*} date
 */
export const formatedDate = (date, format) => {
    //"DD-MMM-YYYY, hh:mm:ss a"
    // return moment(date).utcOffset("+05:30").format(format);
    return moment(date).format(format);
};

/**
 * @public
 * @description To delete file
 */
export const fsUnlink = (file) => {
    //
    fs.unlink(file, (err) => {
        if (err) console.log(err);
        else {
            // console.log(`\nDeleted file: ${file}`);
        }
    });
};

/**
 * @description To get standard date format
 * @param {*} date
 */
export const standardDate = (date) => {
    //"Day 12 aug 2020 at time"

    let day = moment(date).utcOffset("+05:30").format("ddd");
    let formatedDate = moment(date).utcOffset("+05:30").format("DD MMM YYYY");
    let time = moment(date).utcOffset("+05:30").format("hh:mm a");

    return `${day} ${formatedDate} at ${time}`;
};

/**
 * @description To get date difference
 * @param {*} date
 */
export const getDateDiff = (
    startDate,
    endDate,
    format1 = "YYYY-MM-DD",
    format2 = "YYYY-MM-DD",
    type = 'days'
) => {
    const date1 = moment(startDate, format1);
    const date2 = moment(endDate, format2);

    return date2.diff(date1, type);
};

/**
 * @description To get month name
 * @param {*} date
 */
export const createMonthName = (
    startDate,
    endDate,
    isSameMonth
) => {
    if (isSameMonth) return moment(startDate).format("MMM-YYYY");

    return `${moment(startDate).format("MMM-YYYY")}/${moment(endDate).format("MMM-YYYY")}`;
};

/**
 * @description To subtract date
 */
export const subtractDate = (date, subtractValue, subtractType, format) => {
    //
    return moment(date)
        .subtract(subtractValue, subtractType)
        .utcOffset("+05:30")
        .format(format);
};

/**
 * @description To add date
 */
export const addDate = (date, addValue, addType, format) => {
    //
    return moment(date).add(addValue, addType).utcOffset("+05:30").format(format);
};

/**
 * @public
 * @description create response when error occurred
 */
export const errorResponse = (res, code, message) => {
    //ERROR RESPONSE
    return res.status(code).json({
        error: true,
        code: code,
        message: message,
        data: null,
    });
};

/**
 * @public
 * @description create response without error
 */
export const correctResponse = (res, code, message, data) => {
    //RESPONSE
    return res.status(code).json({
        error: false,
        code: code,
        message: message,
        data: data,
    });
};

/**
 * @public
 * @description To create internal server response
 */
export const internalServerError = (res, message, error) => {
    console.log(`Error occure while ${message} Error -->`, error);
    //Server Error Response
    return res.status(constants.INTERNAL_SERVER_ERROR.code).json({
        error: true,
        code: constants.INTERNAL_SERVER_ERROR.code,
        message: constants.INTERNAL_SERVER_ERROR.message,
        data: {},
    });
};

/**
 * @public
 * @description To capitalize all string
 */
export const capitalizeAll = (s) => {
    if (typeof s !== "string") return "";

    let strings = s.split(" ");
    let str = "";

    //
    for (let string of strings) {
        str += string.toUpperCase();
        //
        if (string !== strings[strings.length - 1]) {
            str += " ";
        }
    }
    return str;
};

/**
 * @public
 * @description To capitalize first char of any string
 */
export const capitalizeFirst = (s) => {
    if (typeof s !== "string") return "";

    let strings = s.split(" ");
    let str = "";
    //
    for (let string of strings) {
        str += string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
        //
        if (string !== strings[strings.length - 1]) {
            str += " ";
        }
    }
    return str;
};


/**
 * @public
 * @description To get percentage or two value
 */
export const getPercentage = (num, total) => {
    let percentage = 0;
    //
    if (total > 0) {
        percentage = (num / total) * 100;
    }

    return parseFloat(percentage.toFixed(2));
};


/**
 * @public
 * @description To mobile validation
 */
export const mobileValidation = (mobile) => {
    let rgx = /[^0-9]/g;
    let num = mobile.replace(".", "");
    let isValid = false;

    if (!isNaN(num) && !rgx.test(num) && num !== "") {
        isValid = true;
    }

    return isValid;
};

/**
 * @public
 * @description To mobile validation
 */
export const replaceSpecificWord = (str) => {
    let rgx = /[(){},.;/!-?<>%^0-9]/g;
    let result = "";

    if (!str) return result;

    result = str.replace(rgx, "");

    if (!result) return "";

    result = result.replace(/\r\n/g, "");

    for (let i = 0; i < constants.REPLACE_WORDS.length; i++) {
        //
        result = result.replace(constants.REPLACE_WORDS[i], "");
    }

    return result;
};

/**
 * @public
 * @description To parse point for page
 */
global.pointParse = (num) => {
    //
    if (num > parseInt(num)) return parseInt(num) + 1;

    return num;
};

/**
 * @public
 * @description To check white space
 */
export const checkWhiteSpace = (str) => {
    let isValid = /\s/;
    if (!str) return false;
    return !isValid.test(str)
}

/**
 * @public
 * @description  await manually built function
 */

export const wait = async (newDb, query) => {
    return new Promise((resolve, reject) => {
        newDb.query(query, function (err, data) {
            if (err) {
                reject(err);
            }
            resolve(data);
        });
    }
    )
}
/**
 * @public
 * @description  manually function that will convert minutes into hours 
 */
export const timeDateConverter = (time) => {
    let newminute = 0;
    let newTime = time / 60;
    let splitHour = newTime.toString().split(".")[0]; ///before
    let splitMin = newTime.toString().split(".")[1]//after
    console.log(splitMin);
    if (!splitMin) {
        newminute = "00"
    } else {
        // console.log("split minute is", splitMin)
        let con_minute = (("." + splitMin) * 60)
        newminute = (Math.round(con_minute))
        // console.log("new minute", newminute)
    }
    let convertedTime = splitHour + ":" + newminute
    // console.log("new converted time ", convertedTime)
    return convertedTime
}


/**
 * Error msg replace
 */
export const errorMsgReplace = (msg) => {
    if (!msg) return null;

    if (msg === "email must be a valid email") {
        return "Please enter a valid email address"
    } else if (msg === "error_message must be a string") {
        return "Please check require answer"
    } else if (msg === "question is not allowed to be empty") {
        return "Please enter question text"
    }

    return msg;
}

    /**
     * @description To replace content place holder
 * @param {*} date
 */
    