import React from 'react';
import CIcon from '@coreui/icons-react';
import hr from './panel/hr';
import hr_manager from './panel/hrManager';

import admin from './panel/admin';
import finance from './panel/finance';
import onboarding_employee from './panel/onboarding_employee';
import employee from './panel/employee';
import manager from './panel/manager';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import navArr from './panel/sidebarMenu';
let panel_role = getLocalDataAsObject('user')
  || [];
let getPanel_role = panel_role && panel_role[0] && panel_role[0].panel_role;

// console.log("panel_role(((((((((((((((((((((((((", getPanel_role)

let role = getPanel_role;
// let role = "onboarding_employee"

let _nav;
// switch(role) {
// case "admin":
//      _nav = admin
// break;
// case "finance":
//      _nav = finance
// break;
// case "recruiter":
//       _nav = hr
// break;
// case "hr_manager":
//       _nav = hr_manager
// break;
// case "onboarding_employee":
//       _nav = onboarding_employee
// break;
// case "employee":
//       _nav = employee
// break;
// case "manager":
//       _nav = employee
// break;
// }

_nav = navArr;

export default _nav;
