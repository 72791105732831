

import React,{ useState, useEffect }from 'react'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'

const EditPaySchedule = () => {
    const api = new API()
    const [payPeriodStartDate, setPayPeriodStartDate] = useState()
    const [payPeriodEndDate, setPayPeriodEndDate] = useState()
    const [payDate, setPayDate] = useState()
    const [payDay, setPayDay] = useState()
    const [noOfWorkingDays, setNoOfWorkingDays] = useState()
    const[payScheduleType,setPayScheduleType]=useState()
    const[active_organisation_working_day,setActive_organisation_working_day]=useState(false)
    const[active_organisation_pay_date,setActive_organisation_pay_date]=useState(false)

    const addPaySchedule = async () => {
        let data = {
            "pay_schedule_type":payScheduleType,
            "pay_schedule_type_formatted": "Every month (Monthly)",
            "pay_day": payDay,
            "pay_day_formatted": "Last day of every month",
            "pay_period_start_date": payPeriodStartDate,
            "pay_period_start_date_formatted":payPeriodStartDate,
            "pay_period_end_date_formatted": payPeriodEndDate,
            "pay_period_formatted": "Nov-2022",
            "pay_period_end_date": payPeriodEndDate,
            "pay_date_formatted": "07/12/2022",
            "pay_date": payDate,
            "working_days": ["mon", "tue", "wed", "thu", "fri", "sat"],
            "no_of_working_days": noOfWorkingDays
        }
  
       
        let result = api.post(config.addPaySchedule,data)
        if (result && result.code === 200) {
            alert(result?.message)
        } else {
            alert(result?.message)
        }


    }
    const handlePayScheduleType=(e)=>{
        const{value}=e.target
        if(value=="actual_days"){
            setPayScheduleType("monthly")
            setNoOfWorkingDays('')
            setActive_organisation_working_day(false)

        }else{
            setPayScheduleType("monthly")
            setNoOfWorkingDays('')
            setActive_organisation_working_day(true)

        }

    }
    const handlePayDate=(e)=>{

        const{value}=e.target
        if(value=="last_day"){
           setPayDay("last day")
            
            setActive_organisation_pay_date(false)

        }else{
            setPayDay("")
            
            setActive_organisation_pay_date(true)

        }
    }
  return (
    <div>
    <div className='row'>
        <div className='col-md-12'>
            <h3 style={{ fontFamily: "sans-serif", fontSize: "24px", lineHeight: "29px" }}>PAY SCHEDULE</h3>
        </div>
    </div>

    <hr />
    <h5 style={{ fontWeight: "bold" }}>Select your working days.</h5>

    <div className='col-xs-12 form-group'>
        <div className='checkbox'>
            <div className='segmented-checkbox selected ember-view'>
                <p style={{ fontSize: "13px" }}>SUN</p>
            </div>
            <div className='segmented-checkbox selected ember-view'>
                <p style={{ fontSize: "13px" }}>MON</p>
            </div>
            <div className='segmented-checkbox selected ember-view'>
                <p style={{ fontSize: "13px" }}>TUE</p>
            </div>
            <div className='segmented-checkbox selected ember-view'>
                <p style={{ fontSize: "13px" }}>WED</p>
            </div>
            <div className='segmented-checkbox selected ember-view'>
                <p style={{ fontSize: "13px" }}>THR</p>
            </div>
            <div className='segmented-checkbox selected ember-view'>
                <p style={{ fontSize: "13px" }}>FRI</p>
            </div>
            <div className='segmented-checkbox selected ember-view'>
                <p style={{ fontSize: "13px" }}>SAT</p>
            </div>
        </div>
    </div>
    <div className='row ml-2'>
        <lable className="col-xs-12 pay-schedule-sub-header">
            Calculate monthly salary based on
        </lable>

    </div>
    <div className='row form-check ml-2 m-3' >     <input className="form-check-input" type="radio" name="no_of_working_days" value="actual_days" id="exampleRadios1" onChange={(e)=>handlePayScheduleType(e)} />
        <label className="form-check-label" for="exampleRadios1">
            Actual days in a month
        </label>


    </div>
    <div className='row form-check ml-2 m-3' >     <input className="form-check-input" type="radio" name="no_of_working_days" id="exampleRadios1" value="no_of_working_days" onChange={(e)=>handlePayScheduleType(e)} />
        <label className="form-check-label" for="exampleRadios1">
            Organisation working days <input type="select" disabled={active_organisation_working_day===true?false:true} value={noOfWorkingDays} onChange={(e)=>setNoOfWorkingDays(e.target.value)}></input> days per month
        </label>


    </div>
    <div className='row ml-2'>
        <lable className="col-xs-12 pay-schedule-sub-header">
            Pay your employees on
        </lable>

    </div>
    <div className='row form-check ml-2 m-3' >     <input className="form-check-input" type="radio" name="pay_date" id="exampleRadios1" value="last_day" onChange={(e)=>handlePayDate(e)} />
        <label className="form-check-label" for="exampleRadios1">
            the last working day of every month
        </label>


    </div>
    <div className='row form-check ml-2 m-3' >     <input className="form-check-input" type="radio" name="pay_date" id="exampleRadios1" value="not_last_day" onChange={(e)=>handlePayDate(e)} />
        <label className="form-check-label" for="exampleRadios1">
            day 
            <select type="select" className=''disabled={active_organisation_pay_date===true?false:true} onChange={(e)=>setPayDay(e.target.value)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
            </select> of every month
        </label>


    </div>
    <div className='row'>
        <div className='col-md-12'>
            <h5>Select First  PayRoll </h5>
        </div>
    </div>
    <div className='row'>
        <div className='col-md-3'>
            <label>From</label>
            <input type="date" className='form form-control' value={payPeriodStartDate} onChange={(e) => setPayPeriodStartDate(e.target.value)}></input>
        </div>
        <div className='col-md-3'>
            <label>To</label>
            <input type="date" className='form form-control' value={payPeriodEndDate} onChange={(e) => setPayPeriodEndDate(e.target.value)}></input>
        </div>
    </div>
    <div className='row'>
        <div className='col-md-12'>
            <label>Pay Date</label>
        </div>
    </div>
    <div className='row'>
        <div className='col-md-3'>
            <input className='form form-control' value={payDate} onChange={(e) => setPayDate(e.target.value)} type="date">

            </input>
        </div>
    </div>
    <hr />
    <button className='btn btn-primary m-4' onClick={addPaySchedule}>Save</button>



    </div>
  )
}

export default EditPaySchedule