import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
  CCollapse,
} from '@coreui/react';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ViewSelectedHrShortList from './viewSelectedShortlist';
import { StatusDropdownMenu } from 'src/subcomponents';

import Moment from 'react-moment';
import moment from 'moment';
import { isPermission } from 'src/utils/common';

const HrShortList = props => {
  const {} = props;
  let api = new API();

  const [mrfRecord, setMrfRecord] = useState();
  const [isSelectedView, setIsSelectedView] = useState(false);
  const [MRFData, setMRFData] = useState();
  const [details, setDetails] = useState([]);
  const [dropdownFilterList, setDropdownFilterList] = useState([
    { value: 'all', label: 'All MRF' },
    { value: 'open', label: 'Open MRF' },
    { value: 'close', label: 'Close MRF' },
    { value: 'hold', label: 'Hold MRF' },
  ]);
  const [selectedDropdownFilter, setSelectedDropdownFilter] = useState('all');
  const [selectedDropdownFilterType, setSelectedDropdownFilterType] = useState(
    '',
  );

  useEffect(() => {
    getMrfRecord();
  }, []);

  const handleOnDropdownItemClick = e => {
    const status = e.currentTarget.getAttribute('data-status');
    const type = e.currentTarget.getAttribute('data-type');
    setSelectedDropdownFilter(status);
    setSelectedDropdownFilterType(type);
  };

  useEffect(() => {
    getMrfRecord(selectedDropdownFilterType);
  }, [selectedDropdownFilter]);
  const getMrfRecord = async () => {
    let data = {
      status: selectedDropdownFilter,
    };
    let result = await api.get(config.showAssignedMrfForRecruiter, true, data);
    if (result && result.code == 200) {
      setMrfRecord(result.data);
    } else {
    }
  };

  const view_selected = mrf => {
    setMRFData(mrf);
    setIsSelectedView(!isSelectedView);
  };

  const fields = [
    { label: 'MRF ID', key: 'mrf_id', _style: { width: '5%' } },
    { label: 'Applying For', key: 'mrf_type', _style: { width: '10%' } },
    { label: "Department", key: "department", _style: { width: "10%" }, },
    { label: "Designation", key: "designation", _style: { width: "10%" }, },
    { label: 'Location', key: 'location', _style: { width: '10%' } },
    { label: 'MRF Assigned At', key: 'assigned_at', _style: { width: '10%' } },
    { label: 'MRF Status', key: 'status', _style: { width: '5%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
  ];
  const toggleDetails = index => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  const getBadge = status => {
    switch (status) {
      case 'Close':
        return 'danger';
      case 'Hold':
        return 'warning';
      default:
        return 'info';
    }
  };
  return (
    <div className="">
      {isSelectedView == false && (
        <div className="container">
          <h3 className="text-uppercase text-center mt-3 mb-4">
            {' '}
            <u>List Of Shortlist Requisition</u>
          </h3>
          <CCard>
            <CCardBody>
              <div className="col-md-12 mb-3 text-right">
                <StatusDropdownMenu
                  onClick={handleOnDropdownItemClick}
                  dropdownItems={dropdownFilterList}
                  toggleBtnColor="primary"
                  toggleBtnText={selectedDropdownFilter}
                  activeTab="hr_shorlist"
                  isShowCounts={true}
                  totalRecords={mrfRecord?.length || 0}
                />
              </div>
              <CDataTable
                items={mrfRecord}
                fields={fields}
                tableFilter={{label: 'Filter:', placeholder: 'Search'}}
                itemsPerPageSelect
                itemsPerPage={10}
                hover
                sorter
                pagination
                scopedSlots={{
                  status: item => (
                    <td>
                      <CBadge color={getBadge(item.active_status)}>
                        {item.active_status}
                      </CBadge>
                    </td>
                  ),
                  show_details: (item, index) => {
                    return (
                      <td className="py-2">
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            toggleDetails(index);
                          }}
                        >
                          {details.includes(index) ? 'Hide' : 'Show'}
                        </CButton>
                      </td>
                    );
                  },
                  details: (item, index) => {
                    return (
                      // <>
                      // {isPermission('view_candidate') &&
                      <CCollapse show={details.includes(index)}>
                        <CCardBody>
                        <p className="text-muted">
                          <b style={{color:"black"}}>MRF Raised By :</b> <b style={{color:"darkblue"}}>{item.created_by_name}</b>
                      </p>
                          <CButton
                            onClick={() => view_selected(item)}
                            size="sm"
                            color="primary"
                            className="ml-1"
                          >
                            View Candidate
                          </CButton>
                        </CCardBody>
                      </CCollapse>
                      // }</>
                    );
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </div>
      )}
      {isSelectedView && (
        <div className="container">
          <ViewSelectedHrShortList
            MRFData={MRFData}
            view_selected={view_selected}
          />
        </div>
      )}
    </div>
  );
};
export default HrShortList;
