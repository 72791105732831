import React from 'react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';
import { CDataTable } from '@coreui/react';

const CoffAppliedList = props => {
  const { data, type, renderDataStatus } = props;
  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: false,
      filter: true,
    },
    {
      label: 'Comp Off Date',
      key: 'leave_credited_for',
      sorter: false,
      filter: true,
    },
    {
      label: 'Comp Off Status',
      key: 'total_leave',
      sorter: false,
      filter: false,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Created Date',
      key: 'created_at',
      sorter: false,
      filter: true,
    },
  ];

  return (
    <CDataTable
    items={data}
    fields={fields}
    tableFilter={{label: 'Filter:', placeholder: 'Search'}}
    itemsPerPageSelect
    itemsPerPage={5}
    hover
    sorter
    pagination
    scopedSlots={{
      status: item => <td>{renderDataStatus(item, type)}</td>,
      leave_credited_for: item => (
        <td>{convertDate(item.leave_credited_for, 'DD-MM-YYYY')}</td>
      ),
      created_at: item => (
        <td>{convertDate(item.created_at, 'DD-MM-YYYY')}</td>
      ),
      s_no: (item, index) => (
        <td>
          <b>{index + 1}</b>
        </td>
      ),
    }}
  />
  );
};

export default CoffAppliedList;
