import React, { Component } from 'react';
//import Urlify from '../../../../componets/Urlify/Urlify';
//import { ReplyContainer } from '../../index';

const FileType = props => {
  const {
    message,
    isHisMsg,
    fileSize,
    fileExt,
    toggleFilePreviewModal,
    previewSupport,
  } = props;

  const getClickFunc = () => {
    const { supported, mime } = previewSupport;
    const fileUrl = message?.payload?.url || '';
    if (supported) {
      const suportedFile = {
        filename: message?.payload?.name || '',
        payload: fileUrl || '',
        mime: mime,
      };

      toggleFilePreviewModal(suportedFile);
    } else {
      // handleDownload(downloadData);
      window.open(fileUrl, '_blank');
    }
  };

  const renderMessage = () => {
    //const fileUrl = message?.payload?.url || '';
    return (
      <div onClick={getClickFunc}>
        {/* <a
          href={fileUrl}
          target="_blank"
          rel="noreferrer"
          onClick={getClickFunc}
        > */}
        <div className="d-flex align-items-start">
          <figure className="m-0">
            <div className="extensiondiv">{fileExt}</div>
          </figure>
          <div
            className="ps-1"
            style={{
              color: isHisMsg ? 'white' : 'black',
              fontSize: '10px',
            }}
          >
            <span className="d-flex">{message?.payload?.name}</span>
            <p className="fw-normal m-0">{fileSize}</p>
          </div>
        </div>
        {/* </a> */}
      </div>
    );
  };

  return <div>{renderMessage()}</div>;
};

export default FileType;
