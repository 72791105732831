
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';

import ViewSelectedCondidateDetails from '../viewSelectedCondidateData'
import AssignedRequisition from '../viewAssignedRequisition';
import ViewSelectedAssignedRequisition from '../viewSelectedAssignedRequisition';
import ViewSelectedReceivedRequisition from './viewSelectedReceivedRequisition';

const ModalPopupReceivedRequisition = (props) => {
    const { isModalOpen, message, isViewModalOpen, approvedRequisition, mrfReceivedforHrManager,receivedRequisition
    } = props
    
    const [popopFor, setPopupFor] = useState("")
    // const [isModalOpen, isModalOpen] = useState(false)
    useEffect(() => {

    }, [])
    
  const fun=()=>{
    isViewModalOpen()
}
    // const isModalOpen = () => {

    // }

    return (
        <div className="">

            <CModal
                show={isModalOpen}
                onClose={() => isViewModalOpen()}
                size="xl"
            >

                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>

                    <ViewSelectedReceivedRequisition 
                    receivedRequisition={approvedRequisition}
                    mrfReceivedforHrManager={mrfReceivedforHrManager}
                    fun={fun}
                    isViewModalOpen={isViewModalOpen}/>



                </CModalBody>
                <CModalFooter>
                    <CButton data-toggle="tooltip" data-placement="top" title="Close" color="danger" onClick={() => fun()}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}

                </CModalFooter>
            </CModal>




        </div>

    )
}
export default ModalPopupReceivedRequisition







