import React, { useState, useEffect } from 'react';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { data } from 'jquery';
const HoldMrfModal = props => {
  const {
    isHoldMRFModalOpen,
    sendMrfData,
    holdMRF,
    bodyData,
    reloadTaskBox,
  } = props;
  const [data, setData] = useState();
  const [radioStatusData, setRadioStatusData] = useState('');
  const [otherStatusData, setOtherStatusData] = useState('');
  const [openOtherInput, setOpenOtherInput] = useState(false);
  let api = new API();
  const submitHoldMrf = async () => {
    if (radioStatusData && radioStatusData == 36) {
      let data = {
        mrf_id: sendMrfData.mrf_id,
        applying_for: sendMrfData.applying_for,
        hold_status: radioStatusData,
        hold_remark: otherStatusData,
      };
      let result = await api.post(config.holdMRF, data);

      if (result && result.code == 200) {
        reloadTaskBox();
        alert(result.message);
        holdMRF();
      }
      if (result && result.code == 400) {
        alert(result.message);
        holdMRF();
      }
    } else {
      let data = {
        mrf_id: sendMrfData.mrf_id,
        applying_for: sendMrfData.applying_for,
        hold_status: radioStatusData,
      };
      let result = await api.post(config.holdMRF, data);

      if (result && result.code == 200) {
        alert(result.message);
        holdMRF();
      }
      if (result && result.code == 400) {
        alert(result.message);
        holdMRF();
      }
    }
  };

  const handelClickData = () => {};

  const handelClickInputData = e => {
    setRadioStatusData(e.target.value);
    checkedOtherData(e.target.value);
  };
  const checkedOtherData = (e) => {
    if (e && e == '36') {
      setOpenOtherInput(true);
    }else{
      setOpenOtherInput(false);
    }
  };

  const setdataForGetStatus = () => {
    let newBodyData = bodyData.slice(32);
    let test1 = newBodyData.filter((data, index) => {
      if(index<5){
          return data;
      }
  })
    setData(test1);

    // data.push(newBodyData)
  };

  useEffect(() => {
    setdataForGetStatus();
  }, [bodyData]);

  return (
    <div className="" style={{ fontSize: '80%' }}>
      <CModal
        show={isHoldMRFModalOpen}
        onClose={() => holdMRF()}
        size="sm"
        style={{ paddingTop: '9%', border: 'none'}}
      >
        <CModalHeader closeButton>
          <CModalTitle>Hold Status</CModalTitle>
        </CModalHeader>
        <CModalBody style={{ textAlign: 'left'}}>
          {data &&
            data.length > 0 &&
            data.map((data, index) => (
              <>
                <input
                  value={data.status_number}
                  name={data.length + 1}
                  onClick={e => handelClickInputData(e)}
                  type="radio"
                />
                &nbsp;
                <label>{data.status_name}</label>
                <br />
              </>
            ))}

          {openOtherInput && openOtherInput == true && (
            <input
              type="text"
              onChange={e => setOtherStatusData(e.target.value)}
            />
          )}
        </CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={() => holdMRF()}>
            <i className="fa fa-times" aria-hidden="true"></i>{' '}
          </CButton>{' '}
          <CButton color="danger" onClick={() => submitHoldMrf()}>
            <i className="fa fa-paper-plane" aria-hidden="true"></i>{' '}
          </CButton>{' '}
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default HoldMrfModal;
